<template>
  <div class="settings settings-campaign-config grid-wrapper">
    <!--
    <p class="title" style="display: flex;">
      {{ $t('Campaigns') }}
      <a class="button" @click.prevent="OpenModal('create')" href="" style="margin-left: auto;">{{ $t('Create campaign') }}</a>
    </p>
    -->
    <div class="grid-info">
      <span class="grid-heading">
        <h1>{{ $t('Campaigns') }}</h1>
        <span class="entries">{{ NumberFormat(entries) }} {{ $t(`entr${entries == 1 ? 'y' : 'ies'}`) }}</span>
      </span>
      <div class="grid-actions">
        <a class="button" style="margin-left: auto;" @click.prevent="OpenModal('create')" href="">{{ $t('Create') }}</a>
      </div>
      <div class="grid-search">
        <div class="search-fields" style="align-items: flex-end; max-width: 100%;">
        <input type="text" v-model="search.query" @keyup.enter="FilterSearch(), $event.target.blur()" :placeholder="$t('Search by title')" style="width: 50%;">
          <div class="size-select" style="width: 100%; margin-top: -100%; margin-left: 30px; display: flex;">
            <div class="grid-state" style="max-width: 225px; min-width: 225px;">
              <p class="grid-heading">{{ $t('Language') }}</p>
              <v-select name="campaign-language-select" @input="FilterByLanguage" :value="languages.find(option => option.code == search.language)" :options="Alphabetize(languages.map(option => {option.label = LanguageName(option.language); return option}), 'label')">
                <template v-slot:selected-option="option">
                  <div class="truncate">
                    <Flag :code="option.country" size="small" type="language" :title="CountryName(option.country)" />
                    <span>{{ option.label }}</span>
                  </div>
                </template>
                <template slot="option" slot-scope="option">
                  <div class="truncate">
                    <Flag :code="option.country" size="small" type="language" :title="CountryName(option.country)" />
                    <span>{{ option.label }}</span>
                  </div>
                </template>
              </v-select>
            </div>
            <div class="grid-state" style="max-width: 160px; min-width: 160px; padding: 4px 0 0; margin-left: 30px;">
              <p class="grid-heading">{{ $t('Page size') }}</p>
              <v-select name="page-size-select" @input="FilterBySize" v-model="search.size" :options="sizes" :searchable="false" :clearable="false" />
            </div>
            <div class="grid-actions" style="padding: 4px 0; margin-left: auto;">
              <!-- <button v-if="!sort.edit" class="button" style="margin-left: auto;" :disabled="!search.language" @click="ChangeOrder">{{ $t('Change order') }}</button> -->
              <button v-if="sort.edit" class="button green" style="margin-left: auto;" @click="SaveOrder">{{ $t('Save order') }}</button>
              <button v-if="sort.edit" class="button red" @click="ResetOrder">{{ $t('Reset order') }}</button>
            </div>
          </div>
        </div>
      </div>
      <!--
      <div class="grid-search-filter">
        <div class="grid-state" style="max-width: 225px;">
          <p class="grid-heading">{{ $t('Language') }}</p>
          <v-select name="campaign-language-select" @input="FilterByLanguage" :value="languages.find(option => option.code == search.language)" :options="languages.map(option => {option.label = LanguageName(option.language); return option})">
            <template v-slot:selected-option="option">
              <div class="truncate">
                <Flag :code="option.country" size="small" />
                <span>{{ option.label }}</span>
              </div>
            </template>
            <template slot="option" slot-scope="option">
              <div class="truncate">
                <Flag :code="option.country" size="small" />
                <span>{{ option.label }}</span>
              </div>
            </template>
          </v-select>
        </div>
        <div class="grid-state">
          <p class="grid-heading">{{ $t('Page size') }}</p>
          <v-select name="page-size-select" @input="FilterBySize" v-model="search.size" :options="sizes" :clearable="false" />
        </div>
        <div class="grid-actions" style="padding: 4px 0;">
          <button v-if="!sort.edit" class="button" style="margin-left: auto;" :disabled="!search.language" @click="ChangeOrder">{{ $t('Change order') }}</button>
          <button v-if="sort.edit" class="button green" style="margin-left: auto;" @click="SaveOrder">{{ $t('Save order') }}</button>
          <button v-if="sort.edit" class="button red" @click="ResetOrder">{{ $t('Reset order') }}</button>
        </div>
      </div>
      -->
    </div>
    <div>
      <table class="list table odd-even">
        <tbody>
          <tr :data-id="item.id" :class="['campaign-list__row', {clickable: !sort.edit, sortable: sort.edit, expired: item.expired}]" @mousedown="ClickOpen" @mousemove="ClickOpen" @mouseup="ClickOpen" :key="item.id" v-for="item in campaigns.items">
            <td v-if="!sort.edit" class="label" style="display: table-cell;" @click="ToggleCheckbox">
              <div class="v-wrapper checkbox">
                <label class="v-checkbox-label">
                  <input class="v-checkbox" type="checkbox" :data-id="item.id" @input="ToggleCheckbox">
                  <span class="v-checkbox-toggle" />
                </label>
              </div>
            </td>
            <td v-if="sort.edit" style="text-align: left;">
              <div style="padding-left: 5px;">{{ item.index || 0 }}</div>
            </td>
            <td>
              <div>{{ item.id }}</div>
            </td>
            <td>
              <div class="checkbox-toggle mini">
                <input type="checkbox" :checked="item.active" @input="Toggle(item, $event)">
                <span class="toggle"></span>
              </div>
            </td>
            <td>
              <div :style="[{'text-decoration': item.expired && 'line-through'}]">{{ item.title || '-' }}</div>
            </td>
            <td>
              <div>{{ String(DateFormat(item.from_date) || '-').replace(/\:\d+$/, '') }}</div>
            </td>
            <td>
              <div>{{ String(DateFormat(item.to_date) || '-').replace(/\:\d+$/, '') }}</div>
            </td>
            <td>
              <div class="center">
                <Flag v-if="item.language" :code="item.language" size="small" type="language" :title="LanguageName(item.language.split('_')[0])" />
              </div>
            </td>
            <td>
              <div class="truncate" style="max-width: 100px;" v-html="Hyperlink({href: item.link, target: '_blank', text: item.link ? LinkText(item.link) : '-', title: item.link})" />
            </td>
            <td>
              <div class="center" style="justify-content: flex-start;">
                <span :class="['indicator-icon desktop', {active: item.has_img_base}]" :title="$t('Desktop')" />
                <span :class="['indicator-icon mobile', {active: item.has_img_mobil}]" :title="$t('Mobile')" />
              </div>
            </td>
            <td v-if="!sort.edit" class="actions" @mouseenter="SetBackground" @mouseleave="SetBackground">
              <a class="icon dots" @click.prevent="" href="">
                <ul class="item-actions">
                  <li><a @click.prevent="OpenModal('edit', item)" href="">{{ $t('Edit') }}</a></li>
                  <li><a @click.prevent="Delete(item)" href="" class="error">{{ $t('Delete') }}</a></li>
                </ul>
              </a>
            </td>
            <td v-if="sort.edit" class="actions sort drag-handle">
              <a class="icon bars" />
            </td>
          </tr>
        </tbody>
        <thead>
          <tr>
            <th v-if="!sort.edit" class="label" style="display: table-cell;">
              <div class="v-wrapper checkbox">
                <label class="v-checkbox-label">
                  <input class="v-checkbox" type="checkbox" ref="select_all" @input="ToggleAllCheckboxes">
                  <span class="v-checkbox-toggle toggle-all" />
                </label>
              </div>
            </th>
            <th v-if="sort.edit" style="height: 34px; width: 49px;">[#]</th>
            <th>{{ $t('ID') }}</th>
            <th>{{ $t('Active') }}</th>
            <th>{{ $t('Title') }}</th>
            <th>{{ $t('Start date') }}</th>
            <th>{{ $t('End date') }}</th>
            <th>{{ $t('Language') }}</th>
            <th>{{ $t('Link') }}</th>
            <th>{{ $t('Image') }}</th>
            <th class="edit" />
            <!-- <th v-if="sort.edit" class="sort" /> -->
          </tr>
        </thead>
      </table>
      <div class="grid-pagination shadow sticky bottom" :style="{marginTop: campaigns.items.length && '2px'}">
        <div class="page-navigation">
          <div class="page-turn prev disabled" @click="PageController(false)"></div>
          <div class="page-number">
            <label class="page-number-current">
              <input type="number" min="1" :max="page.last" :value="page.current" @blur="PageNavigator" @keydown="PageNavigator">
              <span class="placeholder">{{NumberFormat(page.current)}}</span>
            </label>
            <span class="page-number-separator">/</span>
            <div class="page-number-last">{{NumberFormat(page.last)}}</div>
          </div>
          <div class="page-turn next" :class="{disabled: page.last == 1}" @click="PageController(true)"></div>
        </div>
      </div>
    </div>

    <Modal modal="modal" :value="modal.open" :title="$t(modal.mode == 'create' ? 'New campaign' : 'Campaign') + (modal.mode == 'edit' ? ' #' + campaign.id : '')">
      <div id="campaign" v-if="modal.open">
        <div class="modal-header"></div>
        <div class="modal-content scrollable">
          <div class="modal-tabs">
            <div class="modal-tabs__body" style="overflow: hidden auto;">
              <div class="modal-tabs__body-content padding">
                <form @submit.prevent style="padding-bottom: 25px;">
                  <button ref="submit" type="submit" hidden />
                  <div v-for="(group, index) in form.fields" :key="index" class="flex-group">
                    <div class="flex-row">
                      <div v-for="(field, index) in group" :key="index" :class="['flex-column', {flex1: field.fill_width}]" :style="[field.name == 'email' ? 'flex: 1; max-width: 627px;' : '', {...{maxWidth: field.type == 'checkbox' && 'max-content'}, ...field.style}]" v-show="!field.hidden">
                        <div :class="['label', {required: field.required}]">
                          <span class="label-text">
                            {{ $t(field.label || Capitalize(field.name).replace(/_/g, ' ')) }}
                          </span>
                          <v-select v-if="field.type == 'select'" :name="field.name" :required="field.required" :disabled="field.disabled" :value="field.value" :options="/language/.test(field.name) ? field.options.map(o => {o.label = LanguageName(o.language); return o;}) : field.options" :title="field.title" :clearable="field.clearable" v-on="field.input ? {input: field.input} : {}">
                            <template #search="{attributes, events}">
                              <input class="vs__search" v-bind="attributes" v-on="events" :required="field.required && !form.data[field.name]">
                            </template>
                            <template v-slot:selected-option="option">
                              <div class="truncate" :title="option.title">
                                <Flag v-if="/language/.test(field.name)" :code="option.country" size="small" />
                                <span>{{ option.label }}</span>
                              </div>
                            </template>
                            <template slot="option" slot-scope="option">
                              <div class="truncate" :title="option.title">
                                <Flag v-if="/language/.test(field.name)" :code="option.country" size="small" />
                                <span>{{ option.label }}</span>
                              </div>
                            </template>
                          </v-select>
                          <div v-else-if="field.type == 'datetime'" class="v-wrapper-date time">
                            <VueCtkDateTimePicker :name="field.name" :required="field.required" :value="form.data[field.name]" label="" hint="" :locale="$i18n.locale.split('_')[0].replace(/no/, 'nb')" format="YYYY-MM-DD HH:mm:ss" :first-day-of-week="1" input-size="sm" :range="false" :no-shortcuts="true" :no-button="true" :auto-close="false" @input="field.input ? field.input($event) : {}" />
                          </div>
                          <div v-else-if="field.type == 'checkbox'" class="checkbox-toggle">
                            <input :type="field.type" :name="field.name" :disabled="field.disabled" :checked="field.value" @input="field.input ? field.input($event) : {}">
                            <span class="toggle" />
                          </div>
                          <div v-else-if="field.type == 'file'" class="v-wrapper-file image">
                            <input :type="field.type" :name="field.name" :required="field.required" :accept="field.accept" :value="field.value" :title="field.title" @input="field.input ? field.input($event) : {}">
                            <div :class="['v-image', /mobil/.test(field.name) ? 'mobile' : 'desktop']" :style="[{'--src': field.image && `url(${field.image})`}]">
                              <div class="clear" @click="ClearImage(field)" />
                            </div>
                          </div>
                          <input v-else class="v-input" :type="field.type" :name="field.name" :required="field.required" :readonly="field.readonly" :pattern="field.pattern" :min="field.min" :max="field.max" :minlength="field.minlength" :step="field.step" :value="field.value" v-model="form.data[field.name]" :title="field.title" @keydown.enter="$event.target.blur()" @input="field.input ? field.input($event) : {}" @blur="field.blur ? field.blur($event) : {}" :placeholder="/link/.test(field.name) && field.placeholder">
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div class="button-container actions">
            <div class="label" style="flex-direction: row; align-items: center; margin-right: auto;">
              <div class="checkbox-toggle">
                <input type="checkbox" v-model="form.data.active">
                <span class="toggle" />
              </div>
              <span style="margin-left: 10px;">{{ $t('Active') }}</span>
            </div>
            <button class="button green" @click.prevent="Update" v-if="modal.mode == 'edit'">{{ $t('Save') }}</button>
            <button class="button red" @click.prevent="Delete" v-if="modal.mode == 'edit'">{{ $t('Delete') }}</button>
            <button class="button green" @click.prevent="Create" v-if="modal.mode == 'create'">{{ $t('Create') }}</button>
          </div>
        </div>
      </div>
    </Modal>

    <div class="loading" v-if="loading">
      <div class="loading-element">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>

  </div>
</template>

<script>
  import { TableElementsBehaviour } from '@/mixins/TableElementsBehaviour';
  import { Config }     from '@/helpers/Config';
  import { Tool }       from '@/helpers/Tool';
  import { BPA }        from '@/helpers/BPA';
  import Modal          from '@/components/snippets/Modal';
  import Flag           from '@/components/snippets/Flag';

  export default {
    name: 'SettingsCampaignConfigView', 
    mixins: [
      TableElementsBehaviour, 
      Config, 
      Tool, 
      BPA
    ],
    components: {
      Modal,
      Flag
    },
    data() {
      return {
        loading: true,
        entries: 0,
        cached: {},
        clickables: [],
        search: {
          query: '',
          initial: null,
          language: '',
          size: 20,
          offset: 1
        },
        page: {
          current: 1,
          number: 1,
          last: 1
        },
        sizes: [10, 20, 30, 50, 100],
        campaign: {},
        campaigns: {
          items: []
        },
        languages: [],
        homepages: {},
        selected: [],
        selection: {
          event: null,
          start: null,
          end: null
        },
        sort: {
          edit: false,
          reset: [],
          order: [],
          regex: /^\{\[(\d+)\]\}/
        },
        ctrl_key: false,
        shift_key: false,
        event: ['keydown', 'keyup'],
        template: [
          {
            title: '',
            from_date: '',
            to_date: ''
          },
          {
            language: '',
            link: ''
          },
          {
            img_base: '',
            img_mobil: ''
          }
        ],
        form: {
          fields: [],
          data: {}
        },
        modal: {
          mode: 'create',
          open: false
        }
      }
    },
    created() {},
    async mounted() {
      this.$eventHub.$on('CloseModal', () => {
        this.main.view.classList.remove('no-scroll');
        this.form = {fields: [], data: {}};
        this.modal.open = false;
        this.campaign = {};
        this.cached.campaign = {};
        BPA.cache.session({name: this.$options.name, set: {campaign: {}}});
        for (let i = 0; i < this.clickables.length; i++) {
          if (this.clickables[i].classList.contains('selected')) {
            this.clickables[i].classList.remove('selected');
          }
        }
      });

      this.cached = BPA.cache.local({name: this.$options.name, get: ['page', 'search']});
      for (let key of Object.keys(this.cached)) this[key] = {...this[key], ...this.cached[key]};
      this.cached = {...this.cached, ...BPA.cache.session({name: this.$options.name, get: 'campaign'})};

      await this.GetLanguageOptions();
      await this.SearchCampaigns();

      this.MountKeyPressListener();
      this.mousedown = () => {
        if (this.shift_key) {
          this.selection.event = null;
          if (document.getSelection) {
            this.selection.event = window.getSelection();
          } else if (document.selection) {
            this.selection.event = document.selection;
          }
          if (!this.selection.event) return;
          let focusNode = this.selection.event.focusNode;
          if (!focusNode || !/toggle/.test(focusNode.className)) return;
          if (focusNode.classList.contains('toggle-all')) return;
          this.selection.start = this.selection.event.focusNode;
          if ('removeAllRanges' in this.selection.event) {
            this.selection.event.removeAllRanges();
          } else if ('empty' in this.selection.event) {
            this.selection.event.empty();
          }
        }
      }
      this.mouseup = () => {
        if (!this.selection.event) return;
        let focusNode = this.selection.event.focusNode;
        if (!focusNode || !/toggle/.test(focusNode.className)) return;
        if (focusNode.classList.contains('toggle-all')) return;
        this.selection.end = this.selection.event.focusNode;
        if (this.selection.start == this.selection.end) {
          ['start', 'end'].map(e => this.selection[e] = null); return;
        }
        this.selection.event = null;
        let start = {row: this.selection.start.closest('tr')};
        let end = {row: this.selection.end.closest('tr')};
        let list = {body: end.row.closest('tbody'), selected: [start.row, end.row]};
        list.items = Array.from(list.body.children);
        start.index = list.items.indexOf(start.row);
        end.index = list.items.indexOf(end.row);
        if (start.index < end.index) {
          for (let i = start.index + 1; i < end.index; i++) {
            list.selected.splice(list.selected.length - 1, 0, list.items[i]);
          }
        }
        if (start.index > end.index) {
          list.selected = [end.row, start.row];
          for (let i = end.index + 1; i < start.index; i++) {
            list.selected.splice(list.selected.length - 1, 0, list.items[i]);
          }
        }
        for (let i = 0; i < list.selected.length; i++) {
          if (i != 0 && i != list.selected.length - 1) {
            let checkbox = list.selected[i].querySelector('input[type=checkbox]');
            if (checkbox) checkbox.checked = !checkbox.checked;
          }
        }
        setTimeout(() => this.selected = Array.from(list.body.querySelectorAll('.v-checkbox:checked')));
      }
      window.addEventListener('mousedown', this.mousedown);
      window.addEventListener('mouseup', this.mouseup);

      this.loading = false;
    },
    destroyed() {
      this.UnmountKeyPressListener();
      window.removeEventListener('mousedown', this.mousedown);
      window.removeEventListener('mouseup', this.mouseup);
    },
    computed: {
      main() {
        const main = {
          company: BPA.util.GetCompany(),
          view: document.querySelector('.content-scroll')
        }
        main.view.scroll = {top: 0};
        return main;
      },
      locale() {
        return this.$i18n.locale;
      },
      keydown() {
        return (event) => {
          this.Ctrl(event);
          this.Shift(event);
        }
      },
      keyup() {
        return () => {
          this.Ctrl(false);
          this.Shift(false);
        }
      },
    },
    watch: {
      locale() {
        //this.state = this.$t(this.Capitalize(this.search.state));
      }
    },
    methods: {
      Ctrl(e) {
        this.ctrl_key = e && e.getModifierState('Control');
      },
      Shift(e) {
        this.shift_key = e && e.getModifierState('Shift');
      },
      MountKeyPressListener() {
        this.event.map(e => addEventListener(e, this[e]));
      },
      UnmountKeyPressListener() {
        this.event.map(e => removeEventListener(e, this[e]));
      },
      CloneObject(object = {}) {
        return Tool.CloneObject(object);
      },
      DateFormat(date) {
        return Tool.DateFormat(date);
      },
      NumberFormat(number) {
        return Tool.NumberFormat(number);
      },
      Capitalize(string = '') {
        return Tool.Capitalize(string);
      },
      TitleCase(string = '') {
        return Tool.TitleCase(string);
      },
      Hyperlink(props = {}) {
        return Tool.Hyperlink(props);
      },
      Alphabetize(list, prop) {
        return Tool.Alphabetize(list, prop);
      },
      CountryName(country_code = '', locale) {
        return Tool.CountryName(country_code, locale);
      },
      LanguageName(country_code = '', locale) {
        return Tool.LanguageName(country_code, locale);
      },
      LinkText(url = '') {
        if (!url) return '';
        url = url.replace(/\/$/, '');
        let index = url.lastIndexOf('/');
        let substr = url.substr(index);
        return substr.replace(/^\//, '');
      },
      async GetHomePage(language_code) {
        const homepages = this.homepages;
        return await new Promise((resolve, reject) => {
          if (!language_code) return reject();
          if (language_code in homepages) {
            return resolve(homepages[language_code]);
          }
          BPA.api.GetCompanyHomepage(language_code).then(response => {
            return BPA.api.response({response, return: 'json'});
          }).then(response => {
            if (!response.ok || !response.result) return reject();
            let homepage = response.result.homepage;
            homepages[language_code] = homepage;
            resolve(homepage);
          }).catch(reject);
        }).catch(e => e);
      },
      async GetLanguageOptions() {
        return await new Promise(async resolve => {
          let options = BPA.locale('options').map(option => {
            let label = option.label.split(' ')[0];
            let code = option.code.replace('-', '_');
            let language = code.split('_')[0].toLowerCase();
            if (/no/.test(code)) {
              code = code.replace('no', 'nb');
            }
            option.code = code;
            option.label = label;
            option.language = language;
            option.country = code.split('_')[1];
            return option;
          }) || [];
          options = [];
          let storage = BPA.util.storage;
          let languages = storage.get('languages');
          let company = BPA.util.GetCompany();
          if (!Object.keys(languages).length) {
            let locales = await this.GetLanguageOrderCount();
            languages[company] = [];
            for (let locale in locales) {
              let option = {code: locale};
              if (/nb/.test(locale)) {
                locale = locale.replace('nb', 'no');
              }
              let code = locale.split('_');
              option.label = this.LanguageName(code[0], 'en');
              option.language = code[0];
              option.country = code[1];
              languages[company].push(option);
            }
            storage.set('languages', languages);
          }
          this.languages = languages[company];
          options = languages[company];
          resolve(options);
        }).catch(e => e);
      },
      async OpenModal(mode, campaign = {}) {
        this.modal.mode = mode;
        this.campaign = campaign;
        if (mode == 'edit') {
          for (let i = 0; i < this.clickables.length; i++) {
            if (this.clickables[i].dataset.id == campaign.id) {
              this.clickables[i].classList.add('selected');
              if (this.cached.campaign && Object.keys(this.cached.campaign).length) {
                this.clickables[i].scrollIntoView({
                  behavior: 'auto',
                  block: 'center',
                  inline: 'center'
                });
              }
              break;
            }
          }
          this.loading = true;
          this.campaign = await this.GetCampaign(campaign.id);
          let images = await this.GetCampaignImage(this.campaign) || {};
          this.campaign = {...this.campaign, ...images};
          let title = this.campaign.title;
          let regex = this.sort.regex;
          let match = title.match(regex);
          let index = match && match[1];
          if (index != null) this.campaign.index = index;
          if (match) this.campaign.title = title.replace(match[0], '').trim();
          BPA.cache.session({name: this.$options.name, set: {campaign: this.campaign}});
        }
        await this.SetupForm(this.campaign);
        this.modal.open = true;
        this.sort.edit = false;
        this.loading = false;
      },
      ClearForm() {
        this.form = {};
      },
      async ValidateForm() {
        return await new Promise(resolve => {
          const button = this.$refs.submit;
          if (button) {
            const form = button.closest('form');
            const fields = Array.from(form.elements);
            if (fields.some(field => !field.checkValidity())) {
              return button.click();
            }
          }
          resolve(true);
        }).catch(e => e);
      },
      async SetupForm(campaign) {
        return await new Promise(async resolve => {
          const fields = [];
          const data = {active: true};
          const template = this.CloneObject(this.template);
          const find_field = (name) => fields.map(group => {
            return group.find(field => field.name == name);
          }).filter(e => e)[0] || {};
          let form = campaign || template;
          if (!Array.isArray(form)) {
            for (let group of template) {
              for (let key in group) {
                group[key] = campaign[key];
              }
            }
            if (Object.keys(campaign).length) {
              data.active = campaign.active;
              data.id = campaign.id;              
            }
            form = template;
          }
          for (let group of form) {
            const row = [];
            for (let key in group) {
              data[key] = group[key];
              let field = {name: key};
              switch (key) {
                case 'title':
                case 'link': {
                  if (key == 'title') {
                    field.type = 'text';
                    field.label = 'Title';
                    field.required = true;
                  }
                  if (key == 'link') {
                    field.type = 'url';
                    field.label = 'Link';
                  }
                  field.blur = (event) => {
                    let value = event.target.value;
                    field.value = String(value).replace(/\s+/g, ' ').trim();
                    if (key == 'title') field.value = this.Capitalize(field.value);
                    if (key == 'link') field.value = field.value.replace(/\s/g, '');
                    this.form.data[key] = field.value;
                    //console.log(this.CloneObject(this.form.data));
                  }
                  field.fill_width = true;
                  //field.required = true;
                  break;
                }
                case 'from_date':
                case 'to_date': {
                  field.type = 'datetime';
                  if (key == 'from_date') field.label = 'Start date';
                  if (key == 'to_date') field.label = 'End date';
                  if (data[key]) {
                    data[key] = Tool.DateToISO(data[key], true);
                  }
                  field.input = (value) => {
                    if (value && !this.form.data[key]) {
                      let type = key.split('_')[0];
                      let time = (type == 'from' ? '00:00' : '23:59') + ':00';
                      value = value.split(' ')[0] + ' ' + time;
                    }
                    this.form.data[key] = value;
                  }
                  field.blur = (event) => {
                    let value = event.target.value;
                    field.value = String(value).trim();
                    this.form.data[key] = field.value;
                    //console.log(this.CloneObject(this.form.data));
                  }
                  field.fill_width = true;
                  //field.required = true;
                  break;
                }
                case 'img_base':
                case 'img_mobil': {
                  field.type = 'file';
                  if (key == 'img_base') {
                    field.style = {
                      width: '100%',
                      //maxWidth: '822px',
                      maxWidth: '65%',
                      flexBasis: 'auto'
                    };
                    field.label = 'Image (desktop)';
                  }
                  if (key == 'img_mobil') {
                    field.label = 'Image (mobile)';
                  }
                  field.accept = '.jpg, .jpeg, .png';
                  field.image = null;
                  field.title = '';
                  if (data[key]) {
                    let base64 = data[key];
                    field.image = base64;
                    data[key] = base64.replace(/^.+base64,/, '');
                  }
                  field.input = (event) => {
                    let file = event.target.files[0];
                    let value = event.target.value;
                    let reader = new FileReader();
                    if (!file) {
                      field.image = null;
                      field.value = '';
                      this.form.data[key] = null;
                      return;
                    }
                    reader.onloadend = () => {
                      let base64 = reader.result;
                      field.image = base64;
                      field.value = value;
                      this.form.data[key] = base64.replace(/^.+base64,/, '');
                      //console.log(this.CloneObject(this.form.data));
                    };
                    reader.readAsDataURL(file);
                  }
                  field.fill_width = true;
                  //field.required = true;
                  break;
                }
                case 'language': {
                  field.type = 'select';
                  field.label = 'Language';
                  field.options = this.CloneObject(this.languages);
                  var locale = this.modal.mode == 'create' ? /*/no/.test(this.locale) ? 'no_NB' :*/ this.locale : data[key];
                  field.value = field.options.find(option => option.code == locale) || '';
                  if (field.value) data[key] = field.value.code;
                  field.input = async (option) => {
                    field.value = option || '';
                    this.form.data[key] = option && option.code;
                    find_field('link').placeholder = await this.GetHomePage(option && option.code);
                    //console.log(this.CloneObject(this.form.data));
                  }
                  field.clearable = false;
                  field.required = true;
                  break;
                }
                case 'active': {
                  field.type = 'checkbox';
                  field.label = 'Active';
                  field.value = true;
                  //field.disabled = true;
                  if (data[key] != undefined) {
                    field.value = data[key];
                    field.disabled = false;
                  }
                  field.input = (event) => {
                    field.value = event.target.checked;
                    this.form.data[key] = field.value ? 'enable' : 'disable';
                    //console.log(this.CloneObject(this.form.data));
                  }
                  break;
                }
              }
              row.push(field);
            }
            fields.push(row);
          }
          console.log(this.CloneObject(data))
          find_field('link').placeholder = await this.GetHomePage(find_field('language').value.code);
          this.form.fields = fields;
          this.form.data = data;
          console.log(this.CloneObject(this.form.fields))
          console.log(this.CloneObject(this.form.data))
          this.$nextTick().then(resolve);
        }).catch(e => e);
      },
      async FilterBySize(option) {
        this.search.size = option;
        await this.FilterSearch();
      },
      async FilterByLanguage(option) {
        option = option ? option.code : '';
        this.search.language = option;
        await this.FilterSearch();
      },
      async FilterSearch() {
        this.page.current = 1;
        this.loading = true;
        await this.SearchCampaigns();
        this.loading = false;
      },
      async SearchCampaigns() {
        if (this.sort.edit) await this.DisableDragSort();
        return await new Promise((resolve, reject) => {
          const request = {
            query: this.search.query,
            size: this.search.size,
            offset: this.page.current
          }
          if (this.search.language) {
            request.language = this.search.language;
          }
          BPA.api.SearchTrackingCampaigns(request).then(response => {
            return BPA.api.response({response, return: 'json'});
          }).then(response => {
            if (!response.ok || !response.result) return reject();
            let json = response.result || {};
            json.items.sort((a, b) => b.id - a.id);
            let now_date = Tool.DateToUTC(new Date());
            for (let item of json.items) {
              item.expired = now_date > item.to_date;
            }
            if (!json.offset) json.offset = 0;
            this.search.initial = this.search.query;
            this.page.current = json.offset + 1;
            //this.page.last = json.total || 1;
            this.page.last = Math.ceil((json.count || 1) / request.size);
            this.entries = json.count || json.items.length;
            json.items.sort((a, b) => b.id - a.id);
            for (let i = 0; i < json.items.length; i++) {
              //json.items[i].index = i;
              //console.log(this.CloneObject(json.items[i]))
            }
            for (let i = json.items.length - 1; i >= 0; i--) {
              json.items[i].index = i;
              let item = json.items[i];
              let title = item.title;
              let regex = this.sort.regex;
              let match = title.match(regex);
              let index = match && match[1];
              if (index != null) item.index = index;
              if (match) item.title = title.replace(match[0], '').trim();
              //console.log(this.CloneObject(json.items[i]))
            }
            if (this.search.language) {
              json.items.sort((a, b) => b.index - a.index);
            }
            //console.log(this.CloneObject(json.items))
            this.campaigns = json;
            BPA.cache.local({name: this.$options.name, set: {page: this.page, search: this.search}});
            this.$nextTick().then(async () => {
              this.clickables = document.querySelectorAll('.clickable');
              if (this.cached.campaign && Object.keys(this.cached.campaign).length) {
                this.OpenModal('edit', this.cached.campaign);
              }
              this.SetPageJumpWidth();
            });
            resolve(json);
          }).catch(reject);
        }).catch(e => e);
      },
      async GetLanguageOrderCount(company_code) {
        return await BPA.api.GetLanguageOrderCount(company_code).then(response => {
          return BPA.api.response({response, return: 'json'});
        }).then(response => response.result || {}).catch(e => e);
      },
      async GetCampaign(campaign_id) {
        return await new Promise((resolve, reject) => {
          if (!campaign_id) return reject();
          BPA.api.GetTrackingCampaign(campaign_id).then(response => {
            return BPA.api.response({response, return: 'json'});
          }).then(response => {
            if (!response.ok || !response.result) return reject();
            resolve(response.result);
          }).catch(reject);
        }).catch(e => e);
      },
      async GetCampaignImage(campaign = {}) {
        return await new Promise(async (resolve, reject) => {
          if (!Object.keys(campaign).length) return reject();
          let img_base = !campaign.has_img_base ? null : true;
          let img_mobil = !campaign.has_img_mobil ? null : true;
          const image = {img_base, img_mobil};
          if (!Object.values(image).some(e => e)) return reject();
          for (let type in image) {
            if (image[type]) image[type] = await new Promise((res, rej) => {
              campaign['url_' + type] = BPA.api.base() + '/campaign_on_tracking/campaign/img/' + campaign.id + '/' + type;
              BPA.api.GetTrackingCampaignImage([campaign.id, type]).then(response => {
                return BPA.api.response({response, return: 'blob'});
              }).then(response => {
                if (!response.ok || !response.result) return reject();
                let reader = new FileReader();
                reader.onloadend = () => res(reader.result);
                reader.readAsDataURL(response.result);
              }).catch(rej);
            }).catch(e => e);
          }
          Promise.allSettled(Object.values(image)).then(promises => {
            for (let i = 0; i < promises.length; i++) {
              if (promises[i].status != 'fulfilled') {
                let message = promises[i].reason;
                return reject(message);
              }
            }
            for (let key in campaign) {
              if (/^url_/.test(key)) {
                image[key] = campaign[key];
              }
            }
            resolve(image);
          });
        }).catch(e => e);
      },
      async CreateCampaign(request = {}) {
        let campaign_active = request.active;
        /*
        for (let group of this.form.fields) {
          for (let field of group) {
            if (field.name == 'active') {
              campaign_active = field.value;
              break;
            }
          }
        }
        */
        return await new Promise((resolve, reject) => {
          if (!Object.keys(request).length) return reject();
          BPA.api.CreateTrackingCampaign(request).then(response => {
            return BPA.api.response({response, return: 'text'});
          }).then(async response => {
            if (!response.ok) return reject();
            if (!campaign_active) {
              await this.ToggleCampaign({
                campaign_id: response.result,
                active: 'disable'
              });
            }
            resolve(response);
          }).catch(reject);
        }).catch(e => e);
      },
      async EditCampaign(request = {}) {
        return await new Promise((resolve, reject) => {
          if (!Object.keys(request).length) return reject();
          BPA.api.EditTrackingCampaign(request).then(response => {
            return BPA.api.response({response});
          }).then(response => {
            if (!response.ok) return reject();
            resolve(response);
          }).catch(reject);
        }).catch(e => e);
      },
      async ToggleCampaign(request = {}) {
        return await new Promise((resolve, reject) => {
          if (!Object.keys(request).length) return reject();
          BPA.api.SetTrackingCampaignStatus(request).then(response => {
            return BPA.api.response({response});
          }).then(response => {
            if (!response.ok) return reject();
            resolve(response);
          }).catch(reject);
        }).catch(e => e);
      },
      async DeleteCampaign(campaign_id) {
        return await new Promise((resolve, reject) => {
          if (!campaign_id) return reject();
          BPA.api.DeleteTrackingCampaign(campaign_id).then(response => {
            return BPA.api.response({response});
          }).then(response => {
            if (!response.ok) return reject();
            resolve(response);
          }).catch(reject);
        }).catch(e => e);
      },
      async Create() {
        if (!await this.ValidateForm()) return;
        const data = this.CloneObject(this.form.data);
        this.$eventHub.$emit('ValidateModalStart', {
          approve: 'Yes, create it',
          disapprove: 'No',
          message: 'Creates the current campaign.',
          type: 'success'
        });
        this.$eventHub.$on('ValidateModalStop', async (approve) => {
          this.$eventHub.$off('ValidateModalStop');
          if (!approve) return;
          for (let [key, value] of Object.entries(data)) {
            if (/date/.test(key) && value) {
              data[key] = Tool.DateToUTC(value);
            }
          }
          this.loading = true;
          console.log(this.CloneObject(data))
          if (await this.CreateCampaign(data)) {
            this.$eventHub.$emit('ShowMessages', {
              message: 'Campaign successfully created',
              type: 'success',
              hide: 2000
            });
            await this.SearchCampaigns();
            this.$eventHub.$emit('CloseModal');
          }
          this.loading = false;
        });
      },
      async Update() {
        const changes = {};
        if (!await this.ValidateForm()) return;
        const data = this.CloneObject(this.form.data);
        for (let [key, value] of Object.entries(data)) {
          let field = this.campaign[key];
          if (/img/.test(key)) field = field ? field.replace(/^.+base64,/, '') : null;
          if (/date/.test(key)) field = field ? Tool.DateToISO(field, true) : null;
          if (field != value) changes[key] = value;
        }
        if (!Object.keys(changes).length) {
          this.$eventHub.$emit('ShowMessages', {
            message: 'No changes made to campaign #' + data.id,
            type: 'warning',
            hide: 2000
          });
          return this.$eventHub.$emit('CloseModal');
        }
        this.$eventHub.$emit('ValidateModalStart', {
          approve: 'Yes, update it',
          disapprove: 'No',
          message: 'Updates the current campaign.',
          type: 'success'
        });
        this.$eventHub.$on('ValidateModalStop', async (approve) => {
          this.$eventHub.$off('ValidateModalStop');
          if (!approve) return;
          const request = {campaign_id: data.id};
          delete data.id;
          /* FILTER FIELDS THAT HAVE NOT CHANGED
          for (let [key, value] of Object.entries(data)) {
            let field = this.campaign[key];
            if (/img/.test(key)) {
              let base64 = field.replace(/^.+base64,/, '');
              if (base64 == value) delete data[key];
            } else if (field == value) {
              delete data[key];
            }
          }
          */
          for (let [key, value] of Object.entries(data)) {
            if (/date/.test(key) && value) {
              data[key] = Tool.DateToUTC(value);
            }
          }
          if ('index' in this.campaign) {
            let index = this.campaign.index;
            data.title = `{[${index}]} ${data.title}`;
          }
          request.body = data;
          this.loading = true;
          if (Object.keys(changes).some(key => key != 'active')) {
            delete request.body.active;
            await this.EditCampaign(request);
          }
          if ('active' in changes) {
            delete request.body;
            request.active = (changes.active ? 'en' : 'dis') + 'able';
            await this.ToggleCampaign(request);
          }
          await this.SearchCampaigns();
          this.$eventHub.$emit('CloseModal');
          this.loading = false;
        });
      },
      async Toggle(campaign = {}, event = {}) {
        let campaigns = [{...campaign, ...{toggle: event.target}}];
        for (let checkbox of this.selected) {
          let table_row = checkbox.closest('tr');
          let toggle = table_row.querySelector('.checkbox-toggle > input');
          let row_item = this.campaigns.items.find(item => item.id == table_row.dataset.id);
          if (row_item && row_item.active == campaign.active) {
            campaigns.push({...row_item, ...{toggle: toggle}});
          }
        }
        campaigns = campaigns.filter((item, index, array) => index == array.findIndex(i => i.id == item.id));
        for (let item of campaigns) item.toggle.checked = event.target.checked;
        let multiple = campaigns.length > 1;
        let status = !campaign.active ? 'enable' : 'disable';
        this.$eventHub.$emit('ValidateModalStart', {
          approve: `Yes, ${status} ${multiple ? 'them' : 'it'}`,
          disapprove: 'No',
          message: `Changes the status of the ${multiple ? 'selected' : 'current'} campaign${multiple ? 's' : ''}.`,
          type: !campaign.active ? 'success' : 'danger'
        });
        this.$eventHub.$on('ValidateModalStop', async (approve) => {
          this.$eventHub.$off('ValidateModalStop');
          if (!approve) {
            for (let item of campaigns) {
              item.toggle.checked = !!campaign.active;
            }
            return;
          }
          this.loading = true;
          for (let item of campaigns) {
            await this.ToggleCampaign({campaign_id: item.id, active: status});
          }
          this.$eventHub.$emit('ShowMessages', {
            message: `Campaign${multiple ? 's' : ''} successfully ${status}d`,
            type: 'success',
            hide: 2000
          });
          await this.SearchCampaigns();
          if (this.modal.mode == 'edit') {
            this.$eventHub.$emit('CloseModal');
          }
          this.loading = false;
        });
      },
      async Delete(campaign = {}) {
        let in_modal = Object.keys(this.campaign).length;
        campaign = in_modal ? this.campaign : campaign;
        let campaigns = [campaign];
        if (!in_modal) {
          for (let checkbox of this.selected) {
            let table_row = checkbox.closest('tr');
            let row_item = this.campaigns.items.find(item => item.id == table_row.dataset.id);
            if (row_item) campaigns.push(row_item);
          }
          campaigns = campaigns.filter((item, index, array) => index == array.findIndex(i => i.id == item.id));
        }
        let multiple = campaigns.length > 1;
        this.$eventHub.$emit('ValidateModalStart', {
          approve: `Yes, delete ${multiple ? 'them' : 'it'}`,
          disapprove: 'No',
          message: `Deletes the ${multiple ? 'selected' : 'current'} campaign${multiple ? 's' : ''}.`,
          type: 'danger'
        });
        this.$eventHub.$on('ValidateModalStop', async (approve) => {
          this.$eventHub.$off('ValidateModalStop');
          if (!approve) return;
          this.loading = true;
          for (let item of campaigns) {
            await this.DeleteCampaign(item.id);
          }
          this.$eventHub.$emit('ShowMessages', {
            message: `Campaign${multiple ? 's' : ''} successfully deleted`,
            type: 'success',
            hide: 2000
          });
          await this.SearchCampaigns();
          if (this.modal.mode == 'edit') {
            this.$eventHub.$emit('CloseModal');
          }
          this.loading = false;
        });
      },
      ClearImage(field) {
        field.value = '';
        field.image = null;
        this.form.data[field.name] = null;
      },
      ToggleAllCheckboxes(e) {
        let selected = [];
        let checked = e.target.checked;
        let campaigns = document.querySelectorAll('tr[data-id]');
        for (let i = 0; i < campaigns.length; i++) {
          let checkbox = campaigns[i].querySelector('.v-checkbox');
          if (checkbox) {
            checkbox.checked = checked;
            if (checked) {
              selected.push(checkbox);
            }
          }
        }
        this.selected = selected;
      },
      ToggleCheckbox(e) {
        let e_target = e.target;
        if (!e_target || e_target.localName == 'span') return;
        if (e_target.localName == 'td') {
          e_target = e_target.querySelector('.v-checkbox');
          e_target.checked = !e_target.checked;
          return e_target.dispatchEvent(new Event('input'));
        }
        let selected = [];
        let checked = e_target.checked;
        let select_all = this.$refs.select_all;
        let campaigns = document.querySelectorAll('tr[data-id]');
        if (!checked) select_all.checked = false;
        for (let i = 0; i < campaigns.length; i++) {
          let checkbox = campaigns[i].querySelector('.v-checkbox');
          if (checkbox && checkbox.checked) {
            selected.push(checkbox);
          }
        }
        if (selected.length == campaigns.length) {
          select_all.checked = true;
        }
        this.selected = selected;
      },
      async ChangeOrder() {
        await this.EnableDragSort();
      },
      async SaveOrder() {
        await this.DisableDragSort();
        this.campaigns.items = this.CloneObject(this.sort.order);
      },
      async ResetOrder() {
        await this.DisableDragSort();
        this.campaigns.items = [];
        this.$nextTick().then(() => {
          this.campaigns.items = this.CloneObject(this.sort.reset);
        });
      },
      async EnableDragSort() {
        this.sort.edit = true;
        this.sort.index = {};
        this.sort.reset = this.CloneObject(this.campaigns.items) || [];
        this.sort.order = this.CloneObject(this.campaigns.items) || [];
        /*
        for (let i = 0; i < this.sort.order.length; i++) {
          this.sort.order[i].index = i;
        }
        */
        /*
        for (let i = this.sort.order.length - 1; i >= 0; i--) {
          this.sort.order[i].index = i;
        }
        */
        return await new Promise(resolve => {
          this.$nextTick().then(() => {
            for (let item of document.querySelectorAll('.sortable')) {
              item.setAttribute('draggable', true);
              item.ondrag = this.HandleDrag;
              item.ondragstart = this.DragStart;
              item.ondragover = this.DragOver;
              item.ondragenter = this.DragEnter;
              item.ondragend = this.HandleDrop;
            }
            resolve(true);
          });
        });
      },
      async DisableDragSort() {
        return await new Promise(resolve => {
          for (let item of document.querySelectorAll('.sortable')) {
            item.removeAttribute('draggable');
            item.ondrag = null;
            item.ondragend = null;
          }
          this.sort.edit = false;
          resolve(true);
        });
      },
      HandleDrag(event) {
        event.preventDefault();
        let x = event.clientX;
        let y = event.clientY;
        let item = event.target;
        let list = item.closest('tbody');
        let swapItem = document.elementFromPoint(x, y);
        item.classList.add('dragging');
        if (!swapItem) swapItem = item;
        if (swapItem.localName != 'tr') {
          swapItem = swapItem.closest('tr');
        }
        if (swapItem && list == swapItem.closest('tbody')) {
          swapItem = swapItem != item.nextSibling ? swapItem : swapItem.nextSibling;
          list.insertBefore(item, swapItem);
        }
      },
      HandleDrop(event) {
        let sort = this.sort;
        let item = event.target;
        let list = item.closest('tbody');
        let items = Array.from(list.children);
        sort.order = items.map(e => sort.order.find(o => o.id == e.dataset.id));
        for (var i = sort.order.length - 1; i >= 0; i--) {
          sort.order[i].index = i;
        }
        item.classList.remove('dragging');
      },
      DragStart(event) {
        let clone = event.target.cloneNode(true);
        clone.style.display = 'none';
        event.dataTransfer.setDragImage(clone, 0, 0);
        
      },
      DragOver(event) {
        event.preventDefault();
        event.dataTransfer.effectAllowed = 'move';
        event.dataTransfer.dropEffect = 'move';
        event.dataTransfer.effect = 'move';
      },
      DragEnter(event) {
        event.preventDefault();
      },
      DragEnd() {
        
      },
      ClickOpen(event) {
        if (!event) return;
        let elm = event.target, row;
        if (elm.localName != 'tr') row = elm.closest('tr');
        const mousedown = (state) => {
          if (state === true || state === false) {
            row.mousedown = state;
          } else {
            return row.mousedown;
          }
        }
        if (!row.classList.contains('clickable')) return;
        for (let i = 0; i < this.clickables.length; i++) {
          if (this.clickables[i] != row) {
            this.clickables[i].classList.remove('hover', 'selected');
          }
        }
        if (elm.type == 'checkbox') return;
        if (/label|checkbox/.test(elm.className)) return;
        if (elm.localName == 'a' || elm.parentElement.localName == 'a') return;
        if (event.type == 'mousedown') {
          mousedown(event.which == 1);
        }
        if (event.type == 'mousemove') {
          mousedown(false);
        }
        if (event.type == 'mouseup') {
          mousedown() && this.OpenModal('edit', row.dataset);
          mousedown(false);
        }
      },
      async PageController(next_page) {
        let load_page;
        const page = {};
        document.querySelectorAll('.page-turn').forEach(arrow => {
          if (arrow) page[arrow.classList.contains('prev') ? 'prev' : 'next'] = arrow;
        });
        if (next_page) {
          if (this.page.current != this.page.last) {
            this.page.current++;
            load_page = true;
            if (this.page.current == this.page.last) {
              page.next.classList.add('disabled');
            }
            page.prev.classList.remove('disabled');
          }
        } else {
          if (this.page.current > 1) {
            this.page.current--;
            load_page = true;
            if (this.page.current == 1) {
              page.prev.classList.add('disabled');
            }
            page.next.classList.remove('disabled');
          }
        }
        if (load_page) {
          this.loading = true;
          await this.SearchCampaigns();
          this.loading = false;
        }
      },
      async PageNavigator(e) {
        const page = {
          event: e.type,
          input: e.target,
          last: Number(e.target.max),
          first: Number(e.target.min),
          number: Number(e.target.value)
        }
        const within_limits = (n) => {
          return n >= page.first && n <= page.last;
        }
        const set_page_number = (n) => {
          this.page.number = n || page.number;
          page.number = Number(this.page.number);
        }
        document.querySelectorAll('.page-turn').forEach(arrow => {
          if (arrow) page[arrow.classList.contains('prev') ? 'prev' : 'next'] = arrow;
        });
        set_page_number();
        if (page.event == 'keydown') {
          if (e.key == 'Enter') {
            page.input.blur();
          }
        }
        if (page.event == 'blur') {
          ['prev', 'next'].map(arrow => {
            page[arrow] && page[arrow].classList.remove('disabled');
          });
          if (page.number <= page.first) {
            set_page_number(page.first);
            page.input.value = page.first;
            page.prev.classList.add('disabled');
            if (page.last == page.first) {
              page.next.classList.add('disabled');
            }
          } else if (page.number >= page.last) {
            set_page_number(page.last);
            page.input.value = page.last;
            page.next.classList.add('disabled');
            if (page.first == page.last) {
              page.prev.classList.add('disabled');
            }
          }
          if (within_limits(page.number) && page.number != this.page.current) {
            this.page.current = page.number;
            this.loading = true;
            await this.SearchCampaigns();
            this.loading = false;
          }
        }
      },
      SetPageJumpWidth() {
        const current = document.querySelector('.page-number-current');
        const last = document.querySelector('.page-number-last');
        if (current && last) {
          const rect = last.getBoundingClientRect();
          const input = current.querySelector('input');
          if (rect && rect.width) {
            current.style.setProperty('width', rect.width + 'px');
          } else {
            current.style.removeProperty('width');
          }
          if (input) {
            input.dispatchEvent(new Event('blur'));
          }
        }
      },
    }
  }
</script>

<style lang="scss" scoped>
  .label {
    .v-wrapper,
    .v-checkbox-label {
      min-height: auto;
    }

    .v-checkbox-toggle {
      width: 18px;
      height: 18px;
      background-color: white;
    }
  }

  .campaign-list__row:not(.clickable) {
    &, * {
      user-select: none;
    }

    &.sortable {
      cursor: grab;
      cursor: default;

      td > * {
        pointer-events: none;
      }

      &.dragging + &:nth-child(odd) {
        background-color: transparent;
      }

      &.dragging + &:nth-child(even) {
        background-color: rgba(183, 183, 183, 0.12);
      }

      &.dragging {
        /*
        box-shadow: 0px 2px 4px -2px rgba(60, 60, 60, 1);
        box-shadow: 0px 2px 4px -2px rgba(0, 0, 0, 1);
        */
        box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.5);
        cursor: grabbing !important;

        & > * {
          //opacity: 0.5;
        }
      }
    }
  }

</style>