<template>
  <div class="settings settings-uid-check-list-view">
		<p class="title">{{ $t('Serial number') }}</p>
		<div class="settings__group">
			<p>{{ $t('Configuration of product serial numbers on company level.') }}</p>
		</div>
		<div class="settings__group">
			<div class="simple-list__title">
				<p>{{ $t('Company') }}</p>
				<p>{{ $t('Inherit') }}</p>
				<p>{{ $t('Overwrite') }}</p>
			</div>
			<ul class="simple-list odd-even">
				<li class="simple-list__item" :data-id="company.id" :key="index" v-for="(company, index) in comp_uid_props">
					<div class="item-wrapper">
						<p class="item-wrapper__text">{{ company.name }}</p>
					</div>
					<div class="item-wrapper">
						<label class="item-wrapper__checkbox checkbox">
							<input type="checkbox" name="inherited" :checked="company.inherited" :disabled="!main" @change.prevent="ChangeProperty">
							<span class="checkmark"></span>
						</label>
					</div>
					<div class="item-wrapper">
						<label class="item-wrapper__checkbox checkbox">
							<input type="checkbox" name="override" :checked="company.override" :disabled="!main" @change.prevent="ChangeProperty">
							<span class="checkmark"></span>
						</label>
					</div>
				</li>
			</ul>

			<div class="loading" v-if="loading">
				<div class="loading-element">
					<span></span>
					<span></span>
					<span></span>
					<span></span>
				</div>
			</div>
      
		</div>
  </div>
</template>

<script>
	import { BPA } from '@/helpers/BPA';
	
  export default {
		name: 'SettingsCompanyUniqueIdentifierView',
		mixins: [BPA],
    data() {
      return {
				main: false,
				loading: false,
				comp_uid_props: []
      }
    },
    created() {
			this.main = BPA.util.IsMainCompany();
			this.companies = BPA.api.Companies('GET');
			this.main && this.GetCompanyUniqueIdentifierProperties();
		},
		computed: {
			checkbox() {
				return document.querySelectorAll('input[type=checkbox]');
			}
		},
    methods: {
			async GetCompanyUniqueIdentifierProperties() {
				this.loading = true;
				return await BPA.api.GetCompanyUniqueIdentifierProperties().then(response => {
					return BPA.api.response({response, return: 'json'});
				}).then(response => {
					this.loading = false;
					if (!response.ok || !response.result) return;
					this.comp_uid_props = response.result || [];
					//this.comp_uid_props = properties.filter(prop => this.companies.some(comp => prop.id == comp.id && comp.main));
				}).catch(e => e);
			},
			ChangeProperty(e) {
				if (!this.main) return;
				const checkbox = e.target;
				this.$eventHub.$emit('ValidateModalStart', {
					approve: 'Yes, change it',
					disapprove: 'No',
					message: 'Changes the current company serial number property.',
					type: 'success'
				});
				this.$eventHub.$on('ValidateModalStop', approve => {
					this.$eventHub.$off('ValidateModalStop');
					if (!approve) return checkbox.checked = !checkbox.checked;
					this.loading = true;
					BPA.api.UpdateCompanyUniqueIdentifierProperties({
						company_code: BPA.company('code', checkbox.closest('[data-id]').dataset.id),
						property: checkbox.name,
						value: checkbox.checked ? 'yes' : 'no'
					}).then(response => {
						return BPA.api.response({response});
					}).then(response => {
						this.loading = false;
						if (!response.ok) return;
						this.$eventHub.$emit('ShowMessages', {
							message: 'Company property sucessfully changed',
							type: 'success',
							hide: 2000
						});
					}).catch(e => e);
				});
			}
    }
  }
</script>

<style lang="scss" scoped>
	@import '../../../assets/style/variables/colors';

	.settings__group {
		position: relative;

		> p + p, 
		> p + ul, 
		> ul + ul, 
		> ul + p {
			margin-top: 10px;
		}
	}

  .simple-list {
    width: 100%;
    list-style-type: none;
    border-top: 2px solid #d3d3d3;

    &, * {
      box-sizing: border-box;
    }

		&__title {
			width: 100%;
			display: flex;
			font-weight: 600;

			& > * {
				width: 100%;
				padding: 10px 10px 2px;
			}
		}

    &__item {
			display: flex;
      position: relative;
      //border-bottom: 1px solid lightgray;

      .item {
        &-wrapper {
          width: 100%;
          /*height: 100%;*/
          display: flex;
					position: relative;
          
          &__text, &__checkbox {
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            min-height: 32px;
            border: 0;
            outline: none;
            position: relative;
						padding: 4px 10px;
						display: flex;
            background-color: transparent;
            font-size: 16px;
						line-height: 23.2px;
						align-items: center;
					}
					
					&__checkbox {
						cursor: pointer;

						input {
							outline: none;
							pointer-events: none;
						}
					}
        }
      }
    }
  }
</style>