<template>
    <div style="cursor: pointer;" @click="$emit('remove-SKU', SKUItem)">
        <p>{{ SKUItem }}</p>
    </div>
</template>
<script>
export default {
    props: {
        SKUItem: String,
    },
    data() {
        return {

        }
    }
}
</script>
<style lang="scss" scoped>
</style>