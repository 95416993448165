<template>
  <div class="wrapper">
    <div class="title">
      <h1>{{ $t('Manage Alert Orders') }}</h1>
    </div>
    <div class="content-wrapper">
      <div>
        <div class="flex">
          <div>
            <h3>{{ $t('Email') }}</h3>
            <p class="input-text">{{ $t('Email') }}</p>
            <input
              class="input"
              type="email"
              spellcheck="false"
              v-model="email"
            />
          </div>
        </div>
        <div class="flex">
          <div class="checkbox-container">
            <p class="input-text">{{ $t('Negative value') }}</p>
            <input
            class="input-checkbox"
            type="checkbox"
            name=""
            id=""
            v-model="dataModel.has_negative_values"
            />
          </div>
          <div class="checkbox-container">
            <p class="input-text star">{{ $t('Zero value') }}</p>
            <input
            class="input-checkbox"
            type="checkbox"
            name=""
            id=""
            v-model="dataModel.has_zero_values"
            />
          </div>
        </div>
        <p class="info-text">{{ $t('Check to enable alarm on negative and zero values.') }}</p>
        <div>
          <button class="button" @click="EnableAlertOrder()">{{ $t('Enable email') }}</button>
          <button class="button red" @click="DisableAlertOrder()">{{ $t('Disable email') }}</button>
        </div>
      </div>
      <div>
        <div>
          <h3 class="star">{{ $t('Product count pr row') }}</h3>
          <div class="info-container">
            <p class="info-text">{{ $t('Set the interval of the count and value, to avoid alarms. E.g. set maximum count to 3, so that everything above 3, will trigger alarm') }}</p>
          </div>
          <div class="flex">
            <div>
              <p class="input-text">{{ $t('Minimum count') }}</p>
              <input
                class="input"
                type="number"
                name=""
                id=""
                v-model="dataModel.min_product_count_pr_row"
              />
            </div>
            <div>
              <p class="input-text">{{ $t('Maximum count') }}</p>
              <input
                class="input"
                type="number"
                name=""
                id=""
                v-model="dataModel.max_product_count_pr_row"
              />
            </div>
          </div>
          <div class="flex">
            <div>
              <p class="input-text">{{ $t('Minimum value') }}</p>
              <input
                class="input"
                type="number"
                name=""
                id=""
                v-model="dataModel.min_row_value"
              />
            </div>
            <div>
              <p class="input-text">{{ $t('Maximum value') }}</p>
              <input
                class="input"
                type="number"
                name=""
                id=""
                v-model="dataModel.max_row_value"
              />
            </div>
          </div>
          <h3>Product count pr order</h3>
          <div class="info-container">
            <p class="info-text">{{ $t('Set the interval of the count and value, to avoid alarms. E.g. set maximum count to 3, so that everything above 3, will trigger alarm') }}</p>
          </div>
          <div class="flex">
            <div>
              <p class="input-text">{{ $t('Minimum count') }}</p>
              <input
                class="input"
                type="number"
                name=""
                id=""
                v-model="dataModel.min_product_count_pr_order"
              />
            </div>
            <div>
              <p class="input-text">{{ $t('Maximum count') }}</p>
              <input
                class="input"
                type="number"
                name=""
                id=""
                v-model="dataModel.max_product_count_pr_order"
              />
            </div>
          </div>
          <div class="flex">
            <div>
              <p class="input-text">{{ $t('Minimum value') }}</p>
              <input
                class="input"
                type="number"
                name=""
                id=""
                v-model="dataModel.min_order_value"
              />
            </div>
            <div>
              <p class="input-text">{{ $t('Maximum value') }}</p>
              <input
                class="input"
                type="number"
                name=""
                id=""
                v-model="dataModel.max_order_value"
              />
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="flex">
          <div class="flex column">
            <h3 class="star">{{ $t('SKU') }}</h3>
            <p class="info-text">{{ $t('Type in the SKU you want to add an alert for') }}</p>
            <p class="input-text">{{ $t('Seperate with , (7045464,7787489)') }}</p>
            <input class="input" type="text" name="" id="" v-model="SKUItem" />
          </div>
          <div class="button-container">
            <button class="button add" @click="AddSKU(SKUItem)">{{ $t('Add') }}</button>
          </div>
        </div>
        <div class="sku-list">
          <div v-if="dataModel.skus.length > 0">
            <div v-for="(item, index) in dataModel.skus" :key="index">
              <EmailAlertSKUItem
                :SKUItem="item"
                @remove-SKU="RemoveSKU($event)"
              ></EmailAlertSKUItem>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="button-container">
      <button class="button" @click="UpdateAlertOrder()">{{ $t('Update') }}</button>
    </div>
    <div class="loading" v-if="loading">
      <div class="loading-element">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  </div>
</template>
<script>
import EmailAlertSKUItem from './EmailAlertSKUItem.vue';
import { BPA } from '@/helpers/BPA';

export default {
  mixins: [BPA],
  components: { EmailAlertSKUItem },
  props: {
    editAlert: {},
  },
  data() {
    return {
      dataModel: {
        has_negative_values: false,
        has_zero_values: false,
        max_product_count_pr_row: null,
        min_product_count_pr_row: null,
        max_product_count_pr_order: null,
        min_product_count_pr_order: null,
        max_order_value: null,
        max_row_value: null,
        min_order_value: null,
        min_row_value: null,
        skus: [''],
      },
      SKUItem: '',
      email: '',
      loading: false,
    };
  },
  mounted() {
    if (this.editAlert.email) {
      this.email = this.editAlert.email
      
      this.dataModel = JSON.parse(JSON.stringify(this.editAlert));
      
      delete this.dataModel.email
      delete this.dataModel.is_disable
    }
  },
  methods: {
    AddSKU(SKU) {

      let tempArray = SKU.split(',')

      tempArray.forEach((temp) => {
        this.dataModel.skus.push(temp)  
      })

      this.SKUItem = '';
    },
    RemoveSKU(SKU) {
      this.dataModel.skus = this.dataModel.skus.filter((item) => item !== SKU);
    },
    ValidateEmail() {
      if (!this.email) {
        this.$eventHub.$emit('ShowMessages', {
          message: 'Email missing',
          type: 'error',
          hide: 4000,
        });
        return false;
      }
      return true;
    },
    ValidateNumberValue(dataModel) {
      for (let value of Object.keys(dataModel)) {
        if(typeof dataModel[value] != 'boolean' && dataModel[value] == '' && !Array.isArray(dataModel[value]))
        {
          dataModel[value] = null;
        }
      }
    },
    async EnableAlertOrder() {
      if(!this.ValidateEmail()) return

      await BPA.api.EnableAlertOrder(this.email)
    },
    async DisableAlertOrder() {
      if(!this.ValidateEmail()) return

      await BPA.api.DisableAlertOrder(this.email)
    },
    async UpdateAlertOrder() {
      let tempData = this.dataModel;

      if(!this.ValidateEmail()) return

      this.ValidateNumberValue(tempData)
      
      this.loading = true;

      let response = await BPA.api.UpdateAlertOrder(this.email, tempData);
      if (response.status == 204) {
        this.$eventHub.$emit('ShowMessages', {
          message: 'Alert order successfully created',
          type: 'success',
          hide: 4000,
        });
        this.dataModel = this.ResetDataModel();
        this.email = ''
      } else {
        this.$eventHub.$emit('ShowMessages', {
          message: 'Alert order creation failed',
          type: 'error',
          hide: 4000,
        });
      }
      this.loading = false;
    },
    ResetDataModel() {
      return {
        has_negative_value: false,
        has_zero_value: false,
        max_product_count_pr_row: null,
        min_product_count_pr_row: null,
        max_product_count_pr_order: null,
        min_product_count_pr_order: null,
        max_order_value: null,
        max_row_value: null,
        min_order_value: null,
        min_row_value: null,
        skus: [''],
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/assets/style/variables/icons.scss';
@import 'src/assets/style/variables/colors.scss';
@import 'src/assets/style/variables/fonts.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 500px;
}
.input-container {
  display: flex;
}
.checkbox-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}
.input-text {
  font-weight: 600;
  color: $lightFont;
  padding-bottom: 2px;
  margin-top: 5px;
}
.input {
  font-size: 1em;
  padding: 7px;
  color: #333;
  appearance: none;
  display: flex;
  background: none;
  border: 1px solid rgba(60, 60, 60, 0.26);
  border-radius: 4px;
  white-space: normal;
  outline: none;
  margin-bottom: 10px;
  margin-right: 10px;
}
.input-checkbox {
  height: 1.5em;
  width: 1.5em;
}
.button-container {
  display: flex;
  height: 100%;
  width: 20%;
  flex-grow: 1;
  align-items: end;
  align-self: flex-end;
}
.button {
  width: 70%;
  flex-shrink: 0;
  background-color: $green;
  margin-top: 25px;
  &.add {
    width: fit-content;
    height: fit-content;
    display: flex;
    margin-bottom: 10px;
  }
  &.red {
    background-color: $red;
  }
}
.title {
  font-weight: 600;
  color: $lightFont;
  padding-bottom: 2px;
  padding-top: 4px;
}
.flex {
  display: flex;
  &.column {
    flex-direction: column;
  }
}
.content-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.sku-list {
  height: fit-content;
  border: 1px solid rgba(60, 60, 60, 0.26);
  border-radius: 0.2rem;
  margin-top: 10px;
  padding: 10px;
}
h3 {
  width: fit-content;
}
.info-container {
  max-width: 422px;
}
.info-text {
  font-size: small;
  color: $lightFont;
  margin-top: 0.5em;
}
</style>
