<template>
  <div>
    <p class="title" style="margin-bottom: 15px">{{ $t('Email Alert') }}</p>
    <div class="tabs">
      <ul class="tabs__head">
        <li
          :class="['tabs__head--item', { active: tab.active == 'email-list' }]"
          @click="tab.active = 'email-list'"
        >
          <span> {{ $t('Email list') }}</span>
        </li>
        <li
          :class="[
            'tabs__head--item',
            { active: tab.active == 'email-config' },
          ]"
          @click="tab.active = 'email-config'"
        >
          <span> {{ $t('Email config') }}</span>
        </li>
      </ul>
      <div v-if="tab.active == 'email-list'">
        <EmailAlertList @editAlert="PassAlertOrder($event)"></EmailAlertList>
      </div>
      <div v-if="tab.active == 'email-config'">
        <EmailAlertConfig :edit-alert="editAlert" ></EmailAlertConfig>
      </div>
    </div>
  </div>
</template>
<script>
import EmailAlertConfig from '@/components/blocks/Settings/SettingsEmailAlert/EmailAlertConfig';
import EmailAlertList from '@/components/blocks/Settings/SettingsEmailAlert/EmailAlertList';
import { BPA } from '@/helpers/BPA'
 
export default {
  mixins: [ BPA ],
  components: {
    EmailAlertList,
    EmailAlertConfig,
  },
  data() {
    return {
      tab: {
        active: 'email-list',
      },
      editAlert: {},
    };
  },
  methods: {
    PassAlertOrder(test) {
      this.editAlert = test
      this.tab.active = 'email-config'      
    }    
  }
};
</script>
<style lang="scss" scoped></style>
