<template>
  <main class="view">
    <div class="row col-12 settings-wrapper">
      <div class="col col-3 settings-wrapper__menu">
        <p class="title">{{ $t('Settings') }}</p>
        <ul>
          <!--
            <li :class="SettingsView == 1 ? 'active' : ''">
              <router-link class="link" :to="{ name: 'settings-email'}"> Email </router-link>
              <ol>
                <li><a @click.prevent="ShowEmailTemplates" href="#">Show email templates</a></li>
                <li><a @click.prevent="CreateEmailTemplate" href="#">Create email template</a></li>
              </ol>
            </li>
            <li :class="SettingsView == 2 ? 'active' : ''">
              <router-link class="link" :to="{ name: 'settings-country'}"> Country & taxes </router-link>
              <ol>
                <li><a href="#">Show tax classes</a></li>
                <li><a href="#">Create tax class</a></li>
              </ol>
            </li>
          -->
          <li :class="SettingsView == 0 ? 'active' : ''">
            <router-link class="link" :to="{name: 'settings-printer'}">{{ $t('Printer') }}</router-link>
          </li>
          <li data-name="dashboard" v-if="permission.dashboard" :class="{active: SettingsView == 3}">
            <router-link class="link" :to="{ name: 'settings-dashboard'}">{{$t('Dashboard settings')}}</router-link>
          </li>
          <li data-name="qrcode" v-if="permission.qrcode" :class="{active: SettingsView == 4}">
            <router-link class="link" :to="{name: 'settings-qrcode'}">{{ $t('QR code') }}</router-link>
          </li>
          <li data-name="company-config" v-if="permission.company_config" :class="{active: SettingsView == 5}">
            <router-link class="link" :to="{name: 'settings-company-config'}">{{ $t('Company') }}</router-link>
          </li>
          <li data-name="global-config" v-if="permission.global_config" :class="{active: SettingsView == 6}">
            <router-link class="link" :to="{name: 'settings-global-config'}">{{ $t('Configuration') }}</router-link>
          </li>
          <li data-name="bulk-invoicing-config" v-if="permission.bulk_invoicing_config" :class="{active: SettingsView == 7}">
            <router-link class="link" :to="{name: 'settings-bulk-invoicing-config'}">{{ $t('Bulk invoicing') }}</router-link>
          </li>
          <li data-name="announcement-config" v-if="permission.announcement_config" :class="{active: SettingsView == 8}">
            <router-link class="link" :to="{name: 'settings-announcement-config'}">{{ $t('Announcements') }}</router-link>
          </li>
          <li data-name="warehouse-map" v-if="permission.warehouse_map" :class="{active: SettingsView == 9}">
            <router-link class="link" :to="{name: 'settings-warehouse-map'}">{{ $t('Warehouse map') }}</router-link>
          </li>
          <li data-name="shipping-config" v-if="permission.shipping_config" :class="{active: SettingsView == 10}">
            <router-link class="link" :to="{name: 'settings-shipping-config'}">{{ $t('Shipping') }}</router-link>
          </li>
          <li data-name="packing-config" v-if="permission.packing_config" :class="{active: SettingsView == 11}">
            <router-link class="link" :to="{name: 'settings-packing-config'}">{{ $t('Packing') }}</router-link>
          </li>
          <li data-name="api-config" v-if="permission.api_config" :class="{active: SettingsView == 12}">
            <router-link class="link" :to="{name: 'settings-api-config'}">{{ $t('API') }}</router-link>
          </li>
          <li data-name="dvr-config" v-if="permission.dvr_config" :class="{active: SettingsView == 13}">
            <router-link class="link" :to="{name: 'settings-dvr-config'}">{{ $t('DVR') }}</router-link>
          </li>
          <li data-name="rma-config" v-if="permission.rma_config" :class="{active: SettingsView == 14}">
            <router-link class="link" :to="{name: 'settings-rma-config'}">{{ $t('RMA') }}</router-link>
          </li>
          <li data-name="campaign-config" v-if="permission.campaign_config" :class="{active: SettingsView == 15}">
            <router-link class="link" :to="{name: 'settings-campaign-config'}">{{ $t('Campaigns') }}</router-link>
          </li>
          <li data-name="address-book" v-if="permission.address_book" :class="{active: SettingsView == 16}">
            <router-link class="link" :to="{name: 'settings-address-book'}">{{ $t('Address book') }}</router-link>
          </li>
          <li data-name="serial-number-config" v-if="permission.serial_number_config" :class="{active: SettingsView == 17}">
            <router-link class="link" :to="{name: 'settings-serial-number-config'}">{{ $t('Serial number') }}</router-link>
          </li>
          <!--
          <li name="printer-management" v-if="Check([0, 17, 18]) && IsRasPi()" :class="{active: SettingsView == 18}">
            <router-link class="link" :to="{name: 'settings-printer-management'}"> Printer management </router-link>
          </li>
          -->
          <li data-name="cache" :class="{active: SettingsView == 19}">
            <router-link class="link" :to="{name: 'settings-cache'}">{{ $t('Cache') }}</router-link>
          </li>
          <li data-name="about" :class="{active: SettingsView == 20}">
            <router-link class="link" :to="{name: 'email-alert'}">{{ $t('Email alert') }}</router-link>
          </li>
          <li data-name="about" :class="{active: SettingsView == 21}">
            <router-link class="link" :to="{name: 'settings-about'}">{{ $t('About') }}</router-link>
          </li>
        </ul>
      </div>
      <div class="col col-9 settings-wrapper__content">
        <div class="col settings-wrapper__content--card" v-if="SettingsView == RouteMapper['serial-number-config'] && Check([0, 17, 18]) && IsMainCompany()">
          <SettingsCompanyUniqueIdentifierView v-if="SettingsView == RouteMapper['serial-number-config'] && Check([0, 17, 18]) && IsMainCompany()" />
        </div>
        <div class="col settings-wrapper__content--card" :class="{'full-height': [3, 4, 7, 8, 12].includes(SettingsView)}">
          <!-- <SettingsEmailView v-if="SettingsView == RouteMapper.email" /> -->
          <SettingsDashboardView v-if="SettingsView == RouteMapper['dashboard'] && permission.dashboard"/>
          <SettingsPrinterView v-if="SettingsView == RouteMapper['printer'] && permission.printer" />
          <SettingsQRCodeView v-if="SettingsView == RouteMapper['qrcode'] && permission.qrcode" />
          <SettingsCompanyConfigView v-if="SettingsView == RouteMapper['company-config'] && permission.company_config" />
          <SettingsGlobalConfigView v-if="SettingsView == RouteMapper['global-config'] && permission.global_config" />
          <SettingsBulkInvoicingConfigView v-if="SettingsView == RouteMapper['bulk-invoicing-config'] && permission.bulk_invoicing_config" />
          <SettingsAnnouncementConfigView v-if="SettingsView == RouteMapper['announcement-config'] && permission.announcement_config" />
          <SettingsWarehouseMapView v-if="SettingsView == RouteMapper['warehouse-map'] && permission.warehouse_map" />
          <SettingsShippingConfigView v-if="SettingsView == RouteMapper['shipping-config'] && permission.shipping_config" />
          <SettingsPackingConfigView v-if="SettingsView == RouteMapper['packing-config'] && permission.packing_config" />
          <SettingsCMSConfigView v-if="SettingsView == RouteMapper['api-config'] && permission.api_config" />
          <SettingsDVRConfigView v-if="SettingsView == RouteMapper['dvr-config'] && permission.dvr_config" />
          <SettingsRMAConfigView v-if="SettingsView == RouteMapper['rma-config'] && permission.rma_config" />
          <SettingsCampaignConfigView v-if="SettingsView == RouteMapper['campaign-config'] && permission.campaign_config" />
          <SettingsAddressBookView v-if="SettingsView == RouteMapper['address-book'] && permission.address_book" />
          <SettingsProductUniqueIdentifierView v-if="SettingsView == RouteMapper['serial-number-config'] && permission.serial_number_config" />
          <!-- <SettingsPrinterManagementView v-if="SettingsView == RouteMapper['printer-management'] && Check([0, 17, 18]) && IsRasPi()" /> -->
          <SettingsCacheView v-if="SettingsView == RouteMapper['cache']" />
          <SettingsEmailAlert v-if="SettingsView == RouteMapper['email-alert']"></SettingsEmailAlert>
          <SettingsAboutView v-if="SettingsView == RouteMapper['about']" />
        </div>
      </div>
    </div>
  </main>
</template>

<script>
  //import SettingsEmailView from '@/components/blocks/Settings/SettingsEmailView';
  import SettingsPrinterView from '@/components/blocks/Settings/SettingsPrinterView';
  import SettingsQRCodeView from '@/components/blocks/Settings/SettingsQRCodeView';
  import SettingsCompanyConfigView from '@/components/blocks/Settings/SettingsCompanyView';
  import SettingsGlobalConfigView from '@/components/blocks/Settings/SettingsGlobalConfigView';
  import SettingsBulkInvoicingConfigView from '@/components/blocks/Settings/SettingsBulkInvoicingConfigView';
  import SettingsAnnouncementConfigView from '@/components/blocks/Settings/SettingsAnnouncementConfigView';
  import SettingsWarehouseMapView from '@/components/blocks/Settings/SettingsWarehouseMapView';
  import SettingsShippingConfigView from '@/components/blocks/Settings/SettingsShippingConfigView';
  import SettingsPackingConfigView from '@/components/blocks/Settings/SettingsPackingConfigView';
  import SettingsCMSConfigView from '@/components/blocks/Settings/SettingsCMSConfigView';
  import SettingsDVRConfigView from '@/components/blocks/Settings/SettingsDVRConfigView';
  import SettingsRMAConfigView from '@/components/blocks/Settings/SettingsRMAConfigView';
  import SettingsCampaignConfigView from '@/components/blocks/Settings/SettingsCampaignConfigView';
  import SettingsAddressBookView from '@/components/blocks/Settings/SettingsAddressBookView';
  import SettingsCompanyUniqueIdentifierView from '@/components/blocks/Settings/SettingsCompanyUniqueIdentifierView';
  import SettingsProductUniqueIdentifierView from '@/components/blocks/Settings/SettingsProductUniqueIdentifierView';
  //import SettingsPrinterManagementView from '@/components/blocks/Settings/SettingsPrinterManagementView';
  import SettingsCacheView from '@/components/blocks/Settings/SettingsCacheView';
  import SettingsAboutView from '@/components/blocks/Settings/SettingsAboutView';
  import SettingsDashboardView from '@/components/blocks/Settings/SettingsDashboardView';
  import SettingsEmailAlert from '@/components/blocks/Settings/SettingsEmailAlertView'

  //import { Permissions } from '@/helpers/Permissions';
  import { Config } from '@/helpers/Config';
  import { BPA } from '@/helpers/BPA';
  //import isPi from 'detect-rpi';

  export default {
    name: 'Settings',
    components: {
      //SettingsEmailView,
      SettingsDashboardView,
      SettingsPrinterView,
      SettingsQRCodeView,
      SettingsCompanyConfigView,
      SettingsGlobalConfigView,
      SettingsBulkInvoicingConfigView,
      SettingsAnnouncementConfigView,
      SettingsWarehouseMapView,
      SettingsShippingConfigView,
      SettingsPackingConfigView,
      SettingsCMSConfigView,
      SettingsDVRConfigView,
      SettingsRMAConfigView,
      SettingsCampaignConfigView,
      SettingsAddressBookView,
      SettingsCompanyUniqueIdentifierView,
      SettingsProductUniqueIdentifierView,
      //SettingsPrinterManagementView,
      SettingsCacheView,
      SettingsAboutView,
      SettingsEmailAlert
    },
    data() {
      return {
        SettingsView: 0,
        RouteMapper: {
          'printer': 0,
          //'email': 1,
          //'country': 2,
          'dashboard': 3,
          'qrcode': 4,
          'company-config': 5,
          'global-config': 6,
          'bulk-invoicing-config': 7,
          'announcement-config': 8,
          'warehouse-map': 9,
          'shipping-config': 10,
          'packing-config': 11,
          'api-config': 12,
          'dvr-config': 13,
          'rma-config': 14,
          'campaign-config': 15,
          'address-book': 16,
          'serial-number-config': 17,
          //'printer-management': 18,
          'cache': 19,
          'email-alert': 20,
          'about': 21
        },
        permission: {
          dashboard: this.Check([/*0, 17, 18*/ 'admin', 'dashboard_admin', 'dashboard']),
          printer: this.Check([/*0, 7, 8, 17*/ 'admin', 'settings_admin', 'parcels_admin', 'parcels'])/* && this.CheckIp()*/,
          qrcode: this.Check([/*0, 17, 19, 20*/ 'admin', 'settings_admin', 'pick_admin', 'pick']) && this.IsMainCompany(),
          company_config: this.Check([/*0, 17, 18*/ 'admin', 'settings_admin', 'settings']),
          global_config: this.Check([/*0, 17*/ 'admin', 'settings_admin']) && this.IsMainCompany(),
          bulk_invoicing_config: this.Check([/*0, 17, 18*/ 'admin', 'settings_admin', 'settings']),
          announcement_config: this.Check([/*0, 17*/ 'admin', 'settings_admin']),
          warehouse_map: this.Check([/*0, 17, 18*/ 'admin', 'settings_admin', 'settings']) && this.IsMainCompany(),
          shipping_config: this.Check([/*0, 17, 18*/ 'admin', 'settings_admin']),
          packing_config: this.Check([/*0, 17, 18*/ 'admin', 'settings_admin']),
          api_config: this.Check([/*0, 17*/ 'admin', 'settings_admin']),
          dvr_config: this.Check([/*0, 17*/ 'admin', 'settings_admin']) && this.IsMainCompany(),
          rma_config: this.Check([/*0, 23*/ 'admin', 'rma_admin']),
          campaign_config: this.Check([/*0, 17*/ 'admin', 'settings_admin']),
          address_book: this.Check([/*0, 7, 8*/ 'admin', 'parcels_admin', 'parcels'])/* && this.CheckIp()*/,
          serial_number_config: this.Check([/*0, 17*/ 'admin', 'settings_admin'])// && this.IsMainCompany()
        }
      }
    },
    created() {
      /*
      console.log(BPA.permissions())
      console.log(BPA.permissions(true))
      //console.log(BPA.permissions(false))
      */
      this.IsInRouteMapper();
    },
    mounted() {
      const settings_menu = Array.from(document.querySelectorAll('.settings-wrapper__menu > ul > li'));
      const route = Object.keys(this.RouteMapper).find(route => this.RouteMapper[route] == this.SettingsView);
      !settings_menu.map(li => li.dataset.name).includes(route) && settings_menu[0]?.querySelector('a').click();
    },
    watch: {
      $route() {
        this.IsInRouteMapper();
      }
    },
    methods: {
      IsInRouteMapper() {
        let name = this.$route.name.replace('settings-', '');
        let routes = Object.keys(this.RouteMapper);
        if (!routes.includes(name)) name = routes[0];
        this.SettingsView = name ? this.RouteMapper[name] : 0;
      },
      SetSettingsView(id) {
        this.SettingsView = id;
      },
      ShowEmailTemplates() {
        this.$eventHub.$emit('ShowEmailTemplates');
      },
      CreateEmailTemplate() {
        this.$eventHub.$emit('ShowCreateEmailTemplate');
      },
      Check(required) {
        return BPA.permissions(required).length;
      },
      IsMainCompany() {
        return BPA.util.IsMainCompany();
      },
      CheckIp() {
        const IP = BPA.util.GetStoredIP();
        return Object.keys(IP).length == 2 && Config.ValidatePublicIpAddress(IP.packing, IP.public);
      },
      IsRasPi() {
        //console.log('Platform:', navigator.platform, navigator.platform.toLowerCase().includes('linux'))
        //console.log('User agent:', navigator.userAgent, navigator.userAgent.toLowerCase().includes('rasp'))
        var isPi = navigator.platform.toLowerCase().includes('linux') && navigator.userAgent.toLowerCase().includes('rasp');
        //console.log('isPi?:', isPi)
        return isPi;
        //return isPi();
      }
    }
  }
</script>

<style scoped>
</style>