<template>
  <div class="settings settings-global-config">
    <p class="title">{{ $t('Configuration') }}</p>
    <div class="flex row">
      <div class="flex column" style="width: 100%;">
        <div class="flex column">
          <h3 class="heading">{{ $t('Paperless orders') }}</h3>
          <div class="label" style="margin-top: 10px; flex-direction: row;">
            <div class="label">
              <div class="toggle">
                <div class="label">{{ $t('Active') }}</div>
                <div class="checkbox-toggle">
                  <input type="checkbox" v-model="config.UsePaperlessOrders" :disabled="readonly">
                  <span class="toggle"></span>
                </div>
              </div>
            </div>
            <div class="label" style="margin-left: 60px; width: 100%;">
              <span class="label-text">{{ $t('Order number') }}</span>
              <input class="v-input" name="order_id" v-model="mapping.order_id" :disabled="readonly" @keydown.enter="HandlePaperlessMapping" :placeholder="$t('1:123456789')">
            </div>
            <div class="label" style="margin: auto 0 0;">
              <div style="width: 30px; height: 34px; margin: 0 15px;">
                <svg focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style="height: 30px; margin-top: 3px;">
                  <path d="M6.99 11L3 15l3.99 4v-3H14v-2H6.99v-3zM21 9l-3.99-4v3H10v2h7.01v3L21 9z" fill="#70757a" />
                </svg>
              </div>
            </div>
            <div class="label" style="width: 100%;">
              <span class="label-text">{{ $t('Paperless') + ' ID' }}</span>
              <input class="v-input" name="paperless_id" v-model="mapping.paperless_id" :disabled="readonly" @keydown.enter="HandlePaperlessMapping" :placeholder="$t('#123456')">
            </div>
          </div>
        </div>
      </div>

      <div class="flex column" style="width: 100%;">
        <div class="flex column" style="width: 100%; margin-top: 40px;">
          <h3 class="heading" style="margin-bottom: -5px;">
            {{ $t('Barcode stickers for wagon boxes') + ' (' + $t('local settings') + ')' }}
          </h3>
          <div class="label" style="width: 100%; margin-top: 0px; flex-direction: row; flex-wrap: wrap;">
            <div class="label" style="margin: 15px 30px 0 0; flex-shrink: 1;">
              <span class="label-text">{{ $t('Amount of stickers') }}</span>
              <input type="number" class="v-input" name="amount" step="1" min="0" v-model="sticker.amount" :disabled="readonly" style="width: 100%;" @keydown.enter="$event.target.blur()" @blur="SetValue(null, 'sticker')">
            </div>
            <div class="label" style="margin: 15px 30px 0 0; flex-shrink: 1;">
              <span class="label-text">{{ $t('Barcodes per row') }}</span>
              <input type="number" class="v-input" name="barcodes_per_row" step="1" min="0" v-model="sticker.barcodes_per_row" :disabled="readonly" style="width: 100%;" @keydown.enter="$event.target.blur()" @blur="SetValue(null, 'sticker')">
            </div>
            <div class="label" style="margin: 15px 30px 0 0; flex-shrink: 1;">
              <span class="label-text">{{ $t('Barcode height mm') }}</span>
              <input type="number" class="v-input" name="barcode_height" step="1" min="0" v-model="sticker.barcode_height" :disabled="readonly" style="width: 100%;" @keydown.enter="$event.target.blur()" @blur="SetValue(null, 'sticker')">
            </div>
            <div class="label" style="margin: 15px 30px 0 0; flex-shrink: 1; min-width: 204px;">
              <span class="label-text">{{ $t('Line color') }}</span>
              <input type="color" class="v-input" name="line_color" v-model="sticker.line_color" :disabled="readonly" style="width: 100%; padding: 0; cursor: pointer;" @input="SetValue(null, 'sticker')">
            </div>
          </div>
          <div class="label" style="width: 100%; flex-direction: row; flex-wrap: wrap;">
            <div class="label" style="margin-top: 15px; margin-right: 30px;">
              <span class="label-text">{{ $t('Margin top mm') }}</span>
              <input type="number" class="v-input" name="margin_top_mm" step="1" min="0" v-model="sticker.margin_top_mm" :disabled="readonly" style="width: 100%;" @keydown.enter="$event.target.blur()" @blur="SetValue(null, 'sticker')">
            </div>
            <div class="label" style="margin-top: 15px; margin-right: 30px;">
              <span class="label-text">{{ $t('Margin right mm') }}</span>
              <input type="number" class="v-input" name="margin_right_mm" step="1" min="0" v-model="sticker.margin_right_mm" :disabled="readonly" style="width: 100%;" @keydown.enter="$event.target.blur()" @blur="SetValue(null, 'sticker')">
            </div>
            <div class="label" style="margin-top: 15px; margin-right: 30px;">
              <span class="label-text">{{ $t('Margin bottom mm') }}</span>
              <input type="number" class="v-input" name="margin_bottom_mm" step="1" min="0" v-model="sticker.margin_bottom_mm" :disabled="readonly" style="width: 100%;" @keydown.enter="$event.target.blur()" @blur="SetValue(null, 'sticker')">
            </div>
            <div class="label" style="margin-top: 15px; margin-right: 30px;">
              <span class="label-text">{{ $t('Margin left mm') }}</span>
              <input type="number" class="v-input" name="margin_left_mm" step="1" min="0" v-model="sticker.margin_left_mm" :disabled="readonly" style="width: 100%;" @keydown.enter="$event.target.blur()" @blur="SetValue(null, 'sticker')">
            </div>
            <div class="label" style="margin-top: 15px; margin-right: 30px;">
              <span class="label-text">{{ $t('Paper width mm') }}</span>
              <input type="number" class="v-input" name="paper_width_mm" step="1" min="0" v-model="sticker.paper_width_mm" :disabled="readonly" style="width: 100%;" @keydown.enter="$event.target.blur()" @blur="SetValue(null, 'sticker')">
            </div>
            <div class="label" style="margin-top: 15px; margin-right: 30px;">
              <span class="label-text">{{ $t('Paper height mm') }}</span>
              <input type="number" class="v-input" name="paper_height_mm" step="1" min="0" v-model="sticker.paper_height_mm" :disabled="readonly" style="width: 100%;" @keydown.enter="$event.target.blur()" @blur="SetValue(null, 'sticker')">
            </div>
          </div>
          <div class="label" style="width: 100%; flex-direction: row; flex-wrap: wrap; margin-top: 10px;">
            <label class="label" style="flex-direction: row-reverse; align-items: center; margin: 15px 30px 0 0; flex-shrink: 0; min-height: 34px; align-self: end;">
              <span class="label-text">{{ $t('Fit to page') }}</span>
              <div class="v-wrapper checkbox">
                <label class="v-checkbox-label" style="margin-right: 10px;">
                  <input class="v-checkbox" type="checkbox" name="fit_to_page" v-model="sticker.fit_to_page" :checked="sticker.fit_to_page == true" :disabled="readonly" @change="SetValue(null, 'sticker')">
                  <span class="v-checkbox-toggle" style="width: 24px; height: 24px;" />
                </label>
              </div>
            </label>
            <label class="label" style="flex-direction: row-reverse; align-items: center; margin: 15px 30px 0 0; flex-shrink: 0; min-height: 34px; align-self: end;">
              <span class="label-text">{{ $t('Display value') }}</span>
              <div class="v-wrapper checkbox">
                <label class="v-checkbox-label" style="margin-right: 10px;">
                  <input class="v-checkbox" type="checkbox" name="display_value" v-model="sticker.display_value" :checked="sticker.display_value == true" :disabled="readonly" @change="SetValue(null, 'sticker')">
                  <span class="v-checkbox-toggle" style="width: 24px; height: 24px;" />
                </label>
              </div>
            </label>
            <label class="label" style="flex-direction: row-reverse; align-items: center; margin: 15px 30px 0 0; flex-shrink: 0; min-height: 34px; align-self: end;">
              <span class="label-text">{{ $t('Page break after row') }}</span>
              <div class="v-wrapper checkbox">
                <label class="v-checkbox-label" style="margin-right: 10px;">
                  <input class="v-checkbox" type="checkbox" name="page_break_after_row" v-model="sticker.page_break_after_row" :checked="sticker.page_break_after_row == true" :disabled="readonly" @change="SetValue(null, 'sticker')">
                  <span class="v-checkbox-toggle" style="width: 24px; height: 24px;" />
                </label>
              </div>
            </label>
          </div>
          <div class="label" style="width: 100%; flex-direction: row; flex-wrap: wrap;">
            <div class="courier-actions" style="min-width: 170px; margin-top: auto; padding-top: 25px; flex-shrink: 0;">
              <a class="button action-toggle" style="min-width: 115px; height: 34px; margin-top: auto;" @click.prevent="$event.target.classList.toggle('visible')" href="">
                {{ $t('Create') }}
                <ul class="button-actions">
                  <li>
                    <a @click.prevent="CreateBarcodeStickers('preview'), $event.target.closest('.action-toggle').classList.remove('visible')" href="" class="success">{{ $t('Preview label') }}</a>
                  </li>
                  <li>
                    <a @click.prevent="CreateBarcodeStickers('download'), $event.target.closest('.action-toggle').classList.remove('visible')" href="" class="success">{{ $t('Download') }}</a>
                  </li>
                  <li>
                    <a @click.prevent="CreateBarcodeStickers('print'), $event.target.closest('.action-toggle').classList.remove('visible')" href="">{{ $t('Print') }}</a>
                  </li>
                </ul>
                <span class="button-icon caret-up" />
              </a>
              <button class="button red" style="min-width: 115px; margin-left: 30px;" @click.prevent="ResetBarcodeStickerConfig">{{ $t('Reset') }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="flex row" style="margin-top: 40px;">
      <div class="flex column" style="width: 100%;">
        <div class="flex column">
          <h3 class="heading">{{ $t('Product links') }}</h3>
          <div class="settings__group" style="width: 100%; display: flex; flex-direction: row;">
            <div class="list-wrapper" style="width: 100%; display: flex; flex-direction: column;">
              <div class="simple-list__title">
                <p class="max-width-300">{{ $t('Portal') }}</p>
                <p>{{ $t('URL') }}</p>
                <div class="simple-list__item item-wrapper" style="width: auto; padding: 0;">
                  <button :class="['item-wrapper__button remove', {hover: product_link.list.items.length}]" :disabled="!product_link.list.items.length" @click="ClearProductLinkList()" @mouseenter="HoverAll" @mouseleave="HoverAll" />
                </div>
              </div>
              <ul class="simple-list odd-even">
                <li class="simple-list__add">
                  <div class="item-wrapper noflex max-width-300">
                    <v-select name="platform" ref="name" v-model="product_link.option.selected" @input="SetProductLinkName" :options="product_link.options.available" taggable>
                      <template v-slot:selected-option="option">
                        <div class="truncate">
                          <span :class="['portal-icon', ProductLinkLogoClassName(option.label)]">{{ option.label }}</span>
                        </div>
                      </template>
                      <template slot="option" slot-scope="option">
                        <div class="truncate">
                          <span :class="['portal-icon', ProductLinkLogoClassName(option.label)]">{{ option.label }}</span>
                        </div>
                      </template>
                      <template v-slot:no-options><span /></template>
                    </v-select>
                  </div>
                  <div class="item-wrapper">
                    <input class="item-wrapper__input lowercase" ref="link" v-model="product_link.link" @keydown.space.prevent @keydown.enter="$event.target.blur(), AddProductLink()" @blur="product_link.link = product_link.link.toLowerCase().replace(/\s/g, '')" spellcheck="false">
                  </div>
                  <div class="item-wrapper" style="width: auto;">
                    <button class="item-wrapper__button add" @click="AddProductLink"></button>
                  </div>
                </li>
                <li class="simple-list__item" :key="index" v-for="(item, index) in product_link.list.items">
                  <div class="item-wrapper max-width-300">
                    <p class="item-wrapper__text" style="padding-left: 9px;">
                      <span :class="['simple-list-icon', ProductLinkLogoClassName(item.name)]" />
                      <span>{{ item.name }}</span>
                    </p>
                    <!--
                    <span class="simple-list-icon" style="left: 10px; position: absolute;" />
                    <input class="item-wrapper__input" style="padding-left: 40px;" v-model="item.name" @input="HandleProductLinkLogo" @change="EditProductLink(item, 'name')" @keydown.enter="$event.target.blur()" @blur="item.name = (item.name).replace(/\s+/g, ' ').trim()" spellcheck="false">
                    -->
                  </div>
                  <div class="item-wrapper">
                    <input class="item-wrapper__input lowercase" v-model="item.link" @keydown.space.prevent @change="EditProductLink(item, 'link')" @keydown.enter="$event.target.blur()" @blur="item.link = (item.link).toLowerCase().replace(/\s/g, '')" spellcheck="false">
                  </div>
                  <div class="item-wrapper" style="width: auto;">
                    <button class="item-wrapper__button remove" @click="RemoveProductLink(item)" @mouseenter="HoverButton" @mouseleave="HoverButton" />
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="flex row sticky-footer">
      <div class="flex column" style="width: auto; margin-left: auto;">
        <button class="button green" style="min-width: 115px;" @click.prevent="ConfirmChanges" :disabled="readonly">{{ $t('Save') }}</button>
      </div>
    </div>

    <transition name="fade">
      <div @mousedown="PopupMouseEvent" @mouseup="PopupMouseEvent" class="validate-popup" v-if="sticker_printer.popup.show">
        <div class="validate-popup__content">
          <form id="popup-form" @submit.prevent>
            <button type="submit" hidden />
            <div class="titles">
              <p class="title">Are you sure?</p>
            </div>
            <div style="width: 750px; margin-bottom: 50px;">
              <div class="flex-group flex-column">
                <div class="flex-group">
                  <div class="flex-row">
                    <div class="flex-column" style="width: 100%; margin-right: 30px;">
                      <div class="label required">
                        <span class="label-text">{{ $t('Label printer') }}</span>
                        <div class="v-select-wrapper">
                          <v-select v-model="sticker_printer.selected" :options="sticker_printer.options" @input="GetPopupPrinterDetails" :clearable="false">
                            <template #search="{attributes, events}">
                              <input class="vs__search" v-bind="attributes" v-on="events" :required="!sticker_printer.selected.id">
                            </template>
                            <template v-slot:selected-option="option">
                              <div class="truncate" :title="sticker_printer.selected.title">
                                <span :class="['state', option.color]">{{ option.label }}</span>
                              </div>
                            </template>
                            <template slot="option" slot-scope="option">
                              <div class="truncate" :title="option.title">
                                <span :class="['state', option.color]">{{ option.label }}</span>
                              </div>
                            </template>
                          </v-select>
                        </diV>
                      </div>
                    </div>
                    <div class="flex-column" style="width: 400px;">
                      <div class="label">
                        <span class="label-text">{{ $t('Paper') }}</span>
                        <div class="v-select-wrapper">
                          <v-select :class="{'vs--loading': sticker_printer.popup.loading}" v-model="sticker_printer.paper.selected" :options="sticker_printer.paper.options">
                            <template slot="spinner">
                              <div class="vs__spinner" v-show="sticker_printer.popup.loading" />
                            </template>
                          </v-select>
                        </diV>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="actions">
              <a class="btn-stateless cancel" @click.prevent="sticker_printer.popup.cancel" href="">No</a>
              <a class="btn-success confirm" @click.prevent="sticker_printer.popup.confirm" href="">Yes, print them</a>
            </div>
          </form>
        </div>
      </div>
    </transition>
    
    <div class="loading" v-if="loading">
      <div class="loading-element">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
    
  </div>
</template>

<script>
  import { Tool } from '@/helpers/Tool';
  import { BPA } from '@/helpers/BPA';

  export default {
    name: 'SettingsGlobalConfigView',
    data() {
      return {
        vue: this,
        loading: true,
        config: {
          UsePaperlessOrders: false
        },
        stored: {},
        mapping: {
          order_id: '',
          paperless_id: ''
        },
        printers: [],
        printer: {
          a4: '',
          label: '',
          options: {
            a4: [],
            all: [],
            label: []
          },
          filters: {
            a4: [],
            label: []
          }
        },
        sticker: {
          amount: 5,
          barcodes_per_row: 1,
          barcode_height: 30,
          line_color: '#000000',
          margin_top_mm: 3,
          margin_right_mm: 3,
          margin_bottom_mm: 3,
          margin_left_mm: 3,
          paper_width_mm: 62,
          paper_height_mm: 29,
          fit_to_page: true,
          display_value: true,
          page_break_after_row: true
        },
        sticker_default: {},
        sticker_printer: {
          popup: {
            show: false,
            loading: false,
            cancel: () => {},
            confirm: () => {}
          },
          paper: {
            selected: '',
            options: []
          },
          selected: '',
          options: []
        },
        product_link: {
          options: {
            default: [
              {label: 'Akeneo'},
              {label: 'Magento'},
              {label: 'Odoo'}
            ],
            available: []
          },
          option: {
            selected: null
          },
          name: '',
          link: '',
          actions: ['create', 'update', 'delete'],
          list: {
            items: [],
            cached: [],
            create: [],
            delete: [],
            update: []
          }
        },
        sticker_show: false,
        readonly: !BPA.permissions(['admin', 'settings_admin']).length
      }
    },
    async created() {
      this.sticker_default = this.CloneObject(this.sticker);
      this.sticker_show = BPA.permissions(['admin', 'settings_admin']).length;
      this.product_link.options.available = this.CloneObject(this.product_link.options.default);
      this.sticker_printer.options = this.CloneObject(this.printer.options.label);

      await BPA.api.GetPrinterList().then(response => {
        return BPA.api.response({response, return: 'json'});
      }).then(async response => {
        if (!response.ok) return this.loading = false;
        this.printers = response.result || [];
        let filters = this.printer.filters;
        let filter = this.GetValue('filter') || {};
        for (let type of ['all', 'a4', 'label']) {
          this.printer.options[type] = Tool.PrinterOptions(this.printers, type != 'all' ? type : '');
        }
        this.printer.filters = {...filters, ...filter};
        for (let type of ['a4', 'label']) {
          this.printer.filters[type].sort();
        }
        await this.GetValues();
        this.loading = false;
      }).catch(e => e);
      this.sticker_printer.options = this.CloneObject(this.printer.options.label);
      
      await this.RefreshProductLinkList();
      await this.RefreshGlobalConfig();
      this.loading = false;
    },
    mounted() {
      let dropdown_toggle = document.querySelector('.v-select[name="platform"] > .vs__dropdown-toggle');
      if (dropdown_toggle) {
        dropdown_toggle.classList.add('borderless');
        Object.assign(dropdown_toggle.style, {
          minHeight: '32px', 
          height: '32px', 
          border: 0
        });
      }
    },
    methods: {
      ConsoleLog(output) {
        console.log(output);
      },
      Capitalize(string) {
        return Tool.Capitalize(string);
      },
      CloneObject(object = {}) {
        return Tool.CloneObject(object);
      },
      GetValue(type) {
        return BPA.printer.fetch(type);
      },
      SetValue(value = '', type = '', get = true) {
        if (type == 'sticker') {
          value = this.CloneObject(this.sticker);
          for (let key in value) {
            value[key] = String(value[key]);
          }
        }
        BPA.printer.handler({key: type, set: value});
        if (get !== false) this.GetValues();
      },
      async GetValues(run = true) {
        if (run === false) return;
        let values = this.GetValue('sticker');
        if (values) {
          for (let key in values) {
            if (/true|false/.test(values[key])) {
              values[key] = values[key] === 'true';
            } else if (/^#[a-zA-Z0-9]{3,6}$/.test(values[key])) {
              values[key] = String(values[key]);
            } else {
              values[key] = Number(values[key]);
            }
          }
          this.sticker = values;
        }
      },
      async SaveChanges(changes) {
        let method = {
          update: {
            action: 'Update',
            request: {}
          },
          delete: {
            action: 'Delete',
            request: []
          }
        };
        for (let key in changes) {
          if (changes[key] === '') {
            method.delete.request.push(key);
          } else {
            method.update.request[key] = changes[key];
          }
        }
        for (let type in method) {
          let empty = true;
          let action = method[type].action;
          let request = method[type].request;
          if (Array.isArray(request)) {
            empty = !request.length;
          } else {
            empty = !Object.keys(request).length;
          }
          if (!empty) return await this[`${action}GlobalConfig`](request);
        }
      },
      ConfirmChanges() {
        if (this.readonly) return;
        const findDifference = (data = {}) => {
          return Object.keys(data.new).reduce((diff, key) => {
            if (data.old[key] === data.new[key]) return diff;
            return {...diff, [key]: data.new[key]};
          }, {});
        }
        let data = {
          new: this.CloneObject(this.config), 
          old: this.CloneObject(this.stored)
        };
        let config_changes = findDifference(data);
        let product_link = this.CloneObject(this.product_link);
        let product_link_changes = {};
        let list = product_link.list;
        for (let action of product_link.actions) {
          if (list[action].length) product_link_changes[action] = list[action];
        }
        if (!Object.keys(config_changes).length && !Object.keys(product_link_changes).length) return;
        this.$eventHub.$emit('ValidateModalStart', {
          approve: 'Yes, save changes',
          disapprove: 'No',
          message: 'Saves changes to configuration.',
          type: 'success'
        });
        this.$eventHub.$on('ValidateModalStop', async (approve) => {
          this.$eventHub.$off('ValidateModalStop');
          if (approve) {
            let request = {response: {}, failure: []};
            if (Object.keys(config_changes).length) {
              request.response = await this.SaveChanges(config_changes);
              if (request.response.ok) {
                await this.RefreshGlobalConfig();
              } else {
                request.failure.push('config');
              }
            }
            if (Object.keys(product_link_changes).length) {
              for (let action in product_link_changes) {
                for (let item of product_link_changes[action]) {
                  switch (action) {
                    case 'create': {
                      request.response = await this.CreateProductLink(item);                      
                      break;
                    }
                    case 'update': {
                      request.response = await this.UpdateProductLink(item);
                      break;
                    }
                    case 'delete': {
                      request.response = await this.DeleteProductLink(item.id);
                    }
                  }
                }
              }
              if (request.response.ok) {
                this.RefreshProductLinkList();
              } else {
                request.failure.push('product_link');
              }
            }
            if (!request.failure.length) {
              this.$eventHub.$emit('ShowMessages', {
                message: 'Configuration successfully updated',
                type: 'success',
                hide: 2000
              });
            } else {
              this.$eventHub.$emit('ShowMessages', {
                message: `Configuration failed: [${request.failure.spit(', ')}]`,
                type: 'error',
                close: true
              });
            }
          }
        });
      },
      SuccessfullySaved(request) {
        if (Array.isArray(request)) {
          for (let key of request) {
            this.config[key] = null;
          }
        } else {
          for (let key in request) {
            this.config[key] = request[key];
          }
        }
        this.stored = this.CloneObject(this.config);
        this.$eventHub.$emit('ShowMessages', {
          message: 'Configuration successfully updated',
          type: 'success',
          hide: 2000
        });
      },
      HandlePaperlessMapping(e = {}) {
        if (!e.target) return;
        let input = e.target;
        let type = input.name;
        let mapping = this.mapping;
        let search = mapping[type] || '';
        search = search.replace(/\s/g, '');
        mapping[type] = search;
        input.value = search;
        input.blur();
        if (!search) return;
        /*
        let valid = null;
        if (/order/.test(type)) {
          valid = /^\d:\d+/.test(search);
        }
        if (/paperless/.test(type)) {
          valid = /^#\d+/.test(search);
        }
        if (!valid) {
          return;
        }
        */
        this.loading = true;
        BPA.api.GetPaperlessMapping(search).then(response => {
          return BPA.api.response({response, return: 'json'});
        }).then(response => {
          this.loading = false;
          let json = response.result;
          if (!response.ok || !json) return;
          let items = json.items || [];
          if (!items.length) return;
          let item = items[0];
          if (/order/.test(type)) {
            mapping.paperless_id = item.barcode;
          }
          if (/paperless/.test(type) && item.order) {
            mapping.order_id = item.order.company_id + ':' + item.order.increment_id;
          }
        }).catch(e => e);
      },
      async GetPrinterDetails(printer_id = '') {
        let printer = this.printers.find(printer => printer.id == printer_id);
        if ('papers' in printer) return printer.papers;
        return await BPA.api.GetPrinterDetails(printer_id).then(response => {
          return BPA.api.response({response, return: 'json'});
        }).then(response => {
          if (!response.ok) return printer.papers = null;
          return printer.papers = response.result.papers;
        }).catch(e => e);
      },
      async GetPopupPrinterDetails() {
        let label_printer = this.printer.label;
        let label_options = this.printer.options.label;
        let selected_printer = this.sticker_printer.selected;
        let selected_label_option = label_options.find(option => option.id == selected_printer.id);
        let selected_sticker_option = this.sticker_printer.options.find(option => option.id == selected_printer.id);
        this.sticker_printer.popup.loading = true;
        if (!('papers' in selected_printer)) {
          if (selected_printer.id != label_printer.id) {
            if (!('papers' in selected_label_option)) {
              selected_label_option.papers = await this.GetPrinterDetails(selected_label_option.id);
            }
            selected_sticker_option.papers = selected_label_option.papers;
            selected_printer.papers = selected_label_option.papers;
          } else {
            if (!('papers' in label_printer)) {
              label_printer.papers = await this.GetPrinterDetails(label_printer.id);
            }
            selected_sticker_option.papers = label_printer.papers;
            selected_label_option.papers = label_printer.papers;
            selected_printer.papers = label_printer.papers;
          }
        }
        this.sticker_printer.paper.options = Object.keys(selected_printer.papers);
        this.sticker_printer.paper.selected = '';
        if (/brother|ql-570/i.test(this.sticker_printer.selected.name)) {
          let small_address_label = this.sticker_printer.paper.options.find(option => {
            return ['62mm x 29mm', 'Small Address Label', 'Lille adresselabel'].includes(option);
          });
          if (small_address_label) this.sticker_printer.paper.selected = small_address_label;
        }
        this.sticker_printer.popup.loading = false;
      }, 
      async CreateBarcodeStickers(action) {
        let request = this.CloneObject(this.sticker);
        if (action == 'print') {
          let printer_id = null;
          let printer = this.sticker_printer;
          await new Promise(async (resolve) => {
            let options = printer.options;
            let popup = printer.popup;
            let label_printer = this.printer.label;
            printer.selected = label_printer;
            let default_sticker_printer = () => options.find(option => /brother|ql-570/i.test(option.name));
            if (BPA.util.IsMainCompany()) printer.selected = default_sticker_printer() || label_printer;
            if (!('papers' in printer.selected)) {
              popup.loading = true;
              let paper_options = await this.GetPrinterDetails(printer.selected.id);
              if (paper_options) printer.paper.options = Object.keys(paper_options);
              if (/brother|ql-570/i.test(printer.selected.name)) {
                let small_address_label = printer.paper.options.find(option => {
                  return ['62mm x 29mm', 'Small Address Label', 'Lille adresselabel'].includes(option);
                });
                if (small_address_label) printer.paper.selected = small_address_label;
              }
              popup.loading = false;
            }
            popup.cancel = () => {
              resolve(false);
            }
            popup.confirm = () => {
              printer_id = printer.selected.id;
              popup.show = false;
              resolve(true);
            }
            popup.show = true;
          });
          if (!printer_id) return;
          request = {printer_id, body: request};
          request.options = {fit_to_page: String(request.body.fit_to_page)};
          if (printer.paper.selected) {
            request.options.paper = printer.paper.selected;
          }
          delete request.body.fit_to_page;
        }
        delete request.fit_to_page;
        this.loading = true;
        BPA.api[`CreatePaperlessCode${Tool.Capitalize(action)}`](request).then(response => {
          return BPA.api.response({response, return: action != 'print' ? 'blob' : 'text'});
        }).then(response => {
          this.loading = false;
          if (!response.ok) return;
          if (action != 'print') {
            let blob = response.result;
            if (action == 'preview') {
              let url = window.URL.createObjectURL(blob);
              window.open(url);
              window.URL.revokeObjectURL(url);
            } else {
              BPA.api.download({blob, name: 'paperless_codes', new_tab: BPA.browser == 'firefox'});
            }
          } else {
            this.$eventHub.$emit('ShowMessages', {
              message: 'Barcode stickers successfully sent to printer',
              type: 'success',
              hide: 2000
            });
          }
        });
      },
      ResetBarcodeStickerConfig() {
        if (JSON.stringify(this.sticker) == JSON.stringify(this.sticker_default)) return;
        this.$eventHub.$emit('ValidateModalStart', {
          approve: 'Yes, reset it',
          disapprove: 'No',
          message: 'Resets sticker configuration to default values.',
          type: 'danger'
        });
        this.$eventHub.$on('ValidateModalStop', (approve) => {
          this.$eventHub.$off('ValidateModalStop');
          if (!approve) return;
          this.sticker = this.CloneObject(this.sticker_default);
          BPA.printer.handler({key: 'sticker', set: null});
        });
      },
      PopupMouseEvent(e) {
        let event = e.type;
        let clicked = e.target;
        let printer = this.sticker_printer;
        if (event == 'mousedown') {
          return printer.popup.mousedown = clicked;
        }
        let mousedown = printer.popup.mousedown;
        let popup = document.querySelector('.validate-popup');
        let cancel = popup.querySelector('a.cancel');
        if ([popup, cancel].includes(clicked) && clicked == mousedown) {
          if (printer.popup.cancel.click) {
            printer.popup.cancel.click();
          }
          printer.popup.show = false;
          this.$eventHub.$off('ValidateModalStop');
        }
        printer.popup.mousedown = null;
      },
      PopupConfirm() {
        this.$eventHub.$emit('ValidateModalStop', true);
        this.sticker_printer.popup.show = false;
      },
      async RefreshGlobalConfig() {
        this.config = await this.GetGlobalConfig() || {};
        this.stored = this.CloneObject(this.config);
      },
      async RefreshProductLinkList() {
        let product_link = this.product_link;
        let options = product_link.options;
        let list = product_link.list;
        list.items = await this.GetProductLinkList();
        list.cached = this.CloneObject(list.items);
        for (let type of ['items', 'cached']) {
          list[type].sort((a, b) => b.id - a.id);
        }
        for (let action of product_link.actions) {
          list[action] = [];
        }
        options.available = [];
        for (let option of options.default) {
          if (!(list.items.some(item => item.name == option.label))) {
            options.available.push(option);
          }
        }
        Tool.Alphabetize(options.available, 'label');
      },
      async ClearProductLinkList(prompt = false) {
        let message = `Clears entire product link list.`;
        let approve = 'Yes, clear it';
        const removeItems = () => {
          for (let item of this.product_link.list.items) {
            this.RemoveProductLink(item);
          }
        }
        if (!prompt) return removeItems();
        if (await this.Prompt({approve, message})) removeItems();
      },
      async RemoveProductLink(item = {}, prompt = false) {
        let message = `Removes ${item.name} product link from list.`;
        let approve = 'Yes, remove it';
        const removeItem = () => {
          let product_link = this.product_link;
          let options = product_link.options;
          let list = product_link.list;
          if (list.cached.length) {
            for (let cached_item of list.cached) {
              if (item.name == cached_item.name) {
                list.delete.unshift(item);
              }
            }
          }
          if (list.create.includes(item)) {
            list.create = list.create.filter(list_item => item.name != list_item.name);
          }
          if (list.update.includes(item)) {
            list.update = list.update.filter(list_item => item.name != list_item.name);
          }
          list.items = list.items.filter(list_item => item.name != list_item.name);
          options.available = [...options.available, ...options.default.filter(option => option.label == item.name)];
          Tool.Alphabetize(options.available, 'label');
        }
        if (!prompt) return removeItem();
        if (await this.Prompt({approve, message})) removeItem();
      },
      SetProductLinkName(option) {
        let product_link = this.product_link;
        let list = product_link.list;
        let name = ((option || {}).label || '').replace(/\s+/g, ' ').trim();
        if (name) {
          let item = list.items.find(item => item.name == name);
          if (item) {
            product_link.option.selected = null;
          } else {
            setTimeout(() => {this.$refs.link.focus()});
            product_link.name = name;
          }
        } else if (typeof option == 'string') {
          product_link.option.selected = {label: option};
          setTimeout(() => {this.$refs.link.focus()});
          product_link.name = option;
        } else {
          product_link.option.selected = null;
          product_link.name = "";
        }
      },
      SetProductLinkURL() {

      },
      AddProductLink() {
        let item = {};
        let required = ['name', 'link'];
        let product_link = this.product_link;
        let options = product_link.options;
        let list = product_link.list;
        for (let key of required) {
          let value = product_link[key];
          if (value) item[key] = value;
        }
        let keys = Object.keys(item);
        if (keys.length < 2) {
          let missing = required.filter(key => !keys.includes(key))[0];
          if (!missing) missing = required[0];
          let field = this.$refs[missing];
          if (Array.isArray(field)) field = field[0];
          if (!field) return;
          if (field.$vnode) field = field.$vnode.elm;
          let select_field = field.querySelector('input[type=search]');
          if (select_field) field = select_field;
          return field.focus();
        }
        list.items.unshift(item);
        for (let key of required) {
          product_link.option.selected = null;
          product_link[key] = "";
        }
        let cached_item = list.cached.find(cached_item => cached_item.name == item.name);
        if (cached_item) {
          if (cached_item.link != item.link) {
            list.delete = list.delete.filter(deleted_item => deleted_item.name != item.name);
            list.update.unshift(item);
          } else {
            let deleted_item = list.delete.find(deleted_item => deleted_item.name == item.name);
            if (deleted_item) {
              list.delete = list.delete.filter(deleted_item => deleted_item.name != item.name);
            } else {
              list.create.unshift(item);
            }
          }
        } else {
          list.create.unshift(item);
        }
        options.available = options.available.filter(option => option.label != item.name);
      },
      EditProductLink(item = {}, field = '') {
        if (!Object.keys(item).length) return;
        if (field == 'link') {
          item.link = item.link.toLowerCase().replace(/\s/g, '');
        }
        let product_link = this.product_link;
        let list = product_link.list;
        let opposite_field = field == 'name' ? 'link' : 'name';
        const compareItem = (list_item = {}, equal_to = false, prop = opposite_field) => {
          return equal_to ? list_item[prop] == item[prop] : list_item[prop] != item[prop];
        }
        let cached_item = list.cached.find(cached_item => compareItem(cached_item, true));
        if (cached_item) {
          if (cached_item.link != item.link) {
            list.update.unshift(item);
          } else {
            list.update = list.update.filter(updated_item => compareItem(updated_item, false));
          }
        }
      },
      HandleProductLinkLogo() {},
      ProductLinkLogoClassName(name = '') {
        let names = Object.values(this.product_link.options.default).map(option => option.label);
        return name.match(new RegExp(names.join('|'), 'i'))?.[0].toLowerCase() || '';
      },
      HoverButton(event) {
        let button = event.target;
        button.classList.toggle('hover');
			},
      HoverAll(event) {
        let button = event.target;
        let wrapper = button.closest('.list-wrapper');
        let list = wrapper.querySelector('.simple-list');
        let items = list.querySelectorAll('.simple-list__item');
				for (let item of items) {
          button = item.querySelector('.item-wrapper__button');
          button.classList.toggle('hover');
        }
			},
      Toast(message) {
        this.$eventHub.$emit('ShowMessages', {
          message: message || 'List successfully cleared', 
          type: 'success', hide: 2000
        });
      },
      async Prompt(props = {}) {
        return await new Promise((resolve, reject) => {
          this.$eventHub.$emit('ValidateModalStart', {
            approve: props.approve || 'Yes, clear it',
            disapprove: 'No',
            message: props.message || 'Clears entire product link list.',
            type: 'danger'
          });
          this.$eventHub.$on('ValidateModalStop', approve => {
            this.$eventHub.$off('ValidateModalStop');
            if (!approve) return reject();
            resolve(true);
          });
        }).catch(e => e);
      },
      async GetGlobalConfig() {
        return await BPA.api.GetCompanyConfig().then(response => {
          return BPA.api.response({response, return: 'json'});
        }).then(response => {
          if (!response.ok) return;
          return response.result;
        }).catch(e => e);
      },
      async UpdateGlobalConfig(request = {}) {
        return await BPA.api.UpdateCompanyConfig(request).then(response => {
          return BPA.api.response({response});
        }).then(response => response).catch(e => e);
      },
      async DeleteGlobalConfig(request = []) {
        return await BPA.api.DeleteCompanyConfig(request).then(response => {
          return BPA.api.response({response});
        }).then(response => response).catch(e => e);
      },
      async GetProductLinkList() {
        return await BPA.api.GetProductLinkList().then(response => {
          return BPA.api.response({response, return: 'json'});
        }).then(response => {
          if (!response.ok) return;
          return response.result;
        }).catch(e => e);
      },
      async CreateProductLink(request = {}) {
        return await BPA.api.CreateProductLink(request).then(response => {
          return BPA.api.response({response, return: 'text'});
        }).then(response => response).catch(e => e);
      },
      async UpdateProductLink(request = {}) {
        return await BPA.api.UpdateProductLink(request).then(response => {
          return BPA.api.response({response});
        }).then(response => response).catch(e => e);
      },
      async DeleteProductLink(item_id) {
        return await BPA.api.DeleteProductLink(item_id).then(response => {
          return BPA.api.response({response});
        }).then(response => response).catch(e => e);
      },
    }
  }
</script>

<style lang="scss" scoped>
  @import '../../../assets/style/variables/vital';
  @import '../../../assets/style/variables/colors';
  @import '../../../assets/style/variables/icons';

  label.label {
    user-select: none;
    
    &:hover {
      * {
        cursor: pointer;
      }

      input[type=checkbox]:not(:disabled) ~ .v-checkbox-toggle {
        border-color: rgba(0, 0, 0, 0.5);
      }
    }
  }

  .simple-list {
    border-top: 2px solid #d3d3d3;
    
		&__item {
      .simple-list-icon {
        left: 0;
        width: 22px;
        height: 22px;
        flex-shrink: 0;
        margin-right: 10px;
        display: inline-block;
        position: relative;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        background-image: $aperture;
        
        &.akeneo {
          background-image: $akeneo;
        }

        &.magento {
          background-image: $magento;
        }

        &.odoo {
          background-image: $odoo;
        }
      }
		}
  }

  .item {
    &-wrapper {
      &.noflex {
        display: block;
      }
    }
  }

  .max-width-300 {
    max-width: 300px;
    align-items: center;
  }

  .v-select {
    .portal-icon {
      display: flex;
      position: relative;
      align-items: center;

      &::before {
        width: 22px;
        height: 22px;
        content: '';
        flex-shrink: 0;
        margin-right: 10px;
        position: relative;
        display: inline-block;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        background-image: $aperture;
      }

      &.akeneo::before {
        background-image: $akeneo;
      }

      &.magento::before {
        background-image: $magento;
      }

      &.odoo::before {
        background-image: $odoo;
      }
    }

    .vs__dropdown-option--highlight {
      .portal-icon {
        &::before {
          mask-size: contain;
          mask-repeat: no-repeat;
          mask-position: center;
          mask-image: $aperture;
          background-color: white;
          background-image: none;
        }

        &.akeneo::before {
          mask-image: $akeneo;
        }

        &.magento::before {
          mask-image: $magento;
        }

        &.odoo::before {
          mask-image: $odoo;
        }
      }
    }
  }

  .sticky-footer {
    margin-top: 15px;
    margin-bottom: -15px;
    border-top: 2px solid lightgray;
    padding-top: 15px;
    padding-bottom: 15px;
    position: sticky;
    bottom: 0;
    background: white;
  }
</style>