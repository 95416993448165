<template>
  <div class="settings settings-warehouse-map grid-wrapper">
    <p class="title" style="margin-bottom: 15px;">
      {{ $t('Warehouse map') }}
    </p>
    <div class="tabs" style="overflow: unset;">
      <ul class="tabs__head">

        <li :class="['tabs__head--item', {active: tab.active == 'results'}]" @click="Tab('results'), !Tab().loaded && Search()">
          <span>{{ $t('Maps') }}</span>
        </li>

        <li :class="['tabs__head--item', {active: tab.active == 'maps'}]" @click="Tab('maps'), !Tab().loaded && Search()">
          <span>{{ $t('Setup') }}</span>
        </li>

        <li :class="['tabs__head--item', {active: tab.active == 'warehouseproductmap'}]" @click="tab.active = 'warehouseproductmap'" v-if="CheckPermission(AdminRoles)">
          <span>{{ $t('Warehouse Product Map') }}</span>
        </li>

        
      </ul>
      <ul class="tabs__body" style="overflow: unset;">

        <li class="tabs__body--content padding" style="padding-bottom: 30px; margin-bottom: -30px;" v-if="tab.active == 'results'">
          <div class="grid-info">
            <span class="grid-heading">
              <h1>{{ $t('Maps') }}</h1>
              <span class="entries">{{ NumberFormat(Tab().entries) }} {{ $t(`entr${Tab().entries == 1 ? 'y' : 'ies'}`) }}</span>
            </span>
            <div class="grid-search">
              <div class="search-fields" style="align-items: flex-end;">
                <input type="text" v-model="Tab().search.query" @keyup.enter="FilterSearch(), $event.target.blur()" :placeholder="$t('Search by title')" style="width: 50%;">
                <div class="size-select" style="width: 160px; margin-top: -100%; margin-left: 30px;">
                  <p class="grid-heading" style="font-weight: 600; color: #b3b8bd; padding-bottom: 2px;">{{ $t('Page size') }}</p>
                  <v-select name="page-size-select" @input="FilterBySize" v-model="Tab().search.page_size" :options="sizes" :searchable="false" :clearable="false" />
                </div>
              </div>
            </div>
          </div>
          <div>
            <table class="list table odd-even">
              <tbody>
                <tr :data-id="item.id" :class="['map-list__row', {clickable: item.is_ready}]" @mousedown="ClickOpen" @mousemove="ClickOpen" @mouseup="ClickOpen" :key="item.id" v-for="item in Tab().list">
                  <td class="label" style="display: table-cell;" @click="ToggleCheckbox">
                    <div class="v-wrapper checkbox">
                      <label class="v-checkbox-label">
                        <input class="v-checkbox" type="checkbox" :data-id="item.id" @input="ToggleCheckbox">
                        <span class="v-checkbox-toggle" />
                      </label>
                    </div>
                  </td>
                  <td>
                    <div>{{ item.id }}</div>
                  </td>
                  <td>
                    <div class="checkbox-toggle mini">
                      <input type="checkbox" :style="{cursor: item.active ? 'default' : ''}" :checked="item.active" @pointerup.prevent="(e) => {if (item.active && !e.button) e.target.checked = !item.active}" @input="!item.active && Toggle(item)" :disabled="!item.is_ready">
                      <span class="toggle"></span>
                    </div>
                  </td>
                  <td>
                    <div>{{ item.title }}</div>
                  </td>
                  <td>
                    <div>{{ NumberFormat(item.uint_size_m) + ' m' }}</div>
                  </td>
                  <td class="status">
                    <div class="flex-row state" :class="[item.has_errors ? 'red' : item.is_ready ? 'green' : item.process ? 'blue' : '']" :title="$t(item.has_errors ? 'Error' : item.is_ready ? 'Ready' : item.process ? 'Processing' : '')">
                      {{ NumberFormat(item.process) + '%' }}
                    </div>
                  </td>
                  <td>
                    <div>{{ item.calc_date ? DateFormat(item.calc_date) : '-' }}</div>
                  </td>
                  <td>
                    <div>{{ DateFormat(item.create_date) }}</div>
                  </td>
                  <td>
                    <div>{{ item.create_by }}</div>
                  </td>
                  <td class="actions" @mouseenter="SetBackground" @mouseleave="SetBackground">
                    <a class="icon dots" @click.prevent="" href="">
                      <ul class="item-actions">
                      <li v-if="item.is_ready"><a @click.prevent="View(item.id)" href="" class="success">{{ $t('View') }}</a></li>
                        <li><a @click.prevent="Delete(item)" href="" class="error">{{ $t('Delete') }}</a></li>
                      </ul>
                    </a>
                  </td>
                </tr>
              </tbody>
              <thead>
                <tr>
                  <th class="label" style="display: table-cell;">
                    <div class="v-wrapper checkbox">
                      <label class="v-checkbox-label">
                        <input class="v-checkbox" type="checkbox" ref="select_all" @input="ToggleAllCheckboxes">
                        <span class="v-checkbox-toggle toggle-all" />
                      </label>
                    </div>
                  </th>
                  <th>{{ $t('ID') }}</th>
                  <th>{{ $t('Active') }}</th>
                  <th>{{ $t('Title') }}</th>
                  <th>{{ $t('Ratio') }}</th>
                  <th>{{ $t('Status') }}</th>
                  <th>{{ $t('Processed') }}</th>
                  <th>{{ $t('Created') }}</th>
                  <th>{{ $t('Created by') }}</th>
                  <th class="edit" />
                </tr>
              </thead>
            </table>
            <div class="grid-pagination shadow sticky bottom" :style="{marginTop: Tab().list.length && '2px'}">
              <div class="page-navigation">
                <div class="page-turn prev disabled" @click="PageController(false)"></div>
                <div class="page-number">
                  <label class="page-number-current">
                    <input type="number" min="1" :max="Tab().page.last" :value="Tab().page.current" @blur="PageNavigator" @keydown="PageNavigator">
                    <span class="placeholder">{{NumberFormat(Tab().page.current)}}</span>
                  </label>
                  <span class="page-number-separator">/</span>
                  <div class="page-number-last">{{NumberFormat(Tab().page.last)}}</div>
                </div>
                <div class="page-turn next" :class="{disabled: Tab().page.last == 1}" @click="PageController(true)"></div>
              </div>
            </div>
          </div>
        </li>

        <li class="tabs__body--content padding" style="padding-bottom: 30px; margin-bottom: -30px;" v-if="tab.active == 'maps'">
          <div class="grid-info">
            <span class="grid-heading">
              <h1>{{ $t('Setup') }}</h1>
              <span class="entries">{{ NumberFormat(Tab().entries) }} {{ $t(`entr${Tab().entries == 1 ? 'y' : 'ies'}`) }}</span>
            </span>
            <div class="grid-actions">
              <a class="button" @click.prevent="dialog.open('setup')" href="" style="margin-left: auto;">{{ $t('Create') }}</a>
            </div>
            <div class="grid-search">
              <div class="search-fields" style="align-items: flex-end;">
                <input type="text" v-model="Tab().search.query" @keyup.enter="FilterSearch(), $event.target.blur()" :placeholder="$t('Search by title')" style="width: 50%;">
                <div class="size-select" style="width: 160px; margin-top: -100%; margin-left: 30px;">
                  <p class="grid-heading" style="font-weight: 600; color: #b3b8bd; padding-bottom: 2px;">{{ $t('Page size') }}</p>
                  <v-select name="page-size-select" @input="FilterBySize" v-model="Tab().search.page_size" :options="sizes" :searchable="false" :clearable="false" />
                </div>
              </div>
            </div>
          </div>
          <div>
            <table class="list table odd-even">
              <tbody>
                <tr :data-id="item.id" :class="['map-list__row', {clickable: true}]" @mousedown="ClickOpen" @mousemove="ClickOpen" @mouseup="ClickOpen" :key="item.id" v-for="item in Tab().list">
                  <td class="label" style="display: table-cell;" @click="ToggleCheckbox">
                    <div class="v-wrapper checkbox">
                      <label class="v-checkbox-label">
                        <input class="v-checkbox" type="checkbox" :data-id="item.id" @input="ToggleCheckbox">
                        <span class="v-checkbox-toggle" />
                      </label>
                    </div>
                  </td>
                  <td>
                    <div>{{ item.id }}</div>
                  </td>
                  <td>
                    <div>{{ item.title }}</div>
                  </td>
                  <td>
                    <div>{{ DateFormat(item.last_update_date) }}</div>
                  </td>
                  <td>
                    <div>{{ item.last_update_by }}</div>
                  </td>
                  <td class="actions" @mouseenter="SetBackground" @mouseleave="SetBackground">
                    <a class="icon dots" @click.prevent="" href="">
                      <ul class="item-actions">
                        <li><a @click.prevent="Edit(item.id)" href="" class="success">{{ $t('Edit') }}</a></li>
                        <li><a @click.prevent="dialog.open('publish', item.id)" href="">{{ $t('Publish') }}</a></li>
                        <li><a @click.prevent="Delete(item)" href="" class="error">{{ $t('Delete') }}</a></li>
                      </ul>
                    </a>
                  </td>
                </tr>
              </tbody>
              <thead>
                <tr>
                  <th class="label" style="display: table-cell;">
                    <div class="v-wrapper checkbox">
                      <label class="v-checkbox-label">
                        <input class="v-checkbox" type="checkbox" ref="select_all" @input="ToggleAllCheckboxes">
                        <span class="v-checkbox-toggle toggle-all" />
                      </label>
                    </div>
                  </th>
                  <th>{{ $t('ID') }}</th>
                  <th>{{ $t('Title') }}</th>
                  <th>{{ $t('Updated') }}</th>
                  <th>{{ $t('Updated by') }}</th>
                  <th class="edit" />
                </tr>
              </thead>
            </table>
            <div class="grid-pagination shadow sticky bottom" :style="{marginTop: Tab().list.length && '2px'}">
              <div class="page-navigation">
                <div class="page-turn prev disabled" @click="PageController(false)"></div>
                <div class="page-number">
                  <label class="page-number-current">
                    <input type="number" min="1" :max="Tab().page.last" :value="Tab().page.current" @blur="PageNavigator" @keydown="PageNavigator">
                    <span class="placeholder">{{NumberFormat(Tab().page.current)}}</span>
                  </label>
                  <span class="page-number-separator">/</span>
                  <div class="page-number-last">{{NumberFormat(Tab().page.last)}}</div>
                </div>
                <div class="page-turn next" :class="{disabled: Tab().page.last == 1}" @click="PageController(true)"></div>
              </div>
            </div>
          </div>
        </li>
        <li class="tabs__body--content padding" style="padding-bottom: 30px; margin-bottom: -30px;" v-if="tab.active == 'warehouseproductmap'">
          <WarehouseProductMap></WarehouseProductMap>
        </li>
      </ul>
    </div>

    <transition name="fade">
      <div @pointerdown="dialog.event" @pointerup="dialog.event" class="validate-popup" v-if="dialog.show">
        <div class="validate-popup__content">
          <div class="titles">
            <p class="title">
              {{ 
                dialog.mode == 'setup' ? 'Map editor' : 
                dialog.mode == 'publish' ? 'Publish map' : '' 
              }}
            </p>
            <p class="subtitle"></p>
            <div class="dialog-form" style="margin-bottom: 15px;">
              <form ref="dialog_form" @submit.prevent>
                <button ref="dialog_submit" type="submit" hidden />
                <div class="flex-column">
                  <div style="margin-bottom: 15px;">
                    <div class="label required">
                      <span class="label-text">
                        {{ 
                          dialog.mode == 'setup' ? 'Ratio (m)' : 
                          dialog.mode == 'publish' ? 'Title' : ''
                        }}
                       </span>
                      <input v-if="dialog.mode == 'setup'" class="v-input" type="number" name="scale" required v-model="editor.data.scale" @keydown.enter="$event.target.blur(), dialog.confirm()" step="0.01" min="0.01" />
                      <input v-if="dialog.mode == 'publish'" class="v-input" type="text" name="title" required v-model="dialog.data.title" @keydown.enter="$event.target.blur(), dialog.confirm()" spellcheck="false" />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div class="actions">
            <a class="btn-stateless cancel" @click.prevent="dialog.cancel" href="">Cancel</a>
            <a class="btn-success confirm" @click.prevent="dialog.confirm" href="">{{ Capitalize(dialog.mode == 'setup' ? 'start' : 'process') }}</a>
          </div>
        </div>
      </div>
    </transition>

    <div v-if="editor.show" class="fullscreen">
      <div id="map-editor" style="height: 100%; width: 100%;" />
      <form @submit.prevent>
        <button ref="submit" type="submit" hidden />
        <div class="fullscreen-header">
          <div class="fullscreen-overlay" v-if="editor.mode == 'view'" />
          <div class="fullscreen-header__tools" />
          <div class="fullscreen-header__title">
            <input type="text" required v-model="editor.data.title" @keydown.enter="$event.target.blur(), Serialize($event)" placeholder="Untitled warehouse map" spellcheck="false" :readonly="editor.mode == 'view'" />
          </div>
          <div class="fullscreen-header__actions">
            <div class="label">
              <span class="label-text">{{ 'Ratio (m)' }}</span>
              <input class="v-input" type="number" required v-model="editor.data.scale" step="0.01" min="0.01" readonly />
            </div>
            <button class="button green" @click="Serialize" v-if="editor.mode != 'view'">{{ Capitalize(editor.mode == 'edit' ? 'update' : 'create') }}</button>
            <button class="button blue" @click="dialog.open('publish', editor.data.id)" v-if="editor.mode == 'edit'">Publish</button>
            <button class="button red" @click="Delete(editor.data)" v-if="editor.mode == 'edit'">Delete</button>
            <button class="button red button-icon plus" @click="Close" title="Close" />
          </div>
        </div>
      </form>
    </div>

    <div class="loading" v-if="loading">
      <div class="loading-element">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>

  </div>
</template>

<script>
  import WarehouseProductMap from '@/components/blocks/Settings/SettingsWarehouseMap/WarehouseProductMap'
  import { TableElementsBehaviour } from '@/mixins/TableElementsBehaviour';
  import { LagerMap }   from '@/js/lager_map_editor';
  import { Config }     from '@/helpers/Config';
  import { Tool }       from '@/helpers/Tool';
  import { BPA }        from '@/helpers/BPA';

  export default {
    name: 'SettingsWarehouseMapView', 
    components: { WarehouseProductMap },
    mixins: [
      TableElementsBehaviour, 
      Config, 
      Tool, 
      BPA
    ],
    props: {
      LagerMap
    },
    data() {
      return {
        loading: true,
        AdminRoles: [/*0 , 35*/ 'admin', 'stock_admin'],
        cached: {},
        clickables: [],
        selected: [],
        map: {},
        sizes: [10, 20, 30, 50, 100],
        form: {
          data: {}
        },
        editor: {
          mode: 'create',
          show: false,
          event: {
            listener: (e) => {
              if (e.type == 'keydown') {
                if (e.key == this.editor.event.keydown) return;
                if (e.key == 'Escape') this.Close();
                this.editor.event.keydown = e.key;
              }
              if (e.type == 'keyup') {
                this.editor.event.keydown = null;
              }
            },
            mount: () => {
              this.editor.event.keydown = null;
              ['keydown', 'keyup'].map(e => window.addEventListener(e, this.editor.event.listener, false));
            },
            unmount: () => {
              this.editor.event.keydown = null;
              ['keydown', 'keyup'].map(e => window.removeEventListener(e, this.editor.event.listener));
            }
          },
          data: {
            id: null,
            init: null,
            title: null,
            scale: 0.02,
            serialized: null
          }
        },
        dialog: {
          show: false,
          mode: '',
          data: {},
          event() {},
          confirm() {},
          cancel() {},
          close() {}
        },
        tab: {
          active: 'results',
          maps: {
            name: 'maps',
            loaded: false,
            entries: 0,
            list: [],
            search: {
              query: '',
              initial: null,
              page_size: 20,
              page_offset: 1
            },
            page: {
              current: 1,
              number: 1,
              last: 1
            },
            sort: {
              prop: 'start_date',
              dir: 'desc',
              by: 'date'
            }
          },
          results: {
            name: 'results',
            loaded: false,
            entries: 0,
            list: [],
            search: {
              query: '',
              initial: null,
              page_size: 20,
              page_offset: 1
            },
            page: {
              current: 1,
              number: 1,
              last: 1
            },
            sort: {
              prop: 'start_date',
              dir: 'desc',
              by: 'date'
            }
          }
        }
      }
    },
    async created() {
      const editor = this.editor;
      const dialog = this.dialog;
      dialog.event = (event) => {
        if (/down/.test(event.type)) {
          dialog.event.target = event.target;
        } else if (event.target == dialog.event.target) {
          if (event.target.classList.contains('validate-popup')) {
            dialog.close();
          }
          dialog.event.target = null;
        }
      }
      dialog.submit = async () => {
        const editor = this.editor;
        const form = this.$refs.dialog_form;
        const submit = this.$refs.dialog_submit;
        if (!await this.ValidateForm(submit)) return;
        let data = await Tool.SerializeFormData(form);
        Object.assign(dialog.data, data);
        dialog.close();
        this.loading = true;
        if (dialog.mode == 'setup') {
          editor.mode = 'create';
          setTimeout(async () => {
            await this.SetupEditor();
            this.loading = false;
            dialog.data = {};
          }, 10);
        }
        if (dialog.mode == 'publish') {
          if (await this.Calculate(dialog.data)) {
            if (editor.mode == 'edit') {
              this.Exit();
            }
          }
          this.loading = false;
          dialog.data = {};
        }
        dialog.mode = '';
      }
      dialog.confirm = () => {
        dialog.submit();
      }
      dialog.cancel = () => {
        dialog.close();
      }
      dialog.close = () => {
        dialog.show = false;
      }
      dialog.open = (mode, id) => {
        dialog.show = true;
        dialog.mode = mode;
        if (mode == 'setup') {
          editor.data.scale = 0.02;
        }
        if (id) {
          dialog.data.map_id = id;

          if (mode == 'publish') {
            dialog.data.title = this.Tab().list.find(e => e.id == id).title;
          }
        }
        this.$nextTick().then(() => {
          let input = document.querySelector('.validate-popup input');
          if (input) input.focus();
        });
      }
    },
    async mounted() {
      this.cached = BPA.cache.local({name: this.$options.name, get: ['page', 'search']});
      for (let key of Object.keys(this.cached)) this.Tab()[key] = {...this.Tab()[key], ...this.cached[key]};
      this.cached = {...this.cached, ...BPA.cache.session({name: this.$options.name, get: 'map'})};
      await this.Search();
      this.loading = false;
    },
    destroyed() {},
    computed: {
      main() {
        const main = {
          company: BPA.util.GetCompany(),
          view: document.querySelector('.content-scroll')
        }
        main.view.scroll = {top: 0};
        return main;
      },
      locale() {
        return this.$i18n.locale;
      }
    },
    watch: {
      locale() {
        //this.state = this.$t(this.Capitalize(this.search.state));
      }
    },
    methods: {
      ConsoleLog(output) {
        console.log(output);
      },
      CloneObject(object = {}) {
        return Tool.CloneObject(object);
      },
      DateFormat(date) {
        return Tool.DateFormat(date);
      },
      NumberFormat(number) {
        return Tool.NumberFormat(number);
      },
      Capitalize(string = '') {
        return Tool.Capitalize(string);
      },
      TitleCase(string = '') {
        return Tool.TitleCase(string);
      },
      Hyperlink(props = {}) {
        return Tool.Hyperlink(props);
      },
      CountryName(country_code = '') {
        return Tool.CountryName(country_code);
      },
      LanguageName(country_code = '') {
        return Tool.LanguageName(country_code);
      },
      Tab(active) {
        let tab = this.tab;
        let tab_active = tab[tab.active];
        if (active) {
          if (!(active in tab)) {
            tab = tab_active.tab;
          }
          tab.active = active;
        } else if ('tab' in tab_active) {
          tab = tab_active.tab;
        }
        tab_active = tab[tab.active];
        this.$nextTick().then(() => {
          let select_all = this.$refs.select_all;
          if (active) {
            if (select_all) {
              select_all.disabled = !tab_active.list?.length;
              select_all.checked = false;
            }
            this.selected = [];
          }
        });
        return tab_active;
      },
      async FilterBySize(option) {
        this.Tab().search.page_size = option;
        await this.FilterSearch();
      },
      async FilterSearch() {
        this.Tab().page.current = 1;
        this.loading = true;
        await this.Search();
        this.loading = false;
      },
      async Search() {
        return await new Promise((resolve, reject) => {
          const tab = this.Tab();
          const tab_name = tab.name;
          const search = tab.search;
          const page = tab.page;
          const request = {
            query: search.query,
            page_size: search.page_size,
            page_offset: page.current
          }
          let search_method = tab_name == 'maps' ? 'SearchWarehouseMaps' : 
          tab_name == 'results' ? 'SearchWarehouseMapResults' : '';
          search_method && BPA.api[search_method](request).then(response => {
            return BPA.api.response({response, return: 'json'});
          }).then(response => {
            if (!response.ok || !response.result) return reject();
            const json = response.result || {};
            search.initial = search.query;
            page.current = json.page_offset + 1;
            page.last = Math.ceil((json.count || 1) / json.page_size);
            tab.entries = json.count;
            for (let item of json.items) {
              if (tab_name == 'maps') {
                item.last_update_by = json.res_user_id[item.last_update_by] || '-';
              }
              if (tab_name == 'results') {
                item.create_by = json.res_users_ids.find(user => user.id == item.create_by)?.name || '-';
              }
            }
            json.items.sort((a, b) => b.id - a.id);
            tab.list = json.items;
            this.$refs.select_all.disabled = !tab.list.length;
            BPA.cache.local({name: this.$options.name, set: {page: tab.page, search: tab.search}});
            this.$nextTick().then(async () => {
              this.clickables = document.querySelectorAll('.clickable');
              if (this.cached.map && Object.keys(this.cached.map).length) {
                this.Edit(this.cached.map);
              }
              this.SetPageJumpWidth();
            });
            tab.loaded = true;
            resolve(json);
          }).catch(reject);
        }).catch(e => e);
      },
      async GetMap(map_id) {
        return await new Promise((resolve, reject) => {
          if (!map_id) return reject();
          BPA.api.GetWarehouseMap(map_id).then(response => {
            return BPA.api.response({response, return: 'json'});
          }).then(response => {
            if (!response.ok || !response.result) return reject();
            resolve(response.result);
          }).catch(reject);
        }).catch(e => e);
      },
      async CreateMap(request = {}) {
        return await new Promise((resolve, reject) => {
          if (!Object.keys(request).length) return reject();
          BPA.api.CreateWarehouseMap(request).then(response => {
            return BPA.api.response({response, return: 'text'});
          }).then(async response => {
            if (!response.ok) return reject();
            resolve(response.result);
          }).catch(reject);
        }).catch(e => e);
      },
      async UpdateMap(request = {}) {
        return await new Promise((resolve, reject) => {
          if (!Object.keys(request).length) return reject();
          BPA.api.UpdateWarehouseMap(request).then(response => {
            return BPA.api.response({response});
          }).then(response => {
            if (!response.ok) return reject();
            resolve(response);
          }).catch(reject);
        }).catch(e => e);
      },
      async CalculateMap(request = {}) {
        return await new Promise((resolve, reject) => {
          if (!Object.keys(request).length) return reject();
          BPA.api.CalculateWarehouseMap(request).then(response => {
            return BPA.api.response({response});
          }).then(response => {
            if (!response.ok) return reject();
            resolve(response);
          }).catch(reject);
        }).catch(e => e);
      },
      async DeleteMap(map_id = '') {
        return await new Promise((resolve, reject) => {
          if (!map_id) return reject();
          BPA.api.DeleteWarehouseMap(map_id).then(response => {
            return BPA.api.response({response});
          }).then(response => {
            if (!response.ok) return reject();
            resolve(response);
          }).catch(reject);
        }).catch(e => e);
      },
      async GetMapResult(result_id = '') {
        return await new Promise((resolve, reject) => {
          if (!result_id) return reject();
          BPA.api.GetWarehouseMapResult(result_id).then(response => {
            return BPA.api.response({response, return: 'json'});
          }).then(response => {
            if (!response.ok || !response.result) return reject();
            resolve(response.result);
          }).catch(reject);
        }).catch(e => e);
      },
      async DeleteResult(result_id = '') {
        return await new Promise((resolve, reject) => {
          if (!result_id) return reject();
          BPA.api.DeleteWarehouseMapResult(result_id).then(response => {
            return BPA.api.response({response});
          }).then(response => {
            if (!response.ok) return reject();
            resolve(response);
          }).catch(reject);
        }).catch(e => e);
      },
      async ActivateResult(result_id = '') {
        return await new Promise((resolve, reject) => {
          if (!result_id) return reject();
          BPA.api.ActivateWarehouseMapResult(result_id).then(response => {
            return BPA.api.response({response});
          }).then(response => {
            if (!response.ok) return reject();
            resolve(response);
          }).catch(reject);
        }).catch(e => e);
      },
      async ValidateForm(button) {
        return await new Promise(resolve => {
          if (button) {
            const form = button.closest('form');
            const fields = Array.from(form.elements);
            if (fields.some(field => !field.checkValidity())) {
              return button.click();
            }
          }
          resolve(true);
        }).catch(e => e);
      },
      async Edit(map_id = '') {
        this.loading = true;
        this.editor.mode = 'edit';
        let map = await this.GetMap(map_id);
        await this.SetupEditor(map);
        this.loading = false;
      },
      async View(result_id = '') {
        this.loading = true;
        this.editor.mode = 'view';
        let result = await this.GetMapResult(result_id + '?with_map');
        result.map_data = JSON.stringify(result.map_data);
        await this.SetupEditor(result);
        this.loading = false;
      },
      async SetupEditor(map) {
        const editor = this.editor;
        let map_data;
        if (map) {
          if (!map.map_data) return;
          map_data = Tool.UnescapeJson(map.map_data);
          editor.data = {
            id: map.id,
            title: map.title,
            scale: map_data.uint_size_m,
            serialized: map_data
          }
          map_data = JSON.stringify(map_data);
        }
        editor.show = true;
        this.$nextTick().then(() => {
          this.map = new LagerMap('map-editor', editor.data.scale, (title, message) => {
            this.$eventHub.$emit('ShowMessages', {
              message: `${title ? `${title}: ` : ''}${message}`,
              type: 'warning',
              hide: 2000
            });
          });
          if (map) this.map.Deserialize(map_data);
          editor.data.init = this.map.Serialize();
          this.$nextTick().then(() => {
            if (editor.mode == 'view') {
              let map_editor = window['map-editor'];
              let canvas = map_editor.querySelector('canvas');
              let buttons = map_editor.querySelectorAll('.icon');
              let zoom = {max: 1.5, min: 0.5, step: 0.1, scale: 1, event: (delta) => {
                let factor = zoom.scale + delta;
                if (factor >= zoom.min && factor <= zoom.max) {
                  clone.style.width = canvas.width * factor + 'px';
                  clone.style.height = canvas.height * factor + 'px';
                  zoom.scale = factor;
                }
              }};
              let clone = canvas.cloneNode(true);
              let context = clone.getContext('2d');
              for (let button of buttons) {
                button.classList.remove('active');
                if (/zoom/.test(button.className)) {
                  let type = /in/.test(button.className) ? 'in' : 'out';
                  zoom[type] = button.cloneNode(true);
                  zoom[type].style.zIndex = 1;
                  zoom[type].onclick = (event) => {
                    event.preventDefault();
                    if (type == 'out') {
                      zoom.event(-zoom.step);
                    } else {
                      zoom.event(zoom.step);
                    }
                  }
                  button.parentNode.replaceChild(zoom[type], button);
                } else {
                  button.style.opacity = 0.35;
                }
              }
              clone.onwheel = (event) => {
                if (event.ctrlKey) {
                  event.preventDefault();
                  if (event.deltaY == 0) return;
                  if (event.deltaY > 0) {
                    zoom.event(-zoom.step);
                  } else {
                    zoom.event(zoom.step);
                  }
                }
              }
              context.drawImage(canvas, 0, 0, canvas.width, canvas.height);
              canvas.parentNode.replaceChild(clone, canvas);
            }
            //editor.event.mount();
          });
        });
      },
      async Serialize(e) {
        e.preventDefault();
        const editor = this.editor;
        let serialized_data = this.map.Serialize();
        let map_title = (this.Tab().list.find(item => item.id == editor.data.id) || {}).title;
        if (e.type == 'keydown' && e.target.localName == 'input' && !editor.data.title) return;
        if (serialized_data != editor.data.init || map_title != editor.data.title) {
          let form_submit = this.$refs.submit;
          if (!await this.ValidateForm(form_submit)) return;
          editor.data.serialized = serialized_data;
          this.form.data = {
            id: editor.data.id,
            title: editor.data.title,
            serialized: editor.data.serialized
          }
          if (editor.mode == 'create') {
            if (!await this.Create()) return;
          } else {
            if (!await this.Update()) return;
          }
          await this.Search();
          this.form.data = {};
        } else {
          if (editor.mode == 'edit' && editor.data.title) {
            this.$eventHub.$emit('ShowMessages', {
              message: `No changes made to warehouse map "${editor.data.title}"`,
              type: 'warning',
              hide: 2000
            });
          }
        }
        this.Exit();
      },
      async Close(e) {
        e.preventDefault();
        const editor = this.editor;
        let serialized_data = this.map.Serialize();
        if (serialized_data != editor.data.init) {
          this.$eventHub.$emit('ValidateModalStart', {
            approve: 'Yes, discard it',
            disapprove: 'No',
            message: `Discards ${editor.mode == 'edit' ? 'changes to ' : ''}the current warehouse map.`,
            type: 'danger'
          });
          this.$eventHub.$on('ValidateModalStop', async (approve) => {
            this.$eventHub.$off('ValidateModalStop');
            if (approve) this.Exit();
          });
        } else {
          this.Exit();
        }
      },
      async Exit() {
        let editor = this.editor;
        //editor.event.unmount();
        editor.show = false;
        editor.data.title = null;
        if (this.map instanceof LagerMap) {
          this.map.Destroy();
        }
        this.map = {};
      },
      async Create() {
        return await new Promise((resolve, reject) => {
          const data = this.CloneObject(this.form.data);
          let request = {title: data.title, map_data: data.serialized};
          this.$eventHub.$emit('ValidateModalStart', {
            approve: 'Yes, create it',
            disapprove: 'No',
            message: 'Creates the current warehouse map.',
            type: 'success'
          });
          this.$eventHub.$on('ValidateModalStop', async (approve) => {
            this.$eventHub.$off('ValidateModalStop');
            if (!approve) return;
            this.loading = true;
            if (await this.CreateMap(request)) {
              this.$eventHub.$emit('ShowMessages', {
                message: 'Warehouse map successfully created',
                type: 'success',
                hide: 2000
              });
              resolve(true);
            } else reject();
            this.loading = false;
          });
        }).catch(e => e);
      },
      async Update() {
        return await new Promise((resolve, reject) => {
          const data = this.CloneObject(this.form.data);
          let request = {map_id: data.id, body: {title: data.title, map_data: data.serialized}};
          this.$eventHub.$emit('ValidateModalStart', {
            approve: 'Yes, update it',
            disapprove: 'No',
            message: 'Updates the current warehouse map.',
            type: 'success'
          });
          this.$eventHub.$on('ValidateModalStop', async (approve) => {
            this.$eventHub.$off('ValidateModalStop');
            if (!approve) return;
            this.loading = true;
            if (await this.UpdateMap(request)) {
              this.$eventHub.$emit('ShowMessages', {
                message: 'Warehouse map successfully updated',
                type: 'success',
                hide: 2000
              });
              resolve(true);
            } else reject();
            this.loading = false;
          });
        }).catch(e => e);
      },
      async Calculate(map = {}) {
        return await new Promise(async (resolve, reject) => {
          if (!Object.keys(map).length) return reject();
          if (!await this.CalculateMap(map)) return reject();
          this.$eventHub.$emit('ShowMessages', {
            message: 'Warehouse map successfully published',
            type: 'success',
            hide: 2000
          });
          this.Tab('results');
          this.Search();
          resolve(true);
        }).catch(e => e);
      },
      async Delete(map = {}) {
        const tab = this.Tab();
        const editor = this.editor;
        const tab_name = tab.name;
        let in_modal = Object.keys(this.map).length;
        map = in_modal ? this.map instanceof LagerMap ? map : this.map : map;
        let maps = [map];
        if (!in_modal) {
          for (let checkbox of this.selected) {
            let table_row = checkbox.closest('tr');
            let row_item = tab.list.find(item => item.id == table_row.dataset.id);
            if (row_item) maps.push(row_item);
          }
          maps = maps.filter((item, index, array) => index == array.findIndex(i => i.id == item.id));
        }
        let multiple = maps.length > 1;
        let all = maps.length == tab.list.length;
        this.$eventHub.$emit('ValidateModalStart', {
          approve: `Yes, delete ${multiple ? 'them' : 'it'}`,
          disapprove: 'No',
          message: `Deletes ${all ? 'all' : 'the'} ${multiple ? `${maps.length} ` : ''}${multiple ? (!all ? 'selected' : '') : 'current'} map${multiple ? 's' : ''}.`,
          type: 'danger'
        });
        this.$eventHub.$on('ValidateModalStop', async (approve) => {
          this.$eventHub.$off('ValidateModalStop');
          if (!approve) return;
          let delete_method = tab_name == 'maps' ? 'DeleteMap' : 
          tab_name == 'results' ? 'DeleteResult' : '';
          this.loading = true;
          if (delete_method) {
            for (let item of maps) {
              await this[delete_method](item.id);
            }
          }
          this.$eventHub.$emit('ShowMessages', {
            message: `Map${multiple ? 's' : ''} successfully deleted`,
            type: 'success',
            hide: 2000
          });
          await this.Search();
          if (tab_name == 'results') {
            if (!tab.list.length) {
              this.Tab('maps');
            }
          }
          if (editor.mode == 'edit') {
            this.Exit();
          }
          this.loading = false;
        });
      },
      async Toggle(item = {}) {
        let tab = this.Tab();
        let active = tab.list.find(item => item.active) || {};
        const revert = () => {
          active.active = true;
          item.active = false;
        }
        active.active = false;
        item.active = true;
        this.$eventHub.$emit('ValidateModalStart', {
          approve: 'Yes, activate it',
          disapprove: 'No',
          message: 'Activates the current map.\n\nNote: Only one map at a time can be active.',
          type: 'success'
        });
        this.$eventHub.$on('ValidateModalStop', async (approve) => {
          this.$eventHub.$off('ValidateModalStop');
          if (!approve) return revert();
          this.loading = true;
          if (await this.ActivateResult(item.id)) {
            this.$eventHub.$emit('ShowMessages', {
              message: `Map "${item.title}" successfully activated`,
              type: 'success',
              hide: 2000
            });
          } else revert();
          this.loading = false;
        });
      },
      ToggleAllCheckboxes(e) {
        let selected = [];
        let checked = e.target.checked;
        let items = document.querySelectorAll('tr[data-id]');
        for (let i = 0; i < items.length; i++) {
          let checkbox = items[i].querySelector('.v-checkbox');
          if (checkbox) {
            checkbox.checked = checked;
            if (checked) {
              selected.push(checkbox);
            }
          }
        }
        this.selected = selected;
      },
      ToggleCheckbox(e) {
        let e_target = e.target;
        if (!e_target || e_target.localName == 'span') return;
        if (e_target.localName == 'td') {
          e_target = e_target.querySelector('.v-checkbox');
          e_target.checked = !e_target.checked;
          return e_target.dispatchEvent(new Event('input'));
        }
        let selected = [];
        let checked = e_target.checked;
        let select_all = this.$refs.select_all;
        let items = document.querySelectorAll('tr[data-id]');
        if (!checked) select_all.checked = false;
        for (let i = 0; i < items.length; i++) {
          let checkbox = items[i].querySelector('.v-checkbox');
          if (checkbox && checkbox.checked) {
            selected.push(checkbox);
          }
        }
        if (selected.length == items.length) {
          select_all.checked = true;
        }
        this.selected = selected;
      },
      ClickOpen(event) {
        if (!event) return;
        let elm = event.target, row;
        if (elm.localName != 'tr') row = elm.closest('tr');
        const mousedown = (state) => {
          if (state === true || state === false) {
            row.mousedown = state;
          } else {
            return row.mousedown;
          }
        }
        if (!row.classList.contains('clickable')) return;
        for (let i = 0; i < this.clickables.length; i++) {
          if (this.clickables[i] != row) {
            this.clickables[i].classList.remove('hover', 'selected');
          }
        }
        if (elm.type == 'checkbox') return;
        if (/label|checkbox/.test(elm.className)) return;
        if (elm.localName == 'a' || elm.parentElement.localName == 'a') return;
        if (event.type == 'mousedown') {
          mousedown(event.which == 1);
        }
        if (event.type == 'mousemove') {
          mousedown(false);
        }
        if (event.type == 'mouseup') {
          if (mousedown()) {
            if (this.tab.active == 'maps') {
              this.Edit(row.dataset.id);
            }
            if (this.tab.active == 'results') {
              this.View(row.dataset.id);
            }
          } 
          mousedown(false);
        }
      },
      async PageController(next_page) {
        let load_page;
        const page = {};
        const tab = this.Tab();
        document.querySelectorAll('.page-turn').forEach(arrow => {
          if (arrow) page[arrow.classList.contains('prev') ? 'prev' : 'next'] = arrow;
        });
        if (next_page) {
          if (tab.page.current != tab.page.last) {
            tab.page.current++;
            load_page = true;
            if (tab.page.current == tab.page.last) {
              page.next.classList.add('disabled');
            }
            page.prev.classList.remove('disabled');
          }
        } else {
          if (tab.page.current > 1) {
            tab.page.current--;
            load_page = true;
            if (tab.page.current == 1) {
              page.prev.classList.add('disabled');
            }
            page.next.classList.remove('disabled');
          }
        }
        if (load_page) {
          this.loading = true;
          await this.Search();
          this.loading = false;
        }
      },
      async PageNavigator(e) {
        const page = {
          event: e.type,
          input: e.target,
          last: Number(e.target.max),
          first: Number(e.target.min),
          number: Number(e.target.value)
        }
        const tab = this.Tab();
        const within_limits = (n) => {
          return n >= page.first && n <= page.last;
        }
        const set_page_number = (n) => {
          tab.page.number = n || page.number;
          page.number = Number(tab.page.number);
        }
        document.querySelectorAll('.page-turn').forEach(arrow => {
          if (arrow) page[arrow.classList.contains('prev') ? 'prev' : 'next'] = arrow;
        });
        set_page_number();
        if (page.event == 'keydown') {
          if (e.key == 'Enter') {
            page.input.blur();
          }
        }
        if (page.event == 'blur') {
          ['prev', 'next'].map(arrow => {
            page[arrow] && page[arrow].classList.remove('disabled');
          });
          if (page.number <= page.first) {
            set_page_number(page.first);
            page.input.value = page.first;
            page.prev.classList.add('disabled');
            if (page.last == page.first) {
              page.next.classList.add('disabled');
            }
          } else if (page.number >= page.last) {
            set_page_number(page.last);
            page.input.value = page.last;
            page.next.classList.add('disabled');
            if (page.first == page.last) {
              page.prev.classList.add('disabled');
            }
          }
          if (within_limits(page.number) && page.number != tab.page.current) {
            tab.page.current = page.number;
            this.loading = true;
            await this.Search();
            this.loading = false;
          }
        }
      },
      SetPageJumpWidth() {
        const current = document.querySelector('.page-number-current');
        const last = document.querySelector('.page-number-last');
        if (current && last) {
          const rect = last.getBoundingClientRect();
          const input = current.querySelector('input');
          if (rect && rect.width) {
            current.style.setProperty('width', rect.width + 'px');
          } else {
            current.style.removeProperty('width');
          }
          if (input) {
            input.dispatchEvent(new Event('blur'));
          }
        }
      },
      CheckPermission(required_permission) {
        return BPA.permissions(required_permission).length;
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '../../../assets/style/variables/colors';
  @import '../../../assets/style/variables/icons';

  .label {
    .v-wrapper,
    .v-checkbox-label {
      min-height: auto;
    }

    .v-checkbox-toggle {
      width: 18px;
      height: 18px;
      background-color: $white;
    }
  }

  .field-group {
    & > h3 {
      margin-bottom: 15px;
    }
    /*
    &:nth-child(8) {
      background: red;
    }
    */
    & ~ .field-group:nth-child(even) {
      margin-top: 15px;
      
      & > h3 {
        display: none;
      }
    }
  }

  .fullscreen {
    inset: 0;
    //left: 123px;
    z-index: 3;
    position: fixed;
    background-color: #f1f2f6;

    &-overlay {
      inset: 0;
      position: absolute;
    }
    
    &-header {
      top: 0;
      left: 0;
      right: 0;
      height: 60px;
      padding: 13px 20px;
      display: flex;
      position: absolute;
      align-items: center;
      pointer-events: none;
      justify-content: space-between;

      * {
        pointer-events: initial;

        & + * {
          margin-left: 30px;
        }
      }

      &::before {
        inset: 0;
        color: $white;
        display: flex;
        font-size: 1.5rem;
        position: absolute;
        align-items: center;
        justify-content: center;
        //content: 'Warehouse Map Editor';
      }

      &__tools,
      &__actions {
        flex-shrink: 0;
        //width: calc(345px + 150px);
        width: 30%;
      }

      &__title {
        z-index: 0;
        margin: 0 auto;
        align-self: center;
        justify-self: center;

        input {
          width: 30%;
          height: 34px;
          min-width: 500px;
          max-width: 100%;
          border: 0;
          color: $textFont;
          outline: none;
          font-weight: 600;
          font-size: 1.622rem;
          text-align: center;
          font-family: inherit;
          background-color: transparent;
          border-bottom: 1px solid rgba(60, 60, 60, 0.26);

          &::placeholder {
            color: rgba(96, 96, 96, 0.25);
          }
          /*
          &:not(:placeholder-shown) {
            border-bottom: 2px solid rgba(96, 96, 96, 1);
          }
          */
          &:focus {
            border-bottom: 1px solid rgba(60, 60, 60, 0.26);
          }
        }
      }

      &__actions {
        margin: 0;
        display: flex;
        justify-content: flex-end;

        .label {
          color: $textFont;
          //margin-right: auto;
          flex-direction: row;
          position: relative;

          &-text {
            display: flex;
            align-items: center;
          }

          .v-input {
            width: 100px;
            color: $textFont;
            margin-left: 10px;
            border-color: rgba(60, 60, 60, 0.26);

            &:focus {
              border-color: rgba(60, 60, 60, 0.26);
            }
          }
          /*
          &::after {
            right: 44px;
            content: 'm';
            display: flex;
            position: relative;
            align-items: center;
            margin-right: -44px;
          }
          */
        }

        .button {
          &-icon {
            padding: 0;
            width: 34px;
            height: 34px;
            overflow: hidden;
            position: relative;
            border-radius: 50%;

            &::before {
              inset: 0;
              content: '';
              padding: 8px;
              display: block;
              position: relative;
              mask-size: contain;
              mask-repeat: no-repeat;
              mask-position: center;
              background-color: $white;
            }

            &.plus::before {
              mask-image: $plus;
              transform: rotate(45deg);
            }
          }
        }
      }
    }
  }
</style>
