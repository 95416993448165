<template>
  <div class="settings settings-shipping-config-view grid-wrapper">
    <p class="title" style="margin-bottom: 15px">
      {{ $t("Shipping") }}
    </p>
    <div class="tabs" style="overflow: unset">
      <ul class="tabs__head">
        <li
          :class="['tabs__head--item', { active: tab.active == 'supplier' }]"
          @click="Tab('supplier')"
        >
          <span>{{ $t("Supplier") }}</span>
        </li>
        <li
          :class="['tabs__head--item', { active: tab.active == 'methods' }]"
          @click="Tab('methods'), !Tab().loaded && Search()"
        >
          <span>{{ $t("Methods") }}</span>
        </li>
        <li
          :class="['tabs__head--item', { active: tab.active == 'packaging' }]"
          @click="Tab('packaging')"
        >
          <span>{{ $t("Packaging") }}</span>
        </li>
      </ul>
      <ul class="tabs__body" style="overflow: unset">
        <li
          class="tabs__body--content padding"
          style="padding-bottom: 30px; margin-bottom: -30px"
          v-if="tab.active == 'supplier'"
        >
          <ul
            class="item-submenu"
            style="margin: -27px 0 27px; border-top: 2px solid #1b577a"
          >
            <li
              :class="[
                'item-submenu__item',
                { active: tab[tab.active].tab.active == 'labels' },
              ]"
              ref="subtab_labels"
              @click="Tab('labels')"
            >
              <span>{{ $t("Labels") }}</span>
            </li>
            <li
              :class="[
                'item-submenu__item',
                { active: tab[tab.active].tab.active == 'tracking' },
              ]"
              ref="subtab_tracking"
              @click="Tab('tracking')"
            >
              <span>{{ $t("Tracking") }}</span>
            </li>
          </ul>
          <ul>
            <li v-if="tab[tab.active].tab.active == 'labels'">
              <div
                class="settings__group"
                style="width: 100%; max-width: 250px"
              >
                <label for="label-printer">{{ $t("Delivery company") }}</label>
                <v-select
                  name="courier"
                  v-model="courier.selected"
                  @input="GetCourierConfig"
                  :options="courier.options"
                  :clearable="false"
                >
                  <template v-slot:selected-option="option">
                    <span :class="['courier', option.code]">
                      {{ option.label }}
                    </span>
                  </template>
                  <template slot="option" slot-scope="option">
                    <span :class="['courier', option.code]">
                      {{ option.label }}
                    </span>
                  </template>
                </v-select>
              </div>
              <div class="settings__group json-editor-container">
                <JsonEditor
                  v-model="json.data"
                  :show-btns="true"
                  :expandedOnStart="true"
                  @json-change="JsonChange"
                  @has-error="JsonError"
                  @json-save="ValidateCourierConfig"
                />
              </div>
            </li>

            <li v-if="tab[tab.active].tab.active == 'tracking'">
              <div class="flex-group" style="width: 100%; max-width: 250px">
                <label for="label-printer">{{ $t("Delivery company") }}</label>
                <v-select
                  name="courier"
                  v-model="courier.selected"
                  @input="GetCourierEventConfig"
                  :options="courier.options"
                  :clearable="false"
                >
                  <template v-slot:selected-option="option">
                    <span :class="['courier', option.code]">
                      {{ option.label }}
                    </span>
                  </template>
                  <template slot="option" slot-scope="option">
                    <span :class="['courier', option.code]">
                      {{ option.label }}
                    </span>
                  </template>
                </v-select>
              </div>
              <div class="flex-group field-group">
                <form
                  @submit.prevent
                  style="height: 100%; padding-bottom: 25px"
                >
                  <button ref="submit" type="submit" hidden />
                  <div
                    class="flex-row"
                    style="align-items: flex-end"
                    v-for="(group, index) in form.fields"
                    :key="index"
                  >
                    <div
                      :class="[
                        'flex-column',
                        { flex1: field.fill_width, half: field.half_width },
                      ]"
                      v-for="(field, name) in group"
                      :key="name"
                    >
                      <div :class="['label', { required: field.required }]">
                        <span class="label-text">
                          {{
                            $t(
                              field.label ||
                                Capitalize(field.name).replace(/_/g, " ")
                            )
                          }}
                        </span>
                        <input
                          class="v-input"
                          :type="field.type"
                          :name="field.name"
                          :required="field.required"
                          :readonly="field.readonly"
                          :pattern="field.pattern"
                          :min="field.min"
                          :max="field.max"
                          :minlength="field.minlength"
                          :step="field.step"
                          :value="field.value"
                          :title="field.title"
                          @keydown.enter="$event.target.blur()"
                          @input="field.input ? field.input($event) : {}"
                          @blur="field.blur ? field.blur($event) : {}"
                          :placeholder="field.placeholder"
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div class="flex row sticky-footer">
                <div class="flex column" style="width: auto; margin-left: auto">
                  <button
                    class="button green"
                    style="min-width: 115px"
                    @click.prevent="Update"
                  >
                    {{ $t("Save") }}
                  </button>
                </div>
              </div>
            </li>
          </ul>
        </li>

        <li
          class="tabs__body--content padding"
          style="padding-bottom: 30px; margin-bottom: -30px"
          v-if="tab.active == 'methods'"
        >
          <div class="grid-info">
            <span class="grid-heading">
              <h1>{{ $t("Methods") }}</h1>
              <span class="entries"
                >{{ NumberFormat(Tab().entries) }}
                {{ $t(`entr${Tab().entries == 1 ? "y" : "ies"}`) }}</span
              >
            </span>
            <div class="grid-actions">
              <a
                class="button"
                style="margin-left: auto"
                @click.prevent="OpenModal('create')"
                href=""
                >{{ $t("Create") }}</a
              >
            </div>
            <div class="grid-search">
              <div
                class="search-fields"
                style="align-items: flex-end; max-width: 100%"
              >
                <input
                  type="text"
                  style="width: 30%"
                  v-model="Tab().search.query"
                  @keyup.enter="FilterSearch(), $event.target.blur()"
                  :placeholder="$t('Search by shipping description')"
                />
                <div
                  class="size-select"
                  style="margin-top: -100%; margin-left: 30px; display: flex"
                >
                  <div class="grid-locked" style="margin-top: 0">
                    <div class="toggle locked">
                      <div
                        class="grid-heading"
                        style="
                          font-weight: 600;
                          color: #b3b8bd;
                          padding-bottom: 2px;
                        "
                      >
                        {{ $t("Active") }}
                      </div>
                      <div class="checkbox-toggle">
                        <input
                          type="checkbox"
                          v-model="Tab().search.active"
                          @click="FilterByActive"
                        />
                        <span class="toggle" />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="size-select"
                  style="width: 200px; margin-top: -100%; margin-left: 30px"
                >
                  <p
                    class="grid-heading"
                    style="
                      font-weight: 600;
                      color: #b3b8bd;
                      padding-bottom: 2px;
                    "
                  >
                    {{ $t("Delivery company") }}
                  </p>
                  <v-select
                    name="courier"
                    @input="FilterByAgent"
                    :options="courier.options"
                  >
                    <template v-slot:selected-option="option">
                      <span :class="['courier', option.code]">
                        {{ option.label }}
                      </span>
                    </template>
                    <template slot="option" slot-scope="option">
                      <span :class="['courier', option.code]">
                        {{ option.label }}
                      </span>
                    </template>
                  </v-select>
                </div>
                <div
                  class="size-select"
                  style="width: 200px; margin-top: -100%; margin-left: 30px"
                >
                  <p
                    class="grid-heading"
                    style="
                      font-weight: 600;
                      color: #b3b8bd;
                      padding-bottom: 2px;
                    "
                  >
                    {{ $t("Shipment type") }}
                  </p>
                  <v-select
                    name="page-size-select"
                    @input="FilterByType"
                    :options="types"
                    :searchable="false"
                  >
                    <template v-slot:selected-option="option">
                      <div class="truncate">
                        <span>{{ $t(option.label) }}</span>
                      </div>
                    </template>
                    <template slot="option" slot-scope="option">
                      <div class="truncate">
                        <span>{{ $t(option.label) }}</span>
                      </div>
                    </template>
                  </v-select>
                </div>
              </div>
            </div>
          </div>
          <div>
            <table class="list table odd-even">
              <tbody>
                <tr
                  :data-item="JSON.stringify(item)"
                  :class="['map-list__row', { clickable: true }]"
                  @mousedown="ClickOpen"
                  @mousemove="ClickOpen"
                  @mouseup="ClickOpen"
                  :key="item.id"
                  v-for="item in Tab().list"
                >
                  <td
                    class="label"
                    style="display: table-cell"
                    @click="ToggleCheckbox"
                  >
                    <div class="v-wrapper checkbox">
                      <label class="v-checkbox-label">
                        <input
                          class="v-checkbox"
                          type="checkbox"
                          :data-id="item.id"
                          @input="ToggleCheckbox"
                        />
                        <span class="v-checkbox-toggle" />
                      </label>
                    </div>
                  </td>
                  <td>
                    <div class="checkbox-toggle mini">
                      <input
                        type="checkbox"
                        :checked="item.active"
                        @input="Toggle(item, $event)"
                      />
                      <span class="toggle"></span>
                    </div>
                  </td>
                  <td class="trackingnumber">
                    <div style="display: flex; align-items: center">
                      <div :class="['courier-icon', item.agent_code]" />
                      <span style="margin-left: 0px">{{
                        (
                          courier.options.find(
                            (option) => option.code == item.agent_code
                          ) || {}
                        ).label || "-"
                      }}</span>
                    </div>
                  </td>
                  <td>
                    <div>{{ item.shipping_method }}</div>
                  </td>
                  <td>
                    <div>
                      {{
                        $t(
                          (types.find((type) => type.code == item.type) || {})
                            .label
                        )
                      }}
                    </div>
                  </td>
                  <td
                    class="actions"
                    @mouseenter="SetBackground"
                    @mouseleave="SetBackground"
                  >
                    <a class="icon dots" @click.prevent="" href="">
                      <ul class="item-actions">
                        <li>
                          <a
                            @click.prevent="OpenModal('edit', item)"
                            href=""
                            class="success"
                            >{{ $t("Edit") }}</a
                          >
                        </li>
                        <li>
                          <a
                            @click.prevent="DeleteShippingMethod(item)"
                            href=""
                            class="error"
                            >{{ $t("Delete") }}</a
                          >
                        </li>
                      </ul>
                    </a>
                  </td>
                </tr>
              </tbody>
              <thead>
                <tr>
                  <th class="label" style="display: table-cell">
                    <div class="v-wrapper checkbox">
                      <label class="v-checkbox-label">
                        <input
                          class="v-checkbox"
                          type="checkbox"
                          ref="select_all"
                          @input="ToggleAllCheckboxes"
                        />
                        <span class="v-checkbox-toggle toggle-all" />
                      </label>
                    </div>
                  </th>
                  <th>{{ $t("Active") }}</th>
                  <th>{{ $t("Delivery company") }}</th>
                  <th>{{ $t("Shipping description") }}</th>
                  <th>{{ $t("Shipment type") }}</th>
                  <th class="edit" />
                </tr>
              </thead>
            </table>
            <div
              class="grid-pagination shadow sticky bottom"
              :style="{ marginTop: Tab().list.length && '2px' }"
            >
              <div class="page-navigation">
                <div
                  class="page-turn prev disabled"
                  @click="PageController(false)"
                ></div>
                <div class="page-number">
                  <label class="page-number-current">
                    <input
                      type="number"
                      min="1"
                      :max="Tab().page.last"
                      :value="Tab().page.current"
                      @blur="PageNavigator"
                      @keydown="PageNavigator"
                    />
                    <span class="placeholder">{{
                      NumberFormat(Tab().page.current)
                    }}</span>
                  </label>
                  <span class="page-number-separator">/</span>
                  <div class="page-number-last">
                    {{ NumberFormat(Tab().page.last) }}
                  </div>
                </div>
                <div
                  class="page-turn next"
                  :class="{ disabled: Tab().page.last == 1 }"
                  @click="PageController(true)"
                ></div>
              </div>
            </div>
          </div>
        </li>

        <li
          class="tabs__body--content padding"
          style="padding-bottom: 30px; margin-bottom: -30px"
          v-if="tab.active == 'packaging'"
        >
          <ul
            class="item-submenu"
            style="margin: -27px 0 27px; border-top: 2px solid #1b577a"
          >
            <li
              :class="[
                'item-submenu__item',
                { active: tab[tab.active].tab.active == 'rules' },
              ]"
              ref="subtab_rules"
              @click="Tab('rules'), !Tab().loaded && Search()"
            >
              <span>{{ $t("Rules") }}</span>
            </li>
            <li
              :class="[
                'item-submenu__item',
                { active: tab[tab.active].tab.active == 'types' },
              ]"
              ref="subtab_types"
              @click="Tab('types'), !Tab().loaded && Search()"
            >
              <span>{{ $t("Types") }}</span>
            </li>
          </ul>
          <ul>
            <li v-if="tab[tab.active].tab.active == 'rules'">
              <div class="grid-info">
                <span class="grid-heading">
                  <h1>{{ $t("Rules") }}</h1>
                  <span class="entries"
                    >{{ NumberFormat(Tab().entries) }}
                    {{ $t(`entr${Tab().entries == 1 ? "y" : "ies"}`) }}</span
                  >
                </span>
                <div class="grid-actions">
                  <!-- <a class="button" style="margin-left: auto;" @click.prevent="OpenModal('create')" href="">{{ $t('Create') }}</a> -->
                </div>
                <div class="grid-search">
                  <div
                    class="search-fields"
                    style="align-items: flex-end; max-width: 100%"
                  >
                    <input
                      type="text"
                      style="width: 30%"
                      v-model="Tab().search.query"
                      @keyup.enter="FilterSearch(), $event.target.blur()"
                      :placeholder="$t('Search')"
                    />
                    <div
                      class="size-select"
                      style="width: 160px; margin-top: -100%; margin-left: 30px"
                    >
                      <p
                        class="grid-heading"
                        style="
                          font-weight: 600;
                          color: #b3b8bd;
                          padding-bottom: 2px;
                        "
                      >
                        {{ $t("Page size") }}
                      </p>
                      <v-select
                        name="page-size-select"
                        @input="FilterBySize"
                        v-model="Tab().search.page_size"
                        :options="sizes"
                        :searchable="false"
                        :clearable="false"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div class="flex row" style="margin-top: 30px">
                  <div class="flex column" style="width: 100%">
                    <div class="flex column">
                      <div
                        class="settings__group"
                        style="width: 100%; display: flex; flex-direction: row"
                      >
                        <div
                          class="list-wrapper"
                          style="
                            width: 100%;
                            display: flex;
                            flex-direction: column;
                          "
                        >
                          <div class="simple-list__title">
                            <p class="max-width-300">{{ $t("Packaging") }}</p>
                            <p>{{ $t("Product") }}</p>
                            <div
                              class="simple-list__item item-wrapper"
                              style="width: auto; padding: 0"
                            >
                              <button
                                :class="[
                                  'item-wrapper__button remove',
                                  { hover: Tab().list.length },
                                ]"
                                :disabled="!Tab().list.length"
                                @click="ClearList('packaging rule', true)"
                                @mouseenter="HoverAll"
                                @mouseleave="HoverAll"
                              />
                            </div>
                          </div>
                          <ul
                            class="simple-list odd-even"
                            style="border-bottom: 0"
                          >
                            <li class="simple-list__add">
                              <div class="item-wrapper noflex max-width-300">
                                <v-select
                                  name="type"
                                  ref="rule"
                                  v-model="Tab().item.rule"
                                  @input="SetOption"
                                  :placeholder="$t('Type')"
                                  :options="Tab().options"
                                >
                                  <template v-slot:selected-option="option">
                                    <div class="truncate">
                                      <span>{{ option.label }}</span>
                                    </div>
                                  </template>
                                  <template slot="option" slot-scope="option">
                                    <div class="truncate">
                                      <span>{{ option.label }}</span>
                                    </div>
                                  </template>
                                  <template v-slot:no-options
                                    ><span
                                  /></template>
                                </v-select>
                              </div>
                              <div class="item-wrapper">
                                <input
                                  class="item-wrapper__input lowercase"
                                  ref="sku"
                                  v-model="Tab().item.sku"
                                  @keydown.space.prevent
                                  @keydown.enter="
                                    $event.target.blur(), AddItem()
                                  "
                                  @blur="
                                    Tab().item.sku = String(Tab().item.sku)
                                      .toLowerCase()
                                      .replace(/\s/g, '')
                                  "
                                  placeholder="SKU"
                                  spellcheck="false"
                                />
                              </div>
                              <div class="item-wrapper" style="width: auto">
                                <button
                                  class="item-wrapper__button add"
                                  @click="AddItem"
                                ></button>
                              </div>
                            </li>
                            <li
                              class="simple-list__item"
                              :key="index"
                              v-for="(item, index) in Tab().list"
                            >
                              <div class="item-wrapper max-width-300">
                                <p
                                  class="item-wrapper__text"
                                  style="padding-left: 9px"
                                >
                                  <span>{{ item.rule.title }}</span>
                                </p>
                              </div>
                              <div class="item-wrapper">
                                <!-- <input class="item-wrapper__input lowercase" v-model="item.sku" @keydown.space.prevent @change="EditItem(item, 'sku')" @keydown.enter="$event.target.blur()" @blur="item.sku = String(item.sku).toLowerCase().replace(/\s/g, '')" spellcheck="false"> -->
                                <p
                                  class="item-wrapper__text"
                                  style="padding-left: 9px"
                                >
                                  <span>{{ item.sku }}</span>
                                </p>
                              </div>
                              <div class="item-wrapper" style="width: auto">
                                <button
                                  class="item-wrapper__button remove"
                                  @click="
                                    RemoveItem(item, 'packaging rule', true)
                                  "
                                  @mouseenter="HoverButton"
                                  @mouseleave="HoverButton"
                                />
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="grid-pagination shadow sticky bottom"
                  :style="{ marginTop: Tab().list.length && '2px' }"
                >
                  <div class="page-navigation">
                    <div
                      class="page-turn prev disabled"
                      @click="PageController(false)"
                    ></div>
                    <div class="page-number">
                      <label class="page-number-current">
                        <input
                          type="number"
                          min="1"
                          :max="Tab().page.last"
                          :value="Tab().page.current"
                          @blur="PageNavigator"
                          @keydown="PageNavigator"
                        />
                        <span class="placeholder">{{
                          NumberFormat(Tab().page.current)
                        }}</span>
                      </label>
                      <span class="page-number-separator">/</span>
                      <div class="page-number-last">
                        {{ NumberFormat(Tab().page.last) }}
                      </div>
                    </div>
                    <div
                      class="page-turn next"
                      :class="{ disabled: Tab().page.last == 1 }"
                      @click="PageController(true)"
                    ></div>
                  </div>
                </div>
              </div>
            </li>

            <li v-if="tab[tab.active].tab.active == 'types'">
              <div class="grid-info">
                <span class="grid-heading">
                  <h1>{{ $t("Types") }}</h1>
                  <span class="entries"
                    >{{ NumberFormat(Tab().entries) }}
                    {{ $t(`entr${Tab().entries == 1 ? "y" : "ies"}`) }}</span
                  >
                </span>
                <div class="grid-actions">
                  <a
                    class="button"
                    style="margin-left: auto"
                    @click.prevent="OpenModal('create')"
                    href=""
                    >{{ $t("Create") }}</a
                  >
                </div>
                <div class="grid-search">
                  <div
                    class="search-fields"
                    style="align-items: flex-end; max-width: 100%"
                  >
                    <input
                      type="text"
                      style="width: 30%"
                      v-model="Tab().search.query"
                      @keyup.enter="FilterSearch(), $event.target.blur()"
                      :placeholder="$t('Search')"
                    />
                    <div
                      class="size-select"
                      style="width: 160px; margin-top: -100%; margin-left: 30px"
                    >
                      <p
                        class="grid-heading"
                        style="
                          font-weight: 600;
                          color: #b3b8bd;
                          padding-bottom: 2px;
                        "
                      >
                        {{ $t("Page size") }}
                      </p>
                      <v-select
                        name="page-size-select"
                        @input="FilterBySize"
                        v-model="Tab().search.page_size"
                        :options="sizes"
                        :searchable="false"
                        :clearable="false"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <table class="list table odd-even">
                  <tbody>
                    <tr
                      :data-item="JSON.stringify(item)"
                      :class="['map-list__row', { clickable: true }]"
                      @mousedown="ClickOpen"
                      @mousemove="ClickOpen"
                      @mouseup="ClickOpen"
                      :key="item.id"
                      v-for="item in Tab().list"
                    >
                      <td
                        class="label"
                        style="display: table-cell"
                        @click="ToggleCheckbox"
                      >
                        <div class="v-wrapper checkbox">
                          <label class="v-checkbox-label">
                            <input
                              class="v-checkbox"
                              type="checkbox"
                              :data-id="item.id"
                              @input="ToggleCheckbox"
                            />
                            <span class="v-checkbox-toggle" />
                          </label>
                        </div>
                      </td>
                      <td>
                        <div>{{ item.id }}</div>
                      </td>
                      <td>
                        <div>{{ item.company }}</div>
                      </td>
                      <td>
                        <div>{{ item.title }}</div>
                      </td>
                      <td>
                        <div>{{ item.crate_type_code }}</div>
                      </td>
                      <td>
                        <div>{{ item.priority }}</div>
                      </td>
                      <td class="status">
                        <div
                          class="flex-row state lowercase"
                          :class="[item.img_mime ? 'green' : 'red']"
                        >
                          {{ item.img_mime || $t("Default") }}
                        </div>
                      </td>
                      <td
                        class="actions"
                        @mouseenter="SetBackground"
                        @mouseleave="SetBackground"
                      >
                        <a class="icon dots" @click.prevent="" href="">
                          <ul class="item-actions">
                            <li>
                              <a
                                @click.prevent="OpenModal('edit', item)"
                                href=""
                                class="success"
                                >{{ $t("Edit") }}</a
                              >
                            </li>
                            <li>
                              <a
                                @click.prevent="
                                  DeleteShippingPackagingType(item)
                                "
                                href=""
                                class="error"
                                >{{ $t("Delete") }}</a
                              >
                            </li>
                          </ul>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                  <thead>
                    <tr>
                      <th class="label" style="display: table-cell">
                        <div class="v-wrapper checkbox">
                          <label class="v-checkbox-label">
                            <input
                              class="v-checkbox"
                              type="checkbox"
                              ref="select_all"
                              @input="ToggleAllCheckboxes"
                            />
                            <span class="v-checkbox-toggle toggle-all" />
                          </label>
                        </div>
                      </th>
                      <th>{{ $t("ID") }}</th>
                      <th>{{ $t("Company") }}</th>
                      <th>{{ $t("Title") }}</th>
                      <th>{{ $t("Code") }}</th>
                      <th>{{ $t("Priority") }}</th>
                      <th>{{ $t("Image") }}</th>
                      <th class="edit" />
                    </tr>
                  </thead>
                </table>
                <div
                  class="grid-pagination shadow sticky bottom"
                  :style="{ marginTop: Tab().list.length && '2px' }"
                >
                  <div class="page-navigation">
                    <div
                      class="page-turn prev disabled"
                      @click="PageController(false)"
                    ></div>
                    <div class="page-number">
                      <label class="page-number-current">
                        <input
                          type="number"
                          min="1"
                          :max="Tab().page.last"
                          :value="Tab().page.current"
                          @blur="PageNavigator"
                          @keydown="PageNavigator"
                        />
                        <span class="placeholder">{{
                          NumberFormat(Tab().page.current)
                        }}</span>
                      </label>
                      <span class="page-number-separator">/</span>
                      <div class="page-number-last">
                        {{ NumberFormat(Tab().page.last) }}
                      </div>
                    </div>
                    <div
                      class="page-turn next"
                      :class="{ disabled: Tab().page.last == 1 }"
                      @click="PageController(true)"
                    ></div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </li>
      </ul>
    </div>

    <Modal
      modal="modal"
      :value="modal.open"
      :title="
        $t(
          modal.mode == 'create'
            ? Tab().name == 'methods'
              ? 'New shipping method'
              : 'New packaging type'
            : Tab().name == 'methods'
            ? 'Shipping method'
            : 'Packaging type'
        ) + modal.title
      "
    >
      <div v-if="modal.open">
        <div class="modal-header" />
        <div class="modal-content scrollable">
          <div class="modal-tabs">
            <div class="modal-tabs__body" style="overflow: hidden auto">
              <div class="modal-tabs__body-content padding">
                <form
                  @submit.prevent
                  style="padding-bottom: 25px"
                >
                  <button ref="submit" type="submit" hidden />
                  <div
                    v-for="(group, index) in form.fields"
                    :key="index"
                    class="flex-group"
                    :style="
                      index == form.fields.length - 1 && {
                        height: 'calc(100% - 150px)',
                      }
                    "
                  >
                    <div
                      class="flex-row"
                      :style="
                        index == form.fields.length - 1
                          ? { height: '100%' }
                          : { alignItems: 'flex-end' }
                      "
                    >
                      <div
                        v-for="(field, index) in group"
                        :key="index"
                        :class="[
                          'flex-column',
                          { flex1: field.fill_width, half: field.half_width },
                        ]"
                        :style="[
                          {
                            maxWidth: field.type == 'checkbox' && 'max-content',
                          },
                        ]"
                        v-show="!field.hidden"
                      >
                        <div
                          :class="['label', { required: field.required }]"
                          :style="
                            field.type == 'checkbox'
                              ? {
                                  flexDirection: `row${
                                    field.type == 'checkbox' ? '-reverse' : ''
                                  }`,
                                  alignItems: 'center',
                                  marginBottom:
                                    field.type == 'checkbox' && '5px',
                                }
                              : field.fill_height && { height: '100%' }
                          "
                        >
                          <span class="label-text">
                            {{
                              $t(
                                field.label ||
                                  Capitalize(field.name).replace(/_/g, " ")
                              )
                            }}
                          </span>
                          <v-select
                            v-if="field.type == 'select'"
                            :name="field.name"
                            :required="field.required"
                            :disabled="field.disabled || readonly"
                            :value="field.value"
                            :options="field.options"
                            :title="field.title"
                            :searchable="field.searchable"
                            :clearable="field.clearable"
                            v-on="field.input ? { input: field.input } : {}"
                          >
                            <template #search="{ attributes, events }">
                              <input
                                class="vs__search"
                                v-bind="attributes"
                                v-on="events"
                                :required="
                                  field.required && !form.data[field.name]
                                "
                              />
                            </template>
                            <template v-slot:selected-option="option">
                              <div v-if="field.name == 'type'" class="truncate">
                                <span>{{ $t(option.label) }}</span>
                              </div>
                              <span v-else :class="['courier', option.code]">
                                {{ option.label }}
                              </span>
                            </template>
                            <template slot="option" slot-scope="option">
                              <div v-if="field.name == 'type'" class="truncate">
                                <span>{{ $t(option.label) }}</span>
                              </div>
                              <span v-else :class="['courier', option.code]">
                                {{ option.label }}
                              </span>
                            </template>
                          </v-select>
                          <div
                            v-else-if="field.type == 'checkbox'"
                            class="v-wrapper checkbox"
                          >
                            <label
                              class="v-checkbox-label"
                              style="margin-right: 10px"
                            >
                              <input
                                class="v-checkbox"
                                :type="field.type"
                                :name="field.name"
                                :disabled="field.disabled"
                                :checked="field.value"
                                @input="field.input ? field.input($event) : {}"
                              />
                              <span
                                class="v-checkbox-toggle"
                                style="width: 24px; height: 24px"
                              />
                            </label>
                          </div>
                          <textarea
                            v-else-if="field.type == 'textarea'"
                            class="v-textarea"
                            style="height: 100%; min-height: 34px; resize: none"
                            :name="field.name"
                            :required="field.required"
                            :readonly="field.readonly"
                            :pattern="field.pattern"
                            v-model="form.data[field.name]"
                            :title="field.title"
                            @input="field.input ? field.input($event) : {}"
                            @blur="field.blur ? field.blur($event) : {}"
                          />
                          <div
                            v-else-if="field.type == 'list'"
                            class="v-input-list border"
                            style="height: 100%"
                          >
                            <ul class="v-list">
                              <li
                                class="v-list-item even-odd clickable"
                                v-for="(item, index) of field.list"
                                :key="index"
                                @click="OpenUser(item.name)"
                              >
                                {{ item.name }}
                              </li>
                            </ul>
                          </div>
                          <div
                            v-else-if="field.type == 'file'"
                            class="v-wrapper-file image"
                            style="background-color: white"
                          >
                            <input
                              :type="field.type"
                              :name="field.name"
                              :required="field.required"
                              :accept="field.accept"
                              :value="field.value"
                              :title="field.title"
                              @input="field.input ? field.input($event) : {}"
                            />
                            <div
                              :class="[
                                'v-image',
                                /default/.test(field.name)
                                  ? 'default'
                                  : 'current',
                              ]"
                              :style="[
                                {
                                  '--src': field.image && `url(${field.image})`,
                                  backgroundColor: 'white',
                                  opacity: field.placeholder && 0.4,
                                },
                              ]"
                            />
                            <div class="clear" @click="ClearImage(field)" />
                          </div>
                          <input
                            v-else
                            class="v-input"
                            :type="field.type"
                            :name="field.name"
                            :required="field.required"
                            :readonly="field.readonly"
                            :pattern="field.pattern"
                            :min="field.min"
                            :max="field.max"
                            :minlength="field.minlength"
                            :step="field.step"
                            :value="field.value"
                            v-model="form.data[field.name]"
                            :title="field.title"
                            @keydown.enter="$event.target.blur()"
                            @keydown="
                              field.keydown ? field.keydown($event) : {}
                            "
                            @input="field.input ? field.input($event) : {}"
                            @blur="field.blur ? field.blur($event) : {}"
                            :spellcheck="
                              'spellcheck' in field ? field.spellcheck : ''
                            "
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-if="modal.mode != 'create' && CheckPermissions(attribute_permissions)" style="padding-top: 25px;">
                    <SettingsShippingAttributeConfig @isDirty="attributes_to_update.push($event)" @isDestroyed="ResetAttributesToUpdate()" :courierAttributeMap="courier_attributes_map" :shippingHandlingInformation="shipping_handling_information" />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div class="button-container actions">
            <div
              class="label"
              style="
                flex-direction: row;
                align-items: center;
                margin-right: auto;
              "
              v-if="'active' in form.data"
            >
              <div class="checkbox-toggle">
                <input type="checkbox" v-model="form.data.active" />
                <span class="toggle" />
              </div>
              <span style="margin-left: 10px">{{ $t("Active") }}</span>
            </div>
            <button
              class="button green"
              @click.prevent="Create"
              v-if="modal.mode == 'create'"
            >
              {{ $t("Create") }}
            </button>
            <button
              class="button green"
              @click.prevent="Update"
              v-if="modal.mode == 'edit'"
            >
              {{ $t("Save") }}
            </button>
            <button
              class="button red"
              @click.prevent="Delete"
              v-if="modal.mode == 'edit'"
            >
              {{ $t("Delete") }}
            </button>
          </div>
        </div>
      </div>
    </Modal>

    <div class="loading" v-if="loading">
      <div class="loading-element">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  </div>
</template>

<script>
import { TableElementsBehaviour } from "@/mixins/TableElementsBehaviour";
import { Tool } from "@/helpers/Tool";
import { BPA } from "@/helpers/BPA";
import Modal from "@/components/snippets/Modal";
import JsonEditor from "vue-json-editor";
import SettingsShippingAttributeConfig from '@/components/blocks/Settings/SettingsShippingConfig/SettingsShippingAttributeConfig';

export default {
  name: "SettingsShippingConfigView",
  mixins: [TableElementsBehaviour, Tool, BPA],
  components: { Modal, JsonEditor, SettingsShippingAttributeConfig },
  data() {
    return {
      shipping_handling_information: {},
      courier_attributes_map: new Map(),
      attributes_to_update: [],
      loading: false,
      clickables: [],
      selected: [],
      cached: {},
      courier: {
        options: [],
        selected: "",
      },
      json: {
        init: {},
        data: {},
      },
      editor: {
        bypass: false,
        passed: false,
        error: "",
      },
      types: [
        { code: "business_address", label: "Business address" },
        { code: "commercial", label: "Private address" },
        { code: "pakkeshop", label: "Parcel shop" },
      ],
      sizes: [10, 20, 30, 50, 100],
      tab: {
        active: "supplier",
        supplier: {
          name: "supplier",
          loaded: true,
          tab: {
            active: "labels",
            labels: {
              name: "labels",
              loaded: false,
            },
            tracking: {
              name: "tracking",
              loaded: false,
              data: {
                bring: {
                  authorization: "",
                  webhookurl: "",
                },
                burd: {
                  password: "",
                  user: "",
                },
                dao: {
                  host: "",
                  kode: "",
                  kunde_id: "",
                },
                dhl: {
                  client_id: "",
                  client_secret: "",
                  customer_id: "",
                },
                gls: {
                  host: "",
                  password: "",
                  path: "",
                  user: "",
                },
                instabox: {
                  signature: "",
                },
                pdk: {
                  host: "",
                  password: "",
                  path: "",
                  port: "",
                  user: "",
                },
              },
              template: {
                bring: [
                  {
                    authorization: "",
                  },
                  {
                    webhookurl: "",
                  },
                ],
                burd: [
                  {
                    user: "",
                    password: "",
                  },
                ],
                dao: [
                  {
                    kunde_id: "",
                    kode: "",
                  },
                  {
                    host: "",
                  },
                ],
                dhl: [
                  {
                    customer_id: "",
                    client_id: "",
                  },
                  {
                    client_secret: "",
                  },
                ],
                gls: [
                  {
                    user: "",
                    password: "",
                  },
                  {
                    host: "",
                    path: "",
                  },
                ],
                instabox: [
                  {
                    signature: "",
                  },
                ],
                pdk: [
                  {
                    user: "",
                    password: "",
                  },
                  {
                    host: "",
                    path: "",
                    port: "",
                  },
                ],
              },
            },
          },
        },
        methods: {
          name: "methods",
          loaded: false,
          entries: 0,
          list: [],
          item: {},
          search: {
            query: "",
            initial: null,
            active: false,
            agent_code: "",
            type: "",
            page_size: 20,
            page_offset: 1,
          },
          page: {
            current: 1,
            number: 1,
            last: 1,
          },
          template: [
            {
              agent_code: "",
              type: "",
            },
            {
              shipping_method: "",
            },
            {
              create_img: "",
              default_create_img: "",
            },
          ],
        },
        packaging: {
          name: "packaging",
          loaded: true,
          tab: {
            active: "rules",
            rules: {
              name: "rules",
              loaded: false,
              entries: 0,
              options: [],
              list: [],
              item: {
                rule: "",
                sku: "",
              },
              search: {
                query: "",
                initial: null,
                page_size: 20,
                page_offset: 1,
              },
              page: {
                current: 1,
                number: 1,
                last: 1,
              },
            },
            types: {
              name: "types",
              loaded: false,
              entries: 0,
              list: [],
              item: {},
              search: {
                query: "",
                initial: null,
                active: false,
                agent_code: "",
                type: "",
                page_size: 20,
                page_offset: 1,
              },
              page: {
                current: 1,
                number: 1,
                last: 1,
              },
              template: [
                {
                  title: "",
                },
                {
                  crate_type_code: "",
                  priority: 0,
                },
                {
                  img_data: "",
                },
              ],
            },
          },
        },
      },
      form: {
        fields: [],
        data: {},
      },
      modal: {
        mode: "create",
        open: false,
      },
      attribute_permissions: [/*0 17 18*/ 'admin', 'settings_admin', 'user_settings'],
      readonly: !BPA.permissions(["admin", "settings_admin"]).length,
      default_create_img:
        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAAAAACPAi4CAAAACXZwQWcAAABAAAAAQADq8/hgAAAEWklEQVRYw9WX6XKjRhCAef8HiySQvGt5vfZuEselOUAcEpe4GdI9MAgQOjb5k3SVyzY1801PX9OtNf9StP80QJR5miRpXtb/AFCnvmMySgmhlJn2Mal+BSBSj1NCGeNSGAMOd0/iQYCI95TAXnm+FCr/I2ZYPwJILEJhPaGm7flBFIW+Z5sUvwEivguovG7pMR0cV2e+BbYArF3cBqQclKfEvryvSB2KaHa6BYhgDSP7ZN7gmUNQCf86wCdgcBaKq04/cTzAuwbA/czKb8VdZYMSI8IAEOJ+XjTiFkF4SDjOARIIHLiBK+4E/xHOIdEloMSAAwZx7hEOBKIquwA4lFPbR/3uEhzCqSUmgBiwrGgeIlQm5b0zO0CN3yKw34QgQC4JKZqrGAFC0MpWvuwJ3V6hWD3BI5wchoDaBAumzYQgmsrd7ewZx5bosHIAAAtQp4+nXUuA+2yXy9Xyi4OsIorjauBLZQWtd0Gqrt3EvCXQlb4BMZYfsPP7cr0gvS4FaNw6Qus0ovtez8DZcYyHt8Wmk9XWdF+Mjf570Ke4q46UgAgUCtX55mKl/wSbsD83hrEE0VGJ1RrEWHz2aaXuIAEe7b3SNG/601oSzL/W20/T2r2uDNACARvjWelZQTTaCiCg2vSR1bzrsFgSQMk8SbPi8FWX+0GFbX2OXMarDoAmOGfo+wpXt7cwj4Hv+1n+rSMYW3HOfS4TAgHZIDIVYG38wNzchyB+kj4ZUwB4npw6ABokmgA2qz9kfbIkoWDLzQSQ0tbw2gA20kA/nmyqCHG8nmqQd2prbSKQZAIwnk5B5PSE/EWfACCUZGFSgHQKeE6DsCcExfc5wKEDRLMaJHBwTwA/zFzhOLBBPGODoCfEyYUb0XVBB1AGHXvho/SVDsSjF15QrtMG1xlpsDbCrCewj7UxAWAJSjsAlJOuHI0AX9Mi8IMgsJnMC2MMOJA2f7RhXI8AG/2LVxZZVlQWmKElnAFiT5nMH62L67Mb3lTmbIzVK3Uc9r6GvJAEyMa6d0KXP1oXliqbRPPzN0NvBcrBAmSpr37wlrB8GeRS6zkJECZVNRKeuLfty1C+wc/zp7TD9jVQN7DUDq2vkUEzfAymIl9uZ5iL1B0U1Rw7surmc4SE/sUBE3KaDB8Wd1QS7hJQga4Kayow2aAsXiV0L458HE/jx9UbPi33CIf+ITwDSnxM/IcIcAGIrHzaH+BX8Ky4awdq41nBZYsjG4/kEQLjg9Q5A9A1jJ7u3CJEa1OzmuvSKgubwPA24IT7WT7fJ5YmEtwbASWO2AkP94871WpPOCc8vmYHaORhv5lf75VrV3bD+9nZIrUJamhXN9v9kMlu3wonYVlGe9msU1/cGTgKpx0YmO2fsrKq66rMk8Bh7dd99sDIk+xxxsE5icqhqfsLflkz1pkbukSCBzI5bqG0EGrPGvfK2FeGDseRi1I5eVFuB8WvDp51FvsH13Fcz4+y6n86Oz8kfwPMD02INEiadQAAAABJRU5ErkJggg==",
    };
  },
  async created() {
    let courier_options = [];
    Object.entries(BPA.api.Couriers("GET")).map((entry) => {
      courier_options.push({ code: entry[0], label: entry[1] });
    });
    this.courier.options = Tool.Alphabetize(courier_options, "label");
    JsonEditor.props.mode.default = this.readonly ? "text" : "code";
    if (this.readonly) {
      let counter = 0;
      let checker = () => {
        clearTimeout(checker.timeout);
        let ready = document.querySelector(".jsoneditor-vue");
        if (ready) {
          let modes_button = document.querySelector("button.jsoneditor-modes");
          let textarea = document.querySelector(".jsoneditor-text");
          textarea.setAttribute("readonly", "");
          modes_button.addEventListener("click", () => {
            let context_menu_root = document.querySelector(
              ".jsoneditor-contextmenu-root"
            );
            let context_menu = context_menu_root.querySelector(
              ".jsoneditor-contextmenu ul"
            );
            if (context_menu)
              Array.from(context_menu.children)
                .filter((item) => {
                  let mode = String(item.textContent).toLowerCase();
                  let default_mode = JsonEditor.props.mode.default;
                  if (mode == default_mode) {
                    item.firstChild.onclick = () => {
                      context_menu_root.remove();
                    };
                  }
                  return mode != default_mode;
                })
                .forEach((item) => item.remove());
          });
          modes_button.remove();
          return;
        }
        if (counter >= 1000) return;
        checker.timeout = setTimeout(checker);
        counter++;
      };
      checker();
    }
    this.courier_attributes_map = this.SetCourierAttributeMap(this.courier.options)
  },
  mounted() {
    this.$eventHub.$on("CloseModal", () => {
      this.main.view.classList.remove("no-scroll");
      this.form = { fields: [], data: {} };
      this.modal.open = false;
      /*
        this.method = {};
        this.cached.method = {};
        BPA.cache.session({name: this.$options.name, set: {method: {}}});
        */
      for (let i = 0; i < this.clickables.length; i++) {
        if (this.clickables[i].classList.contains("selected")) {
          this.clickables[i].classList.remove("selected");
        }
      }
    });
    this.InitJsonEditor();
  },
  computed: {
    main() {
      const main = {
        company: BPA.util.GetCompany(),
        view: document.querySelector(".content-scroll"),
      };
      main.view.scroll = { top: 0 };
      return main;
    },
    locale() {
      return this.$i18n.locale;
    },
  },
  watch: {
    locale() {
      this.saveButton.textContent = this.$t("Save");
    },
  },
  methods: {
    ConsoleLog(output) {
      console.log(output);
    },
    CloneObject(object = {}) {
      return Tool.CloneObject(object);
    },
    DateFormat(date) {
      return Tool.DateFormat(date);
    },
    NumberFormat(number) {
      return Tool.NumberFormat(number);
    },
    Capitalize(string = "") {
      return Tool.Capitalize(string);
    },
    TitleCase(string = "") {
      return Tool.TitleCase(string);
    },
    Hyperlink(props = {}) {
      return Tool.Hyperlink(props);
    },
    CountryName(country_code = "") {
      return Tool.CountryName(country_code);
    },
    LanguageName(country_code = "") {
      return Tool.LanguageName(country_code);
    },
    InitJsonEditor() {
      this.courier.selected = "";
      this.json = { init: {}, data: {} };
      let poweredby = document.querySelector(".jsoneditor-poweredBy");
      if (poweredby) poweredby.href = "https://ace.c9.io/";
      this.saveButton = document.querySelector(".json-save-btn") || {};
      this.saveButton.parentElement?.classList.add("sticky-footer");
      this.saveButton.textContent = this.$t("Save");
      this.saveButton.disabled = this.readonly;
      Array("mouseup", "click").map((event) => {
        this.saveButton[`on${event}`] = (e) => {
          if (e.type == "mouseup") {
            let editor = document.querySelector(".jsoneditor");
            let mode = editor.className.split("-").pop();
            let content = null;
            let bypass = false;
            switch (mode) {
              case "tree":
                content = editor.querySelector(".jsoneditor-value").textContent;
                bypass = ["{0}", "null", ""].includes(content);
                break;
              case "code":
                content = editor.querySelector(".ace_content").textContent;
                bypass = !content.length || this.IsJsonString(content);
                break;
              case "form":
                break;
              case "text":
                content = editor.querySelector(".jsoneditor-text").value;
                break;
              case "view":
            }
            this.editor.bypass = bypass;
          } else if (!this.editor.passed) {
            if (!this.courier.selected.code) return;
            if (this.JsonUnchanged()) return;
            this.UpdateCourierConfig();
          }
        };
      });
    },
    Tab(active) {
      let tab = this.tab;
      let tab_active = tab[tab.active];
      if (active) {
        if (!(active in tab)) {
          tab = tab_active.tab;
        }
        tab.active = active;
      } else if ("tab" in tab_active) {
        tab = tab_active.tab;
      }
      tab_active = tab[tab.active];
      this.$nextTick().then(async () => {
        let refs = this.$refs;
        let select_all = refs.select_all;
        if (active) {
          if (active == "labels") {
            this.InitJsonEditor();
            tab_active.loaded = false;
          }
          if (active == "tracking") {
            this.courier.selected = "";
            if (tab_active.loaded) {
              this.ClearForm();
              tab_active.loaded = false;
            }
          }
          if (active == "methods") {
            //let methods = await window.fetch(`../methods.json?${new Date().getTime()}`).then(r => r.json()).then(r => r) || [];
            //for (let method of methods) await this.SetShippingMethod(method);
            if (tab_active.loaded) {
              this.SetPageJumpWidth();
            }
          }
          if (["supplier", "packaging"].includes(active)) {
            tab = tab_active.tab;
            let subtab = refs[`subtab_${tab.active}`];
            if (subtab) subtab.dispatchEvent(new Event("click"));
          }
          if (active == "rules") {
            let dropdown_toggle = document.querySelector(
              '.v-select[name="type"] > .vs__dropdown-toggle'
            );
            if (dropdown_toggle) {
              dropdown_toggle.classList.add("borderless");
              Object.assign(dropdown_toggle.style, {
                minHeight: "32px",
                height: "32px",
                border: 0,
              });
            }
            if (!tab_active.loaded) {
              tab_active.options = await this.GetShippingCrateRuleOptions();
            } else {
              this.SetPageJumpWidth();
            }
          }
          if (active == "types") {
            if (tab_active.loaded) {
              this.SetPageJumpWidth();
            }
          }
          if (select_all) {
            select_all.disabled = !tab_active.list?.length;
            select_all.checked = false;
          }
          this.selected = [];
        }
      });
      return tab_active;
    },
    async FilterByActive(event) {
      this.Tab().search.active = event?.target?.checked;
      await this.FilterSearch();
    },
    async FilterByAgent(option) {
      this.Tab().search.agent_code = option?.code;
      await this.FilterSearch();
    },
    async FilterByType(option) {
      this.Tab().search.type = option?.code;
      await this.FilterSearch();
    },
    async FilterBySize(option) {
      this.Tab().search.page_size = option;
      await this.FilterSearch();
    },
    async FilterSearch() {
      this.Tab().page.current = 1;
      this.loading = true;
      await this.Search();
      this.loading = false;
    },
    async Search() {
      let tab = this.Tab();
      let tab_name = tab.name;
      let search = tab.search;
      let page = tab.page;
      let method = null;
      let request = {
        query: search.query,
        page_size: search.page_size,
        page_offset: page.current,
      };
      switch (tab_name) {
        case "methods": {
          method = "SearchShippingMethods";
          if (search.active) {
            request.active = search.active;
          }
          if (search.agent_code) {
            request.agent_code = search.agent_code;
          }
          if (search.type) {
            request.type = search.type;
          }
          break;
        }
        case "rules": {
          method = "SearchShippingCrateRules";
          break;
        }
        case "types": {
          method = "SearchShippingCrateTypes";
          break;
        }
        default: {
          return (tab.loaded = false);
        }
      }
      let data = await this[method](request);
      search.initial = search.query;
      page.current = request.page_offset;
      page.last = Math.ceil((data.count || 1) / request.page_size);
      tab.entries = data.count;
      tab.list = data.items;
      tab.cached = this.CloneObject(tab.list);
      //BPA.cache.local({name: this.$options.name, set: {page: tab.page, search: tab.search}});
      this.$nextTick().then(async () => {
        let select_all = this.$refs.select_all;
        if (select_all) {
          select_all.disabled = !tab.list.length;
          select_all.checked = false;
        }
        this.clickables = document.querySelectorAll(".clickable");
        /*
          if (this.cached.method && Object.keys(this.cached.method).length) {
            this.OpenModal('edit', this.cached.method);
          }
          */
        this.SetPageJumpWidth();
      });
      tab.loaded = true;
    },
    async OpenModal(mode, item = {}) {
      let tab = this.Tab();
      let tab_name = tab.name;
      this.modal.mode = mode;
      this.modal.title = "";
      tab.item = item;
      if (mode == "edit") {
        for (let i = 0; i < this.clickables.length; i++) {
          if (this.clickables[i].dataset.item == JSON.stringify(tab.item)) {
            this.clickables[i].classList.add("selected");
            /*
              if (this.cached.method && Object.keys(this.cached.method).length) {
                this.clickables[i].scrollIntoView({
                  behavior: 'auto',
                  block: 'center',
                  inline: 'center'
                });
              }
              */
            break;
          }
        }
      }
      switch (tab_name) {
        case "methods": {
          this.loading = true;
          let default_create_img = (await this.GetShippingCrateImage()) || "";
          if (mode == "edit") {
            tab.item = (await this.GetShippingMethod(item)) || {};
            this.shipping_handling_information = tab.item
            tab.item.create_img =
              (await this.GetShippingCrateImage(item)) || "";
            tab.item.default_create_img = default_create_img;
            let courier = this.courier.options.find(
              (option) => option.code == tab.item.agent_code
            );
            this.modal.title = `:&nbsp; <span class="agent-icon ${courier.code}" title="${courier.label}"></span>${tab.item.shipping_method}`;
            //BPA.cache.session({name: this.$options.name, set: {method: this.method}});
          } else {
            tab.item.active = true;
            tab.item.create_img = default_create_img;
            tab.item.default_create_img = default_create_img;
          }
          break;
        }
        case "types": {
          this.loading = true;
          let default_create_img = (await this.GetShippingCrateImage()) || "";
          tab.item.default_create_img = default_create_img;
          tab.item.img_data = default_create_img;
          if (mode == "edit") {
            this.modal.title = ` #${item.id}`;
            let img_data =
              (await this.GetShippingCrateTypeImage(item.id)) || "";
            if (img_data) tab.item.img_data = img_data;
          } else {
            tab.item.priority = 0;
          }
          break;
        }
      }
      await this.SetupForm(tab.item);
      this.modal.open = true;
      this.loading = false;
    },
    ClearForm() {
      this.form = {
        fields: [],
        data: {},
      };
    },
    async ValidateForm() {
      return await new Promise((resolve) => {
        let button = this.$refs.submit;
        if (button) {
          let form = button.closest("form");
          let fields = Array.from(form.elements);
          if (fields.some((field) => !field.checkValidity())) {
            return button.click();
          }
        }
        resolve(true);
      }).catch((e) => e);
    },
    async SetupForm(item = {}) {
      return await new Promise(async (resolve) => {
        let fields = [];
        let form = item;
        let tab = this.Tab();
        let mode = this.modal.mode;
        let data = tab.name == "methods" ? { active: true } : { priority: 0 };
        let template = this.CloneObject(tab.template);
        const find_field = (name) =>
          fields
            .map((group) => {
              return group.find((field) => field.name == name);
            })
            .filter((e) => e)[0] || {};
        if (!Object.keys(form).length) {
          form = template;
        }
        if (!Array.isArray(form)) {
          for (let group of template) {
            for (let key in group) {
              group[key] = item[key];
            }
          }
          if (Object.keys(item).length) {
            if ("priority" in item) {
              data.priority = item.priority;
            }
            if ("active" in item) {
              data.active = item.active;
            }
            if ("id" in item) {
              data.id = item.id;
            }
          }
          form = template;
        }
        for (let group of form) {
          const row = [];
          for (let key in group) {
            data[key] = group[key];
            let field = { name: key };
            switch (key) {
              case "agent_code":
              case "type": {
                field.type = "select";
                field.required = true;
                let type = /type/.test(key);
                let options = !type ? this.courier.options : this.types;
                field.label = !type ? "Delivery company" : "Shipment type";
                field.options = this.CloneObject(options);
                if (data[key]) {
                  field.value = field.options.find((option) => {
                    return option.code == data[key];
                  });
                }
                field.input = (option) => {
                  field.value = option || "";
                  this.form.data[key] = option && option.code;
                  //console.log(this.CloneObject(this.form.data));
                };
                break;
              }
              case "shipping_method":
              case "title": {
                field.type = "text";
                if (key == "shipping_method") {
                  field.label = "Shipping description";
                }
                field.required = true;
                field.fill_width = true;
                if (key == "title") {
                  field.half_width = true;
                }
                if (data[key]) {
                  field.value = data[key];
                }
                field.keydown = (event) => {
                  if (event.code == "Space" && !event.target.value.length) {
                    event.preventDefault();
                  }
                };
                field.blur = (event) => {
                  let input = event.target;
                  let value = input.value;
                  field.value = value
                    .replace(/^\s+/, "")
                    .replace(/\s+/g, " ")
                    .trim();
                  if (key == "title") {
                    field.value = this.Capitalize(field.value);
                  }
                  input.value = field.value;
                  this.form.data[key] = field.value;
                  if (tab.name == "types") {
                    let crate_type_code = find_field("crate_type_code");
                    if (!crate_type_code.value && field.value) {
                      crate_type_code.value = field.value
                        .replace(/^\s+/, "")
                        .replace(/\s+/g, " ")
                        .trim()
                        .replace(/\s/g, "_")
                        .toLowerCase();
                      this.form.data.crate_type_code = crate_type_code.value;
                    }
                  }
                  //console.log(this.CloneObject(this.form.data));
                };
                break;
              }
              case "create_img":
              case "default_create_img":
              case "img_data": {
                field.type = "file";
                field.fill_width = true;
                if (key == "create_img") {
                  field.label =
                    "Packaging image (for this shipping method only)";
                }
                if (key == "default_create_img") {
                  field.label =
                    "Default packaging image (for all shipping methods)";
                }
                if (key == "img_data") {
                  field.label = "Image";
                  field.half_width = true;
                }
                field.accept = ".jpg, .jpeg, .png";
                field.image = null;
                field.title = "";
                if (data[key]) {
                  let base64 = data[key] || "";
                  field.image = base64;
                  data[key] = base64.replace(/^.+,/, "");
                  if (key == "create_img") {
                    if (item.create_img == item.default_create_img) {
                      field.placeholder = true;
                    }
                  }
                  if (key == "default_create_img") {
                    if (item.create_img != item.default_create_img) {
                      field.placeholder = true;
                    }
                  }
                  if (key == "img_data") {
                    if (item.img_data == item.default_create_img) {
                      field.placeholder = true;
                    }
                  }
                }
                field.input = (event) => {
                  let file = event.target.files[0];
                  let value = event.target.value;
                  let reader = new FileReader();
                  if (!file) return;
                  reader.onloadend = () => {
                    let base64 = reader.result || "";
                    field.image = base64;
                    field.value = value;
                    if (key == "create_img") {
                      let default_create_img = find_field("default_create_img");
                      if (default_create_img.image != base64) {
                        default_create_img.placeholder = true;
                        delete field.placeholder;
                      }
                    }
                    if (key == "default_create_img") {
                      let create_img = find_field("create_img");
                      if (create_img.image.replace(/^.+,/, "") == data[key]) {
                        create_img.image = base64;
                        this.form.data.create_img = base64.replace(/^.+,/, "");
                        create_img.placeholder = true;
                        delete field.placeholder;
                      }
                    }
                    if (key == "img_data") {
                      if (item.default_create_im != base64) {
                        delete field.placeholder;
                      }
                    }
                    this.form.data[key] = base64.replace(/^.+,/, "");
                    //console.log(this.CloneObject(this.form.data));
                  };
                  reader.readAsDataURL(file);
                };
                break;
              }
              case "priority": {
                field.type = "number";
                field.label = "Priority";
                //field.required = true;
                field.min = 0;
                if (data[key]) {
                  field.value = data[key];
                }
                field.blur = (event) => {
                  let value = Number(event.target.value);
                  if (!value || value < 0) value = 0;
                  field.value = value;
                  this.form.data[key] = field.value;
                  //console.log(this.CloneObject(this.form.data));
                };
                break;
              }
              case "crate_type_code": {
                field.type = "text";
                field.label = "Code";
                field.spellcheck = false;
                if (data[key]) {
                  field.value = data[key];
                }
                field.required = mode == "create";
                field.readonly = mode == "edit";
                field.keydown = (event) => {
                  if (event.code == "Space" && !event.target.value.length) {
                    event.preventDefault();
                  }
                };
                field.blur = (event) => {
                  let input = event.target;
                  let value = input.value;
                  field.value = value
                    .replace(/^\s+/, "")
                    .replace(/\s+/g, " ")
                    .trim()
                    .replace(/\s/g, "_")
                    .toLowerCase();
                  input.value = field.value;
                  this.form.data[key] = field.value;
                  //console.log(this.CloneObject(this.form.data));
                };
                break;
              }
            }
            row.push(field);
          }
          fields.push(row);
        }
        this.form.data = data;
        this.form.fields = fields;
        //console.log('this.form.data', this.CloneObject(this.form.data))
        //console.log('this.form.fields', this.CloneObject(this.form.fields))
        this.$nextTick().then(resolve);
      }).catch((e) => e);
    },
    async SetupTemplate(agent = "", item = {}) {
      return await new Promise(async (resolve) => {
        let fields = [];
        let form = item;
        let tab = this.Tab();
        let data = {};
        let template = this.CloneObject(tab.template[agent]);
        /*
          const find_field = (name) => fields.map(group => {
            return group.find(field => field.name == name);
          }).filter(e => e)[0] || {};
          */
        if (!Object.keys(form).length) {
          form = template;
        }
        if (!Array.isArray(form)) {
          for (let group of template) {
            for (let key in group) {
              group[key] = item[key];
            }
          }
          if (Object.keys(item).length) {
            if ("priority" in item) {
              data.priority = item.priority;
            }
            if ("active" in item) {
              data.active = item.active;
            }
            if ("id" in item) {
              data.id = item.id;
            }
          }
          form = template;
        }
        for (let group of form) {
          const row = [];
          for (let key in group) {
            data[key] = group[key];
            let field = { name: key };
            //field.required = true;
            field.type = "text";
            //field.placeholder = key;
            let value = data[key];
            if (value) {
              field.value = value;
              this.form.data[key] = field.value;
            }
            field.blur = (event) => {
              value = event.target.value;
              if (field.type == "number") {
                if (value && !Number.isNaN(value)) {
                  value = parseInt(value);
                } else {
                  value = null;
                }
              }
              field.value = value;
              this.form.data[key] = field.value;
            };
            switch (key) {
              case "authorization": {
                field.label = "Authorization";
                field.fill_width = true;
                field.half_width = true;
                break;
              }
              case "client_id": {
                field.label = "Client ID";
                break;
              }
              case "client_secret": {
                field.label = "Client secret";
                field.fill_width = true;
                field.half_width = true;
                break;
              }
              case "customer_id": {
                field.label = "Customer ID";
                break;
              }
              case "customer_number": {
                field.label = "Customer number";
                break;
              }
              case "host": {
                field.label = "Host";
                break;
              }
              case "kode": {
                field.label = "Code";
                break;
              }
              case "kunde_id": {
                field.label = "Customer ID";
                break;
              }
              case "password": {
                field.label = "Password";
                break;
              }
              case "path": {
                field.label = "Path";
                break;
              }
              case "port": {
                field.label = "Port";
                field.type = "number";
                break;
              }
              case "signature": {
                field.label = "Signature";
                field.fill_width = true;
                field.half_width = true;
                break;
              }
              case "user": {
                field.label = "User";
                break;
              }
              case "webhookurl": {
                field.label = "Webhook URL";
                field.fill_width = true;
                field.half_width = true;
                break;
              }
            }
            row.push(field);
          }
          fields.push(row);
        }
        this.form.data = data;
        this.form.fields = fields;
        //console.log('this.form.data', this.CloneObject(this.form.data))
        //console.log('this.form.fields', this.CloneObject(this.form.fields))
        this.$nextTick().then(resolve);
      }).catch((e) => e);
    },
    ClearImage(field) {
      let data = this.form.data;
      let fields = this.form.fields;
      let default_create_img_base64 = this.default_create_img.replace(
        /^.+,/,
        ""
      );
      const find_field = (name) =>
        fields
          .map((group) => {
            return group.find((field) => field.name == name);
          })
          .filter((e) => e)[0] || {};
      if (field.name == "create_img") {
        let default_create_img = find_field("default_create_img");
        if (default_create_img.image != this.default_create_img) {
          field.image = default_create_img.image;
          data.create_img = data.default_create_img;
        } else {
          field.image = this.default_create_img;
          data.create_img = default_create_img_base64;
        }
        delete default_create_img.placeholder;
        field.placeholder = true;
      }
      if (field.name == "default_create_img") {
        let create_img = find_field("create_img");
        if (create_img.image == field.image) {
          create_img.image = this.default_create_img;
          data.create_img = default_create_img_base64;
          create_img.placeholder = true;
        }
        field.image = this.default_create_img;
        data.default_create_img = default_create_img_base64;
      }
      if (field.name == "img_data") {
        let item = this.Tab().item;
        field.image = item.default_create_img;
        data.img_data = item.default_create_img.replace(/^.+,/, "");
        field.placeholder = true;
      }
      field.value = "";
    },
    GetCourierConfig(courier) {
      if (!courier) {
        this.json = { init: {}, data: {} };
        return (this.courier.selected = "");
      }
      this.courier.selected = courier;
      BPA.api
        .GetCourierConfig(courier.code)
        .then((response) => {
          return BPA.api.response({ response, return: "json" });
        })
        .then((response) => {
          if (!response.ok) return;
          let json = response.result || {};
          let tab = this.Tab();
          this.json = { init: json, data: json };
          tab.loaded = true;
        })
        .catch((e) => e);
    },
    async GetCourierEventConfig(courier = {}) {
      if (!courier) {
        this.json = { init: {}, data: {} };
        return (this.courier.selected = "");
      }
      this.courier.selected = courier;
      return await BPA.api
        .GetCourierEventConfig(courier.code)
        .then((response) => {
          return BPA.api.response({ response, return: "json" });
        })
        .then(async (response) => {
          if (!response.ok) {
            this.ClearForm();
            return;
          }
          let tab = this.Tab();
          let json = response.result || {};
          for (let key in json) {
            json[key] = json[key] || "";
          }
          await this.SetupTemplate(courier.code, json);
          tab.data[courier.code] = json;
          tab.loaded = true;
        })
        .catch((e) => e);
    },
    async UpdateCourierEventConfig(request) {
      return await BPA.api
        .SetCourierEventConfig(request)
        .then((response) => {
          return BPA.api.response({ response });
        })
        .then((response) => response.ok)
        .catch((e) => e);
    },
    IsHtmlString(string = "") {
      return Tool.IsHtmlString(string);
    },
    IsJsonString(string = "") {
      return Tool.IsJsonString(string);
    },
    JsonChange() {
      this.$eventHub.$emit("HideMessages");
      this.editor.error = "";
    },
    JsonError(error) {
      this.editor.error = error;
    },
    JsonUnchanged() {
      return JSON.stringify(this.json.init) == JSON.stringify(this.json.data);
    },
    ValidateCourierConfig() {
      if (this.readonly) return;
      this.editor.passed = false;
      if (this.JsonUnchanged()) return;
      if (!this.courier.selected) {
        this.$eventHub.$emit("ShowMessages", {
          message: "No courier selected",
          type: "error",
        });
        return;
      }
      if (this.editor.error && !this.editor.bypass) {
        this.$eventHub.$emit("ShowMessages", {
          message: this.editor.error,
          type: "error",
          close: true,
        });
        return;
      }
      this.editor.passed = true;
      this.UpdateCourierConfig();
    },
    HoverButton(event) {
      let button = event.target;
      button.classList.toggle("hover");
    },
    HoverAll(event) {
      let button = event.target;
      let wrapper = button.closest(".list-wrapper");
      let list = wrapper.querySelector(".simple-list");
      let items = list.querySelectorAll(".simple-list__item");
      for (let item of items) {
        button = item.querySelector(".item-wrapper__button");
        button.classList.toggle("hover");
      }
    },
    Toast(message) {
      this.$eventHub.$emit("ShowMessages", {
        message: message || "List successfully cleared",
        type: "success",
        hide: 2000,
      });
    },
    async Prompt(props = {}) {
      return await new Promise((resolve, reject) => {
        this.$eventHub.$emit("ValidateModalStart", {
          approve: props.approve || "Yes, clear it",
          disapprove: "No",
          message: props.message || "Clears the entire list.",
          type: "danger",
        });
        this.$eventHub.$on("ValidateModalStop", (approve) => {
          this.$eventHub.$off("ValidateModalStop");
          if (!approve) return reject();
          resolve(true);
        });
      }).catch((e) => e);
    },
    async ClearList(type = "", prompt = false) {
      let message = `Deletes all the ${type ? `${type}s` : "items"}.`;
      let approve = "Yes, delete them";
      let tab = this.Tab();
      const removeItems = async () => {
        let error = false;
        this.loading = true;
        for (let item of tab.list) {
          error = !(await this.RemoveItem(item, type));
          if (error) break;
        }
        if (!error)
          this.$eventHub.$emit("ShowMessages", {
            message: `All ${type ? `${type}s` : "items"} successfully deleted`,
            type: "success",
            hide: 2000,
          });
        await this.Search();
        this.loading = false;
      };
      if (!tab.list.length) return;
      if (!prompt) return await removeItems();
      if (await this.Prompt({ approve, message })) await removeItems();
    },
    async RemoveItem(item = {}, type = "", prompt = false) {
      let message = `Deletes the current ${type || "item"}.`;
      let approve = "Yes, delete it";
      let tab = this.Tab();
      const removeItem = async () => {
        let error = false;
        if (prompt) {
          this.loading = true;
        }
        switch (tab.name) {
          case "rules": {
            error = !(await this.DeleteShippingCrateRule(item.id));
            if (!error) {
              for (let i = 0; i < tab.cached.length; i++) {
                let cached_item = tab.cached[i];
                if (item.id == cached_item.id) {
                  tab.cached.splice(i, 1);
                }
              }
            }
            break;
          }
        }
        if (prompt) {
          if (!error)
            this.$eventHub.$emit("ShowMessages", {
              message: `${this.Capitalize(
                type || "item"
              )} successfully deleted`,
              type: "success",
              hide: 2000,
            });
          await this.Search();
          this.loading = false;
        }
        return !error;
      };
      if (!prompt) return await removeItem();
      if (await this.Prompt({ approve, message })) return await removeItem();
    },
    SetOption(option = {}, field = "") {
      if (field) this.Tab().item[field] = (option || {}).id;
    },
    AddItem() {
      let item = {};
      let tab = this.Tab();
      let rule = tab.item;
      let required = ["rule", "sku"];
      for (let key of required) {
        let value = rule[key];
        if (value) item[key] = value;
      }
      let keys = Object.keys(item);
      if (keys.length < required.length) {
        let missing = required.filter((key) => !keys.includes(key))[0];
        if (!missing) missing = required[0];
        let field = this.$refs[missing];
        if (Array.isArray(field)) field = field[0];
        if (!field) return;
        if (field.$vnode) field = field.$vnode.elm;
        let select_field = field.querySelector("input[type=search]");
        if (select_field) field = select_field;
        return field.focus();
      }
      this.Create();
    },
    EditItem(item = {}, field = "") {
      if (!Object.keys(item).length) return;
      let tab = this.Tab();
      if (tab.name == "rules") {
        if (field == "sku") {
          item.sku = item.sku.toLowerCase().replace(/\s/g, "");
        }
        this.UpdateShippingPackagingRule(item);
      }
    },
    async Create() {
      if (!(await this.ValidateForm())) return;
      switch (this.Tab().name) {
        case "methods":
          return await this.CreateShippingMethod();
        case "rules":
          return await this.CreateShippingPackagingRule();
        case "types":
          return await this.CreateShippingPackagingType();
      }
    },
    async Update() {
      if (!(await this.ValidateForm())) return;
      switch (this.Tab().name) {
        case "tracking":
          return await this.UpdateShippingSupplierTracking();
        case "methods":
          return await this.UpdateShippingMethod();
        case "types":
          return await this.UpdateShippingPackagingType();
      }
    },
    async Delete() {
      switch (this.Tab().name) {
        case "methods":
          return await this.DeleteShippingMethod();
        case "types":
          return await this.DeleteShippingPackagingType();
      }
    },
    async CreateShippingMethod() {
      let tab = this.Tab();
      let item = this.CloneObject(tab.item);
      let data = this.CloneObject(this.form.data);
      let images = ["create_img", "default_create_img"];
      this.$eventHub.$emit("ValidateModalStart", {
        approve: "Yes, create it",
        disapprove: "No",
        message: "Creates the current shipping method.",
        type: "success",
      });
      this.$eventHub.$on("ValidateModalStop", async (approve) => {
        this.$eventHub.$off("ValidateModalStop");
        if (!approve) return;
        this.loading = true;
        if (await this.SetShippingMethod(data)) {
          if (!data.active) {
            await this.SetActiveShippingMethod(data);
          }
          for (let image of images) {
            if (data[image] != item[image].replace(/^.+,/, "")) {
              if (data[image] == this.default_create_img.replace(/^.+,/, "")) {
                data[image] = "";
              }
              if (/default/.test(image)) {
                if (data[image]) {
                  await this.SetDefaultShippingCrateImage({
                    img_data: data[image],
                  });
                } else {
                  await this.DeleteDefaultShippingCrateImage();
                }
              } else {
                if (data[image]) {
                  await this.SetShippingCrateImage({
                    ...data,
                    ...{ body: { img_data: data[image] } },
                  });
                }
              }
            }
          }
          this.$eventHub.$emit("ShowMessages", {
            message: "Shipping method successfully created",
            type: "success",
            hide: 2000,
          });
          await this.Search();
          this.$eventHub.$emit("CloseModal");
        }
        this.loading = false;
      });
    },
    async UpdateShippingMethod() {
      let tab = this.Tab();
      let item = this.CloneObject(tab.item);
      let data = this.CloneObject(this.form.data);
      let images = ["create_img", "default_create_img"];
      let update = {};
      for (let key in data) {
        if (data[key] != item[key]) {
          if (/img/.test(key)) {
            if (data[key] != item[key].replace(/^.+,/, "")) {
              if (data[key] != this.default_create_img.replace(/^.+,/, "")) {
                update[key] = data[key];
              } else {
                update[key] = "";
              }
            }
          } else {
            update[key] = data[key];
          }
        }
      }
      if(this.attributes_to_update.length > 0)
      {
        this.UpdateAttributes(this.attributes_to_update)
      }

      let update_keys = Object.keys(update);
      let update_length = update_keys.length;
      if (!update_length && (this.attributes_to_update.length < 1 || this.attributes_to_update.length == undefined)) {
        this.$eventHub.$emit("CloseModal");
        this.$eventHub.$emit("ShowMessages", {
          message: "No changes made",
          type: "warning",
          hide: 2000,
        });
        return;
      }
      if(!update_length) {
        this.$eventHub.$emit("CloseModal");
        return;
      }
      if (update.shipping_method) {
        this.$eventHub.$emit("ValidateModalStart", {
          approve: "Yes, create it",
          disapprove: "No",
          message: "Creates a new shipping method.",
          type: "success",
        });
      } else {
        this.$eventHub.$emit("ValidateModalStart", {
          approve: "Yes, update it",
          disapprove: "No",
          message: "Updates the current shipping method.",
          type: "success",
        });
      }
      this.$eventHub.$on("ValidateModalStop", async (approve) => {
        this.$eventHub.$off("ValidateModalStop");
        if (!approve) return;
        this.loading = true;
        let error = false;
        let bypass_set_shipping_method =
          update_keys.filter((key) => images.includes(key) || key == "active")
            .length == update_length;
        if (
          bypass_set_shipping_method ||
          (await this.SetShippingMethod(data))
        ) {
          for (let image of images) {
            if (image in update) {
              if (/default/.test(image)) {
                if (update[image]) {
                  error = !(await this.SetDefaultShippingCrateImage({
                    img_data: update[image],
                  }));
                } else {
                  error = !(await this.DeleteDefaultShippingCrateImage());
                }
              } else {
                if (update[image]) {
                  error = !(await this.SetShippingCrateImage({
                    ...data,
                    ...{ body: { img_data: update[image] } },
                  }));
                } else {
                  error = !(await this.DeleteShippingCrateImage(data));
                }
              }
            }
          }
          if ("active" in update) {
            await this.SetActiveShippingMethod(data);
          }
          if (!error)
            this.$eventHub.$emit("ShowMessages", {
              message: "Shipping method successfully updated",
              type: "success",
              hide: 2000,
            });
          await this.Search();
          this.$eventHub.$emit("CloseModal");
        }
        this.loading = false;
      });
    },
    async DeleteShippingMethod(item = {}) {
      let tab = this.Tab();
      let in_modal = Object.keys(tab.item).length;
      item = in_modal ? tab.item : item;
      let items = [item];
      if (!in_modal) {
        for (let checkbox of this.selected) {
          let table_row = checkbox.closest("tr");
          let row_item = tab.list.find(
            (item) => table_row.dataset.item == JSON.stringify(item)
          );
          if (row_item) items.push(row_item);
        }
        items = items.filter(
          (item, index, array) =>
            index ==
            array.findIndex((i) => JSON.stringify(i) == JSON.stringify(item))
        );
      }
      let multiple = items.length > 1;
      this.$eventHub.$emit("ValidateModalStart", {
        approve: `Yes, delete ${multiple ? "them" : "it"}`,
        disapprove: "No",
        message: `Deletes the ${
          multiple ? "selected" : "current"
        } shipping method${multiple ? "s" : ""}.`,
        type: "danger",
      });
      this.$eventHub.$on("ValidateModalStop", async (approve) => {
        this.$eventHub.$off("ValidateModalStop");
        if (!approve) return;
        this.loading = true;
        let error = false;
        for (item of items) {
          error = !(await BPA.api
            .DeleteShippingMethod(item)
            .then((response) => {
              return BPA.api.response({ response });
            })
            .then((response) => response.ok)
            .catch((e) => e));
          if (error) break;
        }
        if (!error)
          this.$eventHub.$emit("ShowMessages", {
            message: `Shipping method${
              multiple ? "s" : ""
            } successfully deleted`,
            type: "success",
            hide: 2000,
          });
        await this.Search();
        if (this.modal.mode == "edit") {
          this.$eventHub.$emit("CloseModal");
        }
        this.loading = false;
      });
    },
    async RefreshShippingPackagingRuleOptions() {
      this.tab[this.tab.active].tab.rules.options =
        await this.GetShippingCrateRuleOptions();
    },
    async CreateShippingPackagingRule() {
      let tab = this.Tab();
      let item = tab.item;
      this.$eventHub.$emit("ValidateModalStart", {
        approve: "Yes, create it",
        disapprove: "No",
        message: "Creates the current packaging rule.",
        type: "success",
      });
      this.$eventHub.$on("ValidateModalStop", async (approve) => {
        this.$eventHub.$off("ValidateModalStop");
        if (!approve) return;
        this.loading = true;
        if (
          await this.SetShippingCrateRule({ ...item, ...{ id: item.rule.id } })
        ) {
          this.$eventHub.$emit("ShowMessages", {
            message: "Packaging rule successfully created",
            type: "success",
            hide: 2000,
          });
          tab.item = { rule: "", sku: "" };
          await this.Search();
        }
        this.loading = false;
      });
    },
    async CreateShippingPackagingType() {
      let data = this.CloneObject(this.form.data);
      this.$eventHub.$emit("ValidateModalStart", {
        approve: "Yes, create it",
        disapprove: "No",
        message: "Creates the current packaging type.",
        type: "success",
      });
      this.$eventHub.$on("ValidateModalStop", async (approve) => {
        this.$eventHub.$off("ValidateModalStop");
        if (!approve) return;
        this.loading = true;
        if (await this.CreateShippingCrateType(data)) {
          this.$eventHub.$emit("ShowMessages", {
            message: "Packaging type successfully created",
            type: "success",
            hide: 2000,
          });
          await this.Search();
          this.$eventHub.$emit("CloseModal");
          await this.RefreshShippingPackagingRuleOptions();
        }
        this.loading = false;
      });
    },
    async UpdateShippingPackagingType() {
      let tab = this.Tab();
      let item = this.CloneObject(tab.item);
      let data = this.CloneObject(this.form.data);
      let update = {};
      for (let key in data) {
        if (/img/.test(key)) {
          if (data[key] != item[key].replace(/^.+,/, "")) {
            if (data[key] != item.default_create_img.replace(/^.+,/, "")) {
              update[key] = data[key];
            } else {
              update[key] = null;
            }
          }
        } else if (data[key] != item[key]) {
          update[key] = data[key];
        }
      }
      let update_keys = Object.keys(update);
      let update_length = update_keys.length;
      if (!update_length) {
        this.$eventHub.$emit("CloseModal");
        this.$eventHub.$emit("ShowMessages", {
          message: "No changes made",
          type: "warning",
          hide: 2000,
        });
        return;
      }
      this.$eventHub.$emit("ValidateModalStart", {
        approve: "Yes, update it",
        disapprove: "No",
        message: "Updates the current packaging type.",
        type: "success",
      });
      this.$eventHub.$on("ValidateModalStop", async (approve) => {
        this.$eventHub.$off("ValidateModalStop");
        if (!approve) return;
        this.loading = true;
        if (await this.UpdateShippingCrateType({ id: data.id, body: update })) {
          this.$eventHub.$emit("ShowMessages", {
            message: "Packaging type successfully updated",
            type: "success",
            hide: 2000,
          });
          if ("title" in update) {
            await this.RefreshShippingPackagingRuleOptions();
          }
          await this.Search();
          this.$eventHub.$emit("CloseModal");
        }
        this.loading = false;
      });
    },
    async UpdateShippingPackagingRule(item) {
      let update = {};
      let tab = this.Tab();
      let cached_item = tab.cached.find((li) => li.id == item.id);
      for (let key in item) {
        if (cached_item[key] != String(item[key]).replace(/^.+,/, "")) {
          update[key] = item[key];
        }
      }
      if (!Object.keys(update).length) return;
      this.$eventHub.$emit("ValidateModalStart", {
        approve: "Yes, update it",
        disapprove: "No",
        message: "Updates the current packaging rule.",
        type: "success",
      });
      this.$eventHub.$on("ValidateModalStop", async (approve) => {
        this.$eventHub.$off("ValidateModalStop");
        if (!approve) {
          for (let list_item of tab.list) {
            if (list_item.id == cached_item.id) {
              list_item = cached_item;
              break;
            }
          }
          return;
        }
        this.loading = true;
        if (await this.SetShippingCrateRule({ ...item, ...update })) {
          this.$eventHub.$emit("ShowMessages", {
            message: "Packaging rule successfully updated",
            type: "success",
            hide: 2000,
          });
          await this.Search();
        }
        this.loading = false;
      });
    },
    async DeleteShippingPackagingType(item = {}) {
      let tab = this.Tab();
      let in_modal = Object.keys(tab.item).length;
      item = in_modal ? tab.item : item;
      let items = [item];
      if (!in_modal) {
        for (let checkbox of this.selected) {
          let table_row = checkbox.closest("tr");
          let row_item = tab.list.find(
            (item) => table_row.dataset.item == JSON.stringify(item)
          );
          if (row_item) items.push(row_item);
        }
        items = items.filter(
          (item, index, array) =>
            index ==
            array.findIndex((i) => JSON.stringify(i) == JSON.stringify(item))
        );
      }
      let multiple = items.length > 1;
      this.$eventHub.$emit("ValidateModalStart", {
        approve: `Yes, delete ${multiple ? "them" : "it"}`,
        disapprove: "No",
        message: `Deletes the ${
          multiple ? "selected" : "current"
        } packaging type${multiple ? "s" : ""}.`,
        type: "danger",
      });
      this.$eventHub.$on("ValidateModalStop", async (approve) => {
        this.$eventHub.$off("ValidateModalStop");
        if (!approve) return;
        this.loading = true;
        let error = false;
        for (item of items) {
          error = !(await this.DeleteShippingCrateType(item.id));
          if (error) break;
        }
        if (!error)
          this.$eventHub.$emit("ShowMessages", {
            message: `Packaging type${
              multiple ? "s" : ""
            } successfully deleted`,
            type: "success",
            hide: 2000,
          });
        await this.Search();
        if (this.modal.mode == "edit") {
          this.$eventHub.$emit("CloseModal");
        }
        this.loading = false;
      });
    },
    async UpdateCourierConfig() {
      return await BPA.api
        .UpdateCourierConfig({
          agent_code: this.courier.selected.code,
          body: this.json.data,
        })
        .then((response) => {
          return BPA.api.response({ response });
        })
        .then((response) => {
          if (!response.ok) return;
          this.json.init = this.json.data;
          this.$eventHub.$emit("ShowMessages", {
            message: "Shipping label configuration successfully updated",
            type: "success",
            hide: 2000,
          });
        })
        .catch((e) => e);
    },
    async UpdateShippingSupplierTracking() {
      let agent_code = this.courier.selected?.code;
      if (!agent_code) return;
      let tab = this.Tab();
      let init = this.CloneObject(tab.data[agent_code]);
      let data = this.CloneObject(this.form.data);
      let update = {};
      for (let key in data) {
        if (data[key] != init[key]) {
          update[key] = data[key];
        }
      }
      if (!Object.keys(update).length) {
        /*
          this.$eventHub.$emit('ShowMessages', {
            message: 'No changes made',
            type: 'warning',
            hide: 2000
          });
          */
        return;
      }
      this.$eventHub.$emit("ValidateModalStart", {
        approve: "Yes, update it",
        disapprove: "No",
        message: "Updates the current tracking configuration.",
        type: "success",
      });
      this.$eventHub.$on("ValidateModalStop", async (approve) => {
        this.$eventHub.$off("ValidateModalStop");
        if (!approve) return;
        this.loading = true;
        if (await this.UpdateCourierEventConfig({ agent_code, body: update })) {
          tab.data[agent_code] = this.CloneObject(data);
          this.$eventHub.$emit("ShowMessages", {
            message: "Tracking configuration successfully updated",
            type: "success",
            hide: 2000,
          });
        }
        this.loading = false;
      });
    },
    async HasResParcelConf() {
      return await BPA.api
        .HasResParcelConf()
        .then((response) => {
          return BPA.api.response({ response, return: "json" });
        })
        .then((response) => {
          if (!response.ok || !response.result) return;
          return response.result;
        })
        .catch((e) => e);
    },
    async SearchShippingMethods(request) {
      request = request || this.Tab().search;
      return await BPA.api
        .SearchShippingMethods(request)
        .then((response) => {
          return BPA.api.response({ response, return: "json" });
        })
        .then((response) => {
          if (!response.ok || !response.result) return;
          return response.result;
        })
        .catch((e) => e);
    },
    async GetShippingMethod(request) {
      return await BPA.api
        .GetShippingMethod(request)
        .then((response) => {
          return BPA.api.response({ response, return: "json" });
        })
        .then((response) => {
          if (!response.ok || !response.result) return;
          return response.result;
        })
        .catch((e) => e);
    },
    async SetShippingMethod(request) {
      return await BPA.api
        .SetShippingMethod(request)
        .then((response) => {
          return BPA.api.response({ response });
        })
        .then((response) => response.ok)
        .catch((e) => e);
    },
    async SetActiveShippingMethod(request) {
      return await BPA.api
        .SetActiveShippingMethod(request)
        .then((response) => {
          return BPA.api.response({ response });
        })
        .then((response) => response.ok)
        .catch((e) => e);
    },
    async GetShippingCrateImage(request) {
      return await new Promise((resolve, reject) => {
        BPA.api
          .GetShippingCrateImage(request)
          .then((response) => {
            return BPA.api.response({ response, return: "blob", error() {} });
          })
          .then((response) => {
            if (!response.ok || !response.result) return reject();
            let reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.readAsDataURL(response.result);
          })
          .catch(reject);
      }).catch((e) => e);
    },
    async SetShippingCrateImage(request) {
      return await BPA.api
        .SetShippingCrateImage(request)
        .then((response) => {
          return BPA.api.response({ response });
        })
        .then((response) => response.ok)
        .catch((e) => e);
    },
    async DeleteShippingCrateImage(request) {
      return await BPA.api
        .DeleteShippingCrateImage(request)
        .then((response) => {
          return BPA.api.response({ response });
        })
        .then((response) => response.ok)
        .catch((e) => e);
    },
    async SetDefaultShippingCrateImage(request) {
      return await BPA.api
        .SetDefaultShippingCrateImage(request)
        .then((response) => {
          return BPA.api.response({ response });
        })
        .then((response) => response.ok)
        .catch((e) => e);
    },
    async DeleteDefaultShippingCrateImage(request) {
      return await BPA.api
        .DeleteDefaultShippingCrateImage(request)
        .then((response) => {
          return BPA.api.response({ response });
        })
        .then((response) => response.ok)
        .catch((e) => e);
    },
    async SearchShippingCrateRules(request) {
      return await BPA.api
        .SearchShippingCrateRules(request)
        .then((response) => {
          return BPA.api.response({ response, return: "json" });
        })
        .then((response) => {
          if (!response.ok || !response.result) return;
          let list = response.result;
          for (let item of list.items) {
            item.rule = list.lookup_crate_type_id.find((crate) => {
              return crate.id == item.crate_type_id;
            });
          }
          return list;
        })
        .catch((e) => e);
    },
    async GetShippingCrateRuleOptions() {
      return await BPA.api
        .GetShippingCrateRuleOptions()
        .then((response) => {
          return BPA.api.response({ response, return: "json" });
        })
        .then((response) => {
          if (!response.ok || !response.result) return;
          return Tool.Alphabetize(
            response.result.map((option) => ({
              id: option.id,
              label: option.title,
            })),
            "label"
          );
        })
        .catch((e) => e);
    },
    async SetShippingCrateRule(request) {
      return await BPA.api
        .SetShippingCrateRule(request)
        .then((response) => {
          return BPA.api.response({ response });
        })
        .then((response) => response.ok)
        .catch((e) => e);
    },
    async DeleteShippingCrateRule(rule_id) {
      return await BPA.api
        .DeleteShippingCrateRule(rule_id)
        .then((response) => {
          return BPA.api.response({ response });
        })
        .then((response) => response.ok)
        .catch((e) => e);
    },
    async SearchShippingCrateTypes(request) {
      return await BPA.api
        .SearchShippingCrateTypes(request)
        .then((response) => {
          return BPA.api.response({ response, return: "json" });
        })
        .then((response) => {
          if (!response.ok || !response.result) return;
          for (let item of response.result.items) {
            item.company = BPA.company("name", item.res_company_id);
          }
          return response.result;
        })
        .catch((e) => e);
    },
    async GetShippingCrateType(type_id) {
      return await BPA.api
        .GetShippingCrateType(type_id)
        .then((response) => {
          return BPA.api.response({ response, return: "json" });
        })
        .then((response) => {
          if (!response.ok || !response.result) return;
          return response.result;
        })
        .catch((e) => e);
    },
    async GetShippingCrateTypeImage(type_id) {
      return await new Promise((resolve, reject) => {
        BPA.api
          .GetShippingCrateTypeImage(type_id)
          .then((response) => {
            return BPA.api.response({ response, return: "blob", error() {} });
          })
          .then((response) => {
            if (!response.ok || !response.result) return reject();
            let reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.readAsDataURL(response.result);
          })
          .catch(reject);
      }).catch((e) => e);
    },
    async CreateShippingCrateType(request) {
      return await BPA.api
        .CreateShippingCrateType(request)
        .then((response) => {
          return BPA.api.response({ response });
        })
        .then((response) => response.ok)
        .catch((e) => e);
    },
    async UpdateShippingCrateType(request) {
      return await BPA.api
        .UpdateShippingCrateType(request)
        .then((response) => {
          return BPA.api.response({ response });
        })
        .then((response) => response.ok)
        .catch((e) => e);
    },
    async DeleteShippingCrateType(type_id) {
      return await BPA.api
        .DeleteShippingCrateType(type_id)
        .then((response) => {
          return BPA.api.response({ response });
        })
        .then((response) => response.ok)
        .catch((e) => e);
    },
    async Toggle(item = {}, event = {}) {
      let tab = this.Tab();
      let items = [{ ...item, ...{ toggle: event.target } }];
      for (let checkbox of this.selected) {
        let table_row = checkbox.closest("tr");
        let toggle = table_row.querySelector(".checkbox-toggle > input");
        let row_item = tab.list.find(
          (item) => table_row.dataset.item == JSON.stringify(item)
        );
        if (row_item && row_item.active == item.active) {
          items.push({ ...row_item, ...{ toggle: toggle } });
        }
      }
      items = items.filter(
        (item, index, array) =>
          index ==
          array.findIndex((i) => JSON.stringify(i) == JSON.stringify(item))
      );
      for (let item of items) item.toggle.checked = event.target.checked;
      let multiple = items.length > 1;
      let active = !item.active ? true : false;
      let status = !item.active ? "enable" : "disable";
      this.$eventHub.$emit("ValidateModalStart", {
        approve: `Yes, ${status} ${multiple ? "them" : "it"}`,
        disapprove: "No",
        message: `${this.Capitalize(status)}s the ${
          multiple ? "selected" : "current"
        } shipping method${multiple ? "s" : ""}.`,
        type: !item.active ? "success" : "danger",
      });
      this.$eventHub.$on("ValidateModalStop", async (approve) => {
        this.$eventHub.$off("ValidateModalStop");
        if (!approve) {
          for (let item of items) {
            item.toggle.checked = !!item.active;
          }
          return;
        }
        this.loading = true;
        for (let item of items) {
          item.active = active;
          await this.SetActiveShippingMethod(item);
        }
        this.$eventHub.$emit("ShowMessages", {
          message: `Shipping method${
            multiple ? "s" : ""
          } successfully ${status}d`,
          type: "success",
          hide: 2000,
        });
        await this.Search();
        if (this.modal.mode == "edit") {
          this.$eventHub.$emit("CloseModal");
        }
        this.loading = false;
      });
    },
    ToggleAllCheckboxes(e) {
      let selected = [];
      let checked = e.target.checked;
      let items = document.querySelectorAll("tr[data-item]");
      for (let i = 0; i < items.length; i++) {
        let checkbox = items[i].querySelector(".v-checkbox");
        if (checkbox) {
          checkbox.checked = checked;
          if (checked) {
            selected.push(checkbox);
          }
        }
      }
      this.selected = selected;
    },
    ToggleCheckbox(e) {
      let e_target = e.target;
      if (!e_target || e_target.localName == "span") return;
      if (e_target.localName == "td") {
        e_target = e_target.querySelector(".v-checkbox");
        e_target.checked = !e_target.checked;
        return e_target.dispatchEvent(new Event("input"));
      }
      let selected = [];
      let checked = e_target.checked;
      let select_all = this.$refs.select_all;
      let items = document.querySelectorAll("tr[data-item]");
      if (!checked) select_all.checked = false;
      for (let i = 0; i < items.length; i++) {
        let checkbox = items[i].querySelector(".v-checkbox");
        if (checkbox && checkbox.checked) {
          selected.push(checkbox);
        }
      }
      if (selected.length == items.length) {
        if (select_all) select_all.checked = true;
      }
      this.selected = selected;
    },
    ClickOpen(event) {
      if (!event) return;
      let elm = event.target,
        row;
      if (elm.localName != "tr") row = elm.closest("tr");
      const mousedown = (state) => {
        if (state === true || state === false) {
          row.mousedown = state;
        } else {
          return row.mousedown;
        }
      };
      if (!row.classList.contains("clickable")) return;
      for (let i = 0; i < this.clickables.length; i++) {
        if (this.clickables[i] != row) {
          this.clickables[i].classList.remove("hover", "selected");
        }
      }
      if (elm.type == "checkbox") return;
      if (/label|checkbox/.test(elm.className)) return;
      if (elm.localName == "a" || elm.parentElement.localName == "a") return;
      if (event.type == "mousedown") {
        mousedown(event.which == 1);
      }
      if (event.type == "mousemove") {
        mousedown(false);
      }
      if (event.type == "mouseup") {
        if (mousedown()) {
          this.OpenModal("edit", JSON.parse(row.dataset.item));
        }
        mousedown(false);
      }
    },
    async PageController(next_page) {
      let load_page;
      const page = {};
      const tab = this.Tab();
      document.querySelectorAll(".page-turn").forEach((arrow) => {
        if (arrow)
          page[arrow.classList.contains("prev") ? "prev" : "next"] = arrow;
      });
      if (next_page) {
        if (tab.page.current != tab.page.last) {
          tab.page.current++;
          load_page = true;
          if (tab.page.current == tab.page.last) {
            page.next.classList.add("disabled");
          }
          page.prev.classList.remove("disabled");
        }
      } else {
        if (tab.page.current > 1) {
          tab.page.current--;
          load_page = true;
          if (tab.page.current == 1) {
            page.prev.classList.add("disabled");
          }
          page.next.classList.remove("disabled");
        }
      }
      if (load_page) {
        this.loading = true;
        await this.Search();
        this.loading = false;
      }
    },
    async PageNavigator(e) {
      const page = {
        event: e.type,
        input: e.target,
        last: Number(e.target.max),
        first: Number(e.target.min),
        number: Number(e.target.value),
      };
      const tab = this.Tab();
      const within_limits = (n) => {
        return n >= page.first && n <= page.last;
      };
      const set_page_number = (n) => {
        tab.page.number = n || page.number;
        page.number = Number(tab.page.number);
      };
      document.querySelectorAll(".page-turn").forEach((arrow) => {
        if (arrow)
          page[arrow.classList.contains("prev") ? "prev" : "next"] = arrow;
      });
      set_page_number();
      if (page.event == "keydown") {
        if (e.key == "Enter") {
          page.input.blur();
        }
      }
      if (page.event == "blur") {
        ["prev", "next"].map((arrow) => {
          page[arrow] && page[arrow].classList.remove("disabled");
        });
        if (page.number <= page.first) {
          set_page_number(page.first);
          page.input.value = page.first;
          page.prev.classList.add("disabled");
          if (page.last == page.first) {
            page.next.classList.add("disabled");
          }
        } else if (page.number >= page.last) {
          set_page_number(page.last);
          page.input.value = page.last;
          page.next.classList.add("disabled");
          if (page.first == page.last) {
            page.prev.classList.add("disabled");
          }
        }
        if (within_limits(page.number) && page.number != tab.page.current) {
          tab.page.current = page.number;
          this.loading = true;
          await this.Search();
          this.loading = false;
        }
      }
    },
    SetPageJumpWidth() {
      const current = document.querySelector(".page-number-current");
      const last = document.querySelector(".page-number-last");
      if (current && last) {
        const rect = last.getBoundingClientRect();
        const input = current.querySelector("input");
        if (rect && rect.width) {
          current.style.setProperty("width", rect.width + "px");
        } else {
          current.style.removeProperty("width");
        }
        if (input) {
          input.dispatchEvent(new Event("blur"));
        }
      }
    },
    SetCourierAttributeMap(couriers) {
      //must match vue component name
      let attributesToSet = [ 'swip_box' ]
      let courierAttributesMap = new Map()

      for(let courier in couriers)
      {
        let x = courierAttributesMap.get(couriers[courier].code)

        if(!x)
        {
          courierAttributesMap.set(couriers[courier].code, attributesToSet)
        }
      }
      return courierAttributesMap
    },
    async UpdateAttributes(attributes_to_update) {
      let attribute_data = this.CloneObject(this.shipping_handling_information)
      let responses = []
      let errorCheck = []

      let attribute_update_map = new Map()

      attribute_data.attributes.forEach((attribute) => {
        let x = attribute_update_map.get(attribute.attribute)

        if(!x) {
          attribute_update_map.set(attribute.attribute, attribute)
        }
      })

      attributes_to_update.forEach((attribute_update) => {

        (attribute_update.isActiveProp == true) ? responses.push(this.UpdateIsActiveAttribute(attribute_update_map.get(attribute_update.component_name).active, attribute_update.component_name, attribute_data.agent_code, attribute_data.shipping_method)) : responses.push(this.UpdateAttributeValues(attribute_update_map.get(attribute_update.component_name).value, attribute_update.component_name, attribute_data.agent_code, attribute_data.shipping_method))
      
      })

      await Promise.allSettled(responses).then((response) => {
        for(let value in response) {
          if(response[value].status != 'fulfilled') {
            errorCheck.push(response[value].value.attribute_name + ' ' + response[value].reason)
          }
        }
      })

      if(errorCheck.length < 1) {
        this.$eventHub.$emit('ShowMessages', {
          message: 'Attributes updated successfully',
          type: 'success',
          hide: 4000,
        })
      } else {
        this.$eventHub.$emit('ShowMessages', {
          message: 'Attributes update failed, see ' + errorCheck,
          type: 'error',
          hide: 4000,
        })
      }
      this.ResetAttributesToUpdate()
    },
    async UpdateIsActiveAttribute(value, attribute, agent_code, shipping_method) {

      let response = await BPA.api.UpdateIsActiveAttribute(value, attribute, agent_code, shipping_method);

      let result = {status: response.status, attribute_name: attribute}

      return result
    },
    async UpdateAttributeValues(value, attribute, agent_code, shipping_method) {

      let response = await BPA.api.UpdateAttributeValue(value, attribute, agent_code, shipping_method)
      
      let result = {status: response.status, attribute_name: attribute}

      return result
    },
    CheckPermissions(required_permissions) {
      return BPA.permissions(required_permissions).length
    },
    ResetAttributesToUpdate() {
      this.attributes_to_update = []
    }
  },
};
</script>

<style lang="scss">
@import "../../../assets/style/variables/vital";

.settings-shipping-config-view,
.settings-rma-config-view {
  .title {
    font-size: 1.622rem;
    font-weight: 600;
    margin-bottom: 35px;
  }

  &,
  .settings-group,
  .json-editor-container,
  .json-editor-container > div,
  .jsoneditor-vue {
    height: 100%;
    display: flex;
    position: relative;
    flex-direction: column;
  }

  .json-editor-container {
    height: 500px;
  }

  .jsoneditor-vue {
    min-height: 300px;
    max-height: 600px;
  }

  .jsoneditor-btns {
    width: 100%;
    display: flex;
    position: relative;
    margin-top: 30px;
    justify-content: flex-end;

    .json-save-btn {
      cursor: pointer;
      align-self: flex-end;
      text-transform: lowercase;
      background-color: #58bf5d;
      color: #ffffff;
      padding: 8px 15px;
      border-radius: 10px;
      font-family: $font;
      font-size: 0.7778rem;
      text-decoration: none;
      font-weight: 600;
      text-align: center;
      min-height: 34px;
      min-width: 115px;

      &::first-letter {
        text-transform: capitalize;
      }

      &:after {
        //content: ' data';
      }
    }
  }

  .item-submenu {
    width: 100%;
    display: flex;
    position: relative;
    background-color: #f4f4f4;

    &__item {
      opacity: 0.7;
      color: #606060;
      font-weight: bold;
      user-select: none;
      position: relative;
      margin: 10px 20px 0;
      padding-bottom: 10px;

      &:hover {
        opacity: 1;
      }

      &:not(.active) {
        cursor: pointer;
      }

      &.active {
        opacity: 1;
        color: #1b577a;
        font-weight: bold;
        text-decoration: underline;
      }
    }
  }

  .item {
    &-wrapper {
      &.noflex {
        display: block;
      }
    }
  }

  .max-width-300 {
    max-width: 300px;
    align-items: center;
  }

  .flex {
    &-group {
      & + .flex-group {
        margin-top: 30px;
      }

      &.border-top {
        border-top: 2px dashed $lightGrey;
        padding-top: 30px;
        margin-top: 40px;
      }
    }

    &-row {
      & + .flex-row {
        margin-top: 20px;
      }

      .flex {
        &-column {
          display: flex;
          flex-direction: column;
          flex: 1;
          flex: 0 0 calc(25% - 23px);

          &.hidden {
            display: none;
          }

          &:not(.hidden):not([style*="display: none"]) ~ .flex-column {
            margin-left: 30px;
          }

          &.flex1 {
            flex: 1;
            min-width: 293px;
          }

          &:not(.flex1) {
            max-width: calc(25% - 23px);
          }

          &.half {
            max-width: calc(50% - 16px);
          }
        }
      }
    }
  }

  .sticky-footer {
    bottom: 0;
    z-index: 6;
    margin-top: 15px;
    margin-bottom: -15px;
    border-top: 2px solid lightgray;
    padding-top: 15px;
    padding-bottom: 15px;
    position: sticky;
    background: white;
  }
}
</style>
