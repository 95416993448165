<template>
  <div class="settings settings-dvr-config">
    <p class="title">{{ $t('DVR') }}</p>
    <div class="flex row">
      <div class="flex column" style="width: 100%;">
        <div class="flex column" style="width: 100%;">
          <h3 class="heading">DVR integration</h3>
          <div class="settings__group flex row" style="margin-top: 10px; align-items: center;">
            <span style="display: flex; flex-grow: 1;">
              <p :class="['state', `${dvr.auth.status ? 'posi' : 'nega'}tive`]" style="text-transform: none;">{{ $t(`Authorisation key ${dvr.auth.status ? 'generated' : 'revoked'}`) }}</p>
            </span>
            <a :class="['button', {green: true}]" style="min-width: 115px; margin: auto 0 0 20px;" @click.prevent="GenerateDVRAuthKey" href="">{{ $t(`${dvr.auth.key ? 'Copy' : 'Create'}`) }}</a>
            <a class="button red" style="min-width: 115px; margin: auto 0 0 20px;" @click.prevent="RevokeDVRAuthKey" href="">{{ $t('Revoke') }}</a>
          </div>
        </div>
        <div class="flex column" style="width: 100%; margin-top: 30px;">
          <h3 class="heading">Device specific</h3>
          <div class="label" style="width: 100%; margin-top: 10px; flex-direction: row; justify-content: space-between;">
            <div class="label" style="width: calc(34% - 30px);">
              <span class="label-text">Endpoint host</span>
              <input class="v-input" :value="dvr.host" @keydown.enter="$event.target.value = $event.target.value.replace(/\s/g, ''); $event.target.blur()" @blur="SetValue($event.target.value, 'host')">
            </div>
            <div class="label" style="width: calc(34% - 30px); margin-left: 30px;">
              <span class="label-text">Start recording</span>
              <input class="v-input" :value="dvr.start" @keydown.enter="$event.target.value = $event.target.value.replace(/\s/g, ''); $event.target.blur()" @blur="SetValue($event.target.value, 'start')">
            </div>
            <div class="label" style="width: calc(34% - 30px); margin-left: 30px;">
              <span class="label-text">Stop recording</span>
              <input class="v-input" :value="dvr.stop" @keydown.enter="$event.target.value = $event.target.value.replace(/\s/g, ''); $event.target.blur()" @blur="SetValue($event.target.value, 'stop')">
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="loading" v-if="loading">
      <div class="loading-element">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
    
  </div>
</template>

<script>
  import { Tool } from '@/helpers/Tool';
  import { BPA } from '@/helpers/BPA';

  export default {
    name: 'SettingsDVRConfigView',
    data() {
      return {
        loading: false,
        prompt: {
          action: '',
          message: '',
          type: ''
        },
        toast: {
          message: ''
        },
        dvr: {
          auth: {
            key: '',
            status: false
          },
          host: '',
          start: '',
          stop: ''
        }
      }
    },
    async created() {
      this.loading = true;
      this.dvr.auth.status = await this.GetDVRAuthStatus();
      this.dvr.host = this.GetValue('host');
      this.dvr.start = this.GetValue('start');
      this.dvr.stop = this.GetValue('stop');
      this.loading = false;
    },
    methods: {
      async PromptUser() {
        return await new Promise((resolve, reject) => {
          let action = this.prompt.action;
          let message = this.prompt.message;
          let type = this.prompt.type || 'success';
          this.$eventHub.$emit('ValidateModalStart', {
            approve: 'Yes' + (action ? ', ' + action : ''),
            disapprove: 'No',
            message: message,
            type: type
          });
          this.$eventHub.$on('ValidateModalStop', approve => {
            this.$eventHub.$off('ValidateModalStop');
            if (!approve) return reject(false);
            resolve(true);
          });
        }).catch(e => e);
      },
      async CopyToClipboard(string) {
        let message = this.toast.message || 'Auth key copied';
        await Tool.CopyToClipboard(string).then(() => {
          this.$eventHub.$emit('ShowMessages', {
            message: message,
            type: 'success',
            hide: 2000
          });
        });
      },
      async GetDVRAuthStatus() {
        return await new Promise((resolve, reject) => {
          BPA.api.GetDVRAuthStatus().then(response => {
            return BPA.api.response({response, return: 'json'});
          }).then(response => {
            if (!response.ok || !response.result) return reject();
            resolve(response.result.status);
          }).catch(reject);
        }).catch(e => e);
      },
      async CreateDVRAuthKey() {
        return await new Promise((resolve, reject) => {
          BPA.api.CreateDVRAuthKey().then(response => {
            return BPA.api.response({response, return: 'text'});
          }).then(response => {
            if (!response.ok || !response.result) return reject();
            resolve(response.result);
          }).catch(reject);
        }).catch(e => e);
      },
      async DeleteDVRAuthKey() {
        return await new Promise((resolve, reject) => {
          if (!this.dvr.auth.status) return resolve(false);
          BPA.api.DeleteDVRAuthKey().then(response => {
            return BPA.api.response({response});
          }).then(response => {
            if (response.ok) {
              resolve(false);
              return this.$eventHub.$emit('ShowMessages', {
                message: 'DVR authorisation key revoked',
                type: 'error',
                hide: 2000
              });
            }
            reject();
          }).catch(reject);
        }).catch(e => e);
      },
      async GenerateDVRAuthKey() {
        if (this.dvr.auth.key) {
          this.toast.message = 'DVR authorisation key copied';
          return this.CopyToClipboard(this.dvr.auth.key);
        }
        this.toast.message = 'DVR authorisation key generated and copied to clipboard';
        this.loading = true;
        this.dvr.auth.key = await this.CreateDVRAuthKey() || '';
        this.loading = false;
        if (this.dvr.auth.key) {
          await this.CopyToClipboard(this.dvr.auth.key);
          return this.dvr.auth.status = true;
        }
        this.dvr.auth.status = false;
      },
      async RevokeDVRAuthKey() {
        if (!this.dvr.auth.status) return;
        this.prompt.action = 'revoke it';
        this.prompt.message = 'Revokes the current DVR authorisation key';
        this.prompt.type = 'danger';
        if (!await this.PromptUser()) return;
        this.loading = true;
        this.dvr.auth.status = !!await this.DeleteDVRAuthKey();
        this.loading = false;
        if (!this.dvr.auth.status) this.dvr.auth.key = '';
      },
      GetValue(type) {
        return BPA.dvr.fetch(type);
      },
      SetValue(value = '', type = '', get = true) {
        BPA.dvr.handler({key: type, set: value});
        if (get !== false) this.GetValue();
      },
    }
  }
</script>

<style lang="scss" scoped>
</style>