<template>
  <div class="settings settings-about-view">
    <p class="title">{{ $t('About') }}</p>
    <div class="settings__group">
      <pre><b>{{ $t('Version') }}</b>: &#9; {{ version }}</pre>
      <pre><b>{{ $t('Release') }}</b>: &#9; {{ DateFormat(build_date) }}</pre>
    </div>
  </div>
</template>

<script>
  import { Tool } from '@/helpers/Tool';
  import conf from '../../../../public/conf.json';

  export default {
    name: 'SettingsAboutView',
    data() {
      return {
        version: conf.version,
        build_date: conf.build_date
      }
    },
    methods: {
      DateFormat(date) {
        return Tool.DateFormat(date);
      }
    }
  }
</script>

<style lang="scss">
  @import '../../../assets/style/variables/vital';

  .title {
    font-size: 1.622rem;
    font-weight: 600;
    margin-bottom: 35px;
  }

  pre {
    font-family: inherit;
    
    & + pre {
      margin-top: 1em;
    }
  }
</style>