<template>
  <div class="settings settings-qrcode-view">
    <p class="title">{{ $t('QR code') }}</p>
    <div class="settings__group">
      <!-- <p>Login to the app by scanning this QR code.</p> -->
      <div class="qrcode-container">
        <qrcode v-if="token" :value="token" :options="{ width: 200 }"></qrcode>
      </div>
    </div>

    <div class="loading" v-if="loading">
      <div class="loading-element">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  </div>
  
</template>

<script>
  import { BPA } from '@/helpers/BPA';
  
  export default {
    name: 'SettingsQRCodeView',
    data() {
      return {
        loading: true,
        token: null
      }
    },
    async created() {
      await BPA.api.GetAPISecret().then(response => {
        return BPA.api.response({response, return: 'text'});
      }).then(response => {
        this.loading = false;
        this.token = response.result;
      }).catch(e => e);
    }
  }
</script>

<style lang="scss" scoped>
  @import '../../../assets/style/variables/vital';

  .title {
    font-size: 1.622rem;
    font-weight: 600;
    margin-bottom: 35px;
  }
</style>