<template>
  <div>
    <div class="">
      <span class="grid-heading">
        <h1>{{ $t('Warehouse Product Map') }}</h1>
      </span>
      <div class="grid-actions">
        <div class="grid-date">
          <p
            class="grid-heading"
            style="color: inherit; margin-top: 5px; margin-bottom: 5px"
          >
            {{ $t('From Date') }}
          </p>
          <div class="date-range flex-row">
            <div class="input">
              <VueCtkDateTimePicker
                id="tab-report-date-range"
                v-model="dataModel.order_from"
                :locale="$i18n.locale.split('_')[0].replace(/no/, 'nb')"
                formatted="ddd, MMM D, YYYY, HH:mm"
                format="YYYY-MM-DDTHH:mm:ss"
                :first-day-of-week="1"
                input-size="sm"
                :range="false"
                :no-shortcuts="true"
                :no-button="false"
                :auto-close="false"
                :no-label="true"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        class="label"
        style="margin-top: 5px; margin-bottom: 5px; max-width: 203px"
      >
        <span class="label-text" style="margin-bottom: 5px">{{
          $t('Show Bestselling Count')
        }}</span>
        <input
          class="v-input"
          name="order_id"
          v-model="dataModel.product_count"
        />
      </div>
      <button class="button" @click="GenerateWarehouseProductMap">
        {{ 'Get Map' }}
      </button>
    </div>
  </div>
</template>

<script>
import { BPA } from '@/helpers/BPA';
import { Tool } from '@/helpers/Tool';

export default {
  components: {},
  mixins: [ BPA, Tool ],
  data() {
    return {
      dataModel: {
        order_from: null,
        product_count: 200,
      },
    };
  },
  mounted() { 
    this.dataModel.order_from = Tool.DateToUTC(Date.now() - 12096e5).split(' ')
    this.dataModel.order_from = this.dataModel.order_from[0] + ' 00:00:00'
  },
  methods: {
    async GenerateWarehouseProductMap() {
      let token = await this.GetWarehouseProductMapToken(
        this.dataModel.product_count,
        this.dataModel.order_from
      );

      window.open(BPA.api.base().replace('/api/beautycos', '') + token);
    },
    async GetWarehouseProductMapToken(product_count, order_from) {
      return await new Promise((resolve, reject) => {
        BPA.api
          .GetDrawProductLinkToken(product_count, order_from)
          .then((response) => {
            return BPA.api
              .response({ response, return: 'text' })
              .then((response) => {
                if (!response.ok || !response.result) {
                  return reject();
                }
                resolve(response.result);
              });
          });
      });
    },
  },
};
</script>

<style scoped>
.grid-heading {
  color: #C2C8CE;
}
</style>
