<template>
  <div class="settings settings-announcement-config grid-wrapper">
    <!--
    <p class="title" style="display: flex;">
      {{ $t('Announcements') }}
      <a class="button" @click.prevent="OpenModal('create')" href="" style="margin-left: auto;">{{ $t('Create announcement') }}</a>
    </p>
    -->
    <div class="grid-info">
      <span class="grid-heading">
        <h1>{{ $t('Announcements') }}</h1>
        <span class="entries">{{ NumberFormat(entries) }} {{ $t(`entr${entries == 1 ? 'y' : 'ies'}`) }}</span>
      </span>
      <div class="grid-actions">
        <a class="button" style="margin-left: auto;" @click.prevent="OpenModal('create')" href="">{{ $t('Create') }}</a>
      </div>
      <div class="grid-search">
        <div class="search-fields" style="align-items: flex-end; max-width: 100%;">
          <input type="text" v-model="search.query" @keyup.enter="FilterSearch(), $event.target.blur()" :placeholder="$t('Search by title')" style="width: 50%;">
          <div class="size-select" style="width: 100%; margin-top: -100%; margin-left: 30px; display: flex;">
            <div class="grid-locked" style="margin-top: 0;">
              <div class="toggle locked">
                <div class="grid-heading" style="font-weight: 600; color: #b3b8bd; padding-bottom: 2px;">{{ $t('Active') }}</div>
                <div class="checkbox-toggle">
                  <input type="checkbox" v-model="search.active" @click="FilterByActive">
                  <span class="toggle" />
                </div>
              </div>
            </div>
            <div class="grid-state" style="max-width: 160px; padding: 4px 0 0; margin-left: 30px;">
              <p class="grid-heading" style="font-weight: 600; color: #b3b8bd; padding-bottom: 2px;">{{ $t('Page size') }}</p>
              <v-select name="page-size-select" @input="FilterBySize" v-model="search.size" :options="sizes" :searchable="false" :clearable="false" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <table class="list table odd-even">
        <tbody>
          <tr :data-id="item.id" :class="['announcement-list__row clickable']" @mousedown="ClickOpen" @mousemove="ClickOpen" @mouseup="ClickOpen" :key="item.id" v-for="item in announcements.items">
            <td class="label" style="display: table-cell;" @click="ToggleCheckbox">
              <div class="v-wrapper checkbox">
                <label class="v-checkbox-label">
                  <input class="v-checkbox" type="checkbox" :data-id="item.id" @input="ToggleCheckbox">
                  <span class="v-checkbox-toggle" />
                </label>
              </div>
            </td>
            <td>
              <div>{{ item.id }}</div>
            </td>
            <td>
              <div class="checkbox-toggle mini">
                <input type="checkbox" :checked="item.active" @input="Toggle(item, $event)">
                <span class="toggle"></span>
              </div>
            </td>
            <td>
              <div>{{ item.title || '-' }}</div>
            </td>
            <td>
              <div>{{ (item.groups || []).join(',\n') }}</div>
            </td>
            <td>
              <div>{{ item.company || '-' }}</div>
            </td>
            <td>
              <div :class="['flex-row state', !item.required_consent ? 'green' : 'red']" style="text-transform: none;">
                {{ item.required_consent ? $t('Required') : $t('Unrequired') }}
              </div>
            </td>
            <td class="actions" @mouseenter="SetBackground" @mouseleave="SetBackground">
              <a class="icon dots" @click.prevent="" href="">
                <ul class="item-actions">
                  <li><a @click.prevent="OpenModal('edit', item)" href="">{{ $t('Edit') }}</a></li>
                  <li><a @click.prevent="Delete(item)" href="" class="error">{{ $t('Delete') }}</a></li>
                </ul>
              </a>
            </td>
          </tr>
        </tbody>
        <thead>
          <tr>
            <th class="label" style="display: table-cell;">
              <div class="v-wrapper checkbox">
                <label class="v-checkbox-label">
                  <input class="v-checkbox" type="checkbox" ref="select_all" @input="ToggleAllCheckboxes">
                  <span class="v-checkbox-toggle toggle-all" />
                </label>
              </div>
            </th>
            <th>{{ $t('ID') }}</th>
            <th>{{ $t('Active') }}</th>
            <th>{{ $t('Title') }}</th>
            <th>{{ $t('Groups') }}</th>
            <th>{{ $t('Company') }}</th>
            <th>{{ $t('Consent') }}</th>
            <th class="edit" />
          </tr>
        </thead>
      </table>
      <div class="grid-pagination shadow sticky bottom" :style="{marginTop: announcements.items.length && '2px'}">
        <div class="page-navigation">
          <div class="page-turn prev disabled" @click="PageController(false)"></div>
          <div class="page-number">
            <label class="page-number-current">
              <input type="number" min="1" :max="page.last" :value="page.current" @blur="PageNavigator" @keydown="PageNavigator">
              <span class="placeholder">{{NumberFormat(page.current)}}</span>
            </label>
            <span class="page-number-separator">/</span>
            <div class="page-number-last">{{NumberFormat(page.last)}}</div>
          </div>
          <div class="page-turn next" :class="{disabled: page.last == 1}" @click="PageController(true)"></div>
        </div>
      </div>
    </div>

    <Modal modal="modal" :value="modal.open" :title="$t(modal.mode == 'create' ? 'New announcement' : 'Announcement') + (modal.mode == 'edit' ? ' #' + announcement.id : '')">
      <div id="announcement" v-if="modal.open">
        <div class="modal-header"></div>
        <div class="modal-content scrollable">
          <div class="modal-tabs">
            <div class="modal-tabs__body" style="overflow: hidden auto;">
              <div class="modal-tabs__body-content padding">
                <form @submit.prevent style="height: 100%; padding-bottom: 25px;">
                  <button ref="submit" type="submit" hidden />
                  <div v-for="(group, index) in form.fields" :key="index" class="flex-group" :style="index == form.fields.length - 1 && {height: 'calc(100% - 150px)'}">
                    <div class="flex-row" :style="index == form.fields.length - 1 ? {height: '100%'} : {alignItems: 'flex-end'}">
                      <div v-for="(field, index) in group" :key="index" :class="['flex-column', {flex1: field.fill_width}]" :style="[{maxWidth: field.type == 'checkbox' && 'max-content'}]" v-show="!field.hidden">
                        <div :class="['label', {required: field.required}]" :style="field.type == 'checkbox' ? {flexDirection: `row${field.type == 'checkbox' ? '-reverse' : ''}`, alignItems: 'center', marginBottom: field.type == 'checkbox' && '5px'} : field.fill_height && {height: '100%'}">
                          <span class="label-text">
                            {{ $t(field.label || Capitalize(field.name).replace(/_/g, ' ')) }}
                            <b v-if="field.type == 'list' && field.list" style="font-size: 0.9em;">
                              ({{ NumberFormat(field.list.length) }})
                            </b>
                          </span>
                          <v-select v-if="field.type == 'select'" :name="field.name" :required="field.required" :disabled="field.disabled" :value="field.value" :multiple="field.multiple" :options="field.options" :title="field.title" :clearable="field.clearable" v-on="field.input ? {input: field.input} : {}">
                            <template #search="{attributes, events}">
                              <input class="vs__search" v-bind="attributes" v-on="events" :required="field.required && !(form.data[field.name] || []).length">
                            </template>
                            <template v-slot:selected-option="option">
                              <div style="display: flex;" :title="(option.users || []).map(user => TitleCase(user.name)).join('\n')">
                                <span>{{ option.label }}</span>
                                <span class="count" style="font-size: 0.9em; margin: 0; white-space: nowrap;">
                                  ({{ NumberFormat(option.users.length) }})
                                </span>
                              </div>
                            </template>
                            <template slot="option" slot-scope="option">
                              <div style="display: flex; align-items: flex-start;">
                                <span>{{ option.label }}</span>
                                <span class="count">
                                  {{ NumberFormat(option.users.length) }}
                                </span>
                              </div>
                            </template>
                          </v-select>
                          <div v-else-if="field.type == 'checkbox'" class="v-wrapper checkbox">
                            <label class="v-checkbox-label" style="margin-right: 10px;">
                              <input class="v-checkbox" :type="field.type" :name="field.name" :disabled="field.disabled" :checked="field.value" @input="field.input ? field.input($event) : {}">
                              <span class="v-checkbox-toggle" style="width: 24px; height: 24px;" />
                            </label>
                          </div>
                          <textarea v-else-if="field.type == 'textarea'" class="v-textarea" style="height: 100%; min-height: 34px; resize: none;" :name="field.name" :required="field.required" :readonly="field.readonly" :pattern="field.pattern" v-html="field.html" :title="field.title" @input="field.input ? field.input($event) : {}" @blur="field.blur ? field.blur($event) : {}" />
                          <div v-else-if="field.type == 'list'" class="v-input-list border" style="height: 100%;">
                            <ul class="v-list" style="overflow-y: auto;">
                              <li class="v-list-item even-odd clickable" v-for="(item, index) of field.list" :key="index" @click="OpenUser(item.name)">{{ item.name }}</li>
                            </ul>
                          </div>
                          <input v-else class="v-input" :type="field.type" :name="field.name" :required="field.required" :readonly="field.readonly" :pattern="field.pattern" :min="field.min" :max="field.max" :minlength="field.minlength" :step="field.step" :value="field.value" v-model="form.data[field.name]" :title="field.title" @keydown.enter="$event.target.blur()" @input="field.input ? field.input($event) : {}" @blur="field.blur ? field.blur($event) : {}">
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div class="button-container actions">
            <div class="label" style="flex-direction: row; align-items: center; margin-right: auto;">
              <div class="checkbox-toggle">
                <input type="checkbox" v-model="form.data.active">
                <span class="toggle" />
              </div>
              <span style="margin-left: 10px;">{{ $t('Active') }}</span>
            </div>
            <button class="button green" @click.prevent="Update" v-if="modal.mode == 'edit'">{{ $t('Save') }}</button>
            <button class="button red" @click.prevent="Delete" v-if="modal.mode == 'edit'">{{ $t('Delete') }}</button>
            <button class="button green" @click.prevent="Create" v-if="modal.mode == 'create'">{{ $t('Create') }}</button>
          </div>
        </div>
      </div>
    </Modal>

    <div class="loading" v-if="loading">
      <div class="loading-element">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>

  </div>
</template>

<script>
  import { TableElementsBehaviour } from '@/mixins/TableElementsBehaviour';
  import { Config }     from '@/helpers/Config';
  import { Tool }       from '@/helpers/Tool';
  import { BPA }        from '@/helpers/BPA';
  import Modal          from '@/components/snippets/Modal';

  export default {
    name: 'SettingsAnnouncementConfigView', 
    mixins: [
      TableElementsBehaviour, 
      Config, 
      Tool, 
      BPA
    ],
    components: {
      Modal,
    },
    data() {
      return {
        loading: true,
        entries: 0,
        cached: {},
        clickables: [],
        search: {
          query: '',
          active: false,
          initial: null,
          size: 20,
          offset: 1
        },
        page: {
          current: 1,
          number: 1,
          last: 1
        },
        sizes: [10, 20, 30, 50, 100],
        announcement: {},
        announcements: {
          items: []
        },
        selected: [],
        selection: {
          event: null,
          start: null,
          end: null
        },
        ctrl_key: false,
        shift_key: false,
        event: ['keydown', 'keyup'],
        user_group: {
          list: {
            options: []
          }
        },
        template: [
          {
            title: ''
          },
          {
            res_user_group_ids: [],
            required_consent: false
          },
          {
            text: '',
            eula_consent_user_ids: []
          }
        ],
        form: {
          fields: [],
          data: {}
        },
        modal: {
          mode: 'create',
          open: false
        }
      }
    },
    created() {},
    async mounted() {
      this.$eventHub.$on('CloseModal', () => {
        this.main.view.classList.remove('no-scroll');
        this.form = {fields: [], data: {}};
        this.modal.open = false;
        this.announcement = {};
        this.cached.announcement = {};
        BPA.cache.session({name: this.$options.name, set: {announcement: {}}});
        for (let i = 0; i < this.clickables.length; i++) {
          if (this.clickables[i].classList.contains('selected')) {
            this.clickables[i].classList.remove('selected');
          }
        }
      });

      this.cached = BPA.cache.local({name: this.$options.name, get: ['page', 'search']});
      for (let key of Object.keys(this.cached)) this[key] = {...this[key], ...this.cached[key]};
      this.cached = {...this.cached, ...BPA.cache.session({name: this.$options.name, get: 'announcement'})};

      await this.SearchAnnouncements();

      this.MountKeyPressListener();
      this.mousedown = () => {
        if (this.shift_key) {
          this.selection.event = null;
          if (document.getSelection) {
            this.selection.event = window.getSelection();
          } else if (document.selection) {
            this.selection.event = document.selection;
          }
          if (!this.selection.event) return;
          let focusNode = this.selection.event.focusNode;
          if (!focusNode || !/toggle/.test(focusNode.className)) return;
          if (focusNode.classList.contains('toggle-all')) return;
          this.selection.start = this.selection.event.focusNode;
          if ('removeAllRanges' in this.selection.event) {
            this.selection.event.removeAllRanges();
          } else if ('empty' in this.selection.event) {
            this.selection.event.empty();
          }
        }
      }
      this.mouseup = () => {
        if (!this.selection.event) return;
        let focusNode = this.selection.event.focusNode;
        if (!focusNode || !/toggle/.test(focusNode.className)) return;
        if (focusNode.classList.contains('toggle-all')) return;
        this.selection.end = this.selection.event.focusNode;
        if (this.selection.start == this.selection.end) {
          ['start', 'end'].map(e => this.selection[e] = null); return;
        }
        this.selection.event = null;
        let start = {row: this.selection.start.closest('tr')};
        let end = {row: this.selection.end.closest('tr')};
        let list = {body: end.row.closest('tbody'), selected: [start.row, end.row]};
        list.items = Array.from(list.body.children);
        start.index = list.items.indexOf(start.row);
        end.index = list.items.indexOf(end.row);
        if (start.index < end.index) {
          for (let i = start.index + 1; i < end.index; i++) {
            list.selected.splice(list.selected.length - 1, 0, list.items[i]);
          }
        }
        if (start.index > end.index) {
          list.selected = [end.row, start.row];
          for (let i = end.index + 1; i < start.index; i++) {
            list.selected.splice(list.selected.length - 1, 0, list.items[i]);
          }
        }
        for (let i = 0; i < list.selected.length; i++) {
          if (i != 0 && i != list.selected.length - 1) {
            let checkbox = list.selected[i].querySelector('input[type=checkbox]');
            if (checkbox) checkbox.checked = !checkbox.checked;
          }
        }
        setTimeout(() => this.selected = Array.from(list.body.querySelectorAll('.v-checkbox:checked')));
      }
      window.addEventListener('mousedown', this.mousedown);
      window.addEventListener('mouseup', this.mouseup);

      this.loading = false;
    },
    destroyed() {
      this.UnmountKeyPressListener();
      window.removeEventListener('mousedown', this.mousedown);
      window.removeEventListener('mouseup', this.mouseup);
    },
    computed: {
      main() {
        const main = {
          company: BPA.util.GetCompany(),
          view: document.querySelector('.content-scroll')
        }
        main.view.scroll = {top: 0};
        return main;
      },
      locale() {
        return this.$i18n.locale;
      },
      keydown() {
        return (event) => {
          this.Ctrl(event);
          this.Shift(event);
        }
      },
      keyup() {
        return () => {
          this.Ctrl(false);
          this.Shift(false);
        }
      },
    },
    watch: {
      locale() {
        //this.state = this.$t(this.Capitalize(this.search.state));
      }
    },
    methods: {
      Ctrl(e) {
        this.ctrl_key = e && e.getModifierState('Control');
      },
      Shift(e) {
        this.shift_key = e && e.getModifierState('Shift');
      },
      MountKeyPressListener() {
        this.event.map(e => addEventListener(e, this[e]));
      },
      UnmountKeyPressListener() {
        this.event.map(e => removeEventListener(e, this[e]));
      },
      ConvertEmojisToUnicode(string = '') {
        return Tool.ConvertEmojisToUnicode(string);
      },
      CloneObject(object = {}) {
        return Tool.CloneObject(object);
      },
      DateFormat(date) {
        return Tool.DateFormat(date);
      },
      NumberFormat(number) {
        return Tool.NumberFormat(number);
      },
      Capitalize(string = '') {
        return Tool.Capitalize(string);
      },
      TitleCase(string = '') {
        return Tool.TitleCase(string);
      },
      Hyperlink(props = {}) {
        return Tool.Hyperlink(props);
      },
      LinkText(url = '') {
        if (!url) return '';
        url = url.replace(/\/$/, '');
        let index = url.lastIndexOf('/');
        let substr = url.substr(index);
        return substr.replace(/^\//, '');
      },
      async OpenModal(mode, announcement = {}) {
        this.modal.mode = mode;
        this.announcement = announcement;
        if (mode == 'edit') {
          for (let i = 0; i < this.clickables.length; i++) {
            if (this.clickables[i].dataset.id == announcement.id) {
              this.clickables[i].classList.add('selected');
              if (this.cached.announcement && Object.keys(this.cached.announcement).length) {
                this.clickables[i].scrollIntoView({
                  behavior: 'auto',
                  block: 'center',
                  inline: 'center'
                });
              }
              break;
            }
          }
          this.loading = true;
          announcement = await this.GetAnnouncement(announcement.id) || {};
          let item = announcement.item || {};
          if (Object.keys(item).length) {
            item.eula_consent_user_ids = item.eula_consent_user_ids.map(id => {
              return announcement.res_users_ids.find(user => {
                return user.id == id
              });
            });
          }
          this.announcement = item;
          BPA.cache.session({name: this.$options.name, set: {announcement: this.announcement}});
        }
        await this.SetupForm(this.announcement);
        this.modal.open = true;
        this.loading = false;
      },
      ClearForm() {
        this.form = {};
      },
      async ValidateForm() {
        return await new Promise(resolve => {
          const button = this.$refs.submit;
          if (button) {
            const form = button.closest('form');
            const fields = Array.from(form.elements);
            if (fields.some(field => !field.checkValidity())) {
              return button.click();
            }
          }
          resolve(true);
        }).catch(e => e);
      },
      async SetupForm(announcement) {
        return await new Promise(async resolve => {
          const fields = [];
          const data = {active: true};
          const template = this.CloneObject(this.template);
          /*const find_field = (name) => fields.map(group => {
            return group.find(field => field.name == name);
          }).filter(e => e)[0] || {};*/
          let form = announcement || template;
          if (!Array.isArray(form)) {
            for (let group of template) {
              for (let key in group) {
                group[key] = announcement[key];
              }
            }
            if (Object.keys(announcement).length) {
              data.active = announcement.active;
              data.id = announcement.id;              
            }
            form = template;
          }
          for (let group of form) {
            const row = [];
            for (let key in group) {
              data[key] = group[key];
              let field = {name: key};
              switch (key) {
                case 'title': {
                  field.type = 'text';
                  field.label = 'Title';
                  field.required = true;
                  field.fill_width = true;
                  field.blur = (event) => {
                    let value = event.target.value;
                    field.value = String(value).replace(/\s+/g, ' ').trim();
                    field.value = this.Capitalize(field.value);
                    this.form.data[key] = field.value;
                    //console.log(this.CloneObject(this.form.data));
                  }
                  break;
                }
                case 'res_user_group_ids': {
                  field.type = 'select';
                  field.label = 'Groups';
                  field.required = true;
                  field.multiple = true;
                  field.fill_width = true;
                  let user_group = this.user_group.list;
                  if (!user_group.options.length) {
                    let groups = await this.GetUserGroupList();
                    let options = [];
                    for (let group of groups) {
                      group.users = (await this.GetUserGroup(group.id) || {}).res_user_ids || [];
                      options.push({id: group.id, label: group.name, users: group.users});
                    }
                    Tool.Alphabetize(options, 'label');
                    user_group.options = options;
                  }
                  field.options = user_group.options;
                  if (data[key]) {
                    field.value = field.options.filter(option => {
                      return data[key].some(group => group.id == option.id);
                    });
                    data[key] = field.value.map(option => option.id);
                  }
                  field.input = (options) => {
                    field.value = options;
                    this.form.data[key] = options.map(option => option.id);
                    //console.log(this.CloneObject(this.form.data));
                  }
                  break;
                }
                case 'required_consent': {
                  field.type = 'checkbox';
                  field.label = 'Consent required';
                  field.value = data[key];
                  field.input = (event) => {
                    field.value = event.target.checked;
                    this.form.data[key] = field.value;
                    //console.log(this.CloneObject(this.form.data));
                  }
                  break;
                }
                case 'text': {
                  field.type = 'textarea';
                  field.label = 'Message';
                  field.required = true;
                  field.fill_width = true;
                  field.fill_height = true;
                  if (data[key]) {
                    field.html = data[key];
                  }
                  field.blur = (event) => {
                    field.value = event.target.value;
                    /*
                    field.value = field.value.replace(/ {2,}/g, ' ').trim();
                    field.value = field.value.replace(/( {1,})?\n/g, '\n');
                    field.value = field.value.replace(/\n( {1,})?/g, '\n');
                    field.value = this.Capitalize(field.value);
                    */
                    this.form.data[key] = field.value;
                    //console.log(this.CloneObject(this.form.data));
                  }
                  break;
                }
                case 'eula_consent_user_ids': {
                  field.type = 'list';
                  field.label = 'Confirmed';
                  field.fill_height = true;
                  field.list = data[key];
                  field.hidden = !field.list;
                  for (let item of field.list || []) {
                    item.name = this.TitleCase(item.name);
                  }
                  //console.log(this.CloneObject(this.form.data));
                }
              }
              row.push(field);
            }
            fields.push(row);
          }
          //console.log(this.CloneObject(data))
          this.form.fields = fields;
          this.form.data = data;
          //console.log(this.CloneObject(this.form.fields))
          //console.log(this.CloneObject(this.form.data))
          this.$nextTick().then(resolve);
        }).catch(e => e);
      },
      async FilterByActive(e) {
        this.search.active = e.target.checked;
        await this.FilterSearch();
      },
      async FilterBySize(option) {
        this.search.size = option;
        await this.FilterSearch();
      },
      async FilterSearch() {
        this.page.current = 1;
        this.loading = true;
        await this.SearchAnnouncements();
        this.loading = false;
      },
      async SearchAnnouncements() {
        return await new Promise((resolve, reject) => {
          const request = {
            query: this.search.query,
            size: this.search.size,
            offset: this.page.current
          }
          if (this.search.active) {
            request.active = true;
          }
          BPA.api.SearchAnnouncements(request).then(response => {
            return BPA.api.response({response, return: 'json'});
          }).then(response => {
            if (!response.ok || !response.result) return reject();
            let json = response.result || {};
            json.items.sort((a, b) => b.id - a.id);
            if (!json.offset) json.offset = 0;
            this.search.initial = this.search.query;
            this.page.current = json.offset + 1;
            //this.page.last = json.total || 1;
            this.page.last = Math.ceil((json.count || 1) / request.size);
            this.entries = json.count || json.items.length;
            json.items.sort((a, b) => b.id - a.id);
            for (let item of json.items) {
              let groups = item.res_user_group_ids;
              for (let i = 0; i < groups.length; i++) {
                let group = json.res_user_group_ids.find(group => group.id == groups[i]);
                groups.splice(i, 1, group.name);
              }
              item.company = BPA.company('name', item.res_company_id);
              delete item.res_user_group_ids;
               delete item.res_company_id;
              item.groups = groups;
            }
            this.announcements = json;
            BPA.cache.local({name: this.$options.name, set: {page: this.page, search: this.search}});
            this.$nextTick().then(async () => {
              this.clickables = document.querySelectorAll('.clickable');
              if (this.cached.announcement && Object.keys(this.cached.announcement).length) {
                this.OpenModal('edit', this.cached.announcement);
              }
              this.SetPageJumpWidth();
            });
            resolve(json);
          }).catch(reject);
        }).catch(e => e);
      },
      async OpenUser(user_name = '') {
        await Tool.CopyToClipboard(user_name, true);
        window.open(window.location.origin + '/users', '_blank');
      },
      async GetAnnouncement(announcement_id) {
        return await new Promise((resolve, reject) => {
          if (!announcement_id) return reject();
          BPA.api.GetAnnouncement(announcement_id).then(response => {
            return BPA.api.response({response, return: 'json'});
          }).then(response => {
            if (!response.ok || !response.result) return reject();
            resolve(response.result);
          }).catch(reject);
        }).catch(e => e);
      },
      async CreateAnnouncement(request = {}) {
        return await new Promise((resolve, reject) => {
          if (!Object.keys(request).length) return reject();
          BPA.api.CreateAnnouncement(request).then(response => {
            return BPA.api.response({response, return: 'text'});
          }).then(async response => {
            if (!response.ok) return reject();
            resolve(response.result);
          }).catch(reject);
        }).catch(e => e);
      },
      async UpdateAnnouncement(request = {}) {
        return await new Promise((resolve, reject) => {
          if (!Object.keys(request).length) return reject();
          BPA.api.UpdateAnnouncement(request).then(response => {
            return BPA.api.response({response});
          }).then(response => {
            if (!response.ok) return reject();
            resolve(response);
          }).catch(reject);
        }).catch(e => e);
      },
      async DeleteAnnouncement(announcement_id) {
        return await new Promise((resolve, reject) => {
          if (!announcement_id) return reject();
          BPA.api.DeleteAnnouncement(announcement_id).then(response => {
            return BPA.api.response({response});
          }).then(response => {
            if (!response.ok) return reject();
            resolve(response);
          }).catch(reject);
        }).catch(e => e);
      },
      async GetUserGroupList() {
        return await new Promise((resolve, reject) => {
          BPA.api.GetUserGroupList().then(response => {
            return BPA.api.response({response, return: 'json'});
          }).then(response => {
            if (!response.ok || !response.result) return reject();
            resolve(response.result);
          }).catch(reject);
        }).catch(e => e);
      },
      async GetUserGroupUserList() {
        return await new Promise((resolve, reject) => {
          BPA.api.GetUserGroupUserList().then(response => {
            return BPA.api.response({response, return: 'json'});
          }).then(response => {
            if (!response.ok || !response.result) return reject();
            resolve(response.result);
          }).catch(reject);
        }).catch(e => e);
      },
      async GetUserGroup(group_id) {
        return await new Promise((resolve, reject) => {
          if (!group_id) return reject();
          BPA.api.GetUserGroup(group_id).then(response => {
            return BPA.api.response({response, return: 'json'});
          }).then(response => {
            if (!response.ok || !response.result) return reject();
            resolve(response.result);
          }).catch(reject);
        }).catch(e => e);
      },
      async Create() {
        if (!await this.ValidateForm()) return;
        const data = this.CloneObject(this.form.data);
        for (let [key, value] of Object.entries(data)) {
          if (key == 'text') {
            data[key] = this.ConvertEmojisToUnicode(value);
          }
        }
        if (!('required_consent' in data)) {
          data.required_consent = false;
        }
        console.log('data', this.CloneObject(data))
        this.$eventHub.$emit('ValidateModalStart', {
          approve: 'Yes, create it',
          disapprove: 'No',
          message: 'Creates the current announcement.',
          type: 'success'
        });
        this.$eventHub.$on('ValidateModalStop', async (approve) => {
          this.$eventHub.$off('ValidateModalStop');
          if (!approve) return;
          this.loading = true;
          let announcement_id = await this.CreateAnnouncement(data);
          if (announcement_id) {
            if (!data.active) {
              await this.UpdateAnnouncement({id: announcement_id, body: {active: false}});
            }
            this.$eventHub.$emit('ShowMessages', {
              message: 'Announcement successfully created',
              type: 'success',
              hide: 2000
            });
            await this.SearchAnnouncements();
            this.$eventHub.$emit('CloseModal');
          }
          this.loading = false;
        });
      },
      async Update() {
        const changes = {};
        if (!await this.ValidateForm()) return;
        let data = this.CloneObject(this.form.data);
        delete data.eula_consent_user_ids;
        for (let [key, value] of Object.entries(data)) {
          let field = this.announcement[key];
          if (key == 'res_user_group_ids') {
            field = field.map(group => group.id);
            if (JSON.stringify(field) != JSON.stringify(value)) {
              changes[key] = value;
            }
          } else {
            if (key == 'text') {
              value = this.ConvertEmojisToUnicode(value);
            }
            if (field != value) {
              changes[key] = value;
            }
          }
        }
        console.log('data', this.CloneObject(data))
        if (!Object.keys(changes).length) {
          this.$eventHub.$emit('ShowMessages', {
            message: 'No changes made to announcement #' + data.id,
            type: 'warning',
            hide: 2000
          });
          return this.$eventHub.$emit('CloseModal');
        }
        this.$eventHub.$emit('ValidateModalStart', {
          approve: 'Yes, update it',
          disapprove: 'No',
          message: 'Updates the current announcement.',
          type: 'success'
        });
        this.$eventHub.$on('ValidateModalStop', async (approve) => {
          this.$eventHub.$off('ValidateModalStop');
          if (!approve) return;
          this.loading = true;
          await this.UpdateAnnouncement({id: data.id, body: changes});
          await this.SearchAnnouncements();
          this.$eventHub.$emit('CloseModal');
          this.loading = false;
        });
      },
      async Toggle(announcement = {}, event = {}) {
        let announcements = [{...announcement, ...{toggle: event.target}}];
        for (let checkbox of this.selected) {
          let table_row = checkbox.closest('tr');
          let toggle = table_row.querySelector('.checkbox-toggle > input');
          let row_item = this.announcements.items.find(item => item.id == table_row.dataset.id);
          if (row_item && row_item.active == announcement.active) {
            announcements.push({...row_item, ...{toggle: toggle}});
          }
        }
        announcements = announcements.filter((item, index, array) => index == array.findIndex(i => i.id == item.id));
        for (let item of announcements) item.toggle.checked = event.target.checked;
        let multiple = announcements.length > 1;
        let status = !announcement.active ? 'enable' : 'disable';
        let active = !announcement.active ? true : false;
        this.$eventHub.$emit('ValidateModalStart', {
          approve: `Yes, ${status} ${multiple ? 'them' : 'it'}`,
          disapprove: 'No',
          message: `Changes the status of the ${multiple ? 'selected' : 'current'} announcement${multiple ? 's' : ''}.`,
          type: !announcement.active ? 'success' : 'danger'
        });
        this.$eventHub.$on('ValidateModalStop', async (approve) => {
          this.$eventHub.$off('ValidateModalStop');
          if (!approve) {
            for (let item of announcements) {
              item.toggle.checked = !!announcement.active;
            }
            return;
          }
          this.loading = true;
          for (let item of announcements) {
            await this.UpdateAnnouncement({id: item.id, body: {active}});
          }
          this.$eventHub.$emit('ShowMessages', {
            message: `Announcement${multiple ? 's' : ''} successfully ${status}d`,
            type: 'success',
            hide: 2000
          });
          await this.SearchAnnouncements();
          if (this.modal.mode == 'edit') {
            this.$eventHub.$emit('CloseModal');
          }
          this.loading = false;
        });
      },
      async Delete(announcement = {}) {
        let in_modal = Object.keys(this.announcement).length;
        announcement = in_modal ? this.announcement : announcement;
        let announcements = [announcement];
        if (!in_modal) {
          for (let checkbox of this.selected) {
            let table_row = checkbox.closest('tr');
            let row_item = this.announcements.items.find(item => item.id == table_row.dataset.id);
            if (row_item) announcements.push(row_item);
          }
          announcements = announcements.filter((item, index, array) => index == array.findIndex(i => i.id == item.id));
        }
        let multiple = announcements.length > 1;
        this.$eventHub.$emit('ValidateModalStart', {
          approve: `Yes, delete ${multiple ? 'them' : 'it'}`,
          disapprove: 'No',
          message: `Deletes the ${multiple ? 'selected' : 'current'} announcement${multiple ? 's' : ''}.`,
          type: 'danger'
        });
        this.$eventHub.$on('ValidateModalStop', async (approve) => {
          this.$eventHub.$off('ValidateModalStop');
          if (!approve) return;
          this.loading = true;
          for (let item of announcements) {
            await this.DeleteAnnouncement(item.id);
          }
          this.$eventHub.$emit('ShowMessages', {
            message: `Announcement${multiple ? 's' : ''} successfully deleted`,
            type: 'success',
            hide: 2000
          });
          await this.SearchAnnouncements();
          if (this.modal.mode == 'edit') {
            this.$eventHub.$emit('CloseModal');
          }
          this.loading = false;
        });
      },
      ClearImage(field) {
        field.value = '';
        field.image = null;
        this.form.data[field.name] = null;
      },
      ToggleAllCheckboxes(e) {
        let selected = [];
        let checked = e.target.checked;
        let announcements = document.querySelectorAll('tr[data-id]');
        for (let i = 0; i < announcements.length; i++) {
          let checkbox = announcements[i].querySelector('.v-checkbox');
          if (checkbox) {
            checkbox.checked = checked;
            if (checked) {
              selected.push(checkbox);
            }
          }
        }
        this.selected = selected;
      },
      ToggleCheckbox(e) {
        let e_target = e.target;
        if (!e_target || e_target.localName == 'span') return;
        if (e_target.localName == 'td') {
          e_target = e_target.querySelector('.v-checkbox');
          e_target.checked = !e_target.checked;
          return e_target.dispatchEvent(new Event('input'));
        }
        let selected = [];
        let checked = e_target.checked;
        let select_all = this.$refs.select_all;
        let announcements = document.querySelectorAll('tr[data-id]');
        if (!checked) select_all.checked = false;
        for (let i = 0; i < announcements.length; i++) {
          let checkbox = announcements[i].querySelector('.v-checkbox');
          if (checkbox && checkbox.checked) {
            selected.push(checkbox);
          }
        }
        if (selected.length == announcements.length) {
          select_all.checked = true;
        }
        this.selected = selected;
      },
      ClickOpen(event) {
        if (!event) return;
        let elm = event.target, row;
        if (elm.localName != 'tr') row = elm.closest('tr');
        const mousedown = (state) => {
          if (state === true || state === false) {
            row.mousedown = state;
          } else {
            return row.mousedown;
          }
        }
        if (!row.classList.contains('clickable')) return;
        for (let i = 0; i < this.clickables.length; i++) {
          if (this.clickables[i] != row) {
            this.clickables[i].classList.remove('hover', 'selected');
          }
        }
        if (elm.type == 'checkbox') return;
        if (/label|checkbox/.test(elm.className)) return;
        if (elm.localName == 'a' || elm.parentElement.localName == 'a') return;
        if (event.type == 'mousedown') {
          mousedown(event.which == 1);
        }
        if (event.type == 'mousemove') {
          mousedown(false);
        }
        if (event.type == 'mouseup') {
          mousedown() && this.OpenModal('edit', row.dataset);
          mousedown(false);
        }
      },
      async PageController(next_page) {
        let load_page;
        const page = {};
        document.querySelectorAll('.page-turn').forEach(arrow => {
          if (arrow) page[arrow.classList.contains('prev') ? 'prev' : 'next'] = arrow;
        });
        if (next_page) {
          if (this.page.current != this.page.last) {
            this.page.current++;
            load_page = true;
            if (this.page.current == this.page.last) {
              page.next.classList.add('disabled');
            }
            page.prev.classList.remove('disabled');
          }
        } else {
          if (this.page.current > 1) {
            this.page.current--;
            load_page = true;
            if (this.page.current == 1) {
              page.prev.classList.add('disabled');
            }
            page.next.classList.remove('disabled');
          }
        }
        if (load_page) {
          this.loading = true;
          await this.SearchAnnouncements();
          this.loading = false;
        }
      },
      async PageNavigator(e) {
        const page = {
          event: e.type,
          input: e.target,
          last: Number(e.target.max),
          first: Number(e.target.min),
          number: Number(e.target.value)
        }
        const within_limits = (n) => {
          return n >= page.first && n <= page.last;
        }
        const set_page_number = (n) => {
          this.page.number = n || page.number;
          page.number = Number(this.page.number);
        }
        document.querySelectorAll('.page-turn').forEach(arrow => {
          if (arrow) page[arrow.classList.contains('prev') ? 'prev' : 'next'] = arrow;
        });
        set_page_number();
        if (page.event == 'keydown') {
          if (e.key == 'Enter') {
            page.input.blur();
          }
        }
        if (page.event == 'blur') {
          ['prev', 'next'].map(arrow => {
            page[arrow] && page[arrow].classList.remove('disabled');
          });
          if (page.number <= page.first) {
            set_page_number(page.first);
            page.input.value = page.first;
            page.prev.classList.add('disabled');
            if (page.last == page.first) {
              page.next.classList.add('disabled');
            }
          } else if (page.number >= page.last) {
            set_page_number(page.last);
            page.input.value = page.last;
            page.next.classList.add('disabled');
            if (page.first == page.last) {
              page.prev.classList.add('disabled');
            }
          }
          if (within_limits(page.number) && page.number != this.page.current) {
            this.page.current = page.number;
            this.loading = true;
            await this.SearchAnnouncements();
            this.loading = false;
          }
        }
      },
      SetPageJumpWidth() {
        const current = document.querySelector('.page-number-current');
        const last = document.querySelector('.page-number-last');
        if (current && last) {
          const rect = last.getBoundingClientRect();
          const input = current.querySelector('input');
          if (rect && rect.width) {
            current.style.setProperty('width', rect.width + 'px');
          } else {
            current.style.removeProperty('width');
          }
          if (input) {
            input.dispatchEvent(new Event('blur'));
          }
        }
      },
    }
  }
</script>

<style lang="scss" scoped>
  .label {
    .v-wrapper,
    .v-checkbox-label {
      min-height: auto;
    }

    .v-checkbox-toggle {
      width: 18px;
      height: 18px;
      background-color: white;
    }
  }

  .v-select {
    .count {
      height: 22px;
      padding: 4px;
      border-radius: 50%;
      display: inline-flex;
      min-width: 22px;
      text-align: center;
      font-weight: bold;
      color: #606060;
      background: #f0f0f1;
      border-radius: 12px;
      font-size: 0.75em;
      position: relative;
      align-items: center;
      justify-content: center;
      align-self: center;
      margin-left: 10px;
    }
  }
</style>