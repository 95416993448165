<template>
  <div class="settings settings-printer-view">
    <p class="title">{{ $t('Printer') }}</p>
    <div class="settings__group">
      <label for="a4_printer">
        <p>{{ $t('A4 printer') }}</p>
        <div class="filter" data-label="Filter">
          <div class="filter-input">
            <div class="filter-input-field" data-type="a4" :contenteditable="true" @keydown="FilterInput" />
            <div class="filter-input-button" />
          </div>
          <div class="filter-list">
            <div class="filter-list-item" :data-item="item" :key="index" v-for="(item, index) in printer.filters.a4">
              <span class="filter-list-item-button" @click="RemoveFilter('a4', index)" />
            </div>
          </div>
        </div>
      </label>
      <v-select name="a4_printer" :value="printer.a4" :disabled="readonly" :options="printer.options.a4" @input="SetValue($event, 'a4')">
        <template v-slot:selected-option="option">
          <div class="truncate" :title="option.title">
            <span :class="['state', option.color]">{{ option.label }}</span>
          </div>
        </template>
        <template slot="option" slot-scope="option">
          <div class="truncate" :title="option.title">
            <span :class="['state', option.color]">{{ option.label }}</span>
          </div>
        </template>
      </v-select>
    </div>
    <div class="settings__group">
      <label for="label_printer">
        <p>{{ $t('Label printer') }}</p>
        <div class="filter" data-label="Filter">
          <div class="filter-input">
            <div class="filter-input-field" data-type="label" :contenteditable="true" @keydown="FilterInput" />
            <div class="filter-input-button" />
          </div>
          <div class="filter-list">
            <div class="filter-list-item" :data-item="item" :key="index" v-for="(item, index) in printer.filters.label">
              <span class="filter-list-item-button" @click="RemoveFilter('label', index)" />
            </div>
          </div>
        </div>
      </label>
      <v-select name="label_printer" :value="printer.label" :disabled="readonly" :options="printer.options.label" @input="SetValue($event, 'label')">
        <template v-slot:selected-option="option">
          <div class="truncate" :title="option.title">
            <span :class="['state', option.color]">{{ option.label }}</span>
          </div>
        </template>
        <template slot="option" slot-scope="option">
          <div class="truncate" :title="option.title">
            <span :class="['state', option.color]">{{ option.label }}</span>
          </div>
        </template>
      </v-select>
    </div>
    <div class="settings__group">
      <label for="packing_station">{{ $t('Packing station') }}</label>
      <input class="vs__dropdown-toggle full-width" name="packing_station" :value="packing.station" :disabled="readonly" @keydown.enter="$event.target.blur()" @blur="SetValue($event.target.value, 'station')">
    </div>
    <div class="button-container">
      <div
          class="button action-toggle"
          @click.prevent="$event.target.classList.toggle('visible')"
          v-if="CheckPermissions(printer_test.permissions)"
        >
          {{ $t('Test Printers') }}
          <ul class="button-actions">
            <li
              v-for="([row, printers], index) in printer_test.package_stations.entries()"
              :key="index"
            >
              <a
                class="actions-toggle" @click.prevent="" href=""
                >{{ row }}
                <u class="button-actions">
                  <li v-for="(printer, index) in printers" :key="index">
                    <a @click.prevent="TestPrint(printer), $event.target.closest('.action-toggle').classList.remove('visible')" href="">{{ printer }}</a>
                  </li>
                </u>
              </a>
            </li>
            <li>
              <a class="all_printers" @click.prevent="TestPrint('ALL'), $event.target.closest('.action-toggle').classList.remove('visible')">ALL</a>
            </li>
          </ul>
          <span class="button-icon caret-up" />
        </div>
    </div>
    <div class="loading" v-if="loading">
      <div class="loading-element">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  </div>
  
</template>

<script>
  import { Tool } from '@/helpers/Tool';
  import { BPA } from '@/helpers/BPA';

  export default {
    name: 'SettingsPrinterView',
    data() {
      return {
        loading: true,
        printers: [],
        printer: {
          a4: '',
          label: '',
          options: {
            a4: [],
            all: [],
            label: []
          },
          filters: {
            a4: [],
            label: []
          }
        },
        packing: {
          station: ''
        },
        readonly: !BPA.permissions(['admin', 'settings_admin', 'parcels_admin']).length,
        printer_test: {
          permissions: [/*0 17 7*/ 'admin', 'settings_admin', 'parcels_admin'],
          all_printers: [],
          filtered_printers: [],
          package_stations: [
            {
              row: '',
              printers: []
            }
          ]
        },
      }
    },
    async created() {
      await BPA.api.GetPrinterList().then(response => {
        return BPA.api.response({response, return: 'json'});
      }).then(async response => {
        if (!response.ok) return this.loading = false;
        this.printers = response.result || [];

        this.printer_test.all_printers = response.result || [];

        let filters = this.printer.filters;
        let filter = this.GetValue('filter') || {};
        for (let type of ['all', 'a4', 'label']) {
          this.printer.options[type] = Tool.PrinterOptions(this.printers, type != 'all' ? type : '');
        }
        this.printer.filters = {...filters, ...filter};
        for (let type of ['a4', 'label']) {
          this.printer.filters[type].sort();
        }
        await this.GetValues();
        this.loading = false;
      }).catch(e => e);
      this.FilterPackageStations()
    },
    methods: {
      CloneObject(object = {}) {
        return Tool.CloneObject(object);
      },
      GetValue(type) {
        return BPA.printer.fetch(type);
      },
      CheckPermissions(required_permissions) {
        return BPA.permissions(required_permissions).length
      },
      SetValue(value = '', type = '', get = true) {
        BPA.printer.handler({key: type, set: value});
        if (get !== false) this.GetValues();
      },
      async GetValues(run = true) {
        if (run === false) return;
        for (let type of ['a4', 'label', 'station']) {
          let field = type == 'station' ? 'packing' : 'printer';
          this[field][type] = this.GetValue(type);
          if (field == 'printer') {
            let fetched = this.printer[type];
            let options = this.printer.options[type];
            let printer = options.find(option => option.id == fetched.id);
            if (printer) {
              this.SetValue(printer, type, false);
              this.printer[type] = this.GetValue(type);
            }
          }
        }
      },
      FilterOptions(filter) {
        BPA.printer.handler({key: 'filter', set: filter});
        for (let type of ['a4', 'label']) {
          let options = Tool.PrinterOptions(this.printers, type);
          this.printer.options[type] = options;
          let fetched = this.printer[type];
          if (!options.find(option => option.id == fetched.id)) {
            this.SetValue(null, type, false);
            this.printer[type] = '';
          }
        }
      },
      FilterInput(event) {
        let input = event.target;
        let value = input.textContent;
        let type = input.dataset.type;
        if (event.keyCode == 13) {
          event.preventDefault();
          if (value.replace(/\s/g, '').length) {
            this.printer.filters[type].push(value);
            input.textContent = '';
            this.AddFilter(type);
          } else {
            input.blur();
          }
        }
      },
      AddFilter(type) {
        let filters = this.printer.filters[type] || [];
        let filter = this.GetValue('filter') || {};
        filter = {...filter, ...{[type]: filters}};
        for (let type of ['a4', 'label']) {
          this.printer.filters[type].sort();
          if (filter[type]) filter[type].sort();
        }
        this.FilterOptions(filter);
      },
      RemoveFilter(type, index) {
        let filters = this.printer.filters[type] || [];
        let filter = this.GetValue('filter') || {};
        filters.splice(index, 1);
        filter[type] = filters;
        for (let type of ['a4', 'label']) {
          this.printer.filters[type].sort();
          if (filter[type]) filter[type].sort();
        }
        if (!filters.length) delete filter[type];
        if (!Object.keys(filter).length) filter = '';
        this.FilterOptions(filter);
      },
      PopupMouseEvent(e) {
        let event = e.type;
        let clicked = e.target;
        let printer = this.sticker_printer;
        if (event == 'mousedown') {
          return printer.popup.mousedown = clicked;
        }
        let mousedown = printer.popup.mousedown;
        let popup = document.querySelector('.validate-popup');
        let cancel = popup.querySelector('a.cancel');
        if ([popup, cancel].includes(clicked) && clicked == mousedown) {
          if (printer.popup.cancel.click) {
            printer.popup.cancel.click();
          }
          printer.popup.show = false;
          this.$eventHub.$off('ValidateModalStop');
        }
        printer.popup.mousedown = null;
      },
      PopupConfirm() {
        this.$eventHub.$emit('ValidateModalStop', true);
        this.sticker_printer.popup.show = false;
      },
      FilterPackageStations() {        
        this.printer_test.filtered_printers = this.printer_test.all_printers.filter((x) => x.computer.length == 4)

        let printer_rows = []
        
        this.printer_test.filtered_printers.forEach((printer) => printer_rows.push(printer.computer.split('-')[1]))
        
        printer_rows.sort()

        let result = new Map()

        for(let printer in printer_rows) {
          
          let value = []

          let contains = result.get(printer_rows[printer].charAt(0))

          if(!contains)
          {
            result.set(printer_rows[printer].charAt(0), value)
            result.get(printer_rows[printer].charAt(0)).push(printer_rows[printer])
          }
          else {
            result.get(printer_rows[printer].charAt(0)).push(printer_rows[printer])
          }
        }

        this.printer_test.package_stations = result
      },
      async TestPrint(computer) {
        let printer_id = '';

        this.printer_test.filtered_printers.forEach((printer) => {
          if(('P-' + computer) == printer.computer) {
            printer_id = printer.id
          }
        })

        let response = await BPA.api.TestPrint(printer_id)

        if(response.status == 204) {
          this.$eventHub.$emit("ShowMessages", {
            message: "Print sent successfully",
            type: "success",
            hide: 2000,
          })
        }
        else {
          this.$eventHub.$emit("ShowMessages", {
            message: "Print failed",
            type: "error",
            hide: 2000,
          })
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '../../../assets/style/variables/vital';
  @import '../../../assets/style/components/v-select/vue-select';

  .title {
    font-size: 1.622rem;
    font-weight: 600;
    margin-bottom: 35px;
  }

  label,
  .filter {
    display: flex;
  }

  label > p {
    min-width: 150px;
  }

  .filter {
    flex: 1 1 auto;
    font-size: 14px;
    align-items: flex-end;
    padding-bottom: 1px;

    &[data-label]::before {
      margin-right: 10px;
      display: inline-block;
      content: attr(data-label)':';
    }

    &-input {
      display: flex;

      &-field {
        width: auto;
        outline: none;
        min-width: 50px;

        &[contenteditable=true] {
          cursor: text;
          box-shadow: inset 0 -4px 0 -2px lightgray;

          &:focus {
            box-shadow: inset 0 -4px 0 -2px $blue;
          }
        }
      }
    }

    &-list {
      display: flex;

      &-item {
        margin-left: 20px;

        &::before {
          content: '"'attr(data-item)'"';
        }

        &-button {
          width: 10px;
          height: 10px;
          cursor: pointer;
          display: inline-flex;
          border-radius: 50%;
          margin-left: 2px;
          position: relative;
          align-items: center;
          justify-content: center;
          transform: rotate(45deg);
          //box-shadow: 0 0 0 2px #9d9d9d;

          &::before,
          &::after {
            width: 10px;
            height: 2px;
            content: '';
            display: block;
            position: absolute;
            background: #9d9d9d;
          }

          &::after {
            transform: rotate(90deg);
          }

          &:hover {
            //box-shadow: 0 0 0 2px $blue;

            &::before,
            &::after {
              background: $blue;
            }
          }
        }
      }
    }
  }
  .button-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
  }
  .all_printers {
    cursor: pointer;
  }
</style>