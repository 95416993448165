<template>
  <div class="wrapper">
    <div class="title">
      <h1>{{ $t('Manage Token') }}</h1>
    </div>
    <div class="grid-state">
      <p class="grid-heading">Token</p>
      <div class="alignInputSvg">
        <input
          id="token"
          class="input"
          type="text"
          :value="token"
          readonly="readonly"
        />
        <svg
          class="copyIcon"
          @click="Copy(token)"
          width="30"
          height="30"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 115.77 122.88"
          xml:space="preserve"
        >
          <path
            class="st0"
            d="M89.62 13.96v7.73h12.2v.02c3.85.01 7.34 1.57 9.86 4.1 2.5 2.51 4.06 5.98 4.07 9.82h.02v73.3h-.02c-.01 3.84-1.57 7.33-4.1 9.86-2.51 2.5-5.98 4.06-9.82 4.07v.02H40.1v-.02c-3.84-.01-7.34-1.57-9.86-4.1-2.5-2.51-4.06-5.98-4.07-9.82h-.02V92.51h-12.2v-.02c-3.84-.01-7.34-1.57-9.86-4.1-2.5-2.51-4.06-5.98-4.07-9.82H0V13.95h.02c.01-3.85 1.58-7.34 4.1-9.86C6.63 1.59 10.1.03 13.94.02V0h61.73v.02c3.85.01 7.34 1.57 9.86 4.1 2.5 2.51 4.06 5.98 4.07 9.82h.02v.02zm-10.58 7.73v-7.75h.02c0-.91-.39-1.75-1.01-2.37-.61-.61-1.46-1-2.37-1v.02H13.95v-.02c-.91 0-1.75.39-2.37 1.01-.61.61-1 1.46-1 2.37h.02v64.62h-.02c0 .91.39 1.75 1.01 2.37.61.61 1.46 1 2.37 1v-.02h12.2V35.64h.02c.01-3.85 1.58-7.34 4.1-9.86 2.51-2.5 5.98-4.06 9.82-4.07v-.02h38.94zm26.14 87.23V35.63h.02c0-.91-.39-1.75-1.01-2.37-.61-.61-1.46-1-2.37-1v.02H40.09v-.02c-.91 0-1.75.39-2.37 1.01-.61.61-1 1.46-1 2.37h.02v73.3h-.02c0 .91.39 1.75 1.01 2.37.61.61 1.46 1 2.37 1v-.02h61.73v.02c.91 0 1.75-.39 2.37-1.01.61-.61 1-1.46 1-2.37h-.02v-.01z"
          />
        </svg>
      </div>
    </div>
    <div class="buttonContainer">
      <button class="button" @click="CreateToken">Create Token</button>
      <button class="button blue" @click="GetToken">Get Token</button>
      <button class="button red" @click="DeleteToken">Delete Token</button>
    </div>
    <div class="loading" v-if="loading">
      <div class="loading-element">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  </div>
</template>

<script>
import { BPA } from '@/helpers/BPA';

export default {
  name: 'DashboardTokenOptions',
  data() {
    return {
      token: '',
      loading: false,
    };
  },
  async mounted() {
    this.token = await this.GetToken();
  },
  methods: {
    Copy(token) {
      navigator.clipboard.writeText(token);
    },
    async GetToken() {
      this.loading = true;
      return await new Promise((resolve, reject) => {
        BPA.api.GetDashboardToken().then((response) => {
          return BPA.api
            .response({ response, return: 'text' })
            .then((response) => {
              if (!response.ok || !response.result)
              {
                this.loading = false
                return reject();
              } 

              resolve(response.result);
              this.loading = false;
            });
        });
      });
    },
    async CreateToken() {
      this.loading = true;
      this.token = await new Promise((resolve, reject) => {
        BPA.api.CreateDashboardToken().then((response) => {
          return BPA.api
            .response({ response, return: 'text' })
            .then((response) => {
              if (!response.ok || !response.result)
              {
                this.loading = false
                return reject();
              } 

              resolve(response.result);
              this.loading = false;
            });
        });
      });
    },
    async DeleteToken() {
      this.loading = true;
      await new Promise((resolve, reject) => {
        BPA.api.DeleteDashboardToken().then((response) => {
          return BPA.api
            .response({ response, return: 'text' })
            .then((response) => {
              if (!response.ok || !response.result)
              {
                this.loading = false
                return reject();
              } 

              resolve(response.result);
              this.loading = false;
            });
        });
      });
      this.token = '';
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/assets/style/variables/icons.scss';
@import 'src/assets/style/variables/colors.scss';
@import 'src/assets/style/variables/fonts.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 500px;
}
.title {
  font-weight: 600;
  color: $lightFont;
  padding-bottom: 2px;
  padding-top: 4px;
}
.grid-state {
  min-width: fit-content;
  padding: 4px 0;
}

.grid-heading {
  font-weight: 600;
  color: $lightFont;
  padding-bottom: 2px;
  margin-top: 5px;
}
.input {
  font-size: 1em;
  padding: 7px;
  color: #333;
  appearance: none;
  display: flex;
  background: none;
  border: 1px solid rgba(60, 60, 60, 0.26);
  border-radius: 4px;
  white-space: normal;
  outline: none;
  margin-bottom: 8px;
  margin-right: 5px;
  width: 60%;
}
.buttonContainer {
  display: flex;
  height: 100%;
  width: 20%;
  flex-grow: 1;
}
.button {
  height: 100%;
  width: 100%;
  flex-shrink: 0;
  background-color: $green;
  margin-top: 25px;
  margin-right: 5px;
  &.red {
    background-color: $red;
  }
  &.blue {
    background-color: $blue;
  }
}
.alignInputSvg {
  display: flex;
}
.copyIcon {
  cursor: pointer;
}
</style>
