<template>
  <div class="attributes-wrapper">
    <div class="label-text">Attributes</div>
    <v-select
      :options="selectedCourierAttribute"
      v-model="activeAttribute"
    >
      <template slot="option" slot-scope="option">
        <div class="truncate">
          <span>
            {{ option.label }}
          </span>
        </div>
      </template>
    </v-select>
    <div class="attributes-container">
      <div v-if="activeAttribute">
        <component
          :is="FormatAttributeName(activeAttribute)"
          :attributeValues="mappedAttributes.get(FormatAttributeName(activeAttribute))"
        />
      </div>
    </div>
  </div>
</template>

<script>
import swip_box from '@/components/blocks/Settings/SettingsShippingConfig/Attributes/swip_box';

export default {
  name: 'SettingsShippingAttributeConfig',
  components: { swip_box },
  emits: ['isDirty', 'isDestroyed'],
  props: {
    courierAttributeMap: Map,
    shippingHandlingInformation: {},
  },
  data() {
    return {
      activeAttribute: null,
      mappedAttributes: new Map(),
      selectedCourierAttribute: [],
    };
  },
  mounted() {
    this.GetActiveAttributes();
  },
  destroyed() {
    this.$emit('isDestroyed')
  },
  methods: {
    GetActiveAttributes() {
      let swip_box = {
        active: false,
        attribute: 'swip_box',
        value: {
          sender: {
            address: '',
            city: '',
            country_iso: '',
            email: '',
            name: '',
            phone: '',
            zip_code: '',
          },
        },
      };

      this.courierAttributeMap.get(this.shippingHandlingInformation.agent_code).forEach((attributeName) => {
        this.selectedCourierAttribute.push(this.FormatAttributeNameDisplay(attributeName))
      })
      
      if (this.shippingHandlingInformation.attributes.length > 0) {
        this.shippingHandlingInformation.attributes.forEach((attribute) => {
          switch (attribute.attribute) {
            case 'swip_box':
              swip_box = attribute;
              if (attribute.active) {
                this.activeAttribute = this.FormatAttributeNameDisplay(attribute.attribute);
              }
              break;
              default:
                break;
              }
            });
          } else {
            this.shippingHandlingInformation.attributes.push(swip_box);
          }
          
      this.courierAttributeMap
        .get(this.shippingHandlingInformation.agent_code)
        .forEach((attribute) => {
          let x = this.mappedAttributes.get(attribute);
          if (!x) {
            this.mappedAttributes.set(attribute, swip_box);
          }
        });
    },
    FormatAttributeName(attribute_name) {
      switch(attribute_name) {
        case 'SwipBox':
          return 'swip_box'
        default:
          break;
      }
    },
    FormatAttributeNameDisplay(attribute_name) {
      switch(attribute_name) {
        case 'swip_box':
          return 'SwipBox'
        default:
          break;
      }
    }
  },
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/style/variables/colors';

.attributes-wrapper {
  padding-bottom: 20px;
}
.attributes-container {
}
</style>
