<template>
    <div>
        <p class="title" style="margin-bottom: 15px;">
         {{ $t('Dashboard') }}
        </p>
        <div class="tabs">
            <ul class="tabs__head">
                <li :class="['tabs__head--item', {active: tab.active == 'deadlines'}]" @click="tab.active = 'deadlines'">
                    <span>{{ $t('Deadlines')}}</span>
                </li>
                <li :class="['tabs__head--item', {active: tab.active == 'create-deadline'}]" @click="tab.active = 'create-deadline'">
                    <span>{{ $t('Create Deadline') }}</span>
                </li>
                <li :class="['tabs__head--item', {active: tab.active == 'manage-token'}]" @click="tab.active = 'manage-token'">
                    <span>{{ $t('Manage Token') }}</span>
                </li>
            </ul>
            <div v-if="tab.active == 'deadlines'">
                <DashboardListing />
            </div>
            <div v-if="tab.active == 'create-deadline'">
                <DashboardSetupConfig @deadlines="tab.active = 'deadlines'" />
            </div>
            <div v-if="tab.active == 'manage-token'">
                <DashboardTokenOptions />
            </div>
        </div>
    </div>
</template>

<script>
import DashboardSetupConfig from './DashboardSettings/DashboardSetupConfig';
import DashboardListing from './DashboardSettings/DashboardListing'
import DashboardTokenOptions from './DashboardSettings/DashboardTokenOptions'

export default {
    name: 'SettingsDashboardView',
    components: { DashboardSetupConfig, DashboardListing, DashboardTokenOptions },
    data() {
        return {
            tab: {
                active: 'deadlines',
            },
        }
    },
    mounted() {
    },
    methods: {
        
    }
}
</script>
<style lang="scss"></style>