<template>
  <div class="settings settings-api-config">
    <p class="title">{{ $t('API') }}</p>
    <div class="flex row">
      <div class="flex column" style="width: 100%;">
        <div class="flex column" style="width: 100%;">
          <h3 class="heading">CMS integration</h3>
          <div class="settings__group flex row" style="margin-top: 10px; align-items: center;">
            <span style="display: flex; flex-grow: 1;">
              <!-- <p>{{ $t('Paste API authorisation key into CMS integration module') }}</p>: -->
              <p :class="['state', `${api.cms.status ? 'posi' : 'nega'}tive`]" style="text-transform: none;">{{ $t(`Authorisation key ${api.cms.status ? 'generated' : 'revoked'}`) }}</p>
            </span>
            <a :class="['button', {green: true}]" style="min-width: 115px; margin: auto 0 0 20px;" @click.prevent="GenerateCMSAuthKey" href="">{{ $t(`${api.cms.key ? 'Copy' : 'Create'}`) }}</a>
            <a class="button red" style="min-width: 115px; margin: auto 0 0 20px;" @click.prevent="RevokeCMSAuthKey" href="">{{ $t('Revoke') }}</a>
          </div>
        </div>
        <!--
        <div class="flex column" style="width: 100%; margin-top: 30px;">
          <h3 class="heading">Webhook</h3>
          <div class="settings__group">
            <div class="flex row">
              <div class="label">
                <span class="label-text">{{ $t('Authorization') }}</span>
                <div class="checkbox-toggle">
                  <input type="checkbox" name="authorization" v-model="companyWebhooks.Authorization">
                  <span class="toggle"></span>
                </div>
              </div>
              <div class="label" style="width: 100%; margin: 0 0 0 30px;">
                <span class="label-text">{{ $t('CMS order') }}</span>
                <input type="text" name="url-cms-order" :disabled="readonly" v-model="companyWebhooks.UrlCmsOrder" @keyup.enter="$event.target.blur(), companyWebhooks.UrlCmsOrder = $event.target.value.replace(/\s/g, '').trim()" spellcheck="false">
              </div>
            </div>
            <div class="label" style="margin-top: 10px;">
              <span class="label-text">{{ $t('Create order comments') }}</span>
              <input type="text" name="url-create-order-comments" :disabled="readonly" v-model="companyWebhooks.UrlCreateOrderComments" @keyup.enter="$event.target.blur(), companyWebhooks.UrlCreateOrderComments = $event.target.value.replace(/\s/g, '').trim()" spellcheck="false">
            </div>
            <div class="label">
              <span class="label-text">{{ $t('Ensure order') }}</span>
              <input type="text" name="url-ensure-order" :disabled="readonly" v-model="companyWebhooks.UrlEnsureOrder" @keyup.enter="$event.target.blur(), companyWebhooks.UrlEnsureOrder = $event.target.value.replace(/\s/g, '').trim()" spellcheck="false">
            </div>
            <div class="label">
              <span class="label-text">{{ $t('Pack order') }}</span>
              <input type="text" name="url-pack-order" :disabled="readonly" v-model="companyWebhooks.UrlPackOrder" @keyup.enter="$event.target.blur(), companyWebhooks.UrlPackOrder = $event.target.value.replace(/\s/g, '').trim()" spellcheck="false">
            </div>
            <div class="label">
              <span class="label-text">{{ $t('Refund CMS order') }}</span>
              <input type="text" name="url-refund-cms-order" :disabled="readonly" v-model="companyWebhooks.UrlRefundCmsOrder" @keyup.enter="$event.target.blur(), companyWebhooks.UrlRefundCmsOrder = $event.target.value.replace(/\s/g, '').trim()" spellcheck="false">
            </div>
            <div class="label">
              <span class="label-text">{{ $t('Status histories') }}</span>
              <input type="text" name="url-status-histories" :disabled="readonly" v-model="companyWebhooks.UrlStatusHistories" @keyup.enter="$event.target.blur(), companyWebhooks.UrlStatusHistories = $event.target.value.replace(/\s/g, '').trim()" spellcheck="false">
            </div>
          </div>
        </div>
        -->
      </div>
    </div>

    <div class="loading" v-if="loading">
      <div class="loading-element">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
    
  </div>
</template>

<script>
  import { Tool } from '@/helpers/Tool';
  import { BPA } from '@/helpers/BPA';

  export default {
    name: 'SettingsAPIConfigView',
    data() {
      return {
        loading: false,
        prompt: {
          action: '',
          message: '',
          type: ''
        },
        toast: {
          message: ''
        },
        api: {
          cms: {
            key: '',
            status: false
          }
        }
      }
    },
    async created() {
      this.loading = true;
      this.api.cms.status = await this.GetCMSAuthStatus();
      this.loading = false;
    },
    methods: {
      async PromptUser() {
        return await new Promise((resolve, reject) => {
          let action = this.prompt.action;
          let message = this.prompt.message;
          let type = this.prompt.type || 'success';
          this.$eventHub.$emit('ValidateModalStart', {
            approve: 'Yes' + (action ? ', ' + action : ''),
            disapprove: 'No',
            message: message,
            type: type
          });
          this.$eventHub.$on('ValidateModalStop', approve => {
            this.$eventHub.$off('ValidateModalStop');
            if (!approve) return reject(false);
            resolve(true);
          });
        }).catch(e => e);
      },
      async CopyToClipboard(string) {
        let message = this.toast.message || 'API key copied';
        await Tool.CopyToClipboard(string).then(() => {
          this.$eventHub.$emit('ShowMessages', {
            message: message,
            type: 'success',
            hide: 2000
          });
        });
      },
      async GetCMSAuthStatus() {
        return await new Promise((resolve, reject) => {
          BPA.api.GetCMSAuthStatus().then(response => {
            return BPA.api.response({response, return: 'json'});
          }).then(response => {
            if (!response.ok || !response.result) return reject();
            resolve(response.result.status);
          }).catch(reject);
        }).catch(e => e);
      },
      async CreateCMSAuthKey() {
        return await new Promise((resolve, reject) => {
          BPA.api.CreateCMSAuthKey().then(response => {
            return BPA.api.response({response, return: 'text'});
          }).then(response => {
            if (!response.ok || !response.result) return reject();
            resolve(response.result);
          }).catch(reject);
        }).catch(e => e);
      },
      async DeleteCMSAuthKey() {
        return await new Promise((resolve, reject) => {
          if (!this.api.cms.status) return resolve(false);
          BPA.api.DeleteCMSAuthKey().then(response => {
            return BPA.api.response({response});
          }).then(response => {
            if (response.ok) {
              resolve(false);
              return this.$eventHub.$emit('ShowMessages', {
                message: 'CMS API authorisation key revoked',
                type: 'error',
                hide: 2000
              });
            }
            reject();
          }).catch(reject);
        }).catch(e => e);
      },
      async GenerateCMSAuthKey() {
        if (this.api.cms.key) {
          this.toast.message = 'CMS API authorisation key copied';
          return this.CopyToClipboard(this.api.cms.key);
        }
        this.toast.message = 'CMS API authorisation key generated and copied to clipboard';
        this.loading = true;
        this.api.cms.key = await this.CreateCMSAuthKey() || '';
        this.loading = false;
        if (this.api.cms.key) {
          await this.CopyToClipboard(this.api.cms.key);
          return this.api.cms.status = true;
        }
        this.api.cms.status = false;
      },
      async RevokeCMSAuthKey() {
        if (!this.api.cms.status) return;
        this.prompt.action = 'revoke it';
        this.prompt.message = 'Revokes the current CMS API authorisation key';
        this.prompt.type = 'danger';
        if (!await this.PromptUser()) return;
        this.loading = true;
        this.api.cms.status = !!await this.DeleteCMSAuthKey();
        this.loading = false;
        if (!this.api.cms.status) this.api.cms.key = '';
      }
    }
  }
</script>

<style lang="scss" scoped>
</style>