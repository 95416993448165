<template>
    <div class="container">
        <h2 style="color: #C2C8CE; margin-block: 0.75rem;">Passcode</h2>
        <div style="font-size: 1.5rem; margin-block: 1rem;">12A,4dqa.&U496kDd</div>
        <h2 style="color: #C2C8CE; margin-block: 0.75rem;">Enable check</h2>
        <div class="content-wrapper">
            <div class="flex-align">
                <label for="">{{ $t('Courier') }}</label>
                <v-select :options="courier.options" v-model="selected_courier" @input="FilterShippingMethods">
                    <template v-slot:selected-option="option">
                        <div class="truncate">
                            <span :class="['courier', option.code]">
                            {{ option.label }}
                            </span>
                        </div>
                        </template>
                        <template slot="option" slot-scope="option">
                        <div class="truncate">
                            <span :class="['courier', option.code]">
                            {{ option.label }}
                            </span>
                        </div>
                    </template>
                </v-select>
            </div>
            <div class="flex-align" style="grid-column: span 2;">
                <label for="">{{ $t('Shipping Method') }}</label>
                <v-select :options="shippings_show.options" v-model="selected_shipping">
                    <template v-slot:selected-option="option">
                        <div class="truncate">
                            <span :class="['courier', option.agent]">
                            {{ option.label }}
                            </span>
                        </div>
                        </template>
                        <template slot="option" slot-scope="option">
                        <div class="truncate">
                            <span :class="['courier', option.agent]">
                            {{ option.label }}
                            </span>
                        </div>
                    </template>
                </v-select>
            </div>
            <div class="flex-align" style="grid-row-start: 2">
                <label for="">{{ $t('Toggle Check') }}</label>
                <v-select :options="opcode.options" v-model="selected_opcode">
                    <template slot="option" slot-scope="option">
                        <div class="truncate">
                            <span>
                            {{ option.label }}
                            </span>
                        </div>
                    </template>
                </v-select>
            </div>
            <div class="button green" @click="SetCheckTrackAndTrace()">{{$t('Save')}}</div>
        </div>
    </div>
</template>

<script>
import { BPA } from '@/helpers/BPA'
    export default {
        mixins: [ BPA, ],
        data() {
            return {
                courier: {
                    options: [],
                },
                couriers: {},
                selected_courier: '',
                shipping: {
                    options: [],
                },
                shippings_show: {
                    options: [],
                },
                selected_shipping: '',
                opcode: {
                    options: ['on', 'off']
                },
                selected_opcode: '',
            }
        },
        mounted() {
            this.GetCouriers()
            this.GetShippingMethods()
        },
        methods: {
            async GetShippingMethods(company_code) {
                return await BPA.api
                    .GetShippingOptions(company_code)
                    .then((response) => {
                    return BPA.api.response({ response, return: "json" });
                    })
                    .then((response) => {
                    if (!response.ok || !response.result) return;
                    let options = response.result || [];
                    let agent_codes = {};
                    let shipping_methods = [];
                    this.shipping.methods = options;
                    for (let i = 0; i < options.length; i++) {
                        if (!agent_codes[options[i].agent_code])
                        agent_codes[options[i].agent_code] = [];
                        agent_codes[options[i].agent_code].push(
                        options[i].shipping_method /*.replace(/%2F/g, '/')*/
                        );
                    }
                    agent_codes = Object.fromEntries(Object.entries(agent_codes).sort());
                    for (let i in agent_codes) {
                        shipping_methods.push(
                        agent_codes[i].sort((a, b) => (a < b ? -1 : a > b ? 1 : 0))
                        );
                    }
                    shipping_methods = shipping_methods.flat();
                    const shipping_methods_options = [];
                    for (let shipping_method of shipping_methods) {
                        let option = options.find(
                        (o) => o.shipping_method == shipping_method
                        );
                        shipping_methods_options.push({
                        code: shipping_method,
                        label: shipping_method,
                        agent: option.agent_code,
                        type: option.type,
                        });
                    }

                    this.shipping.options = shipping_methods_options;
                    this.shippings_show.options = shipping_methods_options;

                    return options;
                })
                .catch((e) => e);
            },
            GetCouriers() {
                this.couriers = BPA.api.Couriers("GET");
                for (let code in this.couriers) {
                    this.courier.options.push({
                    code: code,
                    label: this.couriers[code],
                    });
                }
            },
            async SetCheckTrackAndTrace() {

                if((this.selected_courier == '' || this.selected_courier == null) || (this.selected_courier == '' || this.selected_courier == null)) {
                    this.$eventHub.$emit("ShowMessages", {
                        message: "Deadline creation failed",
                        type: "error",
                        hide: 2000,
                    });
                    return
                }

                let params = {
                    toggle: '',
                    courier: this.selected_courier.code,
                    shipping_method: this.selected_shipping.code
                }

                if(this.selected_opcode != null || this.selected_opcode != '') {
                    if(this.selected_opcode == 'on') {
                        params.toggle =  'true'
                    } 
                    if(this.selected_opcode == 'off') {
                        params.toggle =  'false'
                    } 
                }

                let response = await BPA.api.SetCheckTrackAndTrace(params)
                
                if (response.status == 204) {
                    this.$eventHub.$emit("ShowMessages", {
                        message: "Deadline successfully created",
                        type: "success",
                        hide: 2000,
                    });
                    this.selected_courier = ''
                    this.selected_shipping = ''
                    this.selected_opcode = ''
                    } else {
                    this.$eventHub.$emit("ShowMessages", {
                        message: "Deadline creation failed",
                        type: "error",
                        hide: 2000,
                    });
                }
            },
            FilterShippingMethods() {
                let orgData = JSON.parse(JSON.stringify(this.shipping));
                if(this.selected_courier == null) {
                    this.shippings_show.options = orgData.options
                    return
                }

                this.shippings_show.options = orgData.options.filter((data) => data.agent.includes(this.selected_courier.code))
            },
        }
    }
</script>

<style scoped lang="scss">
@import 'src/assets/style/variables/icons.scss';
@import 'src/assets/style/variables/colors.scss';
@import 'src/assets/style/variables/fonts.scss';

.container {
    margin-block: 1rem;
}
.content-wrapper{
    display: grid;
    grid-template-columns: 25% 50% 25%; 
    gap: 0.5rem;
}
.flex-align {
    display: flex;
    flex-direction: column;
}
.flex-align > label {
    color: #C2C8CE;
    font-size: 1rem;
}
.button {
    grid-row-start: 2;
    grid-column-start: 3;
    max-height: 2rem;
    align-self: self-end;
}
.h2 {
    color: #C2C8CE !important;
}
.shipping-select {
  .courier {
    width: 22px;
    height: 22px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    &.bring {
      background-image: $bring;
    }

    &.burd {
      background-image: $burd;
    }

    &.dao {
      background-image: $dao;
    }

    &.dhl {
      background-image: $dhl;
    }

    &.gls {
      background-image: $gls;
    }

    &.instabox {
      background-image: $instabox;
    }

    &.pdk {
      background-image: $pdk;
    }

    &.royalmail {
      background-image: $royalmail;
    }

    &.shop {
      background-image: $shop;
    }

    &.dfm {
      background-image: $dfm;
    }

    &.dpd {
      background-image: $dpd;
    }

    &.pat {
      background-image: $pat;
    }
  }
}
</style>