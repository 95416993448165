<template>
  <div>
    <div class="attribute-title">
      <img src="https://www.swipbox.com/assets/img/Logo/logo.svg" alt="SwipBox" width="200" height="100">
    </div>
    <label class="label-text" for="">Active</label>
    <div class="checkbox-toggle">
      <input
        type="checkbox"
        v-model="dataModel.active"
        @change="EmitIsActiveDirty()"
      />
      <span class="toggle" />
    </div>
    <div class="attribute-grid-wrapper">
      <div class="attribute-wrapper">
        <label class="label-text" for="">{{ $t('Name') }}</label>
        <input class="input" type="text" @change="EmitIsDirty()" :placeholder="attributeValues.value.sender.name" v-model="dataModel.value.sender.name" />
      </div>
      <div class="attribute-wrapper">
        <label class="label-text" for="">{{ $t('Phone') }}</label>
        <input class="input" type="text" @change="EmitIsDirty()" :placeholder="attributeValues.value.sender.phone" v-model="dataModel.value.sender.phone" />
      </div>
      <div class="attribute-wrapper">
        <label class="label-text" for="">{{ $t('Email') }}</label>
        <input class="input" type="text" @change="EmitIsDirty()" :placeholder="attributeValues.value.sender.email" v-model="dataModel.value.sender.email" />
      </div>
      <div class="attribute-wrapper">
        <label class="label-text" for="">{{ $t('Address') }}</label>
        <input class="input" type="text" @change="EmitIsDirty()" :placeholder="attributeValues.value.sender.address" v-model="dataModel.value.sender.address" />
      </div>
      <div class="attribute-wrapper">
        <label class="label-text" for="">{{ $t('ZipCode') }}</label>
        <input class="input" type="text" @change="EmitIsDirty()" :placeholder="attributeValues.value.sender.zip_code" v-model="dataModel.value.sender.zip_code" />
      </div>
      <div class="attribute-wrapper">
        <label class="label-text" for="">{{ $t('City') }}</label>
        <input class="input" type="text" @change="EmitIsDirty()" :placeholder="attributeValues.value.sender.city" v-model="dataModel.value.sender.city" />
      </div>
      <div class="attribute-wrapper">
        <label class="label-text" for="">{{ $t('Country ISO') }}</label>
        <v-select
        class="iso-codes"
        :options="countriesISO"
        v-model="dataModel.value.sender.country_iso"
        :placeholder="attributeValues.value.sender.country_iso"
        :input="EmitIsDirty()"
      >
        <template slot="option" slot-scope="option">
          <div class="truncate">
            <span>
              {{ option.label }}
            </span>
          </div>
        </template>
      </v-select>
      </div>
    </div>
  </div>
</template>

<script>
import { BPA } from '@/helpers/BPA'
import { Tool } from '@/helpers/Tool'

export default {
  name: 'swip_box',
  props: {
      attributeValues: {}
  },
  mixins: [ BPA, Tool ],
  data() {
    return {
      dataModel: {
        active: null,
        attribute: '',
        value: {
          sender: {
            address: '',
            city: '',
            country_iso: '',
            email: '',
            name: '',
            phone: '',
            zip_code: '',
          },
        },
      },
      isActive: false,
      isDirty: false,
      countriesISO: [],
    }
  },
  mounted() {
    this.dataModel = this.attributeValues;
    this.GetCountriesISO()
  },
  methods: {
    Alphabetize(list, prop) {
        return Tool.Alphabetize(list, prop);
      },
    EmitIsDirty() {
      if(this.isDirty == false)
      {
        this.$parent.$emit('isDirty', { component_name: this.$options.name, isDirty: true, isActiveProp: false })
        this.isDirty = true
      }
    },
    EmitIsActiveDirty() {
      if(this.isActive == false)
      {
        this.$parent.$emit('isDirty', { component_name: this.$options.name, isDirty: true, isActiveProp: true })
        this.isActive = true
      }
    },
    GetCountriesISO() {
      BPA.api.Countries('GET').forEach((country) => {
        this.countriesISO.push(country.iso_code_2)
      })
      this.countriesISO = this.Alphabetize(this.countriesISO)
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../../assets/style/variables/colors';
@import '../../../../../assets/style/variables/fonts';

.attribute-grid-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;
}
.attribute-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.attribute-title {
  width: 100%;
  display: flex;
  font-size: $f-xxl;
  white-space: pre;
  align-items: center;
  font-weight: 600;
  margin-block: 10px;
}
.input {
  width: 100%;
  font-size: 1em;
  padding: 7px;
  color: #333;
  appearance: none;
  display: flex;
  background: none;
  border: 1px solid rgba(60, 60, 60, 0.26);
  border-radius: 4px;
  white-space: normal;
  outline: none;
  margin-bottom: 10px;
}
.iso-codes {
  width: 100%;
  margin-bottom: 10px;
  margin-right: 10px;
}
</style>
