<template>
  <div class="settings settings-cache-view">
    <p class="title">
      {{ $t('Cache') }}
      <a :class="['button red', {disabled}]" style="height: 34px; min-width: 115px; margin-left: auto;" @click.prevent="ClearAll" :disabled="disabled" href="">{{ $t('Clear') }}</a>
    </p>
    <div class="settings__group" style="width: 100%; display: flex; flex-direction: row;">
      <div class="cache-wrapper" :key="index" v-for="(type, index) in cache.types" style="width: 100%; display: flex; flex-direction: column;">
        <div class="simple-list__title" style="height: 32px; align-items: flex-end;">
          <p>{{ $t(Capitalize(type) + ' storage') }}</p>
          <div class="simple-list__item item-wrapper" style="width: auto; padding: 0;">
            <button :class="['item-wrapper__button remove', {hover: cache[type].length}]" :disabled="!cache[type].length" @click="ClearCache(type)" @mouseenter="HoverAll" @mouseleave="HoverAll" />
          </div>
        </div>
        <ul class="simple-list odd-even">
          <li class="simple-list__item" :key="index" v-for="(item, index) in cache[type]">
            <div class="item-wrapper">
              <p class="item-wrapper__text" data-type="barcode">{{ item }}</p>
            </div>
            <div class="item-wrapper" style="width: auto;">
              <button class="item-wrapper__button remove" @click="RemoveCache({type, item})" @mouseenter="HoverButton" @mouseleave="HoverButton" />
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
  import { Tool } from '@/helpers/Tool';
  import { BPA } from '@/helpers/BPA';

  export default {
    name: 'SettingsCacheView',
    data() {
      return {
        cache: {
          local: [],
          session: [],
          types: ['local', 'session']
        },
        disabled: true
      }
    },
    created() {
      this.RefreshCache();
    },
    methods: {
      Capitalize(string) {
        return Tool.Capitalize(string);
      },
      CloneObject(object = {}) {
        return Tool.CloneObject(object);
      },
      RefreshCache() {
        this.cache = {...this.cache, ...this.GetCache()};
        this.disabled = !this.cache.types.map(type => this.cache[type].length).filter(e => e).length;
      },
      GetCache() {
        let cache = {};
        for (let type of this.cache.types) {
          cache[type] = Object.keys(BPA.cache.get({type})).sort();
        }
        return cache;
      },
      async ClearAll(event) {
        let button = event.target;
        let disabled = button.hasAttribute('disabled');
        if (disabled || button.classList.contains('disabled')) return;
        if (!await this.Prompt()) return;
        for (let type of this.cache.types) {
          BPA.cache.clear(type);
        }
        this.Toast();
        this.RefreshCache();
      },
      async ClearCache(type) {
        let storage = type + ' storage';
        let message = `Clears cache from ${storage}.`;
        let approve = 'Yes, clear it';
        if (await this.Prompt({approve, message})) {
          BPA.cache.clear(type);
          this.Toast(`${this.Capitalize(storage)} cache successfully cleared`);
          this.RefreshCache();
        }
      },
      async RemoveCache(props = {}) {
        let storage = props.type + ' storage';
        let message = `Removes ${props.item.bold()} from ${storage} cache.`;
        let approve = 'Yes, remove it';
        if (await this.Prompt({approve, message})) {
          BPA.cache.remove(props);
          this.Toast(`${props.item} successfully removed from ${storage}`);
          this.RefreshCache();
        }
      },
      HoverButton(event) {
        let button = event.target;
        button.classList.toggle('hover');
			},
      HoverAll(event) {
        let button = event.target;
        let wrapper = button.closest('.cache-wrapper');
        let list = wrapper.querySelector('.simple-list');
        let items = list.querySelectorAll('.simple-list__item');
				for (let item of items) {
          button = item.querySelector('.item-wrapper__button');
          button.classList.toggle('hover');
        }
			},
      Toast(message) {
        this.$eventHub.$emit('ShowMessages', {
          message: message || 'Cache successfully cleared', 
          type: 'success', hide: 2000
        });
      },
      async Prompt(props = {}) {
        return await new Promise((resolve, reject) => {
          this.$eventHub.$emit('ValidateModalStart', {
            approve: props.approve || 'Yes, clear it',
            disapprove: 'No',
            message: props.message || 'Clears cache from local and session storage.',
            type: 'danger'
          });
          this.$eventHub.$on('ValidateModalStop', approve => {
            this.$eventHub.$off('ValidateModalStop');
            if (!approve) return reject();
            resolve(true);
          });
        }).catch(e => e);
      }
    }
  }
</script>

<style lang="scss">
  @import '../../../assets/style/variables/vital';
  @import '../../../assets/style/variables/colors';

  .title {
    font-size: 1.622rem;
    font-weight: 600;
    margin-bottom: 35px;
    display: flex;
    align-items: flex-end;
  }

  .settings__group {
		position: relative;

		> p + p, 
		> p + ul, 
		> ul + ul, 
		> ul + p {
			margin-top: 10px;
		}
	}

  .cache-wrapper {
    & ~ .cache-wrapper {
      margin-left: 30px;
    }
  }

  .simple-list {
    width: 100%;
    list-style-type: none;
    border-top: 2px solid #d3d3d3;

    &, * {
      box-sizing: border-box;
    }

		&__title {
			width: 100%;
      height: 32px;
			display: flex;
			font-weight: 600;
      align-items: flex-end;

			& > * {
				width: 100%;
				padding: 10px 10px 2px;
			}
		}

    &__add {
      display: flex;
		}
		
		&__item {
      display: flex;
      position: relative;

			.item-wrapper__button.remove.hover {
        background-color: $red;

        &::after {
          color: #ffffff;
        }
			}
		}
  }

  .item {
    &-wrapper {
      width: 100%;
      display: flex;
      position: relative;
      
      &__input::placeholder,
      &__input:placeholder-shown {
        color: #c3c3c3;
        opacity: 1;
      }
      
      &__input, 
      &__text {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        min-height: 32px;
        border: 0;
        color: $textFont;
        outline: none;
        display: flex;
        align-items: center;
        position: relative;
        padding: 4px 10px;
        background-color: transparent;
        font-size: 16px;
        line-height: 23.2px;
        font-family: inherit;
      }

      &__button {
        border: 0;
        width: 32px;
        min-height: 32px;
        height: 100%;
        outline: none;
        border-radius: 0;
        user-select: none;
        position: relative;
        overflow: hidden;
        cursor: pointer;
        //background-color: rgba(200, 200, 200, 0.2);

        &.add {
          background-color: $green;
        }
        
        &.remove {
          &:after {
            transform: rotate(45deg);
            transform-origin: center;
            color: #909090;
          }
        }
        
        &:after {
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          display: flex;
          font-size: 30px;
          content: "+";
          color: #ffffff;
          position: absolute;
          align-items: center;
          flex-direction: column;
          justify-content: center;
          user-select: none;
          pointer-events: none;
        }

        &:disabled::after {
          color: #909090;
        }
      }
    }
  }
</style>