<template>
  <div class="settings settings-rma-config-view grid-wrapper">
    <p class="title" style="margin-bottom: 15px">
      {{ $t('RMA') }}
    </p>
    <div class="tabs" style="overflow: unset">
      <ul class="tabs__head">
        <li
          :class="['tabs__head--item', { active: tab.active == 'design' }]"
          @click="Tab('design')"
        >
          <span>{{ $t('Design') }}</span>
        </li>
        <li
          :class="['tabs__head--item', { active: tab.active == 'order' }]"
          @click="Tab('order')"
        >
          <span>{{ $t('Order') }}</span>
        </li>
        <li
          :class="['tabs__head--item', { active: tab.active == 'shipping' }]"
          @click="Tab('shipping')"
        >
          <span>{{ $t('Shipping') }}</span>
        </li>
        <li
          :class="['tabs__head--item', { active: tab.active == 'shops' }]"
          @click="Tab('shops')"
        >
          <span>{{ $t('Shops') }}</span>
        </li>

        <li style="margin-left: auto; display: flex">
          <div
            class="label"
            style="height: 34px; flex-direction: row; align-items: center"
          >
            <router-link class="button" style="margin-right: 5px;" :to="{ name: 'RMA-Landing-Page-Preview' }"> {{ $t('RMA Preview') }}</router-link>
            <div class="checkbox-toggle">
              <input type="checkbox" v-model="rma.enabled" />
              <span class="toggle" />
            </div>
            <span style="margin-left: 10px">{{ $t('Active') }}</span>
          </div>
          <div class="label" style="margin-left: 30px; width: 250px">
            <v-select
              style="caret-color: transparent;"
              v-if="languages"
              name="language"
              v-model="rma.language"
              :value="rma.language"
              :options="
                Alphabetize(
                  languages.map((option) => {
                    option.label = `${LanguageName(option.language)}`;
                    return option;
                  }),
                  'label'
                )
              "
              :clearable="false"

            >
              <template v-slot:selected-option="option">
                <div class="truncate">
                  <Flag
                    :code="option.code"
                    size="small"
                    type="language"
                    :title="CountryName(option.country)"
                  />
                  <span>{{ option.label }}</span>
                </div>
              </template>
              <template slot="option" slot-scope="option">
                <div class="truncate">
                  <Flag
                    :code="option.code"
                    size="small"
                    type="language"
                    :title="CountryName(option.country)"
                  />
                  <span>{{ option.label }}</span>
                </div>
              </template>
            </v-select>
          </div>
        </li>
      </ul>

      <ul class="tabs__body" style="overflow: unset">
        <li
          class="tabs__body--content padding"
          style="padding-bottom: 30px; margin-bottom: -30px"
          v-if="tab.active == 'design'"
        >
          <ul
            class="item-submenu"
            style="margin: -27px 0 27px; border-top: 2px solid #1b577a"
          >
            <li
              :class="[
                'item-submenu__item',
                { active: tab[tab.active].tab.active == 'landingPage' },
              ]"
              ref="subtab_landingPage"
              @click="Tab('landingPage')"
            >
              <span>{{ $t('Landing Page') }}</span>
            </li>
            <li
              :class="[
                'item-submenu__item',
                { active: tab[tab.active].tab.active == 'textBelowBox' },
              ]"
              ref="subtab_textBelowBox"
              @click="Tab('textBelowBox')"
            >
              <span>{{ $t('Text below box') }}</span>
            </li>
            <li
              :class="[
                'item-submenu__item',
                { active: tab[tab.active].tab.active == 'button' },
              ]"
              ref="subtab_button"
              @click="Tab('button')"
            >
              <span>{{ $t('Button') }}</span>
            </li>
            <li
              :class="[
                'item-submenu__item',
                { active: tab[tab.active].tab.active == 'background' },
              ]"
              ref="subtab_background"
              @click="Tab('background')"
            >
              <span>{{ $t('Background') }}</span>
            </li>
            <li
              :class="[
                'item-submenu__item',
                { active: tab[tab.active].tab.active == 'header' },
              ]"
              ref="subtab_header"
              @click="Tab('header')"
            >
              <span>{{ $t('Header') }}</span>
            </li>
            <li
              :class="[
                'item-submenu__item',
                { active: tab[tab.active].tab.active == 'footer' },
              ]"
              ref="subtab_footer"
              @click="Tab('footer')"
            >
              <span>{{ $t('Footer') }}</span>
            </li>
          </ul>

          <ul>
            <li v-if="tab[tab.active].tab.active == 'text'">
              <div class="flex row sticky-footer">
                <div class="flex column" style="width: auto; margin-left: auto">
                  <button
                    class="button green"
                    style="min-width: 115px"
                    @click.prevent
                  >
                    {{ $t('Save') }}
                  </button>
                </div>
              </div>
            </li>

            <li v-if="tab[tab.active].tab.active == 'button'">
              <div class="flex row sticky-footer">
                <div class="flex column" style="width: auto; margin-left: auto">
                  <button
                    class="button green"
                    style="min-width: 115px"
                    @click.prevent
                  >
                    {{ $t('Save') }}
                  </button>
                </div>
              </div>
            </li>

            <!-- <li v-if="tab[tab.active].tab.active == 'background'">
              <div class="flex row sticky-footer">
                <div class="flex column" style="width: auto; margin-left: auto">
                  <button
                    class="button green"
                    style="min-width: 115px"
                    @click.prevent
                  >
                    {{ $t('Save') }}
                  </button>
                </div>
              </div>
            </li> -->
          </ul>
        </li>

        <li
          class="tabs__body--content padding"
          style="padding-bottom: 30px; margin-bottom: -30px"
          v-if="tab.active == 'order'"
        >
          <ul
            class="item-submenu"
            style="margin: -27px 0 27px; border-top: 2px solid #1b577a"
          >
            <li
              :class="[
                'item-submenu__item',
                { active: tab[tab.active].tab.active == 'address' },
              ]"
              ref="subtab_address"
              @click="Tab('address')"
            >
              <span>{{ $t('Address') }}</span>
            </li>
            <li
              :class="[
                'item-submenu__item',
                { active: tab[tab.active].tab.active == 'reasons' },
              ]"
              ref="subtab_reasons"
              @click="Tab('reasons')"
            >
              <span>{{ $t('Reasons') }}</span>
            </li>
          </ul>

          <ul>
            <li v-if="tab[tab.active].tab.active == 'address'">
              <div class="flex row sticky-footer">
                <div class="flex column" style="width: auto; margin-left: auto">
                  <button
                    class="button green"
                    style="min-width: 115px"
                    @click.prevent
                  >
                    {{ $t('Save') }}
                  </button>
                </div>
              </div>
            </li>

            <li v-if="tab[tab.active].tab.active == 'reasons'">
              <div class="flex row sticky-footer">
                <div class="flex column" style="width: auto; margin-left: auto">
                  <button
                    class="button green"
                    style="min-width: 115px"
                    @click.prevent
                  >
                    {{ $t('Save') }}
                  </button>
                </div>
              </div>
            </li>
          </ul>
        </li>

        <li
          class="tabs__body--content padding"
          style="padding-bottom: 30px; margin-bottom: -30px"
          v-if="tab.active == 'shipping'"
        ></li>

        <li
          class="tabs__body--content padding"
          style="padding-bottom: 30px; margin-bottom: -30px"
          v-if="tab.active == 'shops'"
        ></li>
      </ul>
      <div v-if="tab[tab.active].tab.active == 'landingPage'">
        <RMAConfigLandingpage></RMAConfigLandingpage>
        <div class="sticky-footer"/>
      </div>
      <div v-if="tab[tab.active].tab.active == 'background'">

        <div class="sticky-footer"/>
      </div>
    </div>


    <div class="loading" v-if="loading">
      <div class="loading-element">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  </div>
</template>

<script>
import { TableElementsBehaviour } from '@/mixins/TableElementsBehaviour';
import { Tool } from '@/helpers/Tool';
import { BPA } from '@/helpers/BPA';
import Flag from '@/components/snippets/Flag';
import RMAConfigLandingpage from '@/components/blocks/Settings/RMASettings/RMALandingpage/RMAConfigLandingPage'

export default {
  name: 'SettingsRMAConfigView',
  mixins: [TableElementsBehaviour, Tool, BPA],
  components: {
    Flag,
    RMAConfigLandingpage,
  },
  data() {
    return {
      loading: false,
      languages: [],
      rma: {
        enabled: false,
        language: 'Default',
        preview: {},
      },
      tab: {
        active: 'design',
        design: {
          name: 'design',
          loaded: false,
          tab: {
            active: 'landingPage',
            landingPage: {
              name: 'landingPage',
              loaded: false,
            },
            textBelowBox: {
              name: 'textBelowBox',
              loaded: false,
            },
            button: {
              name: 'button',
              loaded: false,
            },
            background: {
              name: 'background',
              loaded: false,
            },
            header: {
              name: 'header',
              loaded: false,
            },
            footer: {
              name: 'footer',
              loaded: false,
            },
          },
        },
        order: {
          name: 'order',
          loaded: true,
          tab: {
            active: 'address',
            address: {
              name: 'address',
              loaded: false,
            },
            reasons: {
              name: 'reasons',
              loaded: false,
            },
          },
        },
        shipping: {
          name: 'shipping',
          loaded: false,
        },
        shops: {
          name: 'shops',
          loaded: false,
        },
      },
    };
  },
  async created() {
    let storage = BPA.util.storage;
    let languages = storage.get('languages');
    let company = BPA.util.GetCompany();
    if (!Object.keys(languages).length || !(company in languages)) {
      let locales = await this.GetLanguageOrderCount();
      languages[company] = [];
      for (let locale in locales) {
        let option = { code: locale };
        if (/nb/.test(locale)) {
          locale = locale.replace('nb', 'no');
        }
        let code = locale.split('_');
        option.label = this.LanguageName(code[0], 'en');
        option.language = code[0];
        option.country = code[1];
        languages[company].push(option);
      }
      storage.set('languages', languages);
    }
    this.languages = languages[company] || [];
    //Adds default lang
    this.languages.push({code: 'Default', country: 'Default', label: 'Default', language: 'Default'})

    if (this.languages.length) {
      console.log();
    }
  },
  mounted() {},
  methods: {
    ConsoleLog(output) {
      console.log(output);
    },
    CloneObject(object = {}) {
      return Tool.CloneObject(object);
    },
    DateFormat(date) {
      return Tool.DateFormat(date);
    },
    NumberFormat(number) {
      return Tool.NumberFormat(number);
    },
    Capitalize(string = '') {
      return Tool.Capitalize(string);
    },
    TitleCase(string = '') {
      return Tool.TitleCase(string);
    },
    Hyperlink(props = {}) {
      return Tool.Hyperlink(props);
    },
    Alphabetize(list, prop) {
      return Tool.Alphabetize(list, prop);
    },
    CountryName(country_code = '', locale) {
      if(country_code == 'Default') return 'Default'
      return Tool.CountryName(country_code, locale);
    },
    LanguageName(country_code = '', locale) {
      return Tool.LanguageName(country_code, locale);
    },
    Tab(active) {
      let tab = this.tab;
      let tab_active = tab[tab.active];
      if (active) {
        if (!(active in tab)) {
          tab = tab_active.tab;
        }
        tab.active = active;
      } else if ('tab' in tab_active) {
        tab = tab_active.tab;
      }
      tab_active = tab[tab.active];
      this.$nextTick().then(async () => {
        if (active) {
          console.log('active', active);
        }
      });
      console.log(tab_active)
      return tab_active;
    },

    async GetLanguageOrderCount(company_code) {
      return await BPA.api
        .GetLanguageOrderCount(company_code)
        .then((response) => {
          return BPA.api.response({ response, return: 'json' });
        })
        .then((response) => response.result || {})
        .catch((e) => e);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
