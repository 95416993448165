<template>
  <div class="settings settings-uid-check-list-view">
		<p class="title">{{ $t('Product checklist') }}</p>
		<div class="settings__group">
			<p>{{ $t('Products matching a barcode added to the check list requires a serial number to be added to the product before being added to the order.') }}</p>
			<p>{{ $t('The serial number rule is a regular expression which is used to validate the serial number when entered in the scanning process.') }}</p>
			<ul>
				<b>{{ $t('Example') }}</b>
				<li style="margin-left: 20px;">
					<p><b style="font-weight: 600;">{{ $t('Regular expression') }}:</b> ^[a-z]\d{10,12}$</p>
				</li>
				<li style="margin-left: 20px;">
					<p><b style="font-weight: 600;">{{ $t('Rule') }}:</b> {{ $t('Must begin with a lowercase letter followed by and ending with between 10 and 12 numbers') }}</p>
				</li>
			</ul>
			<p>{{ $t('Use') }} <span v-html="Hyperlink({href: 'https://regex101.com', target: '_blank', text: 'regex101'})" />	{{ $t('to test regular expressions.') }}</p>
		</div>
		<div class="settings__group" style="width: 100%; display: flex; flex-direction: row;">
      <div class="list-wrapper" style="width: 100%; display: flex; flex-direction: column;">
        <div class="simple-list__title">
          <p>{{ $t(label.barcode) }}</p>
          <p>{{ $t(label.regex) }}</p>
          <!--<p style="width: 32px; flex-grow: 0; flex-shrink: 0;"></p>-->
          <div class="simple-list__item item-wrapper" style="width: auto; padding: 0;">
            <button :class="['item-wrapper__button remove', {hover: uid_check_list.length}]" :disabled="!uid_check_list.length" @click="ClearProductLinkList()" @mouseenter="HoverAll" @mouseleave="HoverAll" />
          </div>
        </div>
        <ul class="simple-list odd-even">
          <li class="simple-list__add">
            <div class="item-wrapper">
              <input class="item-wrapper__input" type="text" :placeholder="$t('Barcode')" data-type="barcode" spellcheck="false" @input="TrimInput" @keydown.enter="$event.target.blur()">
            </div>
            <div class="item-wrapper">
              <input class="item-wrapper__input" type="text" :placeholder="$t('Regular expression')" data-type="regex" spellcheck="false" @input="TrimInput" @keydown.enter="$event.target.blur()" title="Example: ^[a-z]\d{10,12}$">
            </div>
            <div class="item-wrapper" style="width: auto;">
              <button class="item-wrapper__button add" @click="AddProductUniqueIdentifierCheck"></button>
            </div>
          </li>
          <li class="simple-list__item" v-for="item in uid_check_list" :key="item.barcode">
            <div class="item-wrapper">
              <p class="item-wrapper__text" data-type="barcode">{{ item.barcode }}</p>
            </div>
            <div class="item-wrapper">
              <p class="item-wrapper__text" data-type="regex">{{ item.regex }}</p>
            </div>
            <div class="item-wrapper" style="width: auto;">
              <button class="item-wrapper__button remove" @click="DeleteProductUniqueIdentifierCheck" @mouseenter="HoverButton" @mouseleave="HoverButton"></button>
            </div>
          </li>
        </ul>
      </div>

			<div class="loading" v-if="loading">
				<div class="loading-element">
					<span></span>
					<span></span>
					<span></span>
					<span></span>
				</div>
			</div>
      
		</div>
  </div>
</template>

<script>
	import { BPA } from '@/helpers/BPA';
	import { Tool } from '@/helpers/Tool';
	
  export default {
		name: 'SettingsProductUniqueIdentifierView',
		mixins: [BPA, Tool],
    data() {
      return {
				loading: false,
				label: {
					barcode: 'Product barcode',
					regex: 'Serial number rule'
				},
				comp_uid_props: [],
				uid_check_list: []
      }
    },
    async created() {
			this.company = BPA.util.GetCompany();
			this.companies = BPA.api.Companies('GET');
			await this.GetProductUniqueIdentifierChecks();

      /*
      //let old_regex = '[0-9]{11}[a-zA-Z][0-9]{3}$';
      for (let product of this.uid_check_list) {
        product.regex = '[0-9]{11}[a-zA-Z]{1,}[0-9]{2,}$';
        
        await BPA.api.UpdateProductUniqueIdentifierChecks(product).then(response => {
          return BPA.api.response({response});
        }).then(response => !response.ok).catch(e => e);

        await BPA.api.DeleteProductUniqueIdentifierCheck(product.barcode).then(response => {
          return BPA.api.response({response});
        }).then(response => !response.ok).catch(e => e);

      }
      await this.GetProductUniqueIdentifierChecks();
      */
		},
		computed: {
			inputs() {
				return [...document.querySelectorAll('input[data-type]')];
			}
		},
    methods: {
			HoverButton(event) {
        let button = event.target;
        button.classList.toggle('hover');
			},
      HoverAll(event) {
        let button = event.target;
        let wrapper = button.closest('.list-wrapper');
        let list = wrapper.querySelector('.simple-list');
        let items = list.querySelectorAll('.simple-list__item');
				for (let item of items) {
          button = item.querySelector('.item-wrapper__button');
          button.classList.toggle('hover');
        }
			},
      TrimInput(e) {
        e.target.value = e.target.value.replace(/\s/g, '').trim();
			},
			Hyperlink(props = {}) {
        return Tool.Hyperlink(props);
      },
			async GetProductUniqueIdentifierChecks() {
				this.loading = true;
				await BPA.api.GetProductUniqueIdentifierChecks().then(response => {
					return BPA.api.response({response, return: 'json'});
				}).then(response => {
					this.loading = false;
					if (!response.ok) return;
					let list = response.result || {};
					let uid_check_list = [];
					let checks = list[this.company] || {};
					for (let sku in checks) {
						uid_check_list.push({
							barcode: sku, 
							regex: checks[sku]
						});
					}
					this.uid_check_list = uid_check_list;
				}).catch(e => e);
			},
      async UpdateProductUniqueIdentifierChecks() {
				const product = {};
				let valid_regex = true;
				this.inputs.forEach(input => product[input.dataset.type] = input.value);
				for (let key in product) {
					if (!product[key].length) {
						this.$eventHub.$emit('ShowMessages', {
							message: `${key == 'barcode' ? this.label.barcode : this.label.regex} is missing`,
							type: 'error',
							hide: 2000
						});
						return;
					}
					for (let item of this.uid_check_list) {
						if (key == 'barcode' && item.barcode == product[key]) {
							this.$eventHub.$emit('ShowMessages', {
								message: 'Barcode already is in check list',
								type: 'error',
								hide: 2000
							});
							return;
						}
					}
				}
				try {
					new RegExp(product.regex);
				} catch (e) {
					valid_regex = false;
				}
				if (!valid_regex) {
					this.$eventHub.$emit('ShowMessages', {
						message: 'Invalid regular expression',
						type: 'error',
						hide: 2000
					});
					return;
				}
				this.loading = true;
				await BPA.api.UpdateProductUniqueIdentifierChecks(product).then(response => {
					return BPA.api.response({response});
				}).then(response => {
					this.loading = false;
					if (!response.ok) return;
					this.inputs.forEach(input => input.value = '');
					this.uid_check_list.unshift(product);
					this.$eventHub.$emit('ShowMessages', {
						message: 'Unique identifier rule added',
						type: 'success',
						hide: 2000
					});
				}).catch(e => e);
			},
      async AddProductUniqueIdentifierCheck() {
        let product = {};
				let valid_regex = true;
        let list = this.uid_check_list;
				this.inputs.forEach(input => product[input.dataset.type] = input.value);
				for (let key in product) {
          let input = this.inputs.find(input => input.dataset.type == key);
					if (!product[key].length) return input.focus();
          if (key == 'barcode') {
            for (let i = 0; i < list.length; i++) {
              if (list[i].barcode == product[key]) {
                return this.$eventHub.$emit('ShowMessages', {
                  message: 'Barcode already is in check list',
                  type: 'error',
                  hide: 2000
                });
              }
            }
          }
          if (key == 'regex') {
            try {
              new RegExp(product.regex);
            } catch (e) {
              valid_regex = false;
            }
            if (!valid_regex) {
              this.$eventHub.$emit('ShowMessages', {
                message: 'Invalid regular expression',
                type: 'error',
                hide: 2000
              });
              return input.focus();
            }
          }
				}

				
      },
			async DeleteProductUniqueIdentifierCheck(e) {
				this.$eventHub.$emit('ValidateModalStart', {
					approve: 'Yes, remove it',
					disapprove: 'No',
					message: 'Deletes the current unique identifier rule.',
					type: 'danger'
				});
				await this.$eventHub.$on('ValidateModalStop', approve => {
					this.$eventHub.$off('ValidateModalStop');
					if (!approve) return;
					let product = {};
					e.target.closest('li').querySelectorAll('[data-type]').forEach(elm => product[elm.dataset.type] = elm.textContent);
					this.loading = true;
					BPA.api.DeleteProductUniqueIdentifierCheck(product.barcode).then(response => {
						return BPA.api.response({response});
					}).then(response => {
						this.loading = false;
						if (!response.ok) return;
						this.uid_check_list = this.uid_check_list.filter(item => {
							return JSON.stringify(item) != JSON.stringify(product);
						});
						this.$eventHub.$emit('ShowMessages', {
							message: 'Unique identifier rule removed',
							type: 'success',
							hide: 2000
						});
					}).catch(e => e);
				});
			}
    }
  }
</script>

<style lang="scss" scoped>
	@import '../../../assets/style/variables/colors';

	.settings__group {
		position: relative;

		> p + p, 
		> p + ul, 
		> ul + ul, 
		> ul + p {
			margin-top: 10px;
		}
	}

  .simple-list {
    width: 100%;
    list-style-type: none;
    border-top: 2px solid #d3d3d3;

    &, * {
      box-sizing: border-box;
    }

		&__title {
			width: 100%;
			display: flex;
			font-weight: 600;

			& > * {
				width: 100%;
				padding: 10px 10px 2px;
			}
		}

    &__add {
      display: flex;
		}
		
		&__item {
			display: flex;
      position: relative;

			.item-wrapper__button.remove.hover {
        background-color: $red;

        &::after {
          color: #ffffff;
        }
			}
		}

    &__item, 
    &__add {
			display: flex;
      position: relative;
      //border-bottom: 1px solid lightgrey;

      .item {
        &-wrapper {
          width: 100%;
          /*height: 100%;*/
          display: flex;
					position: relative;
					
					&__input::placeholder,
					&__input:placeholder-shown {
						color: #c3c3c3;
						opacity: 1;
					}
          
          &__input, 
          &__text {
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            min-height: 32px;
            border: 0;
            outline: none;
            position: relative;
            padding: 4px 10px;
            background-color: transparent;
            font-size: 16px;
            line-height: 23.2px;
            font-family: inherit;
          }

          &__button {
            border: 0;
            width: 32px;
						min-height: 32px;
						height: 100%;
            outline: none;
            border-radius: 0;
            user-select: none;
            position: relative;
						cursor: pointer;
            //background-color: rgba(200, 200, 200, 0.2);

						&.add {
							background-color: $green;
						}

            &.remove {
              &:after {
                transform: rotate(45deg);
                transform-origin: center;
                color: #909090;
              }
            }
            
            &:after {
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              display: flex;
              font-size: 30px;
							content: "+";
							color: #ffffff;
              position: absolute;
              align-items: center;
              flex-direction: column;
							justify-content: center;
							user-select: none;
							pointer-events: none;
            }
          }
        }
      }
    }
  }
</style>