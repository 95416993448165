<template>
    <div>

        <div class="wrapper">
            <div class="container">
            <div class="inputContainer">
                <label class="input-label">Header Color</label>
                <input
                class="input"
                type="text"
                v-model="preview.headerColor"/>
            </div>
            <div class="inputContainer">
                <label class="input-label">Main Box Text</label>
                <input
                class="input"
                type="text"
                v-model="preview.mainBoxText"/>
            </div>
            <div class="inputContainer">
                <label class="input-label">Main Box Logo</label>
                <input
                class="input"
                type="text"
                v-model="preview.mainBoxLogo"/>
                <label class="input-label">Preview</label>
                <img type="file"
                class="image-preview"
                :src="PreviewImageLogoPath"
                >
            </div>
        </div>
        <div class="container">
            <div class="inputContainer">
                <div class="continue-button-container">
                    <div>
                        <label class="input-label">Continue Button Text</label>
                        <input
                        class="input btn"
                        type="text"
                        v-model="preview.continueButton.text"/>
                    </div>
                    <div>
                        <label class="input-label">Continue Button Color</label>
                        <input
                        class="input btn"
                        type="text"
                        v-model="preview.continueButton.color"/>
                    </div>
                </div>
            </div>
            <div class="inputContainer">
                <label class="input-label">Bottom Box Text</label>
                <input
                class="input"
                type="text"
                v-model="preview.buttomBoxText"/>
            </div>
            <div class="inputContainer">
                <label class="input-label">Background Image</label>
                <input
                class="input"
                type="text"
                v-model="preview.backgroundImage"/>
                <label class="input-label">Preview</label>
                <img type="file"
                class="image-preview"
                :src="PreviewImageBackgroundPath"
                >
            </div>
        </div>
    </div>
    <div class="route-flex-container">
        <button class="save-button" @click="SaveConfig">Save</button>
    </div>
</div>
</template>
<script>
export default {
    components: {
    },
    data() {
        return {
            preview: {
                headerColor: '',
                mainBoxLogo: '',
                mainBoxText: '',
                continueButton: {
                    text: '',
                    color: ''
                },
                buttomBoxText: '',
                backgroundImage: '',
            },
        }
    },
    computed: {
        PreviewImageLogoPath() {
            let tempMainLogoPath = 'https://img.uxwing.com/wp-content/themes/uxwing/download/video-photography-multimedia/no-image-photography-icon.svg'

            if(this.preview.mainBoxLogo != '') tempMainLogoPath = this.preview.mainBoxLogo

            return tempMainLogoPath
        },
        PreviewImageBackgroundPath() {
            let tempBackgroundImagePath = 'https://img.uxwing.com/wp-content/themes/uxwing/download/video-photography-multimedia/no-image-photography-icon.svg'

            if(this.preview.backgroundImage != '') tempBackgroundImagePath = this.preview.backgroundImage

            return tempBackgroundImagePath
        }
    },
    methods: {
        SaveConfig() {
            //TODO Validate data, if some fields havent been filled, take from default/maybe put default in inputs.
            
            console.log(this.preview)
            this.$router.push({name: 'RMA-Landing-Page-Preview', params:{ preview: JSON.stringify(this.preview) }})
            //Open preview with new data.
        }
    }
}
</script>
<style lang="scss" scoped>
@import "src/assets/style/variables/colors.scss";
@import "src/assets/style/variables/fonts.scss";

.wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
}
.container {
    width: 50%;
    margin: 5px;
}
.input-label {
  font-weight: 600;
  color: $lightFont;
  padding-bottom: 2px;
  margin-top: 5px;
}
.input {
    font-size: 1em;
    padding: 7px;
    color: #333;
    appearance: none;
    display: flex;
    background: none;
    border: 1px solid rgba(60, 60, 60, 0.26);
    border-radius: 4px;
    white-space: normal;
    outline: none;
    margin-bottom: 10px;
    width: 100%;
}
.image-preview {
    padding: 7px;
    border: 1px solid rgba(60, 60, 60, 0.26);
    border-radius: 4px;
    margin-bottom: 10px;
    max-width: 80%;
    display: flex;
    padding: 10px;
}
.continue-button-container {
    display: flex;
    justify-content: space-between;
}
.save-button {
    font-size: $f-xs;
    font-weight: 600;
    padding: 8px 15px;
    border-radius: 10px;
    text-decoration: none;
    min-width: 115px;
    color: $white;
    background-color: $green;
    cursor: pointer;
    border: none;
}
.route-flex-container {
    display: flex;
    justify-content: end;
}
</style>
