<template>
  <div class="wrapper">
    <div class="title">
      <h1>{{ $t('Alert orders') }}</h1>
    </div>
    <div class="grid-search">
      <input
        type="text"
        v-model="emailSearch"
        :placeholder="$t('Search')"
        @input="GetOrdersBySearch(emailSearch)"
        spellcheck="false"
      />
    </div>
    <table class="list table odd-even">
      <tbody v-if="dataModel">
        <tr v-for="(item, index) in dataModel.items" :key="index">
          <td>
            {{ item.email }}
          </td>
          <td class="sku-table">
            <span class="sku" v-for="(sku, index) in item.skus" :key="index">
              {{ sku }}
            </span>
          </td>
          <td>
            {{ item.min_product_count_pr_row }} -
            {{ item.max_product_count_pr_row }}
          </td>
          <td>
            {{ item.min_product_count_pr_order }} -
            {{ item.max_product_count_pr_order }}
          </td>
          <td>{{ item.min_order_value }} - {{ item.max_order_value }}</td>
          <td>{{ item.min_row_value }} - {{ item.max_row_value }}</td>
          <td class="center-value">
            <input
              class="disabled-checkbox"
              v-model="item.is_disable"
              type="checkbox"
              name=""
              id=""
            />
          </td>
          <td class="actions">
            <a class="icon dots" @click.prevent="" href="">
              <ul class="item-actions">
                <li>
                  <a @click.prevent="DeleteAlertOrder(item.email)">{{
                    $t('Delete')
                  }}</a>
                </li>
                <li>
                  <a @click.prevent="$emit('editAlert', item)">{{
                    $t('Edit')
                  }}</a>
                </li>
              </ul>
            </a>
          </td>
        </tr>
      </tbody>
      <thead>
        <tr>
          <th>{{ $t('Email') }}</th>
          <th>{{ $t('SKU') }}</th>
          <th>{{ $t('Min - Max Pr row') }}</th>
          <th>{{ $t('Min - Max Pr order') }}</th>
          <th>{{ $t('Min - Max order value') }}</th>
          <th>{{ $t('Min - Max row value') }}</th>
          <th>{{ $t('Disabled') }}</th>
          <th class="edit" />
        </tr>
      </thead>
    </table>
    <div class="grid-pagination">
      <div class="page-navigation">
        <div
          class="page-turn prev disabled"
          @click="PageController(false)"
        ></div>
        <div class="page-number">
          <label class="page-number-current">
            <input
              type="number"
              min="1"
              :max="page.last"
              :value="page.current"
              @blur="PageNavigator"
              @keydown="PageNavigator"
            />
            <span class="placeholder">{{ NumberFormat(page.current) }}</span>
          </label>
          <span class="page-number-separator">/</span>
          <div class="page-number-last">{{ NumberFormat(page.last) }}</div>
        </div>
        <div
          class="page-turn next"
          :class="{ disabled: page.last == 1 }"
          @click="PageController(true)"
        ></div>
      </div>
    </div>
    <div class="loading" v-if="loading">
      <div class="loading-element">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  </div>
</template>
<script>
import { BPA } from '@/helpers/BPA';
import { Tool } from '@/helpers/Tool';

export default {
  mixins: [Tool, BPA],
  emit: ['editAlert'],
  data() {
    return {
      dataModel: {
        has_negative_values: false,
        has_zero_values: false,
        max_product_count_pr_row: null,
        min_product_count_pr_row: null,
        max_product_count_pr_order: null,
        min_product_count_pr_order: null,
        max_order_value: null,
        max_row_value: null,
        min_order_value: null,
        min_row_value: null,
        skus: [''],
        email: '',
        is_disable: false,
      },
      emailSearch: '',
      loading: false,
      page: {
        current: 1,
        number: 1,
        last: 1,
        order: {
          direction: 'desc',
          by: 'id',
        },
      },
    };
  },
  mounted() {
    this.GetOrdersBySearch();
  },
  methods: {
    NumberFormat(number) {
      return Tool.NumberFormat(number);
    },
    async GetOrdersBySearch() {
      try {
        this.loading = true;
        await BPA.api
          .GetOrdersBySearch({
            search: this.emailSearch || ' ',
            page_offset: this.page.current,
            page_size: 20,
          })
          .then((response) => {
            return BPA.api.response({ response, return: 'json' });
          })
          .then((response) => {
            if (!response.ok || !response.result) return;

            this.dataModel = response.result;
            console.log(this.dataModel);
            this.page.last = this.GetTotalPage(this.dataModel.count) || 1;

            this.$nextTick().then(() => {
              this.SetPageJumpWidth();
            });
          })
          .catch((e) => e);
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },
    async DeleteAlertOrder(email) {
      console.log('Delete alert order');
      await this.$eventHub.$emit('ValidateModalStart', {
        approve: 'Yes, delete alert',
        disapprove: 'No, keep alert',
        message: 'Deletes alert order from system',
        type: 'danger',
      });

      this.$eventHub.$on('ValidateModalStop', (approve) => {
        this.$eventHub.$off('ValidateModalStop');

        if (!approve) return;

        this.loading = true;
        try {
          BPA.api
            .DeleteAlertOrder(email)
            .then((response) => {
              return BPA.api.response({ response });
            })
            .then((response) => {
              if (response.status == 204) {
                this.$eventHub.$emit('ShowMessages', {
                  message: 'Alert order successfully deleted',
                  type: 'success',
                  hide: 4000,
                });
                this.GetOrdersBySearch();
              } else {
                this.$eventHub.$emit('ShowMessages', {
                  message: 'Alert order deletion failed',
                  type: 'error',
                  hide: 4000,
                });
              }
            });
        } catch (e) {
          console.log(e);
        } finally {
          this.loading = false;
        }
      });
    },
    PageController(next_page) {
      let load_page;
      const page = {};
      document.querySelectorAll('.page-turn').forEach((arrow) => {
        if (arrow)
          page[arrow.classList.contains('prev') ? 'prev' : 'next'] = arrow;
      });
      if (next_page) {
        if (this.page.current != this.page.last) {
          this.page.current++;
          load_page = true;
          if (this.page.current == this.page.last) {
            page.next.classList.add('disabled');
          }
          page.prev.classList.remove('disabled');
        }
      } else {
        if (this.page.current > 1) {
          this.page.current--;
          load_page = true;
          if (this.page.current == 1) {
            page.prev.classList.add('disabled');
          }
          page.next.classList.remove('disabled');
        }
      }
      if (load_page) {
        this.QueryLists();
      }
    },
    PageNavigator(e) {
      const page = {
        event: e.type,
        input: e.target,
        last: Number(e.target.max),
        first: Number(e.target.min),
        number: Number(e.target.value),
      };
      const within_limits = (n) => {
        return n >= page.first && n <= page.last;
      };
      const set_page_number = (n) => {
        this.page.number = n || page.number;
        page.number = Number(this.page.number);
      };
      document.querySelectorAll('.page-turn').forEach((arrow) => {
        if (arrow)
          page[arrow.classList.contains('prev') ? 'prev' : 'next'] = arrow;
      });
      set_page_number();
      if (page.event == 'keydown') {
        if (e.key == 'Enter') {
          page.input.blur();
        }
      }
      if (page.event == 'blur') {
        ['prev', 'next'].map((arrow) => {
          page[arrow] && page[arrow].classList.remove('disabled');
        });
        if (page.number <= page.first) {
          set_page_number(page.first);
          page.input.value = page.first;
          page.prev.classList.add('disabled');
          if (page.last == page.first) {
            page.next.classList.add('disabled');
          }
        } else if (page.number >= page.last) {
          set_page_number(page.last);
          page.input.value = page.last;
          page.next.classList.add('disabled');
          if (page.first == page.last) {
            page.prev.classList.add('disabled');
          }
        }
        if (within_limits(page.number) && page.number != this.page.current) {
          this.page.current = page.number;
          this.QueryLists();
        }
      }
    },
    SetPageJumpWidth() {
      const current = document.querySelector('.page-number-current');
      const last = document.querySelector('.page-number-last');
      if (current && last) {
        const rect = last.getBoundingClientRect();
        const input = current.querySelector('input');
        if (rect && rect.width) {
          current.style.setProperty('width', rect.width + 'px');
        } else {
          current.style.removeProperty('width');
        }
        if (input) {
          input.dispatchEvent(new Event('blur'));
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/assets/style/variables/icons.scss';
@import 'src/assets/style/variables/colors.scss';
@import 'src/assets/style/variables/fonts.scss';
.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 500px;
}
.title {
  font-weight: 600;
  color: $lightFont;
  padding-bottom: 2px;
  padding-top: 4px;
}
.grid-search {
  width: 60%;
  position: relative;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 30px;
  background-color: $white;

  input:not(.vs__search):not([type='checkbox']) {
    width: 60%;
    border: none;
    color: $textFont;
    padding-left: 35px;
    box-sizing: border-box;
    outline: none;
    font-size: $f-l;

    &::placeholder {
      font-size: $f-l;
      color: darken($lightGrey, 20%);
    }

    &:not(.vs__search) {
      height: 35px;
      border-bottom: 2px solid $lightGrey;
    }
  }

  &:after {
    content: ' ';
    width: 30px;
    height: 30px;
    display: inline-block;
    background-image: url('../../../../assets/images/icons/search.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 70%;
    position: absolute;
    bottom: 5px;
    left: 0px;
  }
}
.grid-pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: $f-xl;
  line-height: 24px;
  user-select: none;
  bottom: 0;
  padding: 10px;
  position: sticky;
  margin-bottom: -30px;
  background-color: $white;
  box-shadow: 0px -2px 0px 0px lightgrey;
  .current-page {
    margin-left: 10px;
    margin-right: 10px;
    line-height: 0px;
    top: 4px;
    position: relative;
  }

  .arrow {
    font-size: $f-xxxl;
    text-decoration: none;
    color: $textFont;

    > span {
      display: flex;
      justify-content: center;
      width: 20px;
    }
  }
}
.page-navigation {
  height: 24px;
  display: flex;
  position: sticky;
  align-items: center;
  justify-content: center;

  .page {
    &-turn {
      width: 24px;
      height: 24px;
      display: block;
      flex-shrink: 0;
      cursor: pointer;
      overflow: hidden;
      position: relative;
      border-radius: 50%;
      background-color: $blue;

      &:after {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        content: '';
        display: block;
        position: absolute;
        mask-size: 35%;
        mask-repeat: no-repeat;
        mask-position: center;
        background-color: $white;
      }
      &.prev {
        &:after {
          mask-image: $chevron-left;
        }
      }

      &.next {
        &:after {
          mask-image: $chevron-right;
        }
      }

      &.disabled {
        cursor: default;
        background-color: lightgrey;

        &:after {
          background-color: $textFont;
        }
      }
    }

    &-number {
      height: 24px;
      display: flex;
      flex-shrink: 0;
      padding: 0 20px;
      align-items: center;
      justify-content: center;

      &-current input,
      &-current input ~ .placeholder,
      &-last {
        color: inherit;
        font-size: inherit;
        font-family: inherit;
      }

      &-current {
        height: 24px;
        text-align: center;
        position: relative;

        input {
          border: 0;
          width: 100%;
          height: 24px;
          outline: none;
          appearance: none;
          text-align: inherit;
          color: transparent;
          margin-bottom: -2px;
          position: relative;
          -moz-appearance: textfield;
          border-bottom: 2px solid $lightGrey;
          transition: border 0.075s ease-in-out;

          &:focus {
            color: $textFont;
            border-color: $blue;

            & ~ .placeholder {
              color: transparent;
            }
          }

          &::placeholder {
            color: $lightGrey;
          }

          &::-webkit-outer-spin-button,
          &::-webkit-inner-spin-button {
            appearance: none;
            margin: 0;
          }
        }

        .placeholder {
          right: 0px;
          width: 100%;
          height: 24px;
          cursor: text;
          text-align: inherit;
          user-select: none;
          position: absolute;
          pointer-events: none;
        }
      }

      &-separator {
        width: 30px;
        text-align: center;
        flex-shrink: 0;
      }
    }
  }
}
.sku:not(:first-of-type)::after {
  content: '-';
}
.sku-table {
  width: 40%;
}
</style>
