<template>
  <div class="settings settings-company-config-view">
    <p class="title">{{ $t('Company') }}</p>
    <div class="flex row">
      <div class="flex column" style="width: 200px;">
        <div class="settings__group">
          <div class="label">
            <span class="label-text">{{ $t('Logo') }}</span>
            <ImageUpload name="logo" :placeholder="$t('Upload')" :image="logoUrl" :disabled="readonly" @change="UpdateCompanyLogo" />
          </div>
        </div>
        <div class="settings__group" style="margin-top: 10px;">
          <div class="label">
            <span class="label-text">{{ $t('Name') }}</span>
            <input type="text" name="company-name" :value="companyData.company_name" :disabled="readonly" @input="UpdateCompanyName" @keyup.enter="$event.target.blur()" spellcheck="false">
          </div>
        </div>
        <div class="settings__group" style="margin-top: 10px;">
          <div class="label">
            <span class="label-text">{{ $t('Homepage') }}</span>
            <input type="text" name="homepage" :value="companyHomepage" :disabled="readonly" @input="UpdateCompanyHomepage" @keyup.enter="$event.target.blur()" spellcheck="false">
          </div>
        </div>
        <div class="settings__group" style="margin-top: 10px;">
          <div class="label">
            <span class="label-text">{{ $t('Home country') }}</span>
            <v-select name="locale" :value="companyHomeCountry" @input="UpdateCompanyHomeCountry" :options="Alphabetize(countries.map(o => {o.label = CountryName(o.code); return o;}), 'label')" :disabled="readonly">
              <template v-slot:selected-option="option">
                <div class="truncate">
                  <Flag :code="option.code" size="small" type="country" />
                  <span>{{ option.label }}</span>
                </div>
              </template>
              <template slot="option" slot-scope="option">
                <div class="truncate">
                  <Flag :code="option.code" size="small" type="country" />
                  <span>{{ option.label }}</span>
                </div>
              </template>
            </v-select>
          </div>
        </div>
        <div class="settings__group" style="margin-top: 10px;">
          <div class="label">
            <span class="label-text">{{ $t('VAT number') }}</span>
            <input type="text" name="cvr" :value="companyData.cvr" :disabled="readonly" @change="UpdateCompanyCvr" @keyup.enter="$event.target.blur()" spellcheck="false">
          </div>
        </div>
        <div class="settings__group" style="margin-top: 10px;">
          <div class="label">
            <span class="label-text">{{ $t('Next employee number') }}</span>
            <input type="number" class="v-wrapper-input" name="next-employee-number" :disabled="readonly" :value="companyNextEmployeeNo" @change="UpdateNextEmployeeNumber" @keyup.enter="$event.target.blur()" spellcheck="false">
          </div>
        </div>
        <div class="settings__group" style="margin-top: 10px;">
          <div class="label v-wrapper border">
            <span class="label-text">{{ $t('Auto punch-out time') }}</span>
            <input type="time" class="v-wrapper-input" name="auto-punch-out-time" :diabled="readonly" :value="companyAutoPunchOutTime.ltz" @change="UpdateAutoPunchOutTime" @keyup.enter="$event.target.blur()">
          </div>
        </div>
      </div>
      <div class="flex column border-bottom">
        <div class="flex row">
          <div class="flex column border-left">
            <h4>{{ $t('Sender') }}</h4>
            <div class="settings__group">
              <div class="label">
                <span class="label-text">{{ $t('Name') }}</span>
                <input type="text" name="sender-name" :value="companyData.invoicing_abroad.sender.name" :disabled="readonly" @input="UpdateCompanyInvoicingAbroad" @keyup.enter="$event.target.blur()" spellcheck="false">
              </div>
              <div class="label">
                <span class="label-text">{{ $t('Address') }}</span>
                <input type="text" name="sender-address" :value="companyData.invoicing_abroad.sender.address" :disabled="readonly" @input="UpdateCompanyInvoicingAbroad" @keyup.enter="$event.target.blur()" spellcheck="false">
              </div>
              <div class="label">
                <span class="label-text">{{ $t('Postal code') }}</span>
                <input type="text" name="sender-zipcode" :value="companyData.invoicing_abroad.sender.zipcode" :disabled="readonly" @input="UpdateCompanyInvoicingAbroad" @keyup.enter="$event.target.blur()" spellcheck="false">
              </div>
              <div class="label">
                <span class="label-text">{{ $t('City') }}</span>
                <input type="text" name="sender-city" :value="companyData.invoicing_abroad.sender.city" :disabled="readonly" @input="UpdateCompanyInvoicingAbroad" @keyup.enter="$event.target.blur()" spellcheck="false">
              </div>
              <div class="label">
                <span class="label-text">{{ $t('Country') }}</span>
                <input type="text" name="sender-country" :value="companyData.invoicing_abroad.sender.country" :disabled="readonly" @input="UpdateCompanyInvoicingAbroad" @keyup.enter="$event.target.blur()" spellcheck="false">
              </div>
              <div class="label">
                <span class="label-text">{{ $t('Phone number') }}</span>
                <input type="text" name="sender-phone" :value="companyData.invoicing_abroad.sender.phone" :disabled="readonly" @input="UpdateCompanyInvoicingAbroad" @keyup.enter="$event.target.blur()" spellcheck="false">
              </div>
              <div class="label">
                <span class="label-text">{{ $t('Fax number') }}</span>
                <input type="text" name="sender-fax" :value="companyData.invoicing_abroad.sender.fax" :disabled="readonly" @input="UpdateCompanyInvoicingAbroad" @keyup.enter="$event.target.blur()" spellcheck="false">
              </div>
            </div>
          </div>
          <div class="flex column border-left">
            <h4>{{ $t('Invoicing') }}</h4>
            <div class="settings__group">
              <div class="label">
                <span class="label-text">{{ $t('Name') }}</span>
                <input type="text" name="invoicing_address-name" :value="companyData.invoicing_abroad.invoicing_address.name" :disabled="readonly" @input="UpdateCompanyInvoicingAbroad" @keyup.enter="$event.target.blur()" spellcheck="false">
              </div>
              <div class="label">
                <span class="label-text">{{ $t('Address') }}</span>
                <input type="text" name="invoicing_address-address" :value="companyData.invoicing_abroad.invoicing_address.address" :disabled="readonly" @input="UpdateCompanyInvoicingAbroad" @keyup.enter="$event.target.blur()" spellcheck="false">
              </div>
              <div class="label">
                <span class="label-text">{{ $t('Postal code') }}</span>
                <input type="text" name="invoicing_address-zipcode" :value="companyData.invoicing_abroad.invoicing_address.zipcode" :disabled="readonly" @input="UpdateCompanyInvoicingAbroad" @keyup.enter="$event.target.blur()" spellcheck="false">
              </div>
              <div class="label">
                <span class="label-text">{{ $t('City') }}</span>
                <input type="text" name="invoicing_address-city" :value="companyData.invoicing_abroad.invoicing_address.city" :disabled="readonly" @input="UpdateCompanyInvoicingAbroad" @keyup.enter="$event.target.blur()" spellcheck="false">
              </div>
              <div class="label">
                <span class="label-text">{{ $t('Country') }}</span>
                <input type="text" name="invoicing_address-country" :value="companyData.invoicing_abroad.invoicing_address.country" :disabled="readonly" @input="UpdateCompanyInvoicingAbroad" @keyup.enter="$event.target.blur()" spellcheck="false">
              </div>
              <div class="label">
                <span class="label-text">{{ $t('Phone number') }}</span>
                <input type="text" name="invoicing_address-phone" :value="companyData.invoicing_abroad.invoicing_address.phone" :disabled="readonly" @input="UpdateCompanyInvoicingAbroad" @keyup.enter="$event.target.blur()" spellcheck="false">
              </div>
              <div class="label">
                <span class="label-text">{{ $t('VAT number') }}</span>
                <input type="text" name="invoicing_address-org_no_vat" :value="companyData.invoicing_abroad.invoicing_address.org_no_vat" :disabled="readonly" @input="UpdateCompanyInvoicingAbroad" @keyup.enter="$event.target.blur()" spellcheck="false">
              </div>
            </div>
          </div>
          <div class="flex column border-left">
            <h4>{{ $t('Recipient') }}</h4>
            <div class="settings__group">
              <div class="label">
                <span class="label-text">{{ $t('Name') }}</span>
                <input type="text" name="recipient-name" :value="companyData.invoicing_abroad.recipient.name" :disabled="readonly" @input="UpdateCompanyInvoicingAbroad" @keyup.enter="$event.target.blur()" spellcheck="false">
              </div>
              <div class="label">
                <span class="label-text">{{ $t('Address') }}</span>
                <input type="text" name="recipient-address" :value="companyData.invoicing_abroad.recipient.address" :disabled="readonly" @input="UpdateCompanyInvoicingAbroad" @keyup.enter="$event.target.blur()" spellcheck="false">
              </div>
              <div class="label">
                <span class="label-text">{{ $t('Postal code') }}</span>
                <input type="text" name="recipient-zipcode" :value="companyData.invoicing_abroad.recipient.zipcode" :disabled="readonly" @input="UpdateCompanyInvoicingAbroad" @keyup.enter="$event.target.blur()" spellcheck="false">
              </div>
              <div class="label">
                <span class="label-text">{{ $t('City') }}</span>
                <input type="text" name="recipient-city" :value="companyData.invoicing_abroad.recipient.city" :disabled="readonly" @input="UpdateCompanyInvoicingAbroad" @keyup.enter="$event.target.blur()" spellcheck="false">
              </div>
              <div class="label">
                <span class="label-text">{{ $t('Country') }}</span>
                <input type="text" name="recipient-country" :value="companyData.invoicing_abroad.recipient.country" :disabled="readonly" @input="UpdateCompanyInvoicingAbroad" @keyup.enter="$event.target.blur()" spellcheck="false">
              </div>
              <div class="label">
                <span class="label-text">{{ $t('Phone number') }}</span>
                <input type="text" name="recipient-phone" :value="companyData.invoicing_abroad.recipient.phone" :disabled="readonly" @input="UpdateCompanyInvoicingAbroad" @keyup.enter="$event.target.blur()" spellcheck="false">
              </div>
              <div class="label">
                <span class="label-text">{{ $t('Fax number') }}</span>
                <input type="text" name="recipient-fax" :value="companyData.invoicing_abroad.recipient.fax" :disabled="readonly" @input="UpdateCompanyInvoicingAbroad" @keyup.enter="$event.target.blur()" spellcheck="false">
              </div>
              <div class="label">
                <span class="label-text">{{ $t('VAT number') }}</span>
                <input type="text" name="recipient-org_no_vat" :value="companyData.invoicing_abroad.recipient.org_no_vat" :disabled="readonly" @input="UpdateCompanyInvoicingAbroad" @keyup.enter="$event.target.blur()" spellcheck="false">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex row">
      <div class="settings__group">
        <div class="label">
          <div style="display: flex; ">
            <span class="label-text flex-label">{{ $t('Order footer') }}</span>
            <button class="button" @click.prevent="OpenTranslateFooterModal" style="min-width: 115px; margin: 0 0 10px auto;">{{ $t('Translation') }}</button>
          </div>
          <div class="order-footer">
            <textarea name="left" :value="companyData.order_footer.left.join('\n')" :disabled="readonly" :rows="footer.rows" @blur="UpdateColumnRow" spellcheck="false" />
            <textarea name="center" :value="companyData.order_footer.center.join('\n')" :disabled="readonly" :rows="footer.rows" @blur="UpdateColumnRow" spellcheck="false" />
            <textarea name="right" :value="companyData.order_footer.right.join('\n')" :disabled="readonly" :rows="footer.rows" @blur="UpdateColumnRow" spellcheck="false" />
          </div>
        </div>
      </div>
    </div>
    <div class="flex column">
      <h4 style="position: relative; transform: none; padding: 30px 0 10px;">{{ $t('CMS') }}</h4>
      <div class="settings__group">
        <div class="flex row">
          <div class="label" style="width: 33.33%;">
            <span class="label-text">{{ $t('Host') }}</span>
            <input type="text" name="cms-host" ref="cms_host" :disabled="readonly" v-model="companyCMS.cms_host" @keyup.enter="$event.target.blur()" @blur="$event.target.value = $event.target.value.replace(/\s/g, '').trim(), companyCMS.cms_host = $event.target.value" spellcheck="false">
          </div>
          <div class="label" style="width: 66.66%; margin: 0 0 0 30px;">
            <span class="label-text">{{ $t('Order link') }}</span>
            <input type="text" name="cms-order-link" :disabled="readonly" v-model="companyCMS.cms_order_link" @keyup.enter="$event.target.blur()" @blur="$event.target.value = $event.target.value.replace(/\s/g, '').trim(), companyCMS.cms_order_link = $event.target.value" spellcheck="false">
          </div>
        </div>
      </div>
    </div>
    <div class="flex column" style="margin-left: 0;">
      <h4 style="position: relative; transform: none; padding: 30px 0 10px;">{{ $t('Webhooks') }}</h4>
      <div class="settings__group">
        <div class="flex row">
          <div class="label" style="width: 100%; max-width: 300px;">
            <span class="label-text">{{ $t('eCommerce platform') }}</span>
            <v-select name="ecommerce-platform" ref="ecommerce_platform" :value="ecommerce.selected" @input="SelectEcommercePlatform" :options="ecommerce.options" :disabled="ecommerce.disabled">
              <template v-slot:selected-option="option">
                <div class="truncate">
                  <span :class="['cms-icon', option.code]">{{ option.label }}</span>
                </div>
              </template>
              <template slot="option" slot-scope="option">
                <div class="truncate">
                  <span :class="['cms-icon', option.code]">{{ option.label }}</span>
                </div>
              </template>
            </v-select>
          </div>
          <div class="label" style="width: 100%; margin: 0 0 0 30px;">
            <span class="label-text">{{ $t('Webhook secret') }}</span>
            <div class="flex row" v-if="companyWebhooks.Authorization">
              <div class="v-wrapper border" style="width: 100%;">
                <div class="v-wrapper-input" style="height: 32px; border: 0; user-select: none;">&#42;&#42;&#42;&#42;&#42;&#42;&#42;&#42;&#42;&#42;&#42;&#42;&#42;&#42;&#42;&#42;&#42;&#42;&#42;&#42;&#42;&#42;&#42;&#42;&#42;&#42;&#42;&#42;&#42;&#42;&#42;&#42;&#42;&#42;&#42;&#42;&#42;&#42;&#42;&#42;&#42;&#42;&#42;&#42;&#42;&#42;&#42;</div>
              </div>
              <button class="button red" style="min-width: 115px; margin-left: 30px; flex-shrink: 0;" @click="RevokeWebhookAuthKey">{{ $t('Revoke') }}</button>
            </div>
            <input v-else type="text" name="url-create-order-comments" :disabled="readonly" v-model="webhookAuthorization" @keyup.enter="$event.target.blur(), webhookAuthorization = $event.target.value.replace(/\s/g, '').trim()" spellcheck="false">
          </div>
        </div>
        <div class="label" style="margin-top: 10px;">
          <span class="label-text">{{ $t('CMS order') }}</span>
          <input type="text" name="url-cms-order" :disabled="readonly" v-model="companyWebhooks.UrlCmsOrder" @keyup.enter="$event.target.blur(), companyWebhooks.UrlCmsOrder = $event.target.value.replace(/\s/g, '').trim()" spellcheck="false">
        </div>
        <div class="label">
          <span class="label-text">{{ $t('Create order comments') }}</span>
          <input type="text" name="url-create-order-comments" :disabled="readonly" v-model="companyWebhooks.UrlCreateOrderComments" @keyup.enter="$event.target.blur(), companyWebhooks.UrlCreateOrderComments = $event.target.value.replace(/\s/g, '').trim()" spellcheck="false">
        </div>
        <div class="label">
          <span class="label-text">{{ $t('Ensure order') }}</span>
          <input type="text" name="url-ensure-order" :disabled="readonly" v-model="companyWebhooks.UrlEnsureOrder" @keyup.enter="$event.target.blur(), companyWebhooks.UrlEnsureOrder = $event.target.value.replace(/\s/g, '').trim()" spellcheck="false">
        </div>
        <div class="label">
          <span class="label-text">{{ $t('Pack order') }}</span>
          <input type="text" name="url-pack-order" :disabled="readonly" v-model="companyWebhooks.UrlPackOrder" @keyup.enter="$event.target.blur(), companyWebhooks.UrlPackOrder = $event.target.value.replace(/\s/g, '').trim()" spellcheck="false">
        </div>
        <div class="label">
          <span class="label-text">{{ $t('Refund CMS order') }}</span>
          <input type="text" name="url-refund-cms-order" :disabled="readonly" v-model="companyWebhooks.UrlRefundCmsOrder" @keyup.enter="$event.target.blur(), companyWebhooks.UrlRefundCmsOrder = $event.target.value.replace(/\s/g, '').trim()" spellcheck="false">
        </div>
        <div class="label">
          <span class="label-text">{{ $t('Status histories') }}</span>
          <input type="text" name="url-status-histories" :disabled="readonly" v-model="companyWebhooks.UrlStatusHistories" @keyup.enter="$event.target.blur(), companyWebhooks.UrlStatusHistories = $event.target.value.replace(/\s/g, '').trim()" spellcheck="false">
        </div>
      </div>
    </div>
    <div class="flex column" style="margin-left: 0;">
      <h4 style="position: relative; transform: none; padding: 30px 0 10px;">{{ $t('API') }}</h4>
      <div class="settings__group">
        <div class="label">
          <span class="label-text">{{ $t('Google Maps key') }}</span>
          <input type="text" name="google-maps-api-key" :disabled="readonly" v-model="companyAPI.google_maps_api_key" @keyup.enter="$event.target.blur()" spellcheck="false">
        </div>
        <div class="label">
          <span class="label-text">{{ $t('Parcel shop key') }}</span>
          <input type="text" name="parcel-shops-api-key" :disabled="readonly" v-model="companyAPI.parcel_shops_api_key" @keyup.enter="$event.target.blur()" spellcheck="false">
        </div>
        <div class="flex row" style="margin-top: 10px;">
          <div class="label" style="width: 100%;">
            <span class="label-text">{{ $t('SMS Gateway key') }}</span>
            <input type="text" name="gateway_api_key" :disabled="readonly" v-model="companyAPI.gateway_api_key" @keyup.enter="$event.target.blur()" spellcheck="false">
          </div>
          <div class="label" style="width: 100%; margin: 0 0 0 30px;">
            <span class="label-text">{{ $t('SMS Gateway secret') }}</span>
            <input type="text" name="gateway_api_secret" :disabled="readonly" v-model="companyAPI.gateway_api_secret" @keyup.enter="$event.target.blur()" spellcheck="false">
          </div>
        </div>
        <div class="label" style="margin-top: 10px;">
          <span class="label-text">{{ $t('Customer information authorization') }}</span>
          <input type="text" name="customer-information-authorization" :disabled="readonly" v-model="companyAPI.customer_information_authorization" @keyup.enter="$event.target.blur()" spellcheck="false">
        </div>
      </div>
    </div>
    <div class="flex row sticky-footer">
      <div class="flex column" style="width: auto; margin-left: auto;">
        <button class="button green" style="min-width: 115px;" @click.prevent="ConfirmSaveChanges" :disabled="readonly">{{ $t('Save') }}</button>
      </div>
    </div>

    <Modal modal="translate" :value="modal.translate.open" :title="$t('Translation')">
      <div id="translate-footer">
        <div class="modal-header">
          <div class="locale-selector">
            <v-select name="locale" @input="GetTranslation" v-model="modal.translate.locale.selected" :options="Alphabetize(modal.translate.locale.options.map(option => {option.label = `${LanguageName(option.language)} (${CountryName(option.country)})`; return option}), 'label')" :clearable="false">
              <template v-slot:selected-option="option">
                <div class="truncate">
                  <Flag :code="option.code" size="small" type="language" />
                  <span>{{ option.label }}</span>
                </div>
              </template>
              <template slot="option" slot-scope="option">
                <div class="truncate">
                  <Flag :code="option.code" size="small" type="language" />
                  <span>{{ option.label }}</span>
                </div>
              </template>
            </v-select>
          </div>
        </div>
        <div class="modal-content">
          <div class="homepage-translation">
            <div class="label">
              <span class="label-text">
                {{ $t('Original') }}
              </span>
              <input type="text" name="homepage-original" :value="homepage.original" readonly :placeholder="$t('Homepage')">
            </div>
            <div class="label">
              <span class="label-text">
                {{ $t('Translation') }}
              </span>
              <input type="text" name="homepage-translate" :value="homepage.translate" :disabled="readonly" :placeholder="$t('Homepage')" @input="UpdateTranslateCompanyHomepage" @keyup.enter="$event.target.blur()" spellcheck="false">
            </div>
          </div>
          <div class="footer-original">
            <div class="flex row">
              <div class="settings__group">
                <div class="label">
                  <span class="label-text">
                    {{ $t('Original') }}
                  </span>
                  <div class="order-footer">
                    <textarea name="left" :value="footer.original.left.join('\n')" readonly />
                    <textarea name="center" :value="footer.original.center.join('\n')" readonly />
                    <textarea name="right" :value="footer.original.right.join('\n')" readonly />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="footer-translated">
            <div class="flex row">
              <div class="settings__group">
                <div class="label">
                  <div class="label-text flex-label">
                    {{ $t('Translation') }}
                  </div>
                  <div class="order-footer">
                    <textarea name="left" :value="footer.translate.left.join('\n')" :disabled="readonly" @blur="UpdateTranslateColumnRow" spellcheck="false" />
                    <textarea name="center" :value="footer.translate.center.join('\n')" :disabled="readonly" @blur="UpdateTranslateColumnRow" spellcheck="false" />
                    <textarea name="right" :value="footer.translate.right.join('\n')" :disabled="readonly" @blur="UpdateTranslateColumnRow" spellcheck="false" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-bottom">
          <div class="footer-content">
            <button class="button submit done" :disabled="readonly" @click.prevent="SaveTranslation">{{ $t('Save') }}</button>
          </div>
        </div>
      </div>
    </Modal>
    
    <div class="loading" v-if="loading">
      <div class="loading-element">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
    
  </div>
</template>

<script>
  import ImageUpload from '@/components/snippets/ImageUpload';
  import Modal from '@/components/snippets/Modal';
  import Flag from '@/components/snippets/Flag';
  import { Tool } from '@/helpers/Tool';
  import { BPA } from '@/helpers/BPA';

  export default {
    name: 'SettingsCompanyConfigView',
    components: {
      ImageUpload,
      Modal,
      Flag
    },
    data() {
      return {
        loading: true,
        logoUrl: null,
        companySettings: {},
        companyHomepage: '',
        companyHomeCountry: '',
        companyNextEmployeeNo: '', 
        companyAutoPunchOutTime: {utc: '', ltz: ''}, 
        webhookAuthorization: '', 
        countries: [],
        locales: [],
        companyLogo: {
          file_name: '',
          opt: {},
          data: ''
        },
        companyData: {
          cvr: '',
          company_name: '',
          order_footer: {},
          invoicing_abroad: {
            sender: {
              name: '',
              address: '',
              zipcode: '',
              city: '',
              country: '',
              phone: '',
              fax: ''
            },
            invoicing_address: {
              name: '',
              address: '',
              zipcode: '',
              city: '',
              country: '',
              phone: '',
              org_no_vat: ''
            },
            recipient: {
              name: '',
              address: '',
              zipcode: '',
              city: '',
              country: '',
              phone: '',
              fax: '',
              org_no_vat: ''
            }
          }
        },
        companyCMS: {
          cms_host: '',
          cms_order_link: ''
        },
        companyAPI: {
          gateway_api_key: '',
          gateway_api_secret: '',
          google_maps_api_key: '',
          parcel_shops_api_key: ''
        }, 
        companyWebhooks: {
          Authorization: false, 
          UrlCmsOrder: '', 
          UrlCreateOrderComments: '', 
          UrlEnsureOrder: '', 
          UrlPackOrder: '', 
          UrlRefundCmsOrder: '', 
          UrlStatusHistories: '', 
          data: {}
        }, 
        ecommerce: {
          disabled: false,
          selected: '',
          options: [
            {
              code: 'magento',
              label: 'Magento',
              path: '/rest/V1/api/packship', 
              urls: {
                UrlCmsOrder: '/order/{order_id}', 
                UrlCreateOrderComments: '/order/comments', 
                UrlEnsureOrder: '/order/ensure/{order_id}', 
                UrlPackOrder: '/order/pack/{order_id}', 
                UrlRefundCmsOrder: '/order/refund/{order_id}', 
                UrlStatusHistories: '/order/comments/{order_id}'
              }
            }
          ]
        }, 
        footer: {
          rows: 3,
          object: {
            center: [],
            left: [],
            right: []
          },
          order: {},
          original: {},
          translate: {},
          initital: {}
        },
        homepage: {
          original: '',
          translate: '',
          initital: ''
        },
        modal: {
          translate: {
            open: false,
            laguage: '',
            locale: {
              options: [],
              selected: {}
            }
          }
        },
        readonly: !BPA.permissions(['admin', 'settings_admin']).length
      }
    },
    computed: {
      textarea() {
        const textareas = {};
        document.querySelectorAll('.order-footer textarea').forEach(textarea => {
          textareas[textarea.name] = textarea;
        });
        return textareas;
      },
      locale() {
        return this.$i18n.locale;
      }
    },
    watch: {
      locale() {
        this.SetModalLanguageOptions();
      }
    },
    async created() {
      this.$eventHub.$on('CloseModal', (modal_name) => {
        if (this.modal[modal_name]) this.modal[modal_name].open = false;
      });
      this.companyData.order_footer = this.CloneObject(this.footer.object);
      ['order', 'original', 'translate'].map(object => {
        this.footer[object] = this.CloneObject(this.footer.object);
      });
      this.logoUrl = this.GetCompanyLogo();
      this.countries = await this.GetCountryOptions();
      this.locales = await this.GetLocaleOptions();
      await this.GetCompanyHomepage();
      await this.GetCompanyData();
      await this.GetCompanyCmsLink();
      await this.GetCompanyApiKeys();
      if (!this.readonly) {
        await this.GetCompanySettings();
        await this.GetCompanyWebhooks();
      }
      this.loading = false;
    },
    methods: {
      CloneObject(object) {
        return Tool.CloneObject(object);
      },
      Capitalize(string) {
        if (string) return Tool.Capitalize(string);
      },
      CountryName(country_code, locale) {
        return Tool.CountryName(country_code, locale);
      },
      LanguageName(country_code, locale) {
        return Tool.LanguageName(country_code, locale);
      },
      Alphabetize(list, prop) {
        return Tool.Alphabetize(list, prop);
      },
      GetCompanyLogo() {
        return BPA.util.GetCompanyLogo(BPA.util.GetCompany()) + '?' + new Date().getTime();
      },
      UpdateCompanyLogo(logoData) {
        this.companyLogo = logoData;
      },
      UpdateCompanyName(e) {
        this.companyData.company_name = String(e.target.value).replace(/\s+'/g, '').trim();
      },
      UpdateCompanyCvr(e) {
        this.companyData.cvr = String(e.target.value).replace(/\D|s/g, '').trim();
      },
      UpdateNextEmployeeNumber(e) {
        let value = e.target.value;
        if (value <= 0) value = 1;
        this.companyNextEmployeeNo = String(value).replace(/\D|s/g, '').trim() || 1;
        e.target.value = this.companyNextEmployeeNo;
      },
      UpdateAutoPunchOutTime(e) {
        let time = {
          utc: this.companyAutoPunchOutTime.utc,
          ltz: e.target.value || ''
        };
        if (time.ltz) {
          let now = new Date();
          let iso = now.toISOString();
          let str = iso.split('T')[0] + ' ' + time.ltz + ':00';
          time.utc = Tool.DateToUTC(str).split(' ')[1].replace(/:00$/, '');
        } else {
          time = {utc: '', ltz: ''};
        }
        for (let type in time) this.companyAutoPunchOutTime[type] = time[type];
      },
      UpdateCompanyHomepage(e) {
        this.companyHomepage = String(e.target.value).replace(/\s+'/g, '').trim();
      },
      UpdateCompanyHomeCountry(option) {
        this.companyHomeCountry = option || '';
      },
      UpdateTranslateCompanyHomepage(e) {
        this.homepage.translate = String(e.target.value).replace(/\s+'/g, '').trim();
      },
      UpdateCompanyInvoicingAbroad(e) {
        this.companyData.invoicing_abroad[e.target.name.split('-')[0]][e.target.name.split('-')[1]] = e.target.value.trim();
      },
      UpdateColumnRow(e) {
        this.companyData.order_footer[e.target.name] = e.target.value.split('\n');
      },
      UpdateTranslateColumnRow(e) {
        this.footer.translate[e.target.name] = e.target.value.split('\n');
      },
      SelectEcommercePlatform(option) {
        this.ecommerce.selected = option || '';
        let select = this.$refs.ecommerce_platform;
        let cms_host = this.companyCMS.cms_host;
        let webhook = this.companyWebhooks;
        if (option && !cms_host) {
          setTimeout(() => {
            select.$refs.search.blur();
            this.$refs.cms_host.focus();
            this.$eventHub.$emit('ShowMessages', {
              message: 'The CMS host URL needs to be filled out before selecting an eCommerce platform',
              type: 'warning',
              hide: 3000
            });
          }, 100);
          return this.ecommerce.selected = '';
        }
        for (let key in webhook) {
          if (option && key in option.urls) {
            webhook[key] = cms_host + option.path + option.urls[key];
          } else if (/^url/i.test(key)) webhook[key] = '';
        }
      },
      RevokeWebhookAuthKey() {
        this.webhookAuthorization = '';
        this.companyWebhooks.Authorization = false;
      }, 
      async SetModalLanguageOptions() {
        let locale = this.modal.translate.locale;
        locale.options = await this.GetLocaleOptions();
        let selected = locale.options.find(option => option.code == this.locale);
        if (selected) locale.selected = selected;
        //console.log(this.CloneObject(locale.options))
      },
      async GetCountryOptions() {
        return await new Promise(resolve => {
          let options = [];
          for (let option of BPA.api.Countries('GET')) {
            option.code = option.iso_code_2;
            option.label = option.name;
            for (let key of Object.keys(option)) {
              if (/name|iso_code/.test(key)) {
                delete option[key];
              }
            }
            options.push(option);
          }
          resolve(options);
        }).catch(e => e);
      },
      async GetLocaleOptions() {
        return await new Promise(async resolve => {
          let options = [];
          for (let option of BPA.locale('options', true)) {
            let option_code = option.code.split('_');
            option.country = option_code[1];
            option.language = option_code[0];
            if (/no/.test(option.code)) {
              option.code = 'nb_NO';
            }
            options.push(option);
          }
          options = [];
          let storage = BPA.util.storage;
          let languages = storage.get('languages');
          let company = BPA.util.GetCompany();
          if (!Object.keys(languages).length) {
            let locales = await this.GetLanguageOrderCount();
            languages[company] = [];
            for (let locale in locales) {
              let option = {code: locale};
              if (/nb/.test(locale)) {
                locale = locale.replace('nb', 'no');
              }
              let code = locale.split('_');
              option.label = this.LanguageName(code[0], 'en');
              option.language = code[0];
              option.country = code[1];
              options.push(option);
              languages[company].push(option);
            }
            storage.set('languages', languages);
          }
          options = languages[company];
          resolve(options);
        }).catch(e => e);
      },
      async GetLanguageOrderCount(company_code) {
        return await BPA.api.GetLanguageOrderCount(company_code).then(response => {
          return BPA.api.response({response, return: 'json'});
        }).then(response => response.result || {}).catch(e => e);
      },
      async GetCompanyHomepage(locale) {
        return await new Promise((resolve, reject) => {
          BPA.api.GetCompanyHomepage(locale).then(response => {
            return BPA.api.response({response, return: 'json'});
          }).then(response => {
            if (!response.ok) return reject();
            let json = response.result || {};
            let homepage = json.homepage || '';
            if (!locale) {
              this.companyHomepage = homepage;
              this.homepage.original = homepage;
              return resolve(homepage);
            }
            this.homepage.translate = homepage;
            this.homepage.initital = homepage;
            resolve(homepage);
          }).catch(reject);
        }).catch(e => e);
      },
      async GetCompanySettings() {
        await BPA.api.GetCompanySettings().then(response => {
          return BPA.api.response({response, return: 'json'});
        }).then(response => {
          if (!response.ok) return;
          let json = response.result || {};
          let country_id = json.CompanyHomeCounty;
          this.companyCMS.cms_host = json.CmsHost || '';
          this.companyHomeCountry = this.countries.find(option => option.id == country_id) || '';
          this.companyNextEmployeeNo = json.NextEmployeeNo || '';
          this.companyAutoPunchOutTime = {utc: json.AutoPunchOutTime || '', ltz: ''};
          if (json.AutoPunchOutTime) {
            let now = new Date();
            let iso = now.toISOString();
            let str = iso.split('T')[0] + ' ' + json.AutoPunchOutTime + ':00';
            this.companyAutoPunchOutTime.ltz = Tool.DateToISO(str, true).split(' ')[1].replace(/:00$/, '');
          }
          this.companySettings = this.CloneObject(json);
        }).catch(e => e);
      },
      async UpdateCompanySettings(request) {
        await BPA.api.UpdateCompanySettings(request).then(response => {
          return BPA.api.response({response, return: 'json'});
        }).then(response => {
          console.log(this.CloneObject(response))
        }).catch(e => e);
      },
      async DeleteCompanySettings(request) {
        await BPA.api.DeleteCompanySettings(request).then(response => {
          return BPA.api.response({response, return: 'json'});
        }).then(response => {
          console.log(this.CloneObject(response))
        }).catch(e => e);
      },
      async GetCompanyWebhooks() {
        await BPA.api.GetCompanyWebhooks().then(response => {
          return BPA.api.response({response, return: 'json'});
        }).then(response => {
          if (!response.ok) return;
          let json = response.result || {};
          let webhooks = this.companyWebhooks;
          //json.Authorization = false;
          let ecommerce = this.ecommerce;
          let options = ecommerce.options;
          let platform = null;          
          for (let key in json) {
            webhooks[key] = json[key] || '';
            let option = /^url/i.test(key) && options.find(option => {
              return new RegExp(option.path).test(webhooks[key]);
            });
            if (option && !platform) platform = option;
          }
          if (platform) ecommerce.selected = platform;
          webhooks.data = this.CloneObject(webhooks);
        }).catch(e => e);
      },
      async UpdateCompanyWebhooks(request) {
        await BPA.api.UpdateCompanyWebhooks(request).then(response => {
          return BPA.api.response({response, return: 'json'});
        }).then(response => {
          console.log(this.CloneObject(response))
        }).catch(e => e);
      },
      async DeleteCompanyWebhooks(request) {
        await BPA.api.DeleteCompanyWebhooks(request).then(response => {
          return BPA.api.response({response, return: 'json'});
        }).then(response => {
          console.log(this.CloneObject(response))
        }).catch(e => e);
      },
      async GetCompanyData() {
        await BPA.api.GetCompanyData().then(response => {
          return BPA.api.response({response, return: 'json'});
        }).then(response => {
          if (!response.ok) return;
          let json = response.result || {};
          for (let prop in json) {
            if (json[prop] != null) {
              if (prop.includes('footer')) {
                for (let key in json[prop]) {
                  let rows = json[prop][key] || [];
                  if (rows.length > this.footer.rows) {
                    this.footer.rows = rows.length;
                  }
                }
              }
              this.companyData[prop] = json[prop];
            }
          }
          this.footer.original = this.CloneObject(json.order_footer);
        }).catch(e => e);
      },
      async GetCompanyCmsLink() {
        await BPA.api.GetCompanyCmsLink().then(response => {
          return BPA.api.response({response, return: 'json'});
        }).then(response => {
          if (!response.ok) return;
          let json = response.result || {};
          this.companyCMS.cms_order_link = json.cms_order_link;
        }).catch(e => e);
      },
      async GetCompanyApiKeys() {
        await BPA.api.GetCompanyApiKeys().then(response => {
          return BPA.api.response({response, return: 'json'});
        }).then(response => {
          if (!response.ok) return;
          this.companyAPI = response.result;
        }).catch(e => e);
      },
      IsValidURL(str) {
        let a = document.createElement('a'); a.href = str;
        return a.host && a.host != window.location.host;
      },
      ConfirmSaveChanges() {
        if (this.readonly) return;
        let cms_order_link = this.companyCMS.cms_order_link;
        let webhook_keys = ['UrlStatusHistories', 'UrlEnsureOrder', 'UrlPackOrder', 'UrlCmsOrder', 'UrlRefundCmsOrder'];
        let webhooks = this.companyWebhooks;
        for (let key of webhook_keys) {
          if (webhooks[key] && !/{order_id}/.test(webhooks[key])) {
            return this.$eventHub.$emit('ShowMessages', {
              message: `Missing {order_id} in ${key} webhook`,
              type: 'error',
              hide: 2000
            });
          }
        }
        if (cms_order_link && !/{order_id}/.test(cms_order_link)) {
          return this.$eventHub.$emit('ShowMessages', {
            message: 'Missing {order_id} in order CMS link',
            type: 'error',
            hide: 2000
          });
        }
        this.$eventHub.$emit('ValidateModalStart', {
          approve: 'Yes, save changes',
          disapprove: 'No',
          message: 'Saves changes to company data.',
          type: 'success'
        });
        this.$eventHub.$on('ValidateModalStop', (approve) => {
          this.$eventHub.$off('ValidateModalStop');
          if (approve) this.SaveCompanyData();
        });
      },
      async SaveCompanyData() {
        const company = {};
        if (this.companyLogo.data) {
          company.logo = new Promise((resolve, reject) => {
            BPA.api.UploadCompanyLogo(this.companyLogo).then(response => {
              return BPA.api.response({response});
            }).then(response => {
              if (!response.ok) return reject();
              resolve(response);
            }).catch(reject);
          }).catch(e => e);
        }
        company.homepage = new Promise((resolve, reject) => {
          const request = {homepage: this.companyHomepage};
          BPA.api.UploadCompanyHomepage(request).then(response => {
            return BPA.api.response({response});
          }).then(response => {
            if (!response.ok) return reject();
            resolve(response);
          }).catch(reject);
        }).catch(e => e);
        company.settings = new Promise(async (resolve, reject) => {
          const settings = {
            old: this.companySettings,
            new: {
              CmsHost: this.companyCMS.cms_host, 
              CompanyHomeCounty: (this.companyHomeCountry || {}).id,
              NextEmployeeNo: Number(this.companyNextEmployeeNo || 0),
              AutoPunchOutTime: this.companyAutoPunchOutTime.utc || ''
            }
          };
          const method = {
            update: {
              action: 'Update',
              request: {}
            },
            delete: {
              action: 'Delete',
              request: []
            }
          };
          for (let key in settings.old) {
            if (settings.new[key] != settings.old[key]) {
              if (!settings.new[key] && settings.old[key]) {
                method.delete.request.push(key);
              }
              if (!settings.old[key] && settings.new[key]) {
                method.update.request[key] = settings.new[key];
              }
              if (settings.old[key] && settings.new[key] && settings.old[key] != settings.new[key]) {
                method.update.request[key] = settings.new[key];
              }
            }
          }
          for (let type in method) {
            let empty = true;
            let action = method[type].action;
            let request = method[type].request;
            if (Array.isArray(request)) {
              empty = !request.length;
            }
            if (request instanceof Object) {
              empty = !Object.keys(request).length;
            }
            if (!empty) {
              if ('NextEmployeeNo' in request) {
                request.EmployeeNo = request.NextEmployeeNo;
                delete request.NextEmployeeNo;
              }
              await BPA.api[`${action}CompanySettings`](request).then(response => {
                return BPA.api.response({response});
              }).then(response => {
                if (!response.ok) return reject();
                if (type == 'delete') {
                  for (let key of request) {
                    if (key == 'CmsHost') {
                      this.companyCMS.cms_host = '';
                    }
                    if (key == 'CompanyHomeCounty') {
                      this.companyHomeCountry = '';
                    }
                    if (key == 'EmployeeNo') {
                      this.companyNextEmployeeNo = '';
                    }
                    if (key == 'AutoPunchOutTime') {
                      this.companyAutoPunchOutTime = {utc: '', ltz: ''};
                    }
                  }
                } else {
                  for (let key in request) {
                    if (key == 'CmsHost') {
                      this.companyCMS.cms_host = request[key];
                    }
                    if (key == 'CompanyHomeCounty') {
                      this.companyHomeCountry = this.countries.find(option => option.id == request[key]);
                    }
                    if (key == 'EmployeeNo') {
                      this.companyNextEmployeeNo = request[key];
                    }
                    if (key == 'AutoPunchOutTime') {
                      this.companyAutoPunchOutTime.utc = request[key];
                    }
                  }
                }
                this.companySettings = {
                  CmsHost: this.companyCMS.cms_host || '', 
                  CompanyHomeCounty: (this.companyHomeCountry || {}).id || '', 
                  NextEmployeeNo: this.companyNextEmployeeNo || '', 
                  AutoPunchOutTime: this.companyAutoPunchOutTime.utc || ''
                }
                resolve(response);
              }).catch(reject);
            } else {
              resolve(true);
            }
          }
        }).catch(e => e);
        company.webhooks = new Promise(async (resolve, reject) => {
          const webhooks = {
            old: this.CloneObject(this.companyWebhooks.data), 
            new: this.CloneObject(this.companyWebhooks)
          };
          for (let type of ['old', 'new']) {
            delete webhooks[type].data;
          }
          const method = {
            update: {
              action: 'Update',
              request: {}
            },
            delete: {
              action: 'Delete',
              request: []
            }
          };
          for (let key in webhooks.old) {
            if (webhooks.new[key] != webhooks.old[key]) {
              if (!webhooks.new[key] && webhooks.old[key]) {
                method.delete.request.push(key);
              }
              if (!webhooks.old[key] && webhooks.new[key]) {
                method.update.request[key] = webhooks.new[key];
              }
              if (webhooks.old[key] && webhooks.new[key]) {
                method.update.request[key] = webhooks.new[key];
              }
            }
          }
          if (this.webhookAuthorization) {
            if (!webhooks.old.Authorization) {
              method.update.request.Authorization = this.webhookAuthorization;
            }
          }
          for (let type in method) {
            let empty = true;
            let action = method[type].action;
            let request = method[type].request;
            if (request instanceof Object) {
              empty = !Object.keys(request).length;
            }
            if (Array.isArray(request)) {
              empty = !request.length;
            }
            if (!empty) {
              await BPA.api[`${action}CompanyWebhooks`](request).then(response => {
                return BPA.api.response({response});
              }).then(response => {
                if (!response.ok) return reject();
                for (let key in request) {
                  if (/authorization/i.test(key)) {
                    this.webhookAuthorization = '';
                    this.companyWebhooks.Authorization = type != 'delete';
                  } else {
                    this.companyWebhooks[key] = type == 'delete' ? '' : request[key];
                  }                  
                }
                this.companyWebhooks.data = this.CloneObject(this.companyWebhooks);
                resolve(response);
              }).catch(reject);
            } else {
              resolve(true);
            }
          }
        })/*.catch(e => e)*/;
        company.data = new Promise((resolve, reject) => {
          BPA.api.UploadCompanyData(this.companyData).then(response => {
            return BPA.api.response({response});
          }).then(response => {
            if (!response.ok) return reject();
            resolve(response);
          }).catch(reject);
        }).catch(e => e);
        company.cms_link = new Promise((resolve, reject) => {
          BPA.api.UploadCompanyCmsLink(this.companyCMS).then(response => {
            return BPA.api.response({response});
          }).then(response => {
            if (!response.ok) return reject();
            resolve(response);
          }).catch(reject);
        }).catch(e => e);
        let api_keys = this.CloneObject(this.companyAPI);
        for (let key in api_keys) {
          if (api_keys[key] == null) {
            delete api_keys[key];
          } else if (api_keys[key] == '') {
            api_keys[key] = ' ';
          } else {
            api_keys[key] = String(api_keys[key]).replace(/\s+/g, ' ').trim();
          }
        }
        if (Object.keys(api_keys).length) {
          company.api_keys = new Promise((resolve, reject) => {
            BPA.api.UploadCompanyApiKeys(api_keys).then(response => {
              return BPA.api.response({response});
            }).then(response => {
              if (!response.ok) return reject();
              resolve(response);
            }).catch(reject);
          }).catch(e => e);
        }
        this.loading = true;
        await Promise.allSettled(Object.values(company)).then(promises => {
          this.loading = false;
          for (let i = 0; i < promises.length; i++) {
            if (promises[i].status != 'fulfilled') {
              const message = promises[i].reason;
              let text = Object.entries(company)[i][0].replace('_', ' ');
              text = text.charAt(0).toUpperCase() + text.substring(1);
              this.$eventHub.$emit('ShowMessages', {
                message: message + (!message.includes(' - ') ? ' - ' + text : ''),
                type: 'error',
                close: true
              });
              return;
            }
          }
          this.homepage.original = this.companyHomepage;
          this.footer.original = this.CloneObject(this.companyData.order_footer);
          this.$eventHub.$emit('ShowMessages', {
            message: 'Company data successfully updated',
            type: 'success',
            hide: 2000
          });
        });
      },
      async OpenTranslateFooterModal() {
        await this.SetModalLanguageOptions();
        this.GetTranslation(this.modal.translate.locale.selected);
      },
      async GetTranslation(option) {
        //console.log(option, option.code)
        if (!option) return;
        let homepage = await this.GetCompanyHomepage(option.code);
        let footer = await BPA.api.GetOrderFooterTranslation(option.code).then(response => {
          return BPA.api.response({response, return: 'json'});
        }).then(response => {
          let footer = response.result || {};
          if (!Object.keys(footer).length) {
            footer = this.CloneObject(this.footer.object);
          }
          this.footer.translate = footer;
          this.footer.initial = this.CloneObject(footer);
          return footer;
        }).catch(e => e);
        if (this.modal.translate.open) return;
        this.modal.translate.open = homepage && footer;
      },
      async SaveTranslation() {
        if (this.readonly) return;
        this.$eventHub.$emit('ValidateModalStart', {
          approve: 'Yes, save changes',
          disapprove: 'No',
          message: 'Saves changes to company translation.',
          type: 'success'
        });
        this.$eventHub.$on('ValidateModalStop', async (approve) => {
          this.$eventHub.$off('ValidateModalStop');
          if (!approve) return;
          this.loading = true;
          const translation = {homepage: true};
          const locale = this.modal.translate.locale.selected.code;
          if (this.homepage.initital != this.homepage.translate) {
            translation.homepage = await new Promise((resolve, reject) => {
              BPA.api.UploadCompanyHomepage({
                homepage: this.homepage.translate,
                locale: locale
              }).then(response => {
                return BPA.api.response({response});
              }).then(response => {
                if (!response.ok) return reject();
                resolve(response);
              }).catch(reject);
            }).catch(e => e);
          }
          if (JSON.stringify(this.footer.initial) != JSON.stringify(this.footer.translate)) {
            if (translation.homepage) translation.footer = await new Promise((resolve, reject) => {
              BPA.api.UploadOrderFooterTranslation({
                ...{locale: locale}, ...this.footer.translate
              }).then(response => {
                return BPA.api.response({response});
              }).then(response => {
                if (!response.ok) return reject();
                resolve(response);
              }).catch(reject);
            }).catch(e => e);
          }
          await Promise.allSettled(Object.values(translation)).then(promises => {
            this.loading = false;
            for (let i = 0; i < promises.length; i++) {
              if (promises[i].status != 'fulfilled') {
                const message = promises[i].reason;
                let text = Object.entries(translation)[i][0].replace('_', ' ');
                text = text.charAt(0).toUpperCase() + text.substring(1);
                this.$eventHub.$emit('ShowMessages', {
                  message: message + (!message.includes(' - ') ? ' - ' + text : ''),
                  type: 'error',
                  close: true
                });
                return;
              }
            }
            this.homepage.initital = this.homepage.translate;
            this.footer.initial = this.CloneObject(this.footer.translate);
            if (Object.values(translation).some(x => x instanceof Object)) {
              return this.$eventHub.$emit('ShowMessages', {
                message: 'Translation successfully updated',
                type: 'success',
                hide: 2000
              });
            }
            this.$eventHub.$emit('ShowMessages', {
              message: 'No changes to translation',
              type: 'warning',
              hide: 2000
            });
          });
        });
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '../../../assets/style/variables/icons';
  
  .title {
    font-size: 1.622rem;
    font-weight: 600;
    margin-bottom: 35px;
  }

  .image-upload {
    height: 169px;
  }

  .settings__group {
    width: 100%;
  }

  .flex {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;

    &.row {
      flex-direction: row;

      & + &.row {
        margin-top: 30px;
      }

      .column + .row {
        margin-left: 30px;
      }
    }

    &.column {
      flex-direction: column;

      & + &.column {
        margin-left: 30px;
      }

      .row + .column {
        margin-top: 30px;
      }

      &.border-left {
        padding-left: 30px;
        position: relative;

        &::before {
          top: -30px;
          left: 0;
          width: 1px;
          height: calc(100% + 97px);
          content: '';
          display: block;
          position: absolute;
          background-color: lightgrey;
        }
      }

      &.border-bottom {
        padding-bottom: 15px;
        border-bottom: 1px solid lightgrey;
      }
    }

    h4 {
      position: absolute;
      transform: translateY(-30px);
    }
  }

  .label {
    display: flex;
    flex-direction: column;
    --border: 1px solid rgba(60, 60, 60, 0.26);

    & + .label {
      margin-top: 10px;
    }

    .flex-label {
      display: flex;
      position: relative;
      align-items: flex-end;
      justify-content: space-between;

      .button {
        margin-bottom: 10px;
      }
    }

    input[type=text] {
      font-size: 1em;
      padding: 7px;
      color: #333;
      appearance: none;
      display: flex;
      background: none;
      border: var(--border);
      border-radius: 4px;
      white-space: normal;
      outline: none;
    }

    .order-footer {
      display: flex;
      overflow: hidden;
      border-radius: 4px;
      border: var(--border);

      textarea {
        width: 100%;
        resize: none;
        outline: none;
        border: none;
        font-size: 1em;
        padding: 7px;
        color: #333;
        font-family: unset;

        &:first-child {
          text-align: left;
        }
        &:not(:first-child):not(:last-child) {
          text-align: center;
        }
        &:last-child {
          text-align: right;
        }
      }

      textarea + textarea {
        border-left: var(--border);
      }
    }
  }

  .modal {
    &-header {
      .locale-selector {
        width: 33.33%;
        flex-shrink: 0;
        min-width: 200px;
        margin-bottom: 10px;
      }
    }

    &-content,
    &-bottom {
      display: flex;
      position: relative;
      flex-direction: column;
    }

    &-content {
      height: 100%;
      overflow-y: auto;

      .homepage {
        &-translation {
          width: 100%;
          display: flex;
          justify-content: space-between;

          & > * {
            flex: 1;
            margin-top: 0;

            & + * {
              margin-left: 30px;
            }
          }

          input {
            &:read-only {
              background-color: #f5f5f5;
            }

            &::placeholder {
              color: darkgray;
            }
          }
        }
      }

      .v-select {
        .flag-icon {
          float: left;
          max-height: 22px;
          margin-right: 10px;
          position: relative;
        }
      }
    }

    &-bottom {
      height: auto;
      //padding-top: 10px;

      .footer-content {
        justify-content: flex-end;
        box-shadow: none;
      }
    }
  }

  .footer {
    &-original,
    &-translated {
      height: 100%;
      margin-top: 25px;

      .flex.row {
        height: 100%;

        .label {
          height: 100%;

          .order-footer {
            height: 100%;

            textarea {
              min-height: 102px;
              max-height: 100%;

              &:read-only {
                background-color: #f5f5f5;
              }
            }
          }
        }
      }
    }
  }

  .sticky-footer {
    z-index: 2;
    bottom: 0;
    margin-top: 15px;
    margin-bottom: -15px;
    border-top: 2px solid lightgray;
    padding-top: 15px;
    padding-bottom: 15px;
    position: sticky;
    background: white;
  }

  .settings-company-config-view {
    .v-select {
      .cms-icon {
        display: flex;
        position: relative;
        align-items: center;

        &::before {
          width: 22px;
          height: 22px;
          content: '';
          flex-shrink: 0;
          margin-right: 10px;
          //border-radius: 50%;
          position: relative;
          display: inline-block;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
        }

        &.magento::before {
          background-image: $magento;
        }
      }
    }
  }
</style>