<template>
  <div class="settings settings-packing-config-view grid-wrapper">
    <p class="title" style="margin-bottom: 15px;">
      {{ $t('Packing') }}
    </p>
    <div class="tabs" style="overflow: unset;">
      <ul class="tabs__head">
        <li :class="['tabs__head--item', {active: tab.active == 'additions'}]" @click="Tab('additions'), !Tab().loaded && Search()">
          <span>{{ $t('Additions') }}</span>
        </li>
        <li :class="['tabs__head--item', {active: tab.active == 'approval'}]" @click="Tab('approval')">
          <span>{{ $t('Approval') }}</span>
        </li>
        <li v-if="!readonly" :class="['tabs__head--item', {active: tab.active == 'label_barcode'}]" @click="Tab('label_barcode')">
          <span>{{ $t('Label Barcode Pass') }}</span>
        </li>
      </ul>
      <ul class="tabs__body" style="overflow: unset;">
        <li class="tabs__body--content padding" style="padding-bottom: 30px; margin-bottom: -30px;" v-if="tab.active == 'additions'">
          <div class="grid-info">
            <span class="grid-heading">
              <h1>{{ $t('Additions') }}</h1>
              <span class="entries">{{ NumberFormat(Tab().entries) }} {{ $t(`entr${Tab().entries == 1 ? 'y' : 'ies'}`) }}</span>
            </span>
            <div class="grid-actions">
              <a class="button" style="margin-left: auto;" @click.prevent="OpenModal('create')" href="">{{ $t('Create') }}</a>
            </div>
            <div class="grid-search">
              <div class="search-fields" style="align-items: flex-end; max-width: 100%;">
                <input type="text" style="width: 30%;" v-model="Tab().search.query" @keyup.enter="FilterSearch(), $event.target.blur()" :placeholder="$t('Search')">
                <div class="size-select" style="width: 300px; margin-top: -100%; margin-left: 30px;">
                  <p class="grid-heading" style="font-weight: 600; color: #b3b8bd; padding-bottom: 2px;">{{ $t('Language') }}</p>
                  <v-select name="locale" :value="locales.find(option => option.code == Tab().search.language)" @input="FilterByLanguage" :options="Alphabetize(locales.map(option => {option.label = `${LanguageName(option.language)}`; return option}), 'label')">
                    <template v-slot:selected-option="option">
                      <div class="truncate">
                        <Flag :code="option.code" size="small" type="language" :title="CountryName(option.country)" />
                        <span>{{ option.label }}</span>
                      </div>
                    </template>
                    <template slot="option" slot-scope="option">
                      <div class="truncate">
                        <Flag :code="option.code" size="small" type="language" :title="CountryName(option.country)" />
                        <span>{{ option.label }}</span>
                      </div>
                    </template>
                  </v-select>
                </div>
                <div class="size-select" style="width: 160px; margin-top: -100%; margin-left: 30px;">
                  <p class="grid-heading" style="font-weight: 600; color: #b3b8bd; padding-bottom: 2px;">{{ $t('Page size') }}</p>
                  <v-select name="page-size-select" v-model="Tab().search.page_size" @input="FilterBySize" :options="sizes" :searchable="false" :clearable="false" />
                </div>
              </div>
            </div>
          
          </div>
          <div>
            <table class="list table odd-even">
              <tbody>
                <tr :data-item="JSON.stringify(item)" :class="['map-list__row', {clickable: true}]" @mousedown="ClickOpen" @mousemove="ClickOpen" @mouseup="ClickOpen" :key="item.id" v-for="item in Tab().list">
                  <td class="label" style="display: table-cell;" @click="ToggleCheckbox">
                    <div class="v-wrapper checkbox">
                      <label class="v-checkbox-label">
                        <input class="v-checkbox" type="checkbox" :data-id="item.id" @input="ToggleCheckbox">
                        <span class="v-checkbox-toggle" />
                      </label>
                    </div>
                  </td>
                  <td>
                    <div>{{ item.id }}</div>
                  </td>
                  <td>
                    <div>{{ item.company }}</div>
                  </td>
                  <td>
                    <div>{{ item.name }}</div>
                  </td>
                  <td>
                    <div style="display: flex; align-items: center;">
                      <Flag :code="item.locale.country" size="small" :title="CountryName(item.locale.country)" />
                      <span style="margin-left: 10px;">{{ locales.find(option => option.code == item.language).label }}</span>
                    </div>
                  </td>
                  <td>
                    <div>{{ item.barcode }}</div>
                  </td>
                  <td class="status">
                    <div class="flex-row state lowercase" :class="[item.img_mime ? 'green' : 'red']">
                      {{ item.img_mime || $t('Undefined') }}
                    </div>
                  </td>
                  <td class="actions" @mouseenter="SetBackground" @mouseleave="SetBackground">
                    <a class="icon dots" @click.prevent="" href="">
                      <ul class="item-actions">
                        <li><a @click.prevent="OpenModal('edit', item)" href="" class="success">{{ $t('Edit') }}</a></li>
                        <li><a @click.prevent="DeleteAdditionalOrderProduct(item)" href="" class="error">{{ $t('Delete') }}</a></li>
                      </ul>
                    </a>
                  </td>
                </tr>
              </tbody>
              <thead>
                <tr>
                  <th class="label" style="display: table-cell;">
                    <div class="v-wrapper checkbox">
                      <label class="v-checkbox-label">
                        <input class="v-checkbox" type="checkbox" ref="select_all" @input="ToggleAllCheckboxes">
                        <span class="v-checkbox-toggle toggle-all" />
                      </label>
                    </div>
                  </th>
                  <th>{{ $t('ID') }}</th>
                  <th>{{ $t('Company') }}</th>
                  <th>{{ $t('Name') }}</th>
                  <th>{{ $t('Language') }}</th>
                  <th>{{ $t('Barcode') }}</th>
                  <th>{{ $t('Image') }}</th>
                  <th class="edit" />
                </tr>
              </thead>
            </table>
            <div class="grid-pagination shadow sticky bottom" :style="{marginTop: Tab().list.length && '2px'}">
              <div class="page-navigation">
                <div class="page-turn prev disabled" @click="PageController(false)"></div>
                <div class="page-number">
                  <label class="page-number-current">
                    <input type="number" min="1" :max="Tab().page.last" :value="Tab().page.current" @blur="PageNavigator" @keydown="PageNavigator">
                    <span class="placeholder">{{NumberFormat(Tab().page.current)}}</span>
                  </label>
                  <span class="page-number-separator">/</span>
                  <div class="page-number-last">{{NumberFormat(Tab().page.last)}}</div>
                </div>
                <div class="page-turn next" :class="{disabled: Tab().page.last == 1}" @click="PageController(true)"></div>
              </div>
            </div>
          </div>
        </li>

        <li class="tabs__body--content padding" style="padding-bottom: 30px; margin-bottom: -30px;" v-if="tab.active == 'approval'">
          <div class="grid-info">
            <span class="grid-heading">
              <h1>{{ $t('Rules') }}</h1>
              <span class="entries">{{ NumberFormat(Tab().entries) }} {{ $t(`entr${Tab().entries == 1 ? 'y' : 'ies'}`) }}</span>
            </span>
            <div class="grid-actions" style="width: 500px; margin: -2px 0 0;" v-if="IsMainCompany()">
              <div class="label" style="width: 100%;">
                <span class="label-text">{{ $t('Approval pin code') }}</span>
                <div class="v-wrapper border">
                  <input :class="['v-wrapper-input', Tab().pin.hidden && 'password']" ref="pin" type="text" v-model="Tab().pin.new" @keydown.enter="$event.target.blur()" style="border: 0;">
                  <button :class="['v-wrapper-input-button icon', Tab().pin.hidden ? 'eye' : 'eye-slash']" :title="$t(Tab().pin.hidden ? 'Show' : 'Hide')" style="border: 0px;" @click.prevent="Tab().pin.hidden = !Tab().pin.hidden" />
                </div>
              </div>
              <button class="button green" style="margin-left: 30px; min-width: 115px;" @click.prevent="SetPin">{{ $t('Save') }}</button>
            </div>
            <div class="grid-search">
              <div class="search-fields" style="align-items: flex-end; max-width: 100%;">
                <input type="text" v-model="Tab().search.query" @keyup.enter="FilterSearch(), $event.target.blur()" :placeholder="$t('Search')" style="width: 30%;">
                <div class="size-select" style="width: 300px; margin-top: -100%; margin-left: 30px;" v-if="!IsMainCompany()">
                  <p class="grid-heading" style="font-weight: 600; color: #b3b8bd; padding-bottom: 2px;">{{ $t('Country') }}</p>
                  <v-select name="country" :value="countries.find(option => option.id == Tab().search.res_country_id)" @input="FilterByCountry" :options="Alphabetize(countries.map(o => {o.label = CountryName(o.code); return o;}), 'label')">
                    <template v-slot:selected-option="option">
                      <div class="truncate">
                        <Flag :code="option.code" size="small" />
                        <span>{{ CountryName(option.code) }}</span>
                      </div>
                    </template>
                    <template slot="option" slot-scope="option">
                      <div class="truncate">
                        <Flag :code="option.code" size="small" />
                        <span>{{ option.label }}</span>
                      </div>
                    </template>
                  </v-select>
                </div>
                <div class="size-select" style="width: 160px; margin-top: -100%; margin-left: 30px;">
                  <p class="grid-heading" style="font-weight: 600; color: #b3b8bd; padding-bottom: 2px;">{{ $t('Page size') }}</p>
                  <v-select name="page-size-select" v-model="Tab().search.page_size" @input="FilterBySize" :options="sizes" :searchable="false" :clearable="false" />
                </div>
              </div>
            </div>
            <div class="grid-search-filter" v-if="IsMainCompany()">
              <div class="size-select" style="width: 180px; margin-top: 20px;">
                <p class="grid-heading" style="font-weight: 600; color: #b3b8bd; padding-bottom: 2px;">{{ $t('Company') }}</p>
                <v-select name="company" :value="companies.find(option => option.code == Tab().search.company)" @input="FilterByCompany" :options="companies" />
              </div>
              <div class="size-select" style="width: 300px; margin-top: 20px; margin-left: 30px;">
                <p class="grid-heading" style="font-weight: 600; color: #b3b8bd; padding-bottom: 2px;">{{ $t('Country') }}</p>
                <v-select name="country" :value="countries.find(option => option.id == Tab().search.res_country_id)" @input="FilterByCountry" :options="Alphabetize(countries.map(option => {option.label = CountryName(option.code); return option}), 'label')">
                  <template v-slot:selected-option="option">
                    <div class="truncate">
                      <Flag :code="option.code" size="small" />
                      <span>{{ CountryName(option.code) }}</span>
                    </div>
                  </template>
                  <template slot="option" slot-scope="option">
                    <div class="truncate">
                      <Flag :code="option.code" size="small" />
                      <span>{{ option.label }}</span>
                    </div>
                  </template>
                </v-select>
              </div>
            </div>
          </div>
          <div>
            <div class="flex row" style="margin-top: 30px;">
              <div class="flex column" style="width: 100%;">
                <div class="flex column">
                  <div class="settings__group" style="width: 100%; display: flex; flex-direction: row;">
                    <div class="list-wrapper" style="width: 100%; display: flex; flex-direction: column;">
                      <div class="simple-list__title">
                        <p class="max-width-300" style="max-width: 180px;">{{ $t('Company') }}</p>
                        <p class="max-width-300" style="max-width: 303px;">{{ $t('Country') }}</p>
                        <p>{{ $t('Product') }}</p>
                        <div class="simple-list__item item-wrapper" style="width: auto; padding: 0;">
                          <button :class="['item-wrapper__button remove', {hover: Tab().list.length}]" :disabled="!Tab().list.length" @click="ClearList('approval rule', true)" @mouseenter="HoverAll" @mouseleave="HoverAll" />
                        </div>
                      </div>
                      <ul class="simple-list odd-even" style="border-bottom: 0;">
                        <li class="simple-list__add">
                          <div class="item-wrapper noflex max-width-300" style="max-width: 180px;">
                            <v-select name="res_company_id" ref="res_company_id" :value="companies.find(option => option.id == Tab().item.res_company_id)" @input="SetOption($event, 'res_company_id')" :placeholder="$t('Select')" :options="companies" :searchable="IsMainCompany()" :clearable="IsMainCompany()" :dropdown-should-open="dropdownShouldOpen">
                              <template v-slot:selected-option="option">
                                <div class="truncate">
                                  <span>{{ option.label }}</span>
                                </div>
                              </template>
                              <template slot="option" slot-scope="option">
                                <div class="truncate">
                                  <span>{{ option.label }}</span>
                                </div>
                              </template>
                              <template v-slot:no-options><span /></template>
                            </v-select>
                          </div>
                          <div class="item-wrapper noflex max-width-300" style="max-width: 303px;">
                            <v-select name="res_country_id" ref="res_country_id" :value="countries.find(option => option.id == Tab().item.res_country_id)" @input="SetOption($event, 'res_country_id')" :placeholder="$t('Select')" :options="Alphabetize(countries.map(o => {o.label = CountryName(o.code); return o;}), 'label')">
                              <template v-slot:selected-option="option">
                                <div class="truncate">
                                  <Flag :code="option.code" size="small" />
                                  <span>{{ CountryName(option.code) }}</span>
                                </div>
                              </template>
                              <template slot="option" slot-scope="option">
                                <div class="truncate">
                                  <Flag :code="option.code" size="small" />
                                  <span>{{ option.label }}</span>
                                </div>
                              </template>
                              <template v-slot:no-options><span /></template>
                            </v-select>
                          </div>
                          <div class="item-wrapper">
                            <input class="item-wrapper__input lowercase" ref="sku" v-model="Tab().item.sku" @keydown.space.prevent @keydown.enter="$event.target.blur(), AddItem()" @blur="Tab().item.sku = String(Tab().item.sku).toLowerCase().replace(/\s/g, '')" placeholder="SKU" spellcheck="false">
                          </div>
                          <div class="item-wrapper" style="width: auto;">
                            <button class="item-wrapper__button add" @click="AddItem" />
                          </div>
                        </li>
                        <li class="simple-list__item" :key="index" v-for="(item, index) in Tab().list">
                          <div class="item-wrapper max-width-300" style="max-width: 180px;">
                            <p class="item-wrapper__text" style="padding-left: 9px;">
                              <span>{{ companies.find(option => option.id == item.res_company_id).label }}</span>
                            </p>
                          </div>
                          <div class="item-wrapper max-width-300" style="max-width: 303px;">
                            <p class="item-wrapper__text" style="padding-left: 9px;">
                              <!--
                              <div style="display: flex; align-items: center;">
                                <Flag :code="item.locale.country" size="small" :title="CountryName(item.locale.country)" />
                                <span style="margin-left: 10px;">{{ locales.find(option => option.code == item.language).label }}</span>
                              </div>
                              -->
                              <Flag :code="countries.find(option => option.id == item.res_country_id).code" size="small" />
                              <span style="margin-left: 10px;">{{ CountryName(countries.find(option => option.id == item.res_country_id).code) }}</span>
                            </p>
                          </div>
                          <div class="item-wrapper">
                            <p class="item-wrapper__text" style="padding-left: 9px;">
                              <span>{{ item.sku }}</span>
                            </p>
                          </div>
                          <div class="item-wrapper" style="width: auto;">
                            <button class="item-wrapper__button remove" @click="RemoveItem(item, 'approval rule', true)" @mouseenter="HoverButton" @mouseleave="HoverButton" />
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="grid-pagination shadow sticky bottom" :style="{marginTop: Tab().list.length && '2px'}">
              <div class="page-navigation">
                <div class="page-turn prev disabled" @click="PageController(false)"></div>
                <div class="page-number">
                  <label class="page-number-current">
                    <input type="number" min="1" :max="Tab().page.last" :value="Tab().page.current" @blur="PageNavigator" @keydown="PageNavigator">
                    <span class="placeholder">{{NumberFormat(Tab().page.current)}}</span>
                  </label>
                  <span class="page-number-separator">/</span>
                  <div class="page-number-last">{{NumberFormat(Tab().page.last)}}</div>
                </div>
                <div class="page-turn next" :class="{disabled: Tab().page.last == 1}" @click="PageController(true)"></div>
              </div>
            </div>
          </div>
        </li>
        <li v-if="tab.active == 'label_barcode'">
          <LabelBarcodePass></LabelBarcodePass>
        </li>
      </ul>
    </div>

    <Modal modal="modal" :value="modal.open" :title="$t(modal.mode == 'create' ? Tab().name == 'additions' ? 'New addition' : '' : Tab().name == 'additions' ? 'Addition' : '') + modal.title">
      <div v-if="modal.open">
        <div class="modal-header" />
        <div class="modal-content scrollable">
          <div class="modal-tabs">
            <div class="modal-tabs__body" style="overflow: hidden auto;">
              <div class="modal-tabs__body-content padding">
                <form @submit.prevent style="height: 100%; padding-bottom: 25px;">
                  <button ref="submit" type="submit" hidden />
                  <div v-for="(group, index) in form.fields" :key="index" class="flex-group" :style="index == form.fields.length - 1 && {height: 'calc(100% - 150px)'}">
                    <div class="flex-row" :style="index == form.fields.length - 1 ? {height: '100%'} : {alignItems: 'flex-end'}">
                      <div v-for="(field, index) in group" :key="index" :class="['flex-column', {flex1: field.fill_width, half: field.half_width}]" :style="[{maxWidth: field.type == 'checkbox' && 'max-content'}]" v-show="!field.hidden">
                        <div :class="['label', {required: field.required}]" :style="field.type == 'checkbox' ? {flexDirection: `row${field.type == 'checkbox' ? '-reverse' : ''}`, alignItems: 'center', marginBottom: field.type == 'checkbox' && '5px'} : field.fill_height && {height: '100%'}">
                          <span class="label-text">
                            {{ $t(field.label || Capitalize(field.name).replace(/_/g, ' ')) }}
                          </span>
                          <v-select v-if="field.type == 'select'" :name="field.name" :required="field.required" :disabled="field.disabled || readonly" :value="field.value" :options="field.name == 'language' ? Alphabetize(field.options.map(option => {option.label = `${LanguageName(option.language)}`; return option}), 'label') : field.options" :title="field.title" :searchable="field.searchable" :clearable="field.clearable" v-on="field.input ? {input: field.input} : {}">
                            <template #search="{attributes, events}">
                              <input class="vs__search" v-bind="attributes" v-on="events" :required="field.required && !form.data[field.name]">
                            </template>
                            <template v-slot:selected-option="option">
                              <div v-if="field.name == 'language'" class="truncate">
                                <Flag :code="option.code" size="small" type="language" :title="CountryName(option.country)" />
                                <span>{{ option.label }}</span>
                              </div>
                              <span v-else :class="['courier', option.code]">
                                {{ option.label }}
                              </span>
                            </template>
                            <template slot="option" slot-scope="option">
                              <div v-if="field.name == 'language'" class="truncate">
                                <Flag :code="option.code" size="small" type="language" :title="CountryName(option.country)" />
                                <span>{{ option.label }}</span>
                              </div>
                              <span v-else :class="['courier', option.code]">
                                {{ option.label }}
                              </span>
                            </template>
                          </v-select>
                          <div v-else-if="field.type == 'checkbox'" class="v-wrapper checkbox">
                            <label class="v-checkbox-label" style="margin-right: 10px;">
                              <input class="v-checkbox" :type="field.type" :name="field.name" :disabled="field.disabled" :checked="field.value" @input="field.input ? field.input($event) : {}">
                              <span class="v-checkbox-toggle" style="width: 24px; height: 24px;" />
                            </label>
                          </div>
                          <textarea v-else-if="field.type == 'textarea'" class="v-textarea" style="height: 100%; min-height: 34px; resize: none;" :name="field.name" :required="field.required" :readonly="field.readonly" :pattern="field.pattern" v-model="form.data[field.name]" :title="field.title" @input="field.input ? field.input($event) : {}" @blur="field.blur ? field.blur($event) : {}" />
                          <div v-else-if="field.type == 'list'" class="v-input-list border" style="height: 100%;">
                            <ul class="v-list">
                              <li class="v-list-item even-odd clickable" v-for="(item, index) of field.list" :key="index" @click="OpenUser(item.name)">{{ item.name }}</li>
                            </ul>
                          </div>
                          <div v-else-if="field.type == 'file'" class="v-wrapper-file image" style="background-color: white;">
                            <input :type="field.type" :name="field.name" :required="field.required" :accept="field.accept" :value="field.value" :title="field.title" @input="field.input ? field.input($event) : {}">
                            <div :class="['v-image', /default/.test(field.name) ? 'default' : 'current']" :style="[{'--src': field.image && `url(${field.image})`, backgroundColor: 'white', opacity: field.placeholder && 0.4}]" />
                            <div class="clear" @click="ClearImage(field)" />
                          </div>
                          <input v-else class="v-input" :type="field.type" :name="field.name" :required="field.required" :readonly="field.readonly" :pattern="field.pattern" :min="field.min" :max="field.max" :minlength="field.minlength" :step="field.step" :value="field.value" v-model="form.data[field.name]" :title="field.title" @keydown.enter="$event.target.blur()" @keydown="field.keydown ? field.keydown($event) : {}" @input="field.input ? field.input($event) : {}" @blur="field.blur ? field.blur($event) : {}" :spellcheck="'spellcheck' in field ? field.spellcheck : ''">
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div class="button-container actions">
            <div class="label" style="flex-direction: row; align-items: center; margin-right: auto;" v-if="'active' in form.data">
              <div class="checkbox-toggle">
                <input type="checkbox" v-model="form.data.active">
                <span class="toggle" />
              </div>
              <span style="margin-left: 10px;">{{ $t('Active') }}</span>
            </div>
            <button class="button green" @click.prevent="Create" v-if="modal.mode == 'create'">{{ $t('Create') }}</button>
            <button class="button green" @click.prevent="Update" v-if="modal.mode == 'edit'">{{ $t('Save') }}</button>
            <button class="button red" @click.prevent="Delete" v-if="modal.mode == 'edit'">{{ $t('Delete') }}</button>
          </div>
        </div>
      </div>
    </Modal>

    <div class="loading" v-if="loading">
      <div class="loading-element">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>

  </div>
</template>

<script>
  import { TableElementsBehaviour } from '@/mixins/TableElementsBehaviour';
  import { Tool } from '@/helpers/Tool';
  import { BPA } from '@/helpers/BPA';
  import Modal from '@/components/snippets/Modal';
  import Flag from '@/components/snippets/Flag';
  import LabelBarcodePass from '@/components/blocks/Settings/SettingsPackingConfig/LabelBarcodePass.vue'

  export default {
    name: 'SettingsPackingConfigView',
    mixins: [TableElementsBehaviour, Tool, BPA],
    components: {Modal, Flag, LabelBarcodePass},
    data() {
      return {
        loading: false,
        clickables: [],
        selected: [],
        cached: {},
        companies: [],
        countries: [],
        languages: [],
        locales: [],
        sizes: [10, 20, 30, 50, 100],
        tab: {
          active: 'additions',
          additions: {
            name: 'additions',
            loaded: false,
            entries: 0,
            list: [],
            item: {},
            search: {
              query: '',
              initial: null,
              language: '',
              page_size: 20,
              page_offset: 1
            },
            page: {
              current: 1,
              number: 1,
              last: 1
            },
            template: [
              {
                name: ''
              },
              {
                language: '',
                barcode: ''
              },
              {
                img_data: '',
              }
            ]
          },
          approval: {
            name: 'approval',
            loaded: false,
            entries: 0,
            pin: {
              hidden: true,
              old: '',
              new: ''
            },
            list: [],
            item: {
              res_company_id: '',
              res_country_id: '',
              sku: ''
            },
            search: {
              query: '',
              initial: null,
              company: '',
              res_country_id: '',
              page_size: 20,
              page_offset: 1
            },
            page: {
              current: 1,
              number: 1,
              last: 1
            }
          },
          label_barcode: {
            name: 'label_barcode'
          }
        },
        form: {
          fields: [],
          data: {}
        },
        modal: {
          mode: 'create',
          open: false
        },
        readonly: !BPA.permissions(['admin', 'settings_admin']).length
      }
    },
    async created() {
      await this.SetLocaleOptions();
    },
    mounted() {
      this.$eventHub.$on('CloseModal', () => {
        this.main.view.classList.remove('no-scroll');
        this.form = {fields: [], data: {}};
        this.modal.open = false;
        /*
        this.method = {};
        this.cached.method = {};
        BPA.cache.session({name: this.$options.name, set: {method: {}}});
        */
        for (let i = 0; i < this.clickables.length; i++) {
          if (this.clickables[i].classList.contains('selected')) {
            this.clickables[i].classList.remove('selected');
          }
        }
      });
      this.Search();
    },
    computed: {
      main() {
        const main = {
          company: BPA.util.GetCompany(),
          view: document.querySelector('.content-scroll')
        }
        main.view.scroll = {top: 0};
        return main;
      },
      locale() {
        return this.$i18n.locale;
      }
    },
    watch: {
      locale() {
        this.SetCountryOptions();
        this.SetLocaleOptions();
      }
    },
    methods: {
      ConsoleLog(output) {
        console.log(output);
      },
      CloneObject(object = {}) {
        return Tool.CloneObject(object);
      },
      DateFormat(date) {
        return Tool.DateFormat(date);
      },
      NumberFormat(number) {
        return Tool.NumberFormat(number);
      },
      Capitalize(string = '') {
        return Tool.Capitalize(string);
      },
      TitleCase(string = '') {
        return Tool.TitleCase(string);
      },
      Hyperlink(props = {}) {
        return Tool.Hyperlink(props);
      },
      Alphabetize(list, prop) {
        return Tool.Alphabetize(list, prop);
      },
      CountryName(country_code = '', locale) {
        return Tool.CountryName(country_code, locale);
      },
      LanguageName(country_code = '', locale) {
        return Tool.LanguageName(country_code, locale);
      },
      async SetCompanyOptions() {
        this.companies = await this.GetCompanyOptions();
      },
      async SetCountryOptions() {
        this.countries = await this.GetCountryOptions();
      },
      async SetLocaleOptions() {
        this.locales = await this.GetLocaleOptions();
      },
      async GetCompanyOptions() {
        return await new Promise(resolve => {
          let options = [];
          for (let option of BPA.api.Companies('GET')) {
            option.label = option.name;
            for (let key of Object.keys(option)) {
              if (/name/.test(key)) {
                delete option[key];
              }
            }
            options.push(option);
          }
          resolve(options);
        }).catch(e => e);
      },
      async GetCountryOptions() {
        return await new Promise(resolve => {
          let options = [];
          for (let option of BPA.api.Countries('GET')) {
            option.code = option.iso_code_2;
            option.label = option.name;
            for (let key of Object.keys(option)) {
              if (/name|iso_code/.test(key)) {
                delete option[key];
              }
            }
            options.push(option);
          }
          resolve(options);
        }).catch(e => e);
      },
      async GetLocaleOptions() {
        return await new Promise(async resolve => {
          let options = [];
          for (let option of BPA.locale('options', true)) {
            let option_code = option.code.split('_');
            option.country = option_code[1];
            option.language = option_code[0];
            if (/no/.test(option.code)) {
              option.code = 'nb_NO';
            }
            options.push(option);
          }
          options = [];
          let storage = BPA.util.storage;
          let languages = storage.get('languages');
          let company = BPA.util.GetCompany();
          if (!Object.keys(languages).length) {
            let locales = await this.GetLanguageOrderCount();
            languages[company] = [];
            for (let locale in locales) {
              let option = {code: locale};
              if (/nb/.test(locale)) {
                locale = locale.replace('nb', 'no');
              }
              let code = locale.split('_');
              option.label = this.LanguageName(code[0], 'en');
              option.language = code[0];
              option.country = code[1];
              options.push(option);
              languages[company].push(option);
            }
            storage.set('languages', languages);
          }
          options = languages[company];
          resolve(options);
        }).catch(e => e);
      },
      async GetLanguageOrderCount(company_code) {
        return await BPA.api.GetLanguageOrderCount(company_code).then(response => {
          return BPA.api.response({response, return: 'json'});
        }).then(response => response.result || {}).catch(e => e);
      },
      Tab(active) {
        let tab = this.tab;
        let tab_active = tab[tab.active];
        if (active) {
          if (!(active in tab)) {
            tab = tab_active.tab;
          }
          tab.active = active;
        } else if ('tab' in tab_active) {
          tab = tab_active.tab;
        }
        tab_active = tab[tab.active];
        this.$nextTick().then(async () => {
          let refs = this.$refs;
          let select_all = refs.select_all;
          if (active) {
            if (active == 'additions') {
              this.SetLocaleOptions();
              if (!tab_active.loaded) {
                this.SetLocaleOptions();
              } else {
                this.SetPageJumpWidth();
              }
            }
            if (active == 'approval') {
              let dropdown_toggles = [...document.querySelectorAll('.v-select[name^="res_"] > .vs__dropdown-toggle')];
              if (dropdown_toggles.length) dropdown_toggles.map(dropdown_toggle => {
                dropdown_toggle.classList.add('borderless');
                Object.assign(dropdown_toggle.style, {
                  minHeight: '32px', 
                  height: '32px', 
                  border: 0
                });
              });
              if (!tab_active.loaded) {
                this.loading = true;
                await this.SetCompanyOptions();
                await this.SetCountryOptions();
                if (this.IsMainCompany()) {
                  let pin = await this.GetOrderProductRequirePasswordPin();
                  tab_active.pin = {hidden: true, old: pin, new: pin};
                } else {
                  this.companies = this.companies.filter(option => !option.main);
                  tab_active.item.res_company_id = this.companies[0]?.id;
                }
                await this.Search();
                this.loading = false;
              } else {
                this.SetPageJumpWidth();
              }
            }
            if (select_all) {
              select_all.disabled = !tab_active.list?.length;
              select_all.checked = false;
            }
            this.selected = [];
          }
        });
        return tab_active;
      },
      async FilterByActive(event) {
        this.Tab().search.active = event?.target?.checked;
        await this.FilterSearch();
      },
      async FilterByCompany(option) {
        this.Tab().search.company = option?.code;
        await this.FilterSearch();
      },
      async FilterByCountry(option) {
        this.Tab().search.res_country_id = option?.id;
        await this.FilterSearch();
      },
      async FilterByLanguage(option) {
        this.Tab().search.language = option?.code;
        await this.FilterSearch();
      },
      async FilterByAgent(option) {
        this.Tab().search.agent_code = option?.code;
        await this.FilterSearch();
      },
      async FilterByType(option) {
        this.Tab().search.type = option?.code;
        await this.FilterSearch();
      },
      async FilterBySize(option) {
        this.Tab().search.page_size = option;
        await this.FilterSearch();
      },
      async FilterSearch() {
        this.Tab().page.current = 1;
        this.loading = true;
        await this.Search();
        this.loading = false;
      },
      async Search() {
        let tab = this.Tab();
        let tab_name = tab.name;
        let search = tab.search;
        let page = tab.page;
        let method = null;
        let request = {
          query: search.query,
          page_size: search.page_size,
          page_offset: page.current
        }        
        switch (tab_name) {
          case 'additions': {
            method = 'SearchAdditionalOrderProducts';
            if (search.language) {
              request.language = search.language;
            }
            break;
          }
          case 'approval': {
            method = 'SearchApprovalRequiredProductRules';
            if (search.company) {
              request.company = search.company;
            }
            if (search.res_country_id) {
              request.res_country_id = search.res_country_id;
            }
            break;
          }
          default: {
            return tab.loaded = false;
          }
        }
        let data = await this[method](request);
        if (!data) return this.loading = false;
        search.initial = search.query;
        page.current = request.page_offset;
        page.last = data.pages_count || Math.ceil((data.count || 1) / request.page_size);
        tab.entries = data.count || data.items_count;
        tab.list = data.items;
        tab.cached = this.CloneObject(tab.list);
        //BPA.cache.local({name: this.$options.name, set: {page: tab.page, search: tab.search}});
        this.$nextTick().then(async () => {
          let select_all = this.$refs.select_all;
          if (select_all) {
            select_all.disabled = !tab.list.length;
            select_all.checked = false;
          }
          this.clickables = document.querySelectorAll('.clickable');
          /*
          if (this.cached.method && Object.keys(this.cached.method).length) {
            this.OpenModal('edit', this.cached.method);
          }
          */
          this.SetPageJumpWidth();
        });
        tab.loaded = true;
      },
      async OpenModal(mode, item = {}) {
        let tab = this.Tab();
        let tab_name = tab.name;
        this.modal.mode = mode;
        this.modal.title = '';
        tab.item = item;
        if (mode == 'edit') {
          for (let i = 0; i < this.clickables.length; i++) {
            if (this.clickables[i].dataset.item == JSON.stringify(tab.item)) {
              this.clickables[i].classList.add('selected');
              /*
              if (this.cached.method && Object.keys(this.cached.method).length) {
                this.clickables[i].scrollIntoView({
                  behavior: 'auto',
                  block: 'center',
                  inline: 'center'
                });
              }
              */
              break;
            }
          }
        }
        switch (tab_name) {
          case 'additions': {
            if (mode == 'edit') {
              this.loading = true;
              tab.item = await this.GetAdditionalOrderProduct(item.id) || {};
              tab.item.img_data = await this.GetAdditionalOrderProductImage(item.id) || '';
              this.modal.title = ` #${item.id}`;
              //BPA.cache.session({name: this.$options.name, set: {method: this.method}});
            }
            break;
          }
        }
        await this.SetupForm(tab.item);
        this.modal.open = true;
        this.loading = false;
      },
      ClearForm() {
        this.form = {
          fields: [],
          data: {}
        };
      },
      async ValidateForm() {
        return await new Promise(resolve => {
          let button = this.$refs.submit;
          if (button) {
            let form = button.closest('form');
            let fields = Array.from(form.elements);
            if (fields.some(field => !field.checkValidity())) {
              return button.click();
            }
          }
          resolve(true);
        }).catch(e => e);
      },
      async SetupForm(item = {}) {
        return await new Promise(async resolve => {
          let fields = [];
          let form = item;
          let tab = this.Tab();
          let data = {};
          let template = this.CloneObject(tab.template);
          /*
          const find_field = (name) => fields.map(group => {
            return group.find(field => field.name == name);
          }).filter(e => e)[0] || {};
          */
          if (!Object.keys(form).length) {
            form = template;
          }
          if (!Array.isArray(form)) {
            for (let group of template) {
              for (let key in group) {
                group[key] = item[key];
              }
            }
            if (Object.keys(item).length) {
              if ('priority' in item) {
                data.priority = item.priority;
              }
              if ('active' in item) {
                data.active = item.active;
              }
              if ('id' in item) {
                data.id = item.id;
              }
            }
            form = template;
          }
          for (let group of form) {
            const row = [];
            for (let key in group) {
              data[key] = group[key];
              let field = {name: key};
              switch (key) {
                case 'language': {
                  field.type = 'select';
                  field.required = true;
                  //field.fill_width = true;
                  //field.half_width = true;
                  field.options = this.CloneObject(this.locales);
                  if (data[key]) {
                    field.value = field.options.find(option => {
                      return option.code == data[key];
                    });
                  }
                  field.input = (option) => {
                    field.value = option || '';
                    this.form.data[key] = option && option.code;
                    //console.log(this.CloneObject(this.form.data));
                  }
                  break;
                }
                case 'barcode': 
                case 'name': {
                  field.type = 'text';
                  field.required = true;
                  if (data[key]) {
                    field.value = data[key];
                  }
                  if (key == 'name') {
                    //field.label = 'Product';
                    field.fill_width = true;
                    field.half_width = true;
                  }
                  if (key == 'barcode') {
                    field.keydown = (event) => {
                      if (event.code == 'Space' && !event.target.value.length) {
                        event.preventDefault();
                      }
                    }
                  }
                  field.blur = (event) => {
                    let input = event.target;
                    let value = input.value;
                    if (key == 'name') {
                      field.value = value.replace(/^\s+/, '').replace(/\s+/g, ' ').trim();
                    }
                    if (key == 'barcode') {
                      field.value = value.replace(/\s/g, '');
                    }
                    input.value = field.value;
                    this.form.data[key] = field.value;
                    //console.log(this.CloneObject(this.form.data));
                  }
                  break;
                } 
                case 'img_data': {
                  field.type = 'file';
                  field.fill_width = true;
                  if (key == 'img_data') {
                    field.label = 'Image';
                    field.half_width = true;
                  }
                  field.accept = '.jpg, .jpeg, .png';
                  field.image = null;
                  field.title = '';
                  if (data[key]) {
                    let base64 = data[key] || '';
                    field.image = base64;
                    data[key] = base64.replace(/^.+,/, '');
                  }
                  field.input = (event) => {
                    let file = event.target.files[0];
                    let value = event.target.value;
                    let reader = new FileReader();
                    if (!file) return;
                    reader.onloadend = () => {
                      let base64 = reader.result || '';
                      field.image = base64;
                      field.value = value;
                      this.form.data[key] = base64.replace(/^.+,/, '');
                      //console.log(this.CloneObject(this.form.data));
                    };
                    reader.readAsDataURL(file);
                  }
                  break;
                }
              }
              row.push(field);
            }
            fields.push(row);
          }
          this.form.data = data;
          this.form.fields = fields;
          //console.log('this.form.data', this.CloneObject(this.form.data))
          //console.log('this.form.fields', this.CloneObject(this.form.fields))
          this.$nextTick().then(resolve);
        }).catch(e => e);
      },
      async SetupTemplate(agent = '', item = {}) {
        return await new Promise(async resolve => {
          let fields = [];
          let form = item;
          let tab = this.Tab();
          let data = {};
          let template = this.CloneObject(tab.template[agent]);
          /*
          const find_field = (name) => fields.map(group => {
            return group.find(field => field.name == name);
          }).filter(e => e)[0] || {};
          */
          if (!Object.keys(form).length) {
            form = template;
          }
          if (!Array.isArray(form)) {
            for (let group of template) {
              for (let key in group) {
                group[key] = item[key];
              }
            }
            if (Object.keys(item).length) {
              if ('priority' in item) {
                data.priority = item.priority;
              }
              if ('active' in item) {
                data.active = item.active;
              }
              if ('id' in item) {
                data.id = item.id;
              }
            }
            form = template;
          }
          for (let group of form) {
            const row = [];
            for (let key in group) {
              data[key] = group[key];
              let field = {name: key};
              //field.required = true;
              field.type = 'text';
              //field.placeholder = key;
              let value = data[key];
              if (value) {
                field.value = value;
                this.form.data[key] = field.value;
              }
              field.blur = (event) => {
                value = event.target.value;
                if (field.type == 'number') {
                  if (value && !Number.isNaN(value)) {
                    value = parseInt(value);
                  } else {
                    value = null;
                  }
                }
                field.value = value;
                this.form.data[key] = field.value;
              }
              switch (key) {
                case 'authorization': {
                  field.label = 'Authorization';
                  field.fill_width = true;
                  field.half_width = true;
                  break;
                }
                case 'client_id': {
                  field.label = 'Client ID';
                  break;
                }
                case 'client_secret': {
                  field.label = 'Client secret';
                  field.fill_width = true;
                  field.half_width = true;
                  break;
                }
                case 'customer_id': {
                  field.label = 'Customer ID';
                  break;
                }
                case 'customer_number': {
                  field.label = 'Customer number';
                  break;
                }
                case 'host': {
                  field.label = 'Host';
                  break;
                }
                case 'kode': {
                  field.label = 'Code';
                  break;
                }
                case 'kunde_id': {
                  field.label = 'Customer ID';
                  break;
                }
                case 'password': {
                  field.label = 'Password';
                  break;
                }
                case 'path': {
                  field.label = 'Path';
                  break;
                }
                case 'port': {
                  field.label = 'Port';
                  field.type = 'number';
                  break;
                }
                case 'signature': {
                  field.label = 'Signature';
                  field.fill_width = true;
                  field.half_width = true;
                  break;
                }
                case 'user': {
                  field.label = 'User';
                  break;
                }
                case 'webhookurl': {
                  field.label = 'Webhook URL';
                  field.fill_width = true;
                  field.half_width = true;
                  break;
                }
              }
              row.push(field);
            }
            fields.push(row);
          }
          this.form.data = data;
          this.form.fields = fields;
          //console.log('this.form.data', this.CloneObject(this.form.data))
          //console.log('this.form.fields', this.CloneObject(this.form.fields))
          this.$nextTick().then(resolve);
        }).catch(e => e);
      },
      ClearImage(field) {
        field.value = '';
        field.image = null;
        this.form.data[field.name] = null;
      },
      GetCourierConfig(courier) {
        if (!courier) {
          this.json = {init: {}, data: {}};
          return this.courier.selected = '';
        }
        this.courier.selected = courier;
        BPA.api.GetCourierConfig(courier.code).then(response => {
          return BPA.api.response({response, return: 'json'});
        }).then(response => {
          if (!response.ok) return;
          let json = response.result || {};
          let tab = this.Tab();
          this.json = {init: json, data: json};
          tab.loaded = true;
        }).catch(e => e);
      },
      async GetCourierEventConfig(courier = {}) {
        if (!courier) {
          this.json = {init: {}, data: {}};
          return this.courier.selected = '';
        }
        this.courier.selected = courier;
        return await BPA.api.GetCourierEventConfig(courier.code).then(response => {
          return BPA.api.response({response, return: 'json'});
        }).then(async response => {
          if (!response.ok) {
            this.ClearForm();
            return;
          }
          let tab = this.Tab();
          let json = response.result || {};
          for (let key in json) {
            json[key] = json[key] || '';
          }
          await this.SetupTemplate(courier.code, json);
          tab.data[courier.code] = json;
          tab.loaded = true;
        }).catch(e => e);
      },
      async UpdateCourierEventConfig(request) {
        return await BPA.api.SetCourierEventConfig(request).then(response => {
          return BPA.api.response({response});
        }).then(response => response.ok).catch(e => e);
      },
      IsHtmlString(string = '') {
        return Tool.IsHtmlString(string);
      },
      IsJsonString(string = '') {
        return Tool.IsJsonString(string);
      },
      JsonChange() {
        this.$eventHub.$emit('HideMessages');
        this.editor.error = '';
      },
      JsonError(error) {
        this.editor.error = error;
      },
      JsonUnchanged() {
        return JSON.stringify(this.json.init) == JSON.stringify(this.json.data);
      },
      ValidateCourierConfig() {
        if (this.readonly) return;
        this.editor.passed = false;
        if (this.JsonUnchanged()) return;
        if (!this.courier.selected) {
          this.$eventHub.$emit('ShowMessages', {
            message: 'No courier selected',
            type: 'error'
          });
          return;
        }
        if (this.editor.error && !this.editor.bypass) {
          this.$eventHub.$emit('ShowMessages', {
            message: this.editor.error,
            type: 'error',
            close: true
          });
          return;
        }
        this.editor.passed = true;
        this.UpdateCourierConfig();
      },
      HoverButton(event) {
        let button = event.target;
        button.classList.toggle('hover');
			},
      HoverAll(event) {
        let button = event.target;
        let wrapper = button.closest('.list-wrapper');
        let list = wrapper.querySelector('.simple-list');
        let items = list.querySelectorAll('.simple-list__item');
				for (let item of items) {
          button = item.querySelector('.item-wrapper__button');
          button.classList.toggle('hover');
        }
			},
      Toast(message) {
        this.$eventHub.$emit('ShowMessages', {
          message: message || 'List successfully cleared', 
          type: 'success', hide: 2000
        });
      },
      async Prompt(props = {}) {
        return await new Promise((resolve, reject) => {
          this.$eventHub.$emit('ValidateModalStart', {
            approve: props.approve || 'Yes, clear it',
            disapprove: 'No',
            message: props.message || 'Clears the entire list.',
            type: 'danger'
          });
          this.$eventHub.$on('ValidateModalStop', approve => {
            this.$eventHub.$off('ValidateModalStop');
            if (!approve) return reject();
            resolve(true);
          });
        }).catch(e => e);
      },
      async ClearList(type = '', prompt = false) {
        let message = `Deletes all the ${type ? `${type}s` : 'items'}.`;
        let approve = 'Yes, delete them';
        let tab = this.Tab();
        const removeItems = async () => {
          let error = false;
          this.loading = true;
          for (let item of tab.list) {
            error = !await this.RemoveItem(item, type);
            if (error) break;
          }
          if (!error) this.$eventHub.$emit('ShowMessages', {
            message: `All ${(type ? `${type}s` : 'items')} successfully deleted`,
            type: 'success',
            hide: 2000
          });
          await this.Search();
          this.loading = false;
        }
        if (!tab.list.length) return;
        if (!prompt) return await removeItems();
        if (await this.Prompt({approve, message})) await removeItems();
      },
      async RemoveItem(item = {}, type = '', prompt = false) {
        let message = `Deletes the current ${type || 'item'}.`;
        let approve = 'Yes, delete it';
        let tab = this.Tab();
        const removeItem = async () => {
          let error = false;
          if (prompt) {
            this.loading = true;
          }
          switch (tab.name) {
            case 'approval': {
              error = !await this.DeleteApprovalRequiredProductRule(item.id);
              if (!error) {
                for (let i = 0; i < tab.cached.length; i++) {
                  let cached_item = tab.cached[i];
                  if (item.id == cached_item.id) {
                    tab.cached.splice(i, 1);
                  }
                }
              }
              break;
            }
          }
          if (prompt) {
            if (!error) this.$eventHub.$emit('ShowMessages', {
              message: `${this.Capitalize(type || 'item')} successfully deleted`,
              type: 'success',
              hide: 2000
            });
            await this.Search();
            this.loading = false;
          }
          return !error;
        }
        if (!prompt) return await removeItem();
        if (await this.Prompt({approve, message})) return await removeItem();
      },
      SetOption(option = {}, field = '') {
        if (field) this.Tab().item[field] = (option || {})[/_id$/.test(field) ? 'id' : 'code'];
      },
      async SetPin() {
        let pin = this.Tab().pin;
        if (pin.new == pin.old) return;
        this.$eventHub.$emit('ValidateModalStart', {
          approve: `Yes, update it`,
          disapprove: 'No',
          message: `Updates the approval pin code.`,
          type: 'success'
        });
        this.$eventHub.$on('ValidateModalStop', async (approve) => {
          this.$eventHub.$off('ValidateModalStop');
          if (!approve) return;
          this.loading = true;
          if (await this.SetOrderProductRequirePasswordPin(pin.new)) {
            this.$eventHub.$emit('ShowMessages', {
              message: `Approval pin code successfully updated`,
              type: 'success',
              hide: 2000
            });
          }
          pin.old = pin.new;
          this.loading = false;
        });
      },
      AddItem() {
        let item = {};
        let tab = this.Tab();
        let rule = tab.item;
        let required = ['res_company_id', 'res_country_id', 'sku'];
        for (let key of required) {
          let value = rule[key];
          if (value) item[key] = value;
        }
        let keys = Object.keys(item);
        if (keys.length < required.length) {
          let missing = required.filter(key => !keys.includes(key))[0];
          if (!missing) missing = required[0];
          let field = this.$refs[missing];
          if (Array.isArray(field)) field = field[0];
          if (!field) return;
          if (field.$vnode) field = field.$vnode.elm;
          let select_field = field.querySelector('input[type=search]');
          if (select_field) field = select_field;
          return field.focus();
        }
        this.Create();
      },
      EditItem(item = {}, field = '') {
        if (!Object.keys(item).length) return;
        let tab = this.Tab();
        if (tab.name == 'rules') {
          if (field == 'sku') {
            item.sku = item.sku.toLowerCase().replace(/\s/g, '');
          }
          this.UpdateShippingPackagingRule(item);
        }
      },
      async Create() {
        if (!await this.ValidateForm()) return;
        switch (this.Tab().name) {
          case 'additions': return await this.CreateAdditionalOrderProduct();
          case 'approval': return await this.CreateApprovalRequiredProductRule();
        }
      },
      async Update() {
        if (!await this.ValidateForm()) return;
        switch (this.Tab().name) {
          case 'additions': return await this.UpdateAdditionalOrderProduct();
        }
      },
      async Delete() {
        switch (this.Tab().name) {
          case 'additions': return await this.DeleteAdditionalOrderProduct();
        }
      },
      async CreateAdditionalOrderProduct() {
        let data = this.CloneObject(this.form.data);
        this.$eventHub.$emit('ValidateModalStart', {
          approve: 'Yes, create it',
          disapprove: 'No',
          message: 'Creates the current additional order product.',
          type: 'success'
        });
        this.$eventHub.$on('ValidateModalStop', async (approve) => {
          this.$eventHub.$off('ValidateModalStop');
          if (!approve) return;
          this.loading = true;
          if (await this.SetAdditionalOrderProduct(data)) {
            this.$eventHub.$emit('ShowMessages', {
              message: 'Additional order product successfully created',
              type: 'success',
              hide: 2000
            });
            await this.Search();
            this.$eventHub.$emit('CloseModal');
          }
          this.loading = false;
        });
      },
      async UpdateAdditionalOrderProduct() {
        let tab = this.Tab();
        let item = this.CloneObject(tab.item);
        let data = this.CloneObject(this.form.data);
        let update = {};
        for (let key in data) {
          if (data[key] != item[key]) {
            if (/img/.test(key)) {
              if (data[key] != item[key].replace(/^.+,/, '')) {
                update[key] = data[key];
              }
            } else {
              update[key] = data[key];
            }
          }
        }
        if (!Object.keys(update).length) {
          this.$eventHub.$emit('CloseModal');
          this.$eventHub.$emit('ShowMessages', {
            message: 'No changes made',
            type: 'warning',
            hide: 2000
          });
          return;
        }
        this.$eventHub.$emit('ValidateModalStart', {
          approve: 'Yes, update it',
          disapprove: 'No',
          message: 'Updates the current additional order product.',
          type: 'success'
        });
        this.$eventHub.$on('ValidateModalStop', async (approve) => {
          this.$eventHub.$off('ValidateModalStop');
          if (!approve) return;
          this.loading = true;
          if (await this.SetAdditionalOrderProduct({id: data.id, body: (delete data.id && data)})) {
            this.$eventHub.$emit('ShowMessages', {
              message: 'Additional order product successfully updated',
              type: 'success',
              hide: 2000
            });
            await this.Search();
            this.$eventHub.$emit('CloseModal');
          }
          this.loading = false;
        });
      },
      async DeleteAdditionalOrderProduct(item = {}) {
        let tab = this.Tab();
        let in_modal = Object.keys(tab.item).length;
        item = in_modal ? tab.item : item;
        let items = [item];
        if (!in_modal) {
          for (let checkbox of this.selected) {
            let table_row = checkbox.closest('tr');
            let row_item = tab.list.find(item => table_row.dataset.item == JSON.stringify(item));
            if (row_item) items.push(row_item);
          }
          items = items.filter((item, index, array) => index == array.findIndex(i => JSON.stringify(i) == JSON.stringify(item)));
        }
        let multiple = items.length > 1;
        this.$eventHub.$emit('ValidateModalStart', {
          approve: `Yes, delete ${multiple ? 'them' : 'it'}`,
          disapprove: 'No',
          message: `Deletes the ${multiple ? 'selected' : 'current'} additional order product${multiple ? 's' : ''}.`,
          type: 'danger'
        });
        this.$eventHub.$on('ValidateModalStop', async (approve) => {
          this.$eventHub.$off('ValidateModalStop');
          if (!approve) return;
          this.loading = true;
          let error = false;
          for (item of items) {
            error = !await BPA.api.DeleteAdditionalOrderProduct(item.id).then(response => {
              return BPA.api.response({response});
            }).then(response => response.ok).catch(e => e);
            if (error) break;
          }
          if (!error) this.$eventHub.$emit('ShowMessages', {
            message: `Additionl order product${multiple ? 's' : ''} successfully deleted`,
            type: 'success',
            hide: 2000
          });
          await this.Search();
          if (this.modal.mode == 'edit') {
            this.$eventHub.$emit('CloseModal');
          }
          this.loading = false;
        });
      },
      async UpdateShippingPackagingRuleOptions() {
        this.tab[this.tab.active].tab.rules.options = await this.GetShippingCrateRuleOptions();
      },
      async CreateApprovalRequiredProductRule() {
        let tab = this.Tab();
        let item = tab.item;
        this.$eventHub.$emit('ValidateModalStart', {
          approve: 'Yes, create it',
          disapprove: 'No',
          message: 'Creates the current approval rule.',
          type: 'success'
        });
        this.$eventHub.$on('ValidateModalStop', async (approve) => {
          this.$eventHub.$off('ValidateModalStop');
          if (!approve) return;
          this.loading = true;
          if (await this.SetApprovalRequiredProductRule(item)) {
            this.$eventHub.$emit('ShowMessages', {
              message: 'Approval rule successfully created',
              type: 'success',
              hide: 2000
            });
            tab.item = {
              res_country_id: '', 
              sku: ''
            };
            if (this.IsMainCompany()) {
              tab.item.res_company_id = '';
            }
            await this.Search();
          }
          this.loading = false;
        });
      },
      async CreateShippingPackagingType() {
        let data = this.CloneObject(this.form.data);
        this.$eventHub.$emit('ValidateModalStart', {
          approve: 'Yes, create it',
          disapprove: 'No',
          message: 'Creates the current packaging type.',
          type: 'success'
        });
        this.$eventHub.$on('ValidateModalStop', async (approve) => {
          this.$eventHub.$off('ValidateModalStop');
          if (!approve) return;
          this.loading = true;
          if (await this.CreateShippingCrateType(data)) {
            this.$eventHub.$emit('ShowMessages', {
              message: 'Packaging type successfully created',
              type: 'success',
              hide: 2000
            });
            await this.Search();
            this.$eventHub.$emit('CloseModal');
            await this.UpdateShippingPackagingRuleOptions();
          }
          this.loading = false;
        });
      },
      async UpdateShippingPackagingType() {
        let tab = this.Tab();
        let item = this.CloneObject(tab.item);
        let data = this.CloneObject(this.form.data);
        let update = {};
        for (let key in data) {
          if (/img/.test(key)) {
            if (data[key] != item[key].replace(/^.+,/, '')) {
              if (data[key] != item.default_create_img.replace(/^.+,/, '')) {
                update[key] = data[key];
              } else {
                update[key] = null;
              }
            }
          } else if (data[key] != item[key]) {
            update[key] = data[key];
          }
        }
        let update_keys = Object.keys(update);
        let update_length = update_keys.length;
        if (!update_length) {
          this.$eventHub.$emit('CloseModal');
          this.$eventHub.$emit('ShowMessages', {
            message: 'No changes made',
            type: 'warning',
            hide: 2000
          });
          return;
        }
        this.$eventHub.$emit('ValidateModalStart', {
          approve: 'Yes, update it',
          disapprove: 'No',
          message: 'Updates the current packaging type.',
          type: 'success'
        });
        this.$eventHub.$on('ValidateModalStop', async (approve) => {
          this.$eventHub.$off('ValidateModalStop');
          if (!approve) return;
          this.loading = true;
          if (await this.UpdateShippingCrateType({id: data.id, body: update})) {
            this.$eventHub.$emit('ShowMessages', {
              message: 'Packaging type successfully updated',
              type: 'success',
              hide: 2000
            });
            if ('title' in update) {
              await this.UpdateShippingPackagingRuleOptions();
            }
            await this.Search();
            this.$eventHub.$emit('CloseModal');
          }
          this.loading = false;
        });
      },
      async UpdateShippingPackagingRule(item) {
        let update = {};
        let tab = this.Tab();
        let cached_item = tab.cached.find(li => li.id == item.id);
        for (let key in item) {
          if (cached_item[key] != String(item[key]).replace(/^.+,/, '')) {
            update[key] = item[key];
          }
        }
        if (!Object.keys(update).length) return;
        this.$eventHub.$emit('ValidateModalStart', {
          approve: 'Yes, update it',
          disapprove: 'No',
          message: 'Updates the current packaging rule.',
          type: 'success'
        });
        this.$eventHub.$on('ValidateModalStop', async (approve) => {
          this.$eventHub.$off('ValidateModalStop');
          if (!approve) {
            for (let list_item of tab.list) {
              if (list_item.id == cached_item.id) {
                list_item = cached_item;
                break;
              }
            }
            return;
          }
          this.loading = true;
          if (await this.SetShippingCrateRule({...item, ...update})) {
            this.$eventHub.$emit('ShowMessages', {
              message: 'Packaging rule successfully updated',
              type: 'success',
              hide: 2000
            });
            await this.Search();
          }
          this.loading = false;
        });
      },
      async DeleteShippingPackagingType(item = {}) {
        let tab = this.Tab();
        let in_modal = Object.keys(tab.item).length;
        item = in_modal ? tab.item : item;
        let items = [item];
        if (!in_modal) {
          for (let checkbox of this.selected) {
            let table_row = checkbox.closest('tr');
            let row_item = tab.list.find(item => table_row.dataset.item == JSON.stringify(item));
            if (row_item) items.push(row_item);
          }
          items = items.filter((item, index, array) => index == array.findIndex(i => JSON.stringify(i) == JSON.stringify(item)));
        }
        let multiple = items.length > 1;
        this.$eventHub.$emit('ValidateModalStart', {
          approve: `Yes, delete ${multiple ? 'them' : 'it'}`,
          disapprove: 'No',
          message: `Deletes the ${multiple ? 'selected' : 'current'} packaging type${multiple ? 's' : ''}.`,
          type: 'danger'
        });
        this.$eventHub.$on('ValidateModalStop', async (approve) => {
          this.$eventHub.$off('ValidateModalStop');
          if (!approve) return;
          this.loading = true;
          let error = false;
          for (item of items) {
            error = !await this.DeleteShippingCrateType(item.id);
            if (error) break;
          }
          if (!error) this.$eventHub.$emit('ShowMessages', {
            message: `Packaging type${multiple ? 's' : ''} successfully deleted`,
            type: 'success',
            hide: 2000
          });
          await this.Search();
          if (this.modal.mode == 'edit') {
            this.$eventHub.$emit('CloseModal');
          }
          this.loading = false;
        });
      },
      async UpdateCourierConfig() {
        return await BPA.api.UpdateCourierConfig({
          agent_code: this.courier.selected.code,
          body: this.json.data
        }).then(response => {
          return BPA.api.response({response});
        }).then(response => {
          if (!response.ok) return;
          this.json.init = this.json.data;
          this.$eventHub.$emit('ShowMessages', {
            message: 'Shipping label configuration successfully updated',
            type: 'success',
            hide: 2000
          });
        }).catch(e => e);
      },
      async UpdateShippingSupplierTracking() {
        let agent_code = this.courier.selected?.code;
        if (!agent_code) return;
        let tab = this.Tab();
        let init = this.CloneObject(tab.data[agent_code]);
        let data = this.CloneObject(this.form.data);
        let update = {};
        for (let key in data) {
          if (data[key] != init[key]) {

            update[key] = data[key];
          }
        }
        if (!Object.keys(update).length) {
          /*
          this.$eventHub.$emit('ShowMessages', {
            message: 'No changes made',
            type: 'warning',
            hide: 2000
          });
          */
          return;
        }
        this.$eventHub.$emit('ValidateModalStart', {
          approve: 'Yes, update it',
          disapprove: 'No',
          message: 'Updates the current tracking configuration.',
          type: 'success'
        });
        this.$eventHub.$on('ValidateModalStop', async (approve) => {
          this.$eventHub.$off('ValidateModalStop');
          if (!approve) return;
          this.loading = true;
          if (await this.UpdateCourierEventConfig({agent_code, body: update})) {
            tab.data[agent_code] = this.CloneObject(data);
            this.$eventHub.$emit('ShowMessages', {
              message: 'Tracking configuration successfully updated',
              type: 'success',
              hide: 2000
            });
          }
          this.loading = false;
        });
      },
      async HasResParcelConf() {
        return await BPA.api.HasResParcelConf().then(response => {
          return BPA.api.response({response, return: 'json'});
        }).then(response => {
          if (!response.ok || !response.result) return;
          return response.result;
        }).catch(e => e);
      },
      async SearchAdditionalOrderProducts(request) {
        request = request || this.Tab().search;
        return await BPA.api.SearchAdditionalOrderProducts(request).then(response => {
          return BPA.api.response({response, return: 'json'});
        }).then(response => {
          if (!response.ok || !response.result) return;
          for (let item of response.result.items) {
            item.locale = this.locales.find(option => option.code == item.language);
            item.company = BPA.company('name', item.res_company_id);
          }
          return response.result;
        }).catch(e => e);
      },
      async GetAdditionalOrderProduct(request) {
        return await BPA.api.GetAdditionalOrderProduct(request).then(response => {
          return BPA.api.response({response, return: 'json'});
        }).then(response => {
          if (!response.ok || !response.result) return;
          return response.result;
        }).catch(e => e);
      },
      async SetAdditionalOrderProduct(request = {}) {
        let action = 'id' in request ? 'Update' : 'Create';
        return await BPA.api[`${action}AdditionalOrderProduct`](request).then(response => {
          return BPA.api.response({response});
        }).then(response => response.ok).catch(e => e);
      },
      async SetActiveShippingMethod(request) {
        return await BPA.api.SetActiveShippingMethod(request).then(response => {
          return BPA.api.response({response});
        }).then(response => response.ok).catch(e => e);
      },
      async GetAdditionalOrderProductImage(request) {
        return await new Promise((resolve, reject) => {
          BPA.api.GetAdditionalOrderProductImage(request).then(response => {
            return BPA.api.response({response, return: 'blob', error(){}});
          }).then(response => {
            if (!response.ok || !response.result) return reject();
            let reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.readAsDataURL(response.result);
          }).catch(reject);
        }).catch(e => e);
      },
      async GetOrderProductRequirePasswordPin() {
        return await BPA.api.GetOrderProductRequirePasswordPin().then(response => {
          return BPA.api.response({response, return: 'json'});
        }).then(response => {
          if (!response.ok || !response.result) return;
          return response.result.pin || '';
        }).catch(e => e);
      },
      async SetOrderProductRequirePasswordPin(request = '') {
        let action = request ? 'Set' : 'Delete';
        return await BPA.api[`${action}OrderProductRequirePasswordPin`](request).then(response => {
          return BPA.api.response({response});
        }).then(response => response.ok).catch(e => e);
      },
      async SetApprovalRequiredProductRule(request) {
        return await BPA.api.CreateApprovalRequiredProductRule(request).then(response => {
          return BPA.api.response({response});
        }).then(response => response.ok).catch(e => e);
      },
      async DeleteApprovalRequiredProductRule(rule_id) {
        return await BPA.api.DeleteApprovalRequiredProductRule(rule_id).then(response => {
          return BPA.api.response({response});
        }).then(response => response.ok).catch(e => e);
      },
      async SearchApprovalRequiredProductRules(request) {
        return await BPA.api.SearchApprovalRequiredProductRules(request).then(response => {
          return BPA.api.response({response, return: 'json'});
        }).then(response => {
          if (!response.ok || !response.result) return;
          return response.result;
        }).catch(e => e);
      },
      async GetShippingCrateRuleOptions() {
        return await BPA.api.GetShippingCrateRuleOptions().then(response => {
          return BPA.api.response({response, return: 'json'});
        }).then(response => {
          if (!response.ok || !response.result) return;
          return Tool.Alphabetize(response.result.map(option => ({id: option.id, label: option.title})), 'label');
        }).catch(e => e);
      },
      async SetShippingCrateRule(request) {
        return await BPA.api.SetShippingCrateRule(request).then(response => {
          return BPA.api.response({response});
        }).then(response => response.ok).catch(e => e);
      },
      async DeleteShippingCrateRule(rule_id) {
        return await BPA.api.DeleteShippingCrateRule(rule_id).then(response => {
          return BPA.api.response({response});
        }).then(response => response.ok).catch(e => e);
      },
      async SearchShippingCrateTypes(request) {
        return await BPA.api.SearchShippingCrateTypes(request).then(response => {
          return BPA.api.response({response, return: 'json'});
        }).then(response => {
          if (!response.ok || !response.result) return;
          for (let item of response.result.items) {
            item.company = BPA.company('name', item.res_company_id);
          }
          return response.result;
        }).catch(e => e);
      },
      async GetShippingCrateType(type_id) {
        return await BPA.api.GetShippingCrateType(type_id).then(response => {
          return BPA.api.response({response, return: 'json'});
        }).then(response => {
          if (!response.ok || !response.result) return;
          return response.result;
        }).catch(e => e);
      },
      async GetShippingCrateTypeImage(type_id) {
        return await new Promise((resolve, reject) => {
          BPA.api.GetShippingCrateTypeImage(type_id).then(response => {
            return BPA.api.response({response, return: 'blob', error(){}});
          }).then(response => {
            if (!response.ok || !response.result) return reject();
            let reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.readAsDataURL(response.result);
          }).catch(reject);
        }).catch(e => e);
      },
      async CreateShippingCrateType(request) {
        return await BPA.api.CreateShippingCrateType(request).then(response => {
          return BPA.api.response({response});
        }).then(response => response.ok).catch(e => e);
      },
      async UpdateShippingCrateType(request) {
        return await BPA.api.UpdateShippingCrateType(request).then(response => {
          return BPA.api.response({response});
        }).then(response => response.ok).catch(e => e);
      },
      async DeleteShippingCrateType(type_id) {
        return await BPA.api.DeleteShippingCrateType(type_id).then(response => {
          return BPA.api.response({response});
        }).then(response => response.ok).catch(e => e);
      },
      async Toggle(item = {}, event = {}) {
        let tab = this.Tab();
        let items = [{...item, ...{toggle: event.target}}];
        for (let checkbox of this.selected) {
          let table_row = checkbox.closest('tr');
          let toggle = table_row.querySelector('.checkbox-toggle > input');
          let row_item = tab.list.find(item => table_row.dataset.item == JSON.stringify(item));
          if (row_item && row_item.active == item.active) {
            items.push({...row_item, ...{toggle: toggle}});
          }
        }
        items = items.filter((item, index, array) => index == array.findIndex(i => JSON.stringify(i) == JSON.stringify(item)));
        for (let item of items) item.toggle.checked = event.target.checked;
        let multiple = items.length > 1;
        let active = !item.active ? true : false;
        let status = !item.active ? 'enable' : 'disable';
        this.$eventHub.$emit('ValidateModalStart', {
          approve: `Yes, ${status} ${multiple ? 'them' : 'it'}`,
          disapprove: 'No',
          message: `${this.Capitalize(status)}s the ${multiple ? 'selected' : 'current'} shipping method${multiple ? 's' : ''}.`,
          type: !item.active ? 'success' : 'danger'
        });
        this.$eventHub.$on('ValidateModalStop', async (approve) => {
          this.$eventHub.$off('ValidateModalStop');
          if (!approve) {
            for (let item of items) {
              item.toggle.checked = !!item.active;
            }
            return;
          }
          this.loading = true;
          for (let item of items) {
            item.active = active;
            await this.SetActiveShippingMethod(item);
          }
          this.$eventHub.$emit('ShowMessages', {
            message: `Shipping method${multiple ? 's' : ''} successfully ${status}d`,
            type: 'success',
            hide: 2000
          });
          await this.Search();
          if (this.modal.mode == 'edit') {
            this.$eventHub.$emit('CloseModal');
          }
          this.loading = false;
        });
      },
      ToggleAllCheckboxes(e) {
        let selected = [];
        let checked = e.target.checked;
        let items = document.querySelectorAll('tr[data-item]');
        for (let i = 0; i < items.length; i++) {
          let checkbox = items[i].querySelector('.v-checkbox');
          if (checkbox) {
            checkbox.checked = checked;
            if (checked) {
              selected.push(checkbox);
            }
          }
        }
        this.selected = selected;
      },
      ToggleCheckbox(e) {
        let e_target = e.target;
        if (!e_target || e_target.localName == 'span') return;
        if (e_target.localName == 'td') {
          e_target = e_target.querySelector('.v-checkbox');
          e_target.checked = !e_target.checked;
          return e_target.dispatchEvent(new Event('input'));
        }
        let selected = [];
        let checked = e_target.checked;
        let select_all = this.$refs.select_all;
        let items = document.querySelectorAll('tr[data-item]');
        if (!checked) select_all.checked = false;
        for (let i = 0; i < items.length; i++) {
          let checkbox = items[i].querySelector('.v-checkbox');
          if (checkbox && checkbox.checked) {
            selected.push(checkbox);
          }
        }
        if (selected.length == items.length) {
          if (select_all) select_all.checked = true;
        }
        this.selected = selected;
      },
      ClickOpen(event) {
        if (!event) return;
        let elm = event.target, row;
        if (elm.localName != 'tr') row = elm.closest('tr');
        const mousedown = (state) => {
          if (state === true || state === false) {
            row.mousedown = state;
          } else {
            return row.mousedown;
          }
        }
        if (!row.classList.contains('clickable')) return;
        for (let i = 0; i < this.clickables.length; i++) {
          if (this.clickables[i] != row) {
            this.clickables[i].classList.remove('hover', 'selected');
          }
        }
        if (elm.type == 'checkbox') return;
        if (/label|checkbox/.test(elm.className)) return;
        if (elm.localName == 'a' || elm.parentElement.localName == 'a') return;
        if (event.type == 'mousedown') {
          mousedown(event.which == 1);
        }
        if (event.type == 'mousemove') {
          mousedown(false);
        }
        if (event.type == 'mouseup') {
          if (mousedown()) {
            this.OpenModal('edit', JSON.parse(row.dataset.item));
          } 
          mousedown(false);
        }
      },
      async PageController(next_page) {
        let load_page;
        const page = {};
        const tab = this.Tab();
        document.querySelectorAll('.page-turn').forEach(arrow => {
          if (arrow) page[arrow.classList.contains('prev') ? 'prev' : 'next'] = arrow;
        });
        if (next_page) {
          if (tab.page.current != tab.page.last) {
            tab.page.current++;
            load_page = true;
            if (tab.page.current == tab.page.last) {
              page.next.classList.add('disabled');
            }
            page.prev.classList.remove('disabled');
          }
        } else {
          if (tab.page.current > 1) {
            tab.page.current--;
            load_page = true;
            if (tab.page.current == 1) {
              page.prev.classList.add('disabled');
            }
            page.next.classList.remove('disabled');
          }
        }
        if (load_page) {
          this.loading = true;
          await this.Search();
          this.loading = false;
        }
      },
      async PageNavigator(e) {
        const page = {
          event: e.type,
          input: e.target,
          last: Number(e.target.max),
          first: Number(e.target.min),
          number: Number(e.target.value)
        }
        const tab = this.Tab();
        const within_limits = (n) => {
          return n >= page.first && n <= page.last;
        }
        const set_page_number = (n) => {
          tab.page.number = n || page.number;
          page.number = Number(tab.page.number);
        }
        document.querySelectorAll('.page-turn').forEach(arrow => {
          if (arrow) page[arrow.classList.contains('prev') ? 'prev' : 'next'] = arrow;
        });
        set_page_number();
        if (page.event == 'keydown') {
          if (e.key == 'Enter') {
            page.input.blur();
          }
        }
        if (page.event == 'blur') {
          ['prev', 'next'].map(arrow => {
            page[arrow] && page[arrow].classList.remove('disabled');
          });
          if (page.number <= page.first) {
            set_page_number(page.first);
            page.input.value = page.first;
            page.prev.classList.add('disabled');
            if (page.last == page.first) {
              page.next.classList.add('disabled');
            }
          } else if (page.number >= page.last) {
            set_page_number(page.last);
            page.input.value = page.last;
            page.next.classList.add('disabled');
            if (page.first == page.last) {
              page.prev.classList.add('disabled');
            }
          }
          if (within_limits(page.number) && page.number != tab.page.current) {
            tab.page.current = page.number;
            this.loading = true;
            await this.Search();
            this.loading = false;
          }
        }
      },
      SetPageJumpWidth() {
        const current = document.querySelector('.page-number-current');
        const last = document.querySelector('.page-number-last');
        if (current && last) {
          const rect = last.getBoundingClientRect();
          const input = current.querySelector('input');
          if (rect && rect.width) {
            current.style.setProperty('width', rect.width + 'px');
          } else {
            current.style.removeProperty('width');
          }
          if (input) {
            input.dispatchEvent(new Event('blur'));
          }
        }
      },
      IsMainCompany() {
        return BPA.util.IsMainCompany();
      },
      dropdownShouldOpen(VueSelect) {
        return this.IsMainCompany() && VueSelect.open
      }
    }
  }
</script>

<style lang="scss">
  @import '../../../assets/style/variables/vital';
  
  .settings-packing-config-view {
    height: 100%;
    display: flex;
    position: relative;
    flex-direction: column;
    
    .title {
      font-size: 1.622rem;
      font-weight: 600;
      margin-bottom: 35px;
    }

    .item-submenu {
      width: 100%;
      display: flex;
      position: relative;
      background-color: #f4f4f4;

      &__item {
        opacity: 0.7;
        color: #606060;
        font-weight: bold;
        user-select: none;
        position: relative;
        margin: 10px 20px 0;
        padding-bottom: 10px;

        &:hover {
          opacity: 1;
        }
        
        &:not(.active) {
          cursor: pointer;
        }

        &.active {
          opacity: 1;
          color: #1b577a;
          font-weight: bold;
          text-decoration: underline;
        }
      }
    }

    .item {
      &-wrapper {
        &.noflex {
          display: block;
        }
      }
    }

    .max-width-300 {
      max-width: 300px;
      align-items: center;
    }

    .flex {
      &-group {
        & + .flex-group {
          margin-top: 30px;
        }

        &.border-top {
          border-top: 2px dashed $lightGrey;
          padding-top: 30px;
          margin-top: 40px;
        }
      }

      &-row {
        & + .flex-row {
          margin-top: 20px;
        }

        .flex {
          &-column {
            display: flex;
            flex-direction: column;
            flex: 1;
            flex: 0 0 calc(25% - 23px);

            &.hidden {
              display: none;
            }
          
            &:not(.hidden):not([style*="display: none"]) ~ .flex-column {
              margin-left: 30px;
            }

            &.flex1 {
              flex: 1;
              min-width: 293px;
            }

            &:not(.flex1) {
              max-width: calc(25% - 23px);
            }

            &.half {
              max-width: calc(50% - 16px);
            }
          }
        }
      }
    }

    .sticky-footer {
      bottom: 0;
      z-index: 6;
      margin-top: 15px;
      margin-bottom: -15px;
      border-top: 2px solid lightgray;
      padding-top: 15px;
      padding-bottom: 15px;
      position: sticky;
      background: white;
    }
  }
</style>