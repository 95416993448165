<template>
  <div class="settings settings-bulk-invoicing-config grid-wrapper">
    <!--
    <p class="title" style="display: flex;">
      {{ $t('Configurations') }}
      <a class="button" @click.prevent="OpenModal('create')" href="" style="margin-left: auto;">{{ $t('Create configuration') }}</a>
    </p>
    -->
    <div class="grid-info">
      <span class="grid-heading">
        <h1>{{ $t('Bulk invoicing') }}</h1>
        <span class="entries">{{ NumberFormat(entries) }} {{ $t(`entr${entries == 1 ? 'y' : 'ies'}`) }}</span>
      </span>
      <div class="grid-actions">
        <a class="button" style="margin-left: auto;" @click.prevent="OpenModal('create')" href="">{{ $t('Create') }}</a>
      </div>
      <!--
      <div class="grid-search">
        <input type="text" v-model="search.query" @keyup.enter="FilterSearch(), $event.target.blur()" :placeholder="$t('Search')">
      </div>
      -->
      <div class="grid-search-filter">
        <div class="grid-locked">
          <div class="toggle locked">
            <div class="grid-heading">{{ $t('Active') }}</div>
            <div class="checkbox-toggle">
              <input @click="FilterByActive" type="checkbox" v-model="search.active">
              <span class="toggle" />
            </div>
          </div>
        </div>
        <div class="grid-state" style="max-width: 225px;">
          <p class="grid-heading">{{ $t('Delivery company') }}</p>
          <v-select class="config-courier-select" @input="FilterByCourier" :value="courier.options.find(option => option.code == search.agent_code)" :options="courier.options">
            <template v-slot:selected-option="option">
              <div class="truncate">
                <span :class="['courier', option.code]">
                  {{ option.label }}
                </span>
              </div>
            </template>
            <template slot="option" slot-scope="option">
              <div class="truncate">
                <span :class="['courier', option.code]">
                  {{ option.label }}
                </span>
              </div>
            </template>
          </v-select>
        </div>
        <div class="grid-state" style="max-width: 300px;">
          <p class="grid-heading">{{ $t('Country') }}</p>
          <v-select name="config-country-select" @input="FilterByCountry" :value="country.options.find(option => option.id == search.country_id)" :options="country.options.map(option => {option.label = CountryName(option.code); return option})">
            <template v-slot:selected-option="option">
              <div class="truncate">
                <Flag :code="option.code" size="small" />
                <span>{{ option.label }}</span>
              </div>
            </template>
            <template slot="option" slot-scope="option">
              <div class="truncate">
                <Flag :code="option.code" size="small" />
                <span>{{ option.label }}</span>
              </div>
            </template>
          </v-select>
        </div>
        <div class="grid-state">
          <p class="grid-heading">{{ $t('Page size') }}</p>
          <v-select name="page-size-select" @input="FilterBySize" v-model="search.page_size" :options="sizes" :clearable="false" />
        </div>
      </div>
    </div>
    <div>
      <table class="list table odd-even">
        <tbody>
          <tr :data-id="item.id" :class="['config-list__row', {clickable: true}]" @mousedown="ClickOpen" @mousemove="ClickOpen" @mouseup="ClickOpen" :key="item.id" v-for="item in configs.items">
            <td class="label" style="display: table-cell;" @click="ToggleCheckbox">
              <div class="v-wrapper checkbox">
                <label class="v-checkbox-label">
                  <input class="v-checkbox" type="checkbox" :data-id="item.id" @input="ToggleCheckbox">
                  <span class="v-checkbox-toggle" />
                </label>
              </div>
            </td>
            <td>
              <div>{{ item.id }}</div>
            </td>
            <td>
              <div class="checkbox-toggle mini">
                <input type="checkbox" :checked="item.active" @input="Toggle(item, $event)">
                <span class="toggle"></span>
              </div>
            </td>
            <td class="trackingnumber">
              <div style="display: flex; align-items: center;">
                <div v-if="item.agent_code" :class="['courier-icon', item.agent_code]" />
                <span style="margin-left: 0px;">{{ item.agent_name }}</span>
              </div>
            </td>
            <td>
              <div style="display: flex; align-items: center;">
                <Flag v-if="item.country_code" :code="item.country_code" size="small" type="country" />
                <span style="margin-left: 10px;">{{ CountryName(item.country_code) }}</span>
              </div>
            </td>
            <td class="actions" @mouseenter="SetBackground" @mouseleave="SetBackground">
              <a class="icon dots" @click.prevent="" href="">
                <ul class="item-actions">
                  <li><a @click.prevent="OpenModal('edit', item)" href="">{{ $t('Edit') }}</a></li>
                  <li><a @click.prevent="Copy(item.id)" href="" class="">{{ $t('Copy') }}</a></li>
                </ul>
              </a>
            </td>
          </tr>
        </tbody>
        <thead>
          <tr>
            <th class="label" style="display: table-cell;">
              <div class="v-wrapper checkbox">
                <label class="v-checkbox-label">
                  <input class="v-checkbox" type="checkbox" ref="select_all" @input="ToggleAllCheckboxes">
                  <span class="v-checkbox-toggle toggle-all" />
                </label>
              </div>
            </th>
            <th>{{ $t('ID') }}</th>
            <th>{{ $t('Active') }}</th>
            <th>{{ $t('Delivery company') }}</th>
            <th>{{ $t('Country') }}</th>
            <th class="edit" />
          </tr>
        </thead>
      </table>
      <div class="grid-pagination shadow sticky bottom" :style="{marginTop: configs.items.length && '2px'}">
        <div class="page-navigation">
          <div class="page-turn prev disabled" @click="PageController(false)"></div>
          <div class="page-number">
            <label class="page-number-current">
              <input type="number" min="1" :max="page.last" :value="page.current" @blur="PageNavigator" @keydown="PageNavigator">
              <span class="placeholder">{{NumberFormat(page.current)}}</span>
            </label>
            <span class="page-number-separator">/</span>
            <div class="page-number-last">{{NumberFormat(page.last)}}</div>
          </div>
          <div class="page-turn next" :class="{disabled: page.last == 1}" @click="PageController(true)"></div>
        </div>
      </div>
    </div>

    <Modal modal="modal" :value="modal.open" :title="(modal.mode == 'create' ? $t('New bulk invoicing configuration') : ($t('Bulk invoicing configuration') + ' #' + config.id))">
      <div id="config" v-if="modal.open">
        <div class="modal-header"></div>
        <div class="modal-content scrollable">
          <div class="modal-tabs">
            <div class="modal-tabs__body">
              <div class="modal-tabs__body-content padding">
                <form @submit.prevent style="padding-bottom: 25px;">
                  <button ref="submit" type="submit" hidden />
                  <div v-for="(group, index) in form.fields" :key="index" :class="['flex-group', {'field-group': group.some(field => /__/.test(field.name))}]">
                    <h3 class="heading" v-if="group.some(field => /__/.test(field.name))">{{ $t(Capitalize(group.some(field => /address/.test(field.name)) ? group[0].name.split('__')[0].split('_')[1] : group.some(field => /type_of/.test(field.name)) ? /*group[0].name.split('__')[0].replace(/_/g, ' ')*/ 'Shipment type' : group.some(field => /send_to/.test(field.name)) ? 'Send to' : ''))}}</h3>
                    <div class="flex-row" style="align-items: flex-end" :style="group.some(field => /type_of/.test(field.name)) && {display: 'grid', gridTemplateColumns: 'repeat(5, 253px)'}">
                      <div v-for="(field, index) in group" :key="index" :class="['flex-column', {flex1: field.fill_width}]" :style="[field.name == 'email' ? 'flex: 1; max-width: 627px;' : '', {maxWidth: /other/.test(field.name) ? 'unset' : field.type == 'checkbox' && 'max-content', marginLeft: /other/.test(field.name) && '-23px'}]" v-show="!field.hidden">
                        <div v-if="field.type == 'list'" class="list-wrapper" style="width: 100%; display: flex; flex-direction: column;">
                          <div class="simple-list__title">
                            <p v-for="(label, index) in field.columns" :key="index">{{ $t(Capitalize(label)) }}</p>
                            <div class="simple-list__item item-wrapper" style="width: auto; padding: 0;">
                              <button :class="['item-wrapper__button remove', {hover: field.list.length}]" :disabled="!field.list.length" @click.prevent="ClearList(field)" @mouseenter="HoverAll" @mouseleave="HoverAll" />
                            </div>
                          </div>
                          <ul class="simple-list odd-even">
                            <li class="simple-list__add">
                              <div class="item-wrapper" v-for="(column, index) in field.columns" :key="index">
                                <input :class="['item-wrapper__input', {lowercase: /email/.test(column)}]" :ref="column" v-model="field.value[column]" @keydown.space="(/email/.test(column) || !field.value[column]) && $event.preventDefault()" @keydown.enter.prevent="$event.target.blur(), AddItem(field, column)" @blur="/email/.test(column) ? (field.value[column] = String(field.value[column]).toLowerCase().replace(/\s/g, '')) : /name/.test(column) ? (field.value[column] = String(field.value[column]).replace(/\s+/g, ' ').trim()) : null" spellcheck="false">
                              </div>
                              <div class="item-wrapper" style="width: auto;">
                                <button class="item-wrapper__button add" @click.prevent="AddItem(field)" />
                              </div>
                            </li>
                            <li class="simple-list__item" v-for="(item, index) in field.list" :key="index">
                              <div class="item-wrapper" v-for="(column, index) in field.columns" :key="index">
                                <p class="item-wrapper__text" style="padding-left: 9px;">
                                  <span>{{ item[column] }}</span>
                                </p>
                              </div>
                              <div class="item-wrapper" style="width: auto;">
                                <button class="item-wrapper__button remove" @click.prevent="RemoveItem(field, item)" @mouseenter="HoverButton" @mouseleave="HoverButton" />
                              </div>
                            </li>
                          </ul>
                        </div>
                        <div v-else :class="['label', {required: field.required}]" :style="(field.type == 'checkbox' || /other/.test(field.name)) && {flexDirection: `row${field.type == 'checkbox' ? '-reverse' : ''}`, alignItems: 'center', marginBottom: field.type == 'checkbox' && '5px'}">
                          <span v-if="/&/.test(field.label)" class="label-text">
                            {{ $t(field.label.split(' & ')[0]) + ' ' + $t('and') + ' ' + $t(field.label.split(' & ')[1]) }}
                          </span>
                          <span v-else class="label-text">
                            {{ $t(field.label || Capitalize(field.name).replace(/_/g, ' ')) }}
                          </span>
                          <v-select v-if="field.type == 'select'" :name="field.name" :required="field.required" :disabled="field.disabled" :value="field.options.find(option => option[/country_id/.test(field.name) ? 'id' : 'code'] == field.value)" :options="/country/.test(field.name) ? field.options.map(o => {o.label = CountryName(o.code); return o;}) : field.options" :title="field.title" :clearable="field.clearable" v-on="field.input ? {input: field.input} : {}">
                            <template #search="{attributes, events}">
                              <input class="vs__search" v-bind="attributes" v-on="events" :required="field.required && !form.data[field.name]">
                            </template>
                            <template v-slot:selected-option="option">
                              <div class="truncate" :title="option.label">
                                <Flag v-if="/country/.test(field.name)" :code="option.code" size="small" />
                                <span :class="[/agent/.test(field.name) && 'courier', option.code]">{{ option.label }}</span>
                              </div>
                            </template>
                            <template slot="option" slot-scope="option">
                              <div class="truncate" :title="option.label">
                                <Flag v-if="/country/.test(field.name)" :code="option.code" size="small" />
                                <span :class="[/agent/.test(field.name) && 'courier', option.code]">{{ option.label }}</span>
                              </div>
                            </template>
                          </v-select>
                          <div v-else-if="field.type == 'datetime'" class="v-wrapper-date time">
                            <VueCtkDateTimePicker :name="field.name" :required="field.required" :value="form.data[field.name]" label="" hint="" :locale="$i18n.locale.split('_')[0].replace(/no/, 'nb')" format="YYYY-MM-DD HH:mm:ss" :first-day-of-week="1" input-size="sm" :range="false" :no-shortcuts="true" :no-button="true" :auto-close="false" @input="field.input ? field.input($event) : {}" />
                          </div>
                          <div v-else-if="field.type == 'toggle'" class="checkbox-toggle">
                            <input type="checkbox" :name="field.name" :disabled="field.disabled" :checked="field.value" @input="field.input ? field.input($event) : {}">
                            <span class="toggle" />
                          </div>
                          <div v-else-if="field.type == 'checkbox'" class="v-wrapper checkbox">
                            <label class="v-checkbox-label" style="margin-right: 10px;">
                              <input class="v-checkbox" :type="field.type" :name="field.name" :disabled="field.disabled" :checked="field.value" @input="field.input ? field.input($event) : {}">
                              <span class="v-checkbox-toggle" style="width: 24px; height: 24px;" />
                            </label>
                          </div>
                          <input v-else class="v-input" :style="/other/.test(field.name) && {marginLeft: '10px', width: '223px'}" :type="field.type" :name="field.name" :required="field.required" :readonly="field.readonly" :pattern="field.pattern" :min="field.min" :max="field.max" :minlength="field.minlength" :step="field.step" :value="field.value" :title="field.title" @keydown.enter="$event.target.blur()" @input="field.input ? field.input($event) : {}" @blur="field.blur ? field.blur($event) : {}" :placeholder="/link/.test(field.name) && field.placeholder">
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div class="button-container actions">
            <div class="label" style="flex-direction: row; align-items: center; margin-right: auto;">
              <div class="checkbox-toggle">
                <input type="checkbox" v-model="form.data.active">
                <span class="toggle" />
              </div>
              <span style="margin-left: 10px;">{{ $t('Active') }}</span>
            </div>
            <button class="button green" @click.prevent="Update" v-if="modal.mode == 'edit'">{{ $t('Save') }}</button>
            <!-- <button class="button red" @click.prevent="Delete" v-if="modal.mode == 'edit'">{{ $t('Delete') }}</button> -->
            <button class="button green" @click.prevent="Copy()" v-if="modal.mode == 'edit'">{{ $t('Copy') }}</button>
            <button class="button green" @click.prevent="Create" v-if="modal.mode == 'create'">{{ $t('Create') }}</button>
          </div>
        </div>
      </div>
    </Modal>

    <transition name="fade">
      <div @pointerdown="dialog.event" @pointerup="dialog.event" class="validate-popup" v-if="dialog.show">
        <div class="validate-popup__content">
          <div class="titles">
            <p class="title">Are you sure?</p>
            <p class="subtitle"></p>
            <div class="dialog-form" style="margin-bottom: 15px;">
              <form @submit.prevent>
                <button ref="dialog_submit" type="submit" hidden />
                <div class="flex-column">
                  <div class="select-dropdown" style="margin-bottom: 15px;">
                    <div class="label required">
                      <span class="label-text">{{ $t('Delivery company') }}</span>
                      <v-select v-model="dialog.form.data.agent" :options="courier.options" :clearable="false" required>
                        <template #search="{attributes, events}">
                          <input class="vs__search" v-bind="attributes" v-on="events" :required="!dialog.form.data.agent">
                        </template>
                        <template v-slot:selected-option="option">
                          <span :class="['courier', option.code]">
                            {{ option.label }}
                          </span>
                        </template>
                        <template slot="option" slot-scope="option">
                          <span :class="['courier', option.code]">
                            {{ option.label }}
                          </span>
                        </template>
                      </v-select>
                    </div>
                    <div class="label required" style="margin-top: 15px;">
                      <span class="label-text">{{ $t('Country') }}</span>
                      <v-select v-model="dialog.form.data.country" :value="country.options.find(option => option.id == search.country_id)" :options="country.options.map(option => {option.label = CountryName(option.code); return option})" required>
                        <template #search="{attributes, events}">
                          <input class="vs__search" v-bind="attributes" v-on="events" :required="!dialog.form.data.country">
                        </template>
                        <template v-slot:selected-option="option">
                          <div class="truncate">
                            <Flag :code="option.code" size="small" />
                            <span>{{ option.label }}</span>
                          </div>
                        </template>
                        <template slot="option" slot-scope="option">
                          <div class="truncate">
                            <Flag :code="option.code" size="small" />
                            <span>{{ option.label }}</span>
                          </div>
                        </template>
                      </v-select>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div class="actions">
            <a class="btn-stateless cancel" @click.prevent="dialog.cancel" href="">No</a>
            <a class="btn-success confirm" @click.prevent="dialog.confirm" href="">Yes, copy it</a>
          </div>
        </div>
      </div>
    </transition>

    <div class="loading" v-if="loading">
      <div class="loading-element">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>

  </div>
</template>

<script>
  import { TableElementsBehaviour } from '@/mixins/TableElementsBehaviour';
  import { Config }     from '@/helpers/Config';
  import { Tool }       from '@/helpers/Tool';
  import { BPA }        from '@/helpers/BPA';
  //import Dialog         from '@/components/snippets/Dialog';
  import Modal          from '@/components/snippets/Modal';
  import Flag           from '@/components/snippets/Flag';

  export default {
    name: 'SettingsBulkInvoicingConfigView', 
    mixins: [
      TableElementsBehaviour, 
      Config, 
      Tool, 
      BPA
    ],
    components: {
      //Dialog,
      Modal,
      Flag
    },
    data() {
      return {
        loading: false,
        entries: 0,
        cached: {},
        clickables: [],
        selected: [],
        config: {},
        configs: {
          items: []
        },
        search: {
          query: '',
          active: false,
          initial: null,
          agent_code: '',
          country_id: '',
          page_size: 20,
          page_offset: 1
        },
        page: {
          current: 1,
          number: 1,
          last: 1
        },        
        country: {
          options: []
        },
        courier: {
          options: []
        },
        sizes: [10, 20, 30, 50, 100],
        template: {
          fields: [
            {
              agent_code: '',
              country_id: '',
              add_date: false,
              sum_country_of_origin_and_tariff: false
            },
            [
              {
                address_sender__name: '',
                address_sender__address: '',
                address_sender__postal_code_city: '',
                address_sender__country: ''
              },
              {
                address_sender__phone: '',
                address_sender__email: '',
                address_sender__fax: '',
                address_sender__vat_no: ''
              }
            ],
            [
              {
                address_invoicing__name: '',
                address_invoicing__address: '',
                address_invoicing__postal_code_city: '',
                address_invoicing__country: ''
              },
              {
                address_invoicing__phone: '',
                address_invoicing__email: '',
                address_invoicing__fax: '',
                address_invoicing__vat_no: ''
              }
            ],
            [
              {
                address_recipient__name: '',
                address_recipient__address: '',
                address_recipient__postal_code_city: '',
                address_recipient__country: ''
              },
              {
                address_recipient__phone: '',
                address_recipient__email: '',
                address_recipient__fax: '',
                address_recipient__vat_no: ''
              }
            ],
            {
              terms_of_delivery: '',
              terms_of_payment: ''
            },
            {
              category_of_transmission: ''
            },
            [
              {
                type_of_shipment__gift: false,
                type_of_shipment__return_after_loan: false,
                type_of_shipment__exhibition_show: false,
                type_of_shipment__for_repair: false,
                type_of_shipment__return_after_repair: false
              },
              {
                type_of_shipment__return_for_credit: false,
                type_of_shipment__for_repair_guarantee: false,
                type_of_shipment__preparation: false,
                type_of_shipment__sample: false,
                type_of_shipment__other: ''
              }
            ],
            {
              declaration_of_origin: ''
            },
            {
              place: '',
              signature: '',
              name_in_capital_letters: ''
            },
            {
              send_to__: {
                email: '',
                name: ''
              }
            }
          ],
          data: {
            add_date: false,
            address_invoicing: {
              address: '',
              country: '',
              email: '',
              fax: '',
              name: '',
              phone: '',
              postal_code_city: '',
              vat_no: ''
            },
            address_recipient: {
              address: '',
              country: '',
              email: '',
              fax: '',
              name: '',
              phone: '',
              postal_code_city: '',
              vat_no: ''
            },
            address_sender: {
              address: '',
              country: '',
              email: '',
              fax: '',
              name: '',
              phone: '',
              postal_code_city: '',
              vat_no: ''
            },
            category_of_transmission: '',
            declaration_of_origin: '',
            name_in_capital_letters: '',
            place: '',
            send_to: {},
            signature: '',
            sum_country_of_origin_and_tariff: true,
            terms_of_delivery: '',
            terms_of_payment: '',
            type_of_shipment: {
              exhibition_show: false,
              for_repair: false,
              for_repair_guarantee: false,
              gift: false,
              other: '',
              preparation: false,
              return_after_loan: false,
              return_after_repair: false,
              return_for_credit: false,
              sample: false
            }
          }
        },
        form: {
          fields: [],
          data: {}
        },
        modal: {
          mode: 'create',
          open: false
        },
        dialog: {
          show: false,
          event() {},
          form: {
            data: {},
            submit() {}
          },
          config_id: null,
          confirm() {},
          cancel() {},
          close() {}
        }
      }
    },
    async created() {
      const dialog = this.dialog;
      dialog.event = (event) => {
        if (/down/.test(event.type)) {
          dialog.event.target = event.target;
        } else if (event.target == dialog.event.target) {
          if (event.target.classList.contains('validate-popup')) {
            dialog.close();
          }
          dialog.event.target = null;
        }
      }
      dialog.submit = async () => {
        const dialog_submit = this.$refs.dialog_submit;
        if (!await this.ValidateForm(dialog_submit)) return;
        const dialog_data = this.CloneObject(dialog.form.data);
        //const config_data = this.CloneObject(this.form.data);
        const config_data = {config_id: dialog.config_id};
        config_data.agent_code = dialog_data.agent.code;
        config_data.country_id = dialog_data.country.id;
        this.loading = true;
        if (await this.CopyConfig(config_data)) {
          this.$eventHub.$emit('ShowMessages', {
            message: `Configuration #${dialog.config_id} successfully copied`,
            type: 'success',
            hide: 2000
          });
          dialog.form.data = {};
          await this.SearchConfig();
          this.$eventHub.$emit('CloseModal');
        }
        this.loading = false;
      }
      dialog.confirm = async () => {
        await dialog.submit();
        dialog.close();
      }
      dialog.cancel = () => {
        dialog.close();
      }
      dialog.close = () => {
        dialog.show = false;
      }
      dialog.open = () => {
        dialog.show = true;
      }
    },
    async mounted() {
      this.$eventHub.$on('CloseModal', () => {
        this.main.view.classList.remove('no-scroll');
        this.form = {fields: [], data: {}};
        this.modal.open = false;
        this.config = {};
        this.cached.config = {};
        BPA.cache.session({name: this.$options.name, set: {config: {}}});
        for (let i = 0; i < this.clickables.length; i++) {
          if (this.clickables[i].classList.contains('selected')) {
            this.clickables[i].classList.remove('selected');
          }
        }
      });

      this.cached = BPA.cache.local({name: this.$options.name, get: ['page', 'search']});
      for (let key of Object.keys(this.cached)) this[key] = {...this[key], ...this.cached[key]};
      this.cached = {...this.cached, ...BPA.cache.session({name: this.$options.name, get: 'config'})};

      Object.entries(BPA.api.Couriers('GET')).map(([key, value]) => {
        if (key != 'shop') this.courier.options.push({code: key, label: value});
      });

      BPA.api.Countries('GET').map(country => {
        let code = (country.iso_code_2).toUpperCase();
        this.country.options.push({id: country.id, code, label: country.name});
      });

      await this.SearchConfig();
      this.loading = false;
    },
    destroyed() {},
    computed: {
      main() {
        const main = {
          company: BPA.util.GetCompany(),
          view: document.querySelector('.content-scroll')
        }
        main.view.scroll = {top: 0};
        return main;
      },
      locale() {
        return this.$i18n.locale;
      }
    },
    watch: {
      locale() {
        //this.state = this.$t(this.Capitalize(this.search.state));
      }
    },
    methods: {
      ConsoleLog(output) {
        console.log(output);
      },
      CloneObject(object = {}) {
        return Tool.CloneObject(object);
      },
      DateFormat(date) {
        return Tool.DateFormat(date);
      },
      NumberFormat(number) {
        return Tool.NumberFormat(number);
      },
      Alphabetize(list, prop) {
        return Tool.Alphabetize(list, prop);
      },
      Capitalize(string = '') {
        return Tool.Capitalize(string);
      },
      TitleCase(string = '') {
        return Tool.TitleCase(string);
      },
      Hyperlink(props = {}) {
        return Tool.Hyperlink(props);
      },
      CountryName(country_code = '') {
        return Tool.CountryName(country_code);
      },
      LanguageName(country_code = '') {
        return Tool.LanguageName(country_code);
      },
      async OpenModal(mode, config) {
        if (mode == 'edit') {
          for (let i = 0; i < this.clickables.length; i++) {
            if (this.clickables[i].dataset.id == config.id) {
              this.clickables[i].classList.add('selected');
              if (this.cached.config && Object.keys(this.cached.config).length) {
                this.clickables[i].scrollIntoView({
                  behavior: 'auto',
                  block: 'center',
                  inline: 'center'
                });
              }
              break;
            }
          }
          if (this.config.id != config.id) {
            this.loading = true;
            this.config = await this.GetConfig(config.id);
          }
          BPA.cache.session({name: this.$options.name, set: {config: this.config}});
        } else {
          this.config = config;
        }
        this.modal.mode = mode;
        await this.SetupForm(this.config);
        this.modal.open = true;
        this.loading = false;
      },
      ClearForm() {
        this.form = {
          fields: [],
          data: {}
        };
      },
      HoverButton(event) {
        let button = event.target;
        button.classList.toggle('hover');
			},
      HoverAll(event) {
        let button = event.target;
        let wrapper = button.closest('.list-wrapper');
        let list = wrapper.querySelector('.simple-list');
        let items = list.querySelectorAll('.simple-list__item');
				for (let item of items) {
          button = item.querySelector('.item-wrapper__button');
          button.classList.toggle('hover');
        }
			},
      async ValidateForm(button) {
        return await new Promise(resolve => {
          if (button) {
            const form = button.closest('form');
            const fields = Array.from(form.elements);
            if (fields.some(field => !field.checkValidity())) {
              return button.click();
            }
          }
          resolve(true);
        }).catch(e => e);
      },
      async SetupForm(config) {
        return await new Promise(async resolve => {
          const fields = [];
          const split = '__';
          const data = {active: true, send_to: {}};
          const template = this.CloneObject(this.template.fields);
          let form = this.CloneObject(config || template);
          config = this.CloneObject(config);
          if (!Array.isArray(form)) {
            for (let group of template) {
              if (Array.isArray(group)) {
                let group_key = Object.keys(group[0])[0].split(split)[0];
                for (let item of group) {
                  for (let item_key in item) {
                    let key = item_key.replace(new RegExp(group_key + split), '');
                    let value = config[group_key][key];
                    if (value == undefined) value = '';
                    item[item_key] = value;
                  }
                }
              } else {
                for (let key in group) {
                  if (/send_to/.test(key)) {
                    let group_key = key.replace(/__/g, '');
                    group[key] = config[group_key];
                    if (!group[key]) {
                      let template_fields = this.CloneObject(this.template.fields);
                      let template_field = template_fields.find(field => Object.keys(field).includes(key));
                      if (template_field) group[key] = template_field[key];
                    }
                  } else {
                    group[key] = config[key];
                  }
                }
              }
            }
            data.send_to = config.send_to;
            data.active = config.active;
            data.id = config.id;
            form = template;
          }
          for (let group of form) {
            let row = [];
            if (Array.isArray(group)) {
              let group_key = Object.keys(group[0])[0].split(split)[0];
              data[group_key] = {};
              for (let item of group) {
                row = [];
                for (let item_key in item) {
                  let key = item_key.replace(new RegExp(group_key + split), '');
                  data[group_key][key] = item[item_key];
                  let field = {name: item_key, type: 'text', label: this.Capitalize(key.replace(/_/g, ' '))};
                  let address_fields = ['name', 'address', 'postal_code_city', 'country', 'phone', 'email', 'fax', 'vat_no'];
                  if (address_fields.includes(key)) {
                    if (key == 'postal_code_city') {
                      field.label = 'Postal code & City';
                      //field.fill_width = true;
                    }
                    if (key == 'country') {
                      field.options = this.CloneObject(this.country.options);
                      field.type = 'select';
                    }
                    if (key == 'vat_no') {
                      field.label = 'VAT No.';
                    }
                  }
                  if (group_key == 'type_of_shipment' && key != 'other') {
                    field.type = 'checkbox';
                  }
                  field.input = (event) => {
                    let value = '';
                    if (field.type == 'select') {
                      if (key == 'country') {
                        value = (event || {}).code || '';
                      }
                    } else if (field.type == 'checkbox') {
                      value = event.target.checked;
                    } else {
                      value = event.target.value;
                    }
                    field.value = value;
                    this.form.data[group_key][key] = value;
                  }
                  field.blur = (event) => {
                    let value = event.target.value;
                    if (key == 'email') {
                      value = value.replace(/\s/g, '').toLowerCase();
                    }
                    field.value = value;
                    this.form.data[group_key][key] = value;
                    //console.log('form', this.CloneObject(data))
                  }
                  field.value = data[group_key][key];
                  row.push(field);
                }
                fields.push(row);
              }
            } else {
              for (let key in group) {
                data[key] = group[key];
                let group_key = key.replace(/__/g, '');
                let field = {name: key, type: 'text', label: this.Capitalize(group_key.replace(/_/g, ' '))};
                let fill_width_fields = ['terms_of_delivery', 'terms_of_payment', 'category_of_transmission', 'declaration_of_origin', 'place', 'signature', 'name_in_capital_letters', 'send_to'];
                if (['country_id', 'agent_code'].includes(key)) {
                  if (key == 'country_id') {
                    field.options = this.CloneObject(this.country.options);
                    field.label = 'Country';
                  }
                  if (key == 'agent_code') {
                    field.options = this.CloneObject(this.courier.options);
                    field.label = 'Delivery company';
                  }
                  if (this.modal.mode == 'edit') {
                    field.disabled = true;
                  }
                  field.required = true;
                  field.type = 'select';
                }
                if (['add_date', 'sum_country_of_origin_and_tariff'].includes(key)) {
                  field.type = 'checkbox';
                }
                field.fill_width = fill_width_fields.includes(group_key);
                field.input = (event) => {
                  let value = '';
                  if (field.type == 'select') {
                    if (key == 'country_id') {
                    value = (event || {}).id || '';
                    }
                    if (key == 'agent_code') {
                      value = (event || {}).code || '';
                    }
                  } else if (field.type == 'checkbox') {
                    value = event.target.checked;
                  } else {
                    value = event.target.value;
                  }
                  field.value = value;
                  this.form.data[key] = value;
                }
                field.value = data[key];
                if (group_key == 'send_to') {
                  field.type = 'list';
                  if (!field.value || !Object.keys(field.value).length) {
                    let fields = this.CloneObject(this.template.fields);
                    let group_field = fields.find(field => Object.keys(field).some(key => new RegExp(group_key).test(key)));
                    if (group_field) field.value = Object.values(group_field)[0];
                  }
                  if (data[group_key]) {
                    field.list = Object.entries(data[group_key]).map(entry => ({email: entry[0], name: entry[1]}));
                  } else {
                    field.list = [];
                  }
                  if (field.list.length) {
                    field.columns = Object.keys(field.list[0]);
                    data[group_key] = field.value;
                  } else {
                    field.columns = Object.keys(field.value);
                    data[group_key] = {};
                  }
                  field.value = {};
                  for (let column of field.columns) {
                    field.value[column] = '';
                  }
                  delete data[key];
                }
                row.push(field);
              }
              fields.push(row);
            }            
          }
          this.form.fields = fields;
          this.form.data = data;
          //console.log('fields', this.CloneObject(fields))
          //console.log('data', this.CloneObject(data))
          this.$nextTick().then(resolve);
        }).catch(e => e);
      },
      async FilterByActive(e) {
        this.search.active = e.target.checked;
        await this.FilterSearch();
      },
      async FilterBySize(option) {
        this.search.page_size = option;
        await this.FilterSearch();
      },
      async FilterByCountry(option) {
        this.search.country_id = (option || {}).id;
        await this.FilterSearch();
      },
      async FilterByCourier(option) {
        this.search.agent_code = (option || {}).code;
        await this.FilterSearch();
      },
      async FilterSearch() {
        this.page.current = 1;
        this.loading = true;
        await this.SearchConfig();
        this.loading = false;
      },
      async SearchConfig() {
        return await new Promise((resolve, reject) => {
          const request = {
            query: this.search.query,
            page_size: this.search.page_size,
            page_offset: this.page.current
          }
          if (this.search.active) {
            request.active = this.search.active;
          }
          if (this.search.country_id) {
            request.country_id = this.search.country_id;
          }
          if (this.search.agent_code) {
            request.agent_code = this.search.agent_code;
          }
          BPA.api.GetBulkInvoicingConfList(request).then(response => {
            return BPA.api.response({response, return: 'json'});
          }).then(response => {
            if (!response.ok || !response.result) return reject();
            const json = response.result || {};
            this.search.initial = this.search.query;
            //this.current_page = request.page_offset + 1;
            this.page.last = json.page_count || 1;
            this.entries = json.item_count;
            for (let item of json.items) {
              item.agent_name = (this.courier.options.find(option => option.code == item.agent_code) || {}).label || '';
              item.country_code = (this.country.options.find(option => option.id == item.country_id) || {}).code || '';
            }
            //json.items.sort((a, b) => b.id - a.id);
            this.configs.items = json.items;
            BPA.cache.local({name: this.$options.name, set: {page: this.page, search: this.search}});
            this.$nextTick().then(async () => {
              this.clickables = document.querySelectorAll('.clickable');
              if (this.cached.config && Object.keys(this.cached.config).length) {
                this.OpenModal('edit', this.cached.config);
              }
              this.SetPageJumpWidth();
            });            
            resolve(json);
          }).catch(reject);
        }).catch(e => e);
      },
      async GetConfig(config_id) {
        return await new Promise((resolve, reject) => {
          if (!config_id) return reject();
          BPA.api.GetBulkInvoicingConfById(config_id).then(response => {
            return BPA.api.response({response, return: 'json'});
          }).then(response => {
            if (!response.ok || !response.result) return reject();
            resolve(response.result);
          }).catch(reject);
        }).catch(e => e);
      },
      AddItem(field = {}, column = '') {
        let item = {};
        let form = this.form;
        //let fields = form.fields;
        let data = form.data;
        let name = field.name;
        let key = name.replace(/__/g, '');
        let value = this.CloneObject(field.value);
        let required = field.columns;
        for (let key of required) {
          if (value[key]) item[key] = value[key];
        }
        let item_keys = Object.keys(item);
        if (item_keys.length < required.length) {
          let missing = required.filter(key => !item_keys.includes(key));
          if (missing.includes(column)) {
            missing = column;
          } else if (!value[missing[0]]) {
            missing = missing[0];
          }
          let ref = this.$refs[missing];
          if (Array.isArray(ref)) ref = ref[0];
          if (!ref) return;
          if (ref.$vnode) ref = ref.$vnode.elm;
          let select_input = ref.querySelector('input[type=search]');
          if (select_input) ref = select_input;
          return ref.focus();
        }
        if (key == 'send_to') {
          data[key][value.email] = value.name;
          field.list = Object.entries(data[key]).map(entry => ({email: entry[0], name: entry[1]}));
          this.$nextTick().then(() => {
            let modal_body = document.querySelector('.modal-tabs__body');
            if (modal_body) modal_body.scrollTop = modal_body.scrollHeight;
          });
          /*
          let existing = false;
          for (let prop in data[key]) {
            console.log(prop)
          }
          for (let item of field.list) {
            if (item.email == value.email) {
              item.name = value.name;
              existing = true;
              break;
            }
          }
          if (!existing) {
            field.list.unshift(value);
          }
          */
        } else {
          field.list.unshift(value);
        }
        for (key in value) {
          field.value[key] = '';
        }
        this.Alphabetize(field.list, 'email');
      },
      RemoveItem(field = {}, item = {}) {
        let form = this.form;
        //let fields = form.fields;
        let data = form.data;
        let name = field.name;
        let key = name.replace(/__/g, '');
        if (key == 'send_to') {
          delete data[key][item.email];
          field.list = Object.entries(data[key]).map(entry => ({email: entry[0], name: entry[1]}));
        }
      },
      ClearList(field = {}) {
        let form = this.form;
        //let fields = form.fields;
        let data = form.data;
        let name = field.name;
        let key = name.replace(/__/g, '');
        if (key == 'send_to') {
          data[key] = {};
          field.list = [];
        }
      },
      async CreateConfig(request = {}) {
        request = this.CloneObject(request);
        const body = {};
        const params = {
          active: null, 
          country_id: null, 
          agent_code: null
        };
        for (let prop in request) {
          let value = request[prop];
          if (prop in params) {
            params[prop] = value;
          } else {
            body[prop] = value;
            /*
            if (typeof value == 'object') {
              let concat = {postal_code: '', city: ''};
              for (let key in value) {
                if (/postal_code|city/.test(key)) {
                  concat[key] = value[key];
                  let concat_keys = Object.keys(concat);
                  let concat_values = Object.values(concat);
                  if (concat_values.length == concat_keys.length) {
                    body[prop][concat_keys.join('_')] = concat_values.join(' ').trim();
                  }
                  delete body[prop][key];
                }
              }
            }
            */
          }
          delete request[prop];
        }
        request = {body, ...params};
        return await new Promise((resolve, reject) => {
          if (!Object.keys(request).length) return reject();
          BPA.api.CreateBulkInvoicingConf(request).then(response => {
            return BPA.api.response({response, return: 'text'});
          }).then(async response => {
            if (!response.ok) return reject();
            if (!params.active) {
              await this.ToggleConfig({
                config_id: response.result,
                active: params.active
              });
            }
            resolve(response.result);
          }).catch(reject);
        }).catch(e => e);
      },
      async EditConfig(request = {}) {
        return await new Promise((resolve, reject) => {
          if (!Object.keys(request).length) return reject();
          BPA.api.UpdateBulkInvoicingConf(request).then(response => {
            return BPA.api.response({response});
          }).then(response => {
            if (!response.ok) return reject();
            resolve(response);
          }).catch(reject);
        }).catch(e => e);
      },
      async SetConfigSendTo(request = {}) {
        return await new Promise((resolve, reject) => {
          if (!Object.keys(request).length) return reject();
          BPA.api.SetBulkInvoicingConfSendTo(request).then(response => {
            return BPA.api.response({response});
          }).then(response => {
            if (!response.ok) return reject();
            resolve(response);
          }).catch(reject);
        }).catch(e => e);
      },
      async ToggleConfig(request = {}) {
        return await new Promise((resolve, reject) => {
          if (!Object.keys(request).length) return reject();
          BPA.api.SetBulkInvoicingConfState(request).then(response => {
            return BPA.api.response({response});
          }).then(response => {
            if (!response.ok) return reject();
            resolve(response);
          }).catch(reject);
        }).catch(e => e);
      },
      async CopyConfig(request = {}) {
        return await new Promise((resolve, reject) => {
          if (!Object.keys(request).length) return reject();
          BPA.api.CopyBulkInvoicingConf(request).then(response => {
            return BPA.api.response({response});
          }).then(response => {
            if (!response.ok) return reject();
            resolve(response);
          }).catch(reject);
        }).catch(e => e);
      },
      async Create() {
        const form_submit = this.$refs.submit;
        if (!await this.ValidateForm(form_submit)) return;
        const data = this.CloneObject(this.form.data);
        this.$eventHub.$emit('ValidateModalStart', {
          approve: 'Yes, create it',
          disapprove: 'No',
          message: 'Creates the current configuration.',
          type: 'success'
        });
        this.$eventHub.$on('ValidateModalStop', async (approve) => {
          this.$eventHub.$off('ValidateModalStop');
          if (!approve) return;
          this.loading = true;
          let error = false;
          let send_to = data.send_to;
          delete data.send_to;
          let config_id = await this.CreateConfig(data);
          if (config_id) {
            if (Object.keys(send_to).length) {
              error = !await this.SetConfigSendTo({config_id, body: send_to});
            }
            if (!error) {
              this.$eventHub.$emit('ShowMessages', {
                message: 'Configuration successfully created',
                type: 'success',
                hide: 2000
              });
            }
            await this.SearchConfig();
            this.$eventHub.$emit('CloseModal');
          }
          this.loading = false;
        });
      },
      async Update() {
        const changes = {};
        const form_submit = this.$refs.submit;
        if (!await this.ValidateForm(form_submit)) return;
        const data = this.CloneObject(this.form.data);
        const config = this.CloneObject(this.config);
        for (let [key, value] of Object.entries(data)) {
          let field = config[key];
          if (typeof value == 'object') {
            if (key == 'send_to') {
              if (JSON.stringify(value) != JSON.stringify(config[key])) {
                if (config[key]) {
                  if (Object.keys(value).length) {
                    changes[key] = value;
                  } else {
                    changes[key] = null;
                  }
                } else {
                  if (Object.keys(value).length) {
                    changes[key] = value;
                  }
                }
              }
            } else {
              for (let [attr, prop] of Object.entries(value)) {
                let nested = config[key][attr];
                if (nested != prop) {
                  if (!changes[key]) {
                    changes[key] = {};
                  }
                  changes[key][attr] = prop;
                }
              }
            }
          } else if (field != value) {
            changes[key] = value;
          }
        }
        //console.log('changes', this.CloneObject(changes))
        if (!Object.keys(changes).length) {
          this.$eventHub.$emit('ShowMessages', {
            message: 'No changes made',
            type: 'warning',
            hide: 2000
          });
          return this.$eventHub.$emit('CloseModal');
        }
        this.$eventHub.$emit('ValidateModalStart', {
          approve: 'Yes, update it',
          disapprove: 'No',
          message: 'Updates the current configuration.',
          type: 'success'
        });
        this.$eventHub.$on('ValidateModalStop', async (approve) => {
          this.$eventHub.$off('ValidateModalStop');
          if (!approve) return;
          const request = {config_id: data.id};
          delete data.id;
          request.body = data;
          this.loading = true;
          if (Object.keys(changes).some(key => !['active', 'send_to'].includes(key))) {
            delete request.body.active;
            delete request.body.send_to;
            await this.EditConfig(request);
          }
          if ('active' in changes) {
            delete request.body;
            request.active = changes.active;
            await this.ToggleConfig(request);
          }
          if ('send_to' in changes) {
            request.body = changes.send_to;
            await this.SetConfigSendTo(request);
          }
          await this.SearchConfig();
          this.$eventHub.$emit('CloseModal');
          this.loading = false;
        });
      },
      async Toggle(config = {}, event = {}) {
        let configs = [{...config, ...{toggle: event.target}}];
        for (let checkbox of this.selected) {
          let table_row = checkbox.closest('tr');
          let toggle = table_row.querySelector('.checkbox-toggle > input');
          let row_item = this.configs.items.find(item => item.id == table_row.dataset.id);
          if (row_item && row_item.active == config.active) {
            configs.push({...row_item, ...{toggle: toggle}});
          }
        }
        configs = configs.filter((item, index, array) => index == array.findIndex(i => i.id == item.id));
        for (let item of configs) item.toggle.checked = event.target.checked;
        let multiple = configs.length > 1;
        let status = !config.active ? true : false;
        this.$eventHub.$emit('ValidateModalStart', {
          approve: `Yes, ${!status ? 'de' : ''}activate ${multiple ? 'them' : 'it'}`,
          disapprove: 'No',
          message: `Changes the status of the ${multiple ? 'selected' : 'current'} configuration${multiple ? 's' : ''}.`,
          type: !config.active ? 'success' : 'danger'
        });
        this.$eventHub.$on('ValidateModalStop', async (approve) => {
          this.$eventHub.$off('ValidateModalStop');
          if (!approve) {
            for (let item of configs) {
              item.toggle.checked = !!config.active;
            }
            return;
          }
          this.loading = true;
          for (let item of configs) {
            await this.ToggleConfig({config_id: item.id, active: status});
          }
          this.$eventHub.$emit('ShowMessages', {
            message: `Configuration${multiple ? 's ' + configs.map(item => (item.id)).sort().join(', ').replace(/, ([^, ]*)$/, ' & ' + '$1') : ' ' + configs[0].id} successfully ${!status ? 'de' : ''}activated`,
            type: 'success',
            hide: 2000
          });
          await this.SearchConfig();
          if (this.modal.mode == 'edit') {
            this.$eventHub.$emit('CloseModal');
          }
          this.loading = false;
        });
      },
      async Copy(config_id) {
        /*
        if (this.config.id != config_id) {
          this.loading = true;
          this.config = await this.GetConfig(config_id);
          this.loading = false;
        }
        */
        this.dialog.config_id = config_id || this.config.id;
        this.dialog.open();
      },
      async Delete(config = {}) {
        let in_modal = Object.keys(this.config).length;
        config = in_modal ? this.config : config;
        let configs = [config];
        if (!in_modal) {
          for (let checkbox of this.selected) {
            let table_row = checkbox.closest('tr');
            let row_item = this.configs.items.find(item => item.id == table_row.dataset.id);
            if (row_item) configs.push(row_item);
          }
          configs = configs.filter((item, index, array) => index == array.findIndex(i => i.id == item.id));
        }
        let multiple = configs.length > 1;
        this.$eventHub.$emit('ValidateModalStart', {
          approve: `Yes, delete ${multiple ? 'them' : 'it'}`,
          disapprove: 'No',
          message: `Deletes the ${multiple ? 'selected' : 'current'} configuration${multiple ? 's' : ''}.`,
          type: 'danger'
        });
        this.$eventHub.$on('ValidateModalStop', async (approve) => {
          this.$eventHub.$off('ValidateModalStop');
          if (!approve) return;
          this.loading = true;
          for (let item of configs) {
            await this.DeleteCampaign(item.id);
          }
          this.$eventHub.$emit('ShowMessages', {
            message: `Configuration${multiple ? 's' : ''} successfully deleted`,
            type: 'success',
            hide: 2000
          });
          await this.SearchConfig();
          if (this.modal.mode == 'edit') {
            this.$eventHub.$emit('CloseModal');
          }
          this.loading = false;
        });
      },
      ToggleAllCheckboxes(e) {
        let selected = [];
        let checked = e.target.checked;
        let configs = document.querySelectorAll('tr[data-id]');
        for (let i = 0; i < configs.length; i++) {
          let checkbox = configs[i].querySelector('.v-checkbox');
          if (checkbox) {
            checkbox.checked = checked;
            if (checked) {
              selected.push(checkbox);
            }
          }
        }
        this.selected = selected;
      },
      ToggleCheckbox(e) {
        let e_target = e.target;
        if (!e_target || e_target.localName == 'span') return;
        if (e_target.localName == 'td') {
          e_target = e_target.querySelector('.v-checkbox');
          e_target.checked = !e_target.checked;
          return e_target.dispatchEvent(new Event('input'));
        }
        let selected = [];
        let checked = e_target.checked;
        let select_all = this.$refs.select_all;
        let configs = document.querySelectorAll('tr[data-id]');
        if (!checked) select_all.checked = false;
        for (let i = 0; i < configs.length; i++) {
          let checkbox = configs[i].querySelector('.v-checkbox');
          if (checkbox && checkbox.checked) {
            selected.push(checkbox);
          }
        }
        if (selected.length == configs.length) {
          select_all.checked = true;
        }
        this.selected = selected;
      },
      ClickOpen(event) {
        if (!event) return;
        let elm = event.target, row;
        if (elm.localName != 'tr') row = elm.closest('tr');
        const mousedown = (state) => {
          if (state === true || state === false) {
            row.mousedown = state;
          } else {
            return row.mousedown;
          }
        }
        if (!row.classList.contains('clickable')) return;
        for (let i = 0; i < this.clickables.length; i++) {
          if (this.clickables[i] != row) {
            this.clickables[i].classList.remove('hover', 'selected');
          }
        }
        if (elm.type == 'checkbox') return;
        if (/label|checkbox/.test(elm.className)) return;
        if (elm.localName == 'a' || elm.parentElement.localName == 'a') return;
        if (event.type == 'mousedown') {
          mousedown(event.which == 1);
        }
        if (event.type == 'mousemove') {
          mousedown(false);
        }
        if (event.type == 'mouseup') {
          mousedown() && this.OpenModal('edit', row.dataset);
          mousedown(false);
        }
      },
      async PageController(next_page) {
        let load_page;
        const page = {};
        document.querySelectorAll('.page-turn').forEach(arrow => {
          if (arrow) page[arrow.classList.contains('prev') ? 'prev' : 'next'] = arrow;
        });
        if (next_page) {
          if (this.page.current != this.page.last) {
            this.page.current++;
            load_page = true;
            if (this.page.current == this.page.last) {
              page.next.classList.add('disabled');
            }
            page.prev.classList.remove('disabled');
          }
        } else {
          if (this.page.current > 1) {
            this.page.current--;
            load_page = true;
            if (this.page.current == 1) {
              page.prev.classList.add('disabled');
            }
            page.next.classList.remove('disabled');
          }
        }
        if (load_page) {
          this.loading = true;
          await this.SearchConfig();
          this.loading = false;
        }
      },
      async PageNavigator(e) {
        const page = {
          event: e.type,
          input: e.target,
          last: Number(e.target.max),
          first: Number(e.target.min),
          number: Number(e.target.value)
        }
        const within_limits = (n) => {
          return n >= page.first && n <= page.last;
        }
        const set_page_number = (n) => {
          this.page.number = n || page.number;
          page.number = Number(this.page.number);
        }
        document.querySelectorAll('.page-turn').forEach(arrow => {
          if (arrow) page[arrow.classList.contains('prev') ? 'prev' : 'next'] = arrow;
        });
        set_page_number();
        if (page.event == 'keydown') {
          if (e.key == 'Enter') {
            page.input.blur();
          }
        }
        if (page.event == 'blur') {
          ['prev', 'next'].map(arrow => {
            page[arrow] && page[arrow].classList.remove('disabled');
          });
          if (page.number <= page.first) {
            set_page_number(page.first);
            page.input.value = page.first;
            page.prev.classList.add('disabled');
            if (page.last == page.first) {
              page.next.classList.add('disabled');
            }
          } else if (page.number >= page.last) {
            set_page_number(page.last);
            page.input.value = page.last;
            page.next.classList.add('disabled');
            if (page.first == page.last) {
              page.prev.classList.add('disabled');
            }
          }
          if (within_limits(page.number) && page.number != this.page.current) {
            this.page.current = page.number;
            this.loading = true;
            await this.SearchConfig();
            this.loading = false;
          }
        }
      },
      SetPageJumpWidth() {
        const current = document.querySelector('.page-number-current');
        const last = document.querySelector('.page-number-last');
        if (current && last) {
          const rect = last.getBoundingClientRect();
          const input = current.querySelector('input');
          if (rect && rect.width) {
            current.style.setProperty('width', rect.width + 'px');
          } else {
            current.style.removeProperty('width');
          }
          if (input) {
            input.dispatchEvent(new Event('blur'));
          }
        }
      },
    }
  }
</script>

<style lang="scss" scoped>
  .label {
    .v-wrapper,
    .v-checkbox-label {
      min-height: auto;
    }

    .v-checkbox-toggle {
      width: 18px;
      height: 18px;
      background-color: white;
    }
  }

  .config-list__row:not(.clickable) {
    &, * {
      user-select: none;
    }

    &.sortable {
      td > * {
        pointer-events: none;
      }

      &.dragging + &:nth-child(odd) {
        background-color: transparent;
      }

      &.dragging + &:nth-child(even) {
        background-color: rgba(183, 183, 183, 0.12);
      }

      &.dragging {
        box-shadow: 0px 2px 4px -2px rgba(60, 60, 60, 1);
        box-shadow: 0px 2px 4px -2px rgba(0, 0, 0, 1);

        & > * {
          //opacity: 0.5;
        }
      }
    }
  }

  .field-group {
    & > h3 {
      margin-bottom: 15px;
    }
    /*
    &:nth-child(8) {
      background: red;
    }
    */
    & ~ .field-group:nth-child(even) {
      margin-top: 15px;
      
      & > h3 {
        display: none;
      }
    }
  }
</style>