<template>
  <div class="wrapper">
    <div class="title">
      <h1>{{ $t("Create Deadline") }}</h1>
    </div>
    <div class="grid-state">
      <p class="grid-heading">Shipping Method</p>
      <v-select :options="shipping.options" v-model="tempShippingDescription">
        <template v-slot:selected-option="option">
          <div class="truncate">
            <span :class="['courier', option.agent]">
              {{ option.label }}
            </span>
          </div>
        </template>
        <template slot="option" slot-scope="option">
          <div class="truncate">
            <span :class="['courier', option.agent]">
              {{ option.label }}
            </span>
          </div>
        </template>
      </v-select>
      <p class="grid-heading" v-if="error.shipping" style="color: red">
        Shipping needed - Select from dropdown
      </p>
    </div>
    <div class="grid-state">
      <p class="grid-heading">Shipping Days</p>
      <v-select
        :options="weekdays"
        :multiple="true"
        v-model="deadlineConfig.days"
      >
        <template slot="option" slot-scope="option">
          <div class="truncate">
            <span>
              {{ option.label }}
            </span>
          </div>
        </template>
      </v-select>
      <p class="grid-heading" v-if="error.day" style="color: red">
        Days needed - Select from dropdown
      </p>
    </div>
    <div class="">
      <p class="grid-heading">Priority(1-3)</p>
      <input
        class="input"
        type="text"
        ref="deadlinePriority"
        pattern="[1-3]"
        v-model="deadlineConfig.priority"
        placeholder="1-3"
      />
      <p class="grid-heading" v-if="error.prio" style="color: red">
        Priority needed - Between 1-3
      </p>
    </div>
    <div class="">
      <span class="grid-heading">{{ $t("Deadline time") }}</span>
      <input
        type="time"
        class="input"
        name="Deadline time"
        v-model="tempDeadlineTime"
      />
      <p class="grid-heading" v-if="error.deadlineTime" style="color: red">
        Time needed
      </p>
    </div>
    <div class="buttonContainer">
      <button class="button" @click="SubmitDeadline">Create</button>
    </div>
  </div>
</template>

<script>
import { BPA } from "@/helpers/BPA";

export default {
  name: "DashboardSetupConfig.vue",
  emits: ["deadlines"],
  data() {
    return {
      deadlineConfig: {
        shipping_description: "",
        deadline_hour: "",
        deadline_minute: "",
        priority: "",
        days: [],
      },
      tempDeadlineTime: "",
      tempShippingDescription: "",
      courier: {
        options: [],
      },
      couriers: {},
      weekdays: [
        "Mon",
        "Tue",
        "Wed",
        "Thu",
        "Fri",
        "Sat",
        "Sun",
        "Mon-Fri",
        "All week",
      ],
      shipping: {
        options: [],
      },
      shippings: {},
      isDisabled: true,
      error: {
        shipping: false,
        day: false,
        prio: false,
        deadlineTime: false,
      },
      prioRegex: /^[1-3]$/,
    };
  },
  async mounted() {
    this.GetCouriers();
    this.GetShippingMethods();
  },
  methods: {
    async GetShippingMethods(company_code) {
      return await BPA.api
        .GetShippingOptions(company_code)
        .then((response) => {
          return BPA.api.response({ response, return: "json" });
        })
        .then((response) => {
          if (!response.ok || !response.result) return;
          let options = response.result || [];
          let agent_codes = {};
          let shipping_methods = [];
          this.shipping.methods = options;
          for (let i = 0; i < options.length; i++) {
            if (!agent_codes[options[i].agent_code])
              agent_codes[options[i].agent_code] = [];
            agent_codes[options[i].agent_code].push(
              options[i].shipping_method /*.replace(/%2F/g, '/')*/
            );
          }
          agent_codes = Object.fromEntries(Object.entries(agent_codes).sort());
          for (let i in agent_codes) {
            shipping_methods.push(
              agent_codes[i].sort((a, b) => (a < b ? -1 : a > b ? 1 : 0))
            );
          }
          shipping_methods = shipping_methods.flat();
          const shipping_methods_options = [];
          for (let shipping_method of shipping_methods) {
            let option = options.find(
              (o) => o.shipping_method == shipping_method
            );
            shipping_methods_options.push({
              code: shipping_method,
              label: shipping_method,
              agent: option.agent_code,
              type: option.type,
            });
          }

          this.shipping.options = shipping_methods_options;

          return options;
        })
        .catch((e) => e);
    },
    GetCouriers() {
      this.couriers = BPA.api.Couriers("GET");
      for (let code in this.couriers) {
        this.courier.options.push({
          code: code,
          label: this.couriers[code],
        });
      }
    },
    async SubmitDeadline() {
      if (this.deadlineConfig.days.includes("All week")) {
        this.deadlineConfig.days = [
          "Mon",
          "Tue",
          "Wed",
          "Thu",
          "Fri",
          "Sat",
          "Sun",
        ];
      }
      if (this.deadlineConfig.days.includes("Mon-Fri")) {
        this.deadlineConfig.days.pop("Mon-Fri");
        this.deadlineConfig.days.push("Mon", "Tue", "Wed", "Thu", "Fri");
      }

      this.deadlineConfig.shipping_description =
        this.tempShippingDescription.code;
      this.deadlineConfig.priority = parseInt(this.deadlineConfig.priority);

      let deadlineTimeArr = this.tempDeadlineTime.split(":");

      this.deadlineConfig.deadline_hour = parseInt(deadlineTimeArr[0]);
      this.deadlineConfig.deadline_minute = parseInt(deadlineTimeArr[1]);

      if (!this.ValidateInputs()) {
        let response = await BPA.api.PostDeadline(this.deadlineConfig);
        if (response.status == 201) {
          this.$eventHub.$emit("ShowMessages", {
            message: "Deadline successfully created",
            type: "success",
            hide: 2000,
          });
          this.$emit("deadlines");
        } else {
          this.$eventHub.$emit("ShowMessages", {
            message: "Deadline creation failed",
            type: "error",
            hide: 2000,
          });
        }
      }
    },
    ValidateInputs() {
      let isValidated = [];

      if (!this.deadlineConfig.shipping_description) {
        isValidated.push(false, "ship");
        this.error.shipping = true;
      } else {
        this.error.shipping = false;
      }
      this.deadlineConfig.days.length !== 0
        ? (isValidated.push(true, "days"), (this.error.day = false))
        : (isValidated.push(false, "days"), (this.error.day = true));

      this.prioRegex.test(this.deadlineConfig.priority) == true
        ? (isValidated.push(true), (this.error.prio = false))
        : (isValidated.push(false, "prio"), (this.error.prio = true));

      if (!this.tempDeadlineTime) {
        this.error.deadlineTime = true;
      } else {
        this.error.deadlineTime = false;
      }

      if (isValidated.includes(false)) {
        isValidated = [];

        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "src/assets/style/variables/icons.scss";
@import "src/assets/style/variables/colors.scss";

.hourBox {
  display: flex;
}
.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 500px;
}
.title {
  font-weight: 600;
  color: $lightFont;
  padding-bottom: 2px;
  padding-top: 4px;
}
.grid-state {
  min-width: fit-content;
  padding: 4px 0;
}

.grid-heading {
  font-weight: 600;
  color: $lightFont;
  padding-bottom: 2px;
  margin-top: 5px;
}
.input {
  font-size: 1em;
  padding: 7px;
  color: #333;
  appearance: none;
  display: flex;
  background: none;
  border: 1px solid rgba(60, 60, 60, 0.26);
  border-radius: 4px;
  white-space: normal;
  outline: none;
  margin-bottom: 10px;
}
.buttonContainer {
  display: flex;
  height: 100%;
  width: 20%;
  flex-grow: 1;
  align-items: end;
  align-self: flex-end;
}
.button {
  height: 100%;
  width: 100%;
  flex-shrink: 0;
  background-color: $green;
  margin-top: 25px;
}
.shipping-select {
  .courier {
    width: 22px;
    height: 22px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    &.bring {
      background-image: $bring;
    }

    &.burd {
      background-image: $burd;
    }

    &.dao {
      background-image: $dao;
    }

    &.dhl {
      background-image: $dhl;
    }

    &.gls {
      background-image: $gls;
    }

    &.instabox {
      background-image: $instabox;
    }

    &.pdk {
      background-image: $pdk;
    }

    &.royalmail {
      background-image: $royalmail;
    }

    &.shop {
      background-image: $shop;
    }

    &.dfm {
      background-image: $dfm;
    }

    &.dpd {
      background-image: $dpd;
    }

    &.pat {
      background-image: $pat;
    }
  }
}
</style>
