<template>
  <div class="settings settings-address-book">
    <p class="title">
      {{ $t('Address book') }}
      <button class="button" style="height: 34px; min-width: 115px; margin-left: auto;" @click.prevent="CreateAddress" :disabled="readonly">{{ $t('Create') }}</button>
      <!-- <a class="button" @click.prevent="CreateAddress" href="" style="margin-left: auto;">{{ $t('Create address') }}</a> -->
    </p>
    <div class="settings__group">
      <table class="list table odd-even">
        <tbody>
          <tr :data-id="item.id" class="list__row clickable" @mousedown="ClickOpen" @mousemove="ClickOpen" @mouseup="ClickOpen" :key="item.id" v-for="item in addresses">
            <td class="item-name">
              <div class="flex-row">
                {{ item.name }}
              </div>
            </td>
            <!--
            <td class="item-att">
              <div class="flex-row">
                {{ item.att }}
              </div>
            </td>
            -->
            <td class="item-address">
              <div class="flex-row">
                {{ item.address }}
              </div>
            </td>
            <!--
            <td class="item-postal-code">
              <div class="flex-row">
                {{ item.zipcode }}
              </div>
            </td>
            -->
            <td class="destination">
              <div class="country-city">
                <Flag :code="item.country.code" size="small" :title="CountryName(item.country.code)" />
                <div v-if="item.city" class="city" style="margin-left: 10px;">{{ item.city }}</div>
              </div>
            </td>
            <td class="item-phone">
              <div class="flex-row">
                {{ item.phone }}
              </div>
            </td>
            <td class="item-email">
              <div class="flex-row">
                {{ item.email }}
              </div>
            </td>
            <!--
            <td class="item-printer">
              <div class="flex-row">
                {{ item.printer }}
              </div>
            </td>
            -->
            <td @mouseenter="SetBackground" @mouseleave="SetBackground" class="actions">
              <a @click.prevent="" href="" class="icon dots">
                <ul class="item-actions">
                  <!-- <li><a @click.prevent="ViewAddress(item)" href="">{{ $t('View') }}</a></li> -->
                  <li><a @click.prevent="EditAddress(item)" href="">{{ $t('Edit') }}</a></li>
                  <li><a @click.prevent="DeleteAddress(item)" href="" class="error">{{ $t('Delete') }}</a></li>
                </ul>
              </a>
            </td>
          </tr>
        </tbody>
        <thead>
          <tr class="list__actions">
            <th class="item-name">{{ $t('Name') }}</th>
            <!-- <th class="item-att">{{ $t('Att.') }}</th> -->
            <th class="item-address">{{ $t('Address') }}</th>
            <!-- <th class="item-postal-code">{{ $t('Postal code') }}</th> -->
            <th class="item-country-city">{{ $t('Country / city') }}</th>
            <th class="item-phone">{{ $t('Phone') }}</th>
            <th class="item-email">{{ $t('Email') }}</th>
            <!-- <th class="item-printer">{{ $t('Printer') }}</th> -->
            <th class="edit" />
          </tr>
        </thead>
      </table>
    </div>

    <Modal modal="modal" :value="modal.open" :title="$t(modal.mode == 'create' ? 'New address' : 'Address') + (modal.mode == 'edit' ? ' #' + address.id : '')">
      <div id="address" v-if="modal.open">
        <div class="modal-header"></div>
        <div class="modal-content scrollable">
          <div class="modal-tabs">
            <div class="modal-tabs__body">
              <div class="modal-tabs__body-content padding">
                <form @submit.prevent>
                  <button id="validate" type="submit" hidden />
                  <div v-for="(group, index) in form.fields" :key="index" class="flex-group">
                    <div class="flex-row">
                      <div v-for="(field, index) in group" :key="index" :class="['flex-column', {flex1: field.fill_width}]" v-show="!field.hidden" :style="field.name == 'email' ? 'flex: 1; max-width: 627px;' : ''">
                        <div :class="['label', {required: field.required}]">
                          <span class="label-text">
                            {{ $t(field.label || Capitalize(field.name).replace(/_/g, ' ')) }}
                          </span>
                          <v-select v-if="field.type == 'select'" :name="field.name" :required="field.required" :disabled="field.disabled || readonly" :value="field.value" :options="/country/.test(field.name) ? Alphabetize(field.options.map(o => {o.label = CountryName(o.code); return o;}), 'label') : field.options" :title="field.title" :clearable="field.clearable" v-on="field.input ? {input: field.input} : {}">
                            <template #search="{attributes, events}">
                              <input class="vs__search" v-bind="attributes" v-on="events" :required="field.required && !form.data[field.name]">
                            </template>
                            <template v-slot:selected-option="option">
                              <div class="truncate" :title="option.title">
                                <Flag v-if="field.name.includes('country')" :code="option.code" size="small" />
                                <span :class="[option.color, {state: field.name.includes('print')}]">{{ option.label }}</span>
                              </div>
                            </template>
                            <template slot="option" slot-scope="option">
                              <div class="truncate" :title="option.title">
                                <Flag v-if="field.name.includes('country')" :code="option.code" size="small" />
                                <span :class="[option.color, {state: field.name.includes('print')}]">{{ option.label }}</span>
                              </div>
                            </template>
                          </v-select>
                          <div v-else-if="field.type == 'checkbox'" class="v-wrapper checkbox" :title="field.title">
                            <label class="v-checkbox-label">
                              <input class="v-checkbox" type="checkbox" :name="field.name" :checked="field.value" :required="field.required">
                              <span class="v-checkbox-toggle" />
                              <p class="v-checkbox-toggle-label">{{ field.label }}</p>
                            </label>
                          </div>
                          <input v-else class="v-input" :type="field.type" :name="field.name" :required="field.required" :readonly="field.readonly" :disabled="readonly" :style="[{'background-color': (field.readonly || readonly) && '#ffffff'}]" :pattern="field.pattern" :min="field.min" :max="field.max" :minlength="field.minlength" :step="field.step" :value="field.value" :title="field.title" v-model="form.data[field.name]" @keydown.enter="$event.target.blur()" @input="field.input ? field.input($event) : {}" @blur="field.blur ? field.blur($event) : {}">
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div class="button-container actions">
            <button class="button green" :disabled="readonly" @click.prevent="UpdateAddress" v-if="modal.mode == 'edit'">{{ $t('Save') }}</button>
            <button class="button red" :disabled="readonly" @click.prevent="DeleteAddress()" v-if="modal.mode == 'edit'">{{ $t('Delete') }}</button>
            <button class="button green" :disabled="readonly" @click.prevent="SubmitAddress" v-if="modal.mode == 'create'">{{ $t('Create') }}</button>
          </div>
        </div>
      </div>
    </Modal>

    <div class="loading" v-if="loading">
      <div class="loading-element">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
    
  </div>
</template>

<script>
  import { TableElementsBehaviour } from '@/mixins/TableElementsBehaviour';
  import { Config }     from '@/helpers/Config';
  import { Tool }       from '@/helpers/Tool';
  import { BPA }        from '@/helpers/BPA';
  import Modal          from '@/components/snippets/Modal';
  import Flag           from '@/components/snippets/Flag';
  
  export default {
    name: 'SettingsAddressBookView',
    mixins: [
      TableElementsBehaviour, 
      Config, 
      Tool, 
      BPA
    ],
    components: {
      Modal,
      Flag
    },
    data() {
      return {
        loading: false,
        clickables: [],
        cached: {},
        address: {},
        printers: [],
        countries: [],
        addresses: [],
        template: [
          {
            first_name: '',
            last_name: '',
            att: ''
          },
          {
            phone: '',
            email: '',
            vat_no: ''
          },
          {
            address: '',
            zipcode: '',
            city: ''
          },
          {
            res_country_id: null,
            printnode_id: null
          }
        ],
        form: {
          fields: [],
          data: {}
        },
        modal: {
          mode: 'create',
          open: false
        },
        readonly: !BPA.permissions(['admin', 'parcels_admin']).length
      }
    },
    created() {},
    async mounted() {
      this.$eventHub.$on('CloseModal', () => {
        this.main.view.classList.remove('no-scroll');
        this.modal.open = false;
        this.form = {fields: [], data: {}};
        this.address = {};
        this.cached.address = {};
        BPA.cache.session({name: this.$options.name, set: {address: {}}});
        for (let i = 0; i < this.clickables.length; i++) {
          if (this.clickables[i].classList.contains('selected')) {
            this.clickables[i].classList.remove('selected');
          }
        }
      });
      this.countries = BPA.api.Countries('GET').map(country => ({
        id: country.id, label: country.name, code: country.iso_code_2
      }));
      this.cached = {...this.cached, ...BPA.cache.session({
        name: this.$options.name, get: 'address'
      })};
      this.loading = true;
      await this.GetPrinterOptions();
      await this.QueryAddresses();
      this.loading = false;
    },
    computed: {
      main() {
        let main = {
          company: BPA.util.GetCompany(),
          view: document.querySelector('.content-scroll')
        }
        main.view.scroll = {top: 0};
        return main;
      },
      locale() {
        return this.$i18n.locale;
      }
    },
    watch: {
      locale() {
        //this.saveButton.textContent = this.$t('Save data');
      }
    },
    methods: {
      CloneObject(object = {}) {
        return Tool.CloneObject(object);
      },
      Capitalize(string = '') {
        return String(Tool.Capitalize(string));
      },
      TitleCase(string = '') {
        return Tool.TitleCase(string);
      },
      CountryName(country_code) {
        return Tool.CountryName(country_code);
      },
      Alphabetize(list, prop) {
        return Tool.Alphabetize(list, prop);
      },
      async ValidateForm() {
        return await new Promise((resolve) => {
          let button = document.getElementById('validate');
          if (button) {
            let form = button.closest('form');
            let fields = Array.from(form.elements);
            if (fields.some(field => !field.checkValidity())) {
              return button.click();
            }
          }
          resolve(true);
        });
      },
      async QueryAddresses() {
        return await new Promise(async (resolve, reject) => {
          let addresses = await this.GetAddressList();
          if (!addresses) reject();
          for (let item of addresses) {
            item.name = item.first_name + ' ' + item.last_name;
            let country = this.countries.find(o => o.id == item.res_country_id) || {};
            let printer = this.printers.find(o => o.id == item.printnode_id) || {};
            item.country = {code: country.code, name: country.label};
            item.printer = printer.label || '';
          }
          this.addresses = addresses;
          BPA.cache.local({name: this.$options.name, set: {addresses}});
          this.$nextTick().then(() => {
            this.clickables = document.querySelectorAll('.clickable');
            if (this.cached.address && Object.keys(this.cached.address).length) {
              this.EditAddress(this.cached.address);
            }
          });
          resolve(addresses);
        });
      },
      async OpenModal(mode) {
        this.modal.mode = mode;
        this.modal.open = true;
      },
      async GetPrinterOptions() {
        return await new Promise((resolve, reject) => {
          BPA.api.GetPrinterList().then(response => {
            return BPA.api.response({response, return: 'json'});
          }).then(response => {
            if (!response.ok) return reject();
            this.printers = Tool.PrinterOptions(response.result, 'label');
            resolve(this.printers);
          }).catch(reject);
        }).catch(e => e);
      },
      async SetupAddressForm(address) {
        return await new Promise(resolve => {
          let fields = [];
          let data = {};
          let template = this.CloneObject(this.template);
          let form = address || template;
          if (!Array.isArray(form)) {
            for (let group of template) {
              for (let key in group) {
                group[key] = address[key];
              }
            }
            form = template;
            data.id = address.id;
          }
          for (let group of form) {
            const row = [];
            for (let key in group) {
              data[key] = group[key];
              let field = {name: key};
              switch (key) {
                case 'first_name':
                case 'last_name':
                case 'att':
                case 'address':
                case 'zipcode':
                case 'city': {
                  field.type = 'text';
                  field.blur = (event) => {
                    const value = event.target.value;
                    field.value = this.TitleCase(value).replace(/\s+/g, ' ').trim();
                    this.form.data[key] = field.value;
                    //console.log(this.CloneObject(this.form.data));
                  }
                  if (key == 'att') field.label = 'Att.';
                  if (['att', 'address'].includes(key)) {
                    field.fill_width = true;
                  }
                  if (key == 'zipcode') field.label = 'Postal code';
                  if (key == 'name') field.required = true;
                  break;
                }
                case 'phone':
                case 'vat_no': {
                  field.type = key == 'phone' ? 'tel' : 'text';
                  if (key == 'vat_no') field.label = 'VAT No.';
                  field.blur = (event) => {
                    const value = event.target.value;
                    field.value = String(value).replace(/\s+/g, '').trim();
                    this.form.data[key] = field.value;
                    //console.log(this.CloneObject(this.form.data));
                  }
                  break;
                }
                case 'email': {
                  field.type = 'email';
                  field.blur = (event) => {
                    const value = event.target.value;
                    field.value = String(value).toLowerCase().replace(/\s+/g, '').trim();
                    this.form.data[key] = field.value;
                    //console.log(this.CloneObject(this.form.data));
                  }
                  break;
                }
                case 'printnode_id':
                case 'res_country_id': {
                  field.type = 'select';
                  const printer = /print/.test(key);
                  field.options = this.CloneObject(this[printer ? 'printers' : 'countries']);
                  field.value = field.options.find(option => option.id == data[key]);
                  field.input = (option) => {
                    field.value = option || '';
                    this.form.data[key] = option && option.id; 
                    //console.log(this.CloneObject(this.form.data));
                  }
                  field.label = printer ? 'Label printer' : 'Country';
                  if (printer) field.fill_width = true;
                  else field.required = true;
                  break;
                }
              }
              row.push(field);
            }
            fields.push(row);
          }
          this.form.fields = fields;
          this.form.data = data;
          //console.log(this.CloneObject(this.form.fields))
          //console.log(this.CloneObject(this.form.data))
          this.$nextTick().then(resolve);
        });
      },
      async GetAddressList() {
        return await new Promise((resolve, reject) => {
          BPA.api.GetAddressBook().then(response => {
            return BPA.api.response({response, return: 'json'});
          }).then(response => {
            this.loading = false;
            if (!response.ok || !response.result) return reject();
            resolve(response.result);
          }).catch(reject);
        }).catch(e => e);
      },
      async CreateAddressListItem(request = {}) {
        return await new Promise((resolve, reject) => {
          if (!Object.keys(request).length) return reject();
          BPA.api.AddItemToAddressBook(request).then(response => {
            return BPA.api.response({response});
          }).then(response => {
            this.loading = false;
            if (!response.ok) return reject();
            resolve(response);
          }).catch(reject);
        }).catch(e => e);
      },
      async GetAddressListItem(address_id) {
        return await new Promise((resolve, reject) => {
          if (!address_id) return reject();
          BPA.api.GetItemFromAddressBook(address_id).then(response => {
            return BPA.api.response({response, return: 'json'});
          }).then(response => {
            this.loading = false;
            if (!response.ok || !response.result) return reject();
            resolve(response.result);
          }).catch(reject);
        }).catch(e => e);
      },
      async EditAddressListItem(request = {}) {
        return await new Promise((resolve, reject) => {
          if (!Object.keys(request).length) return reject();
          BPA.api.UpdateItemInAddressBook(request).then(response => {
            return BPA.api.response({response});
          }).then(response => {
            this.loading = false;
            if (!response.ok) return reject();
            resolve(response);
          }).catch(reject);
        }).catch(e => e);
      },
      async DeleteAddressListItem(address_id) {
        return await new Promise((resolve, reject) => {
          if (!address_id) return reject();
          BPA.api.RemoveItemFromAddressBook(address_id).then(response => {
            return BPA.api.response({response});
          }).then(response => {
            this.loading = false;
            if (!response.ok) return reject();
            resolve(response);
          }).catch(reject);
        }).catch(e => e);
      },
      async SubmitAddress() {
        if (!await this.ValidateForm()) return;
        this.$eventHub.$emit('ValidateModalStart', {
          approve: 'Yes, create it',
          disapprove: 'No',
          message: 'Creates the current address.',
          type: 'success'
        });
        this.$eventHub.$on('ValidateModalStop', async (approve) => {
          this.$eventHub.$off('ValidateModalStop');
          if (!approve) return;
          this.loading = true;
          if (await this.CreateAddressListItem(this.form.data)) {
            await this.QueryAddresses();
          }
          this.$eventHub.$emit('CloseModal', 'modal');
          this.loading = false;
        });
      },
      async UpdateAddress() {
        if (!await this.ValidateForm()) return;
        this.$eventHub.$emit('ValidateModalStart', {
          approve: 'Yes, update it',
          disapprove: 'No',
          message: 'Updates the current address.',
          type: 'success'
        });
        this.$eventHub.$on('ValidateModalStop', async (approve) => {
          this.$eventHub.$off('ValidateModalStop');
          if (!approve) return;
          const data = this.CloneObject(this.form.data);
          const request = {address_id: data.id};
          delete data.id;
          request.body = data;
          this.loading = true;
          if (await this.EditAddressListItem(request)) {
            await this.QueryAddresses();
          }
          this.$eventHub.$emit('CloseModal', 'modal');
          this.loading = false;
        });
      },
      async DeleteAddress(address) {
        address = address || this.form.data || {};
        this.$eventHub.$emit('ValidateModalStart', {
          approve: 'Yes, delete it',
          disapprove: 'No',
          message: 'Deletes the current address.',
          type: 'danger'
        });
        this.$eventHub.$on('ValidateModalStop', async (approve) => {
          this.$eventHub.$off('ValidateModalStop');
          if (!approve) return;
          this.loading = true;
          if (await this.DeleteAddressListItem(address.id)) {
            await this.QueryAddresses();
          }
          if (this.modal.mode == 'edit') {
            this.$eventHub.$emit('CloseModal', 'modal');
          }
          this.loading = false;
        });
      },
      async CreateAddress() {
        await this.SetupAddressForm();
        this.OpenModal('create');
      },
      async ViewAddress() {
        this.modal.mode = 'view';
      },
      async EditAddress(address) {
        for (let i = 0; i < this.clickables.length; i++) {
          if (this.clickables[i].dataset.id == address.id) {
            this.clickables[i].classList.add('selected');
            if (this.cached.address && Object.keys(this.cached.address).length) {
              this.clickables[i].scrollIntoView({
                behavior: 'auto',
                block: 'center',
                inline: 'center'
              });
            }
            break;
          }
        }
        this.loading = true;
        this.address = await this.GetAddressListItem(address.id);
        await this.SetupAddressForm(this.address);
        this.loading = false;
        BPA.cache.session({name: this.$options.name, set: {address}});
        this.OpenModal('edit');
      },
      ClickOpen(event) {
        if (!event) return;
        let elm = event.target, row;
        if (elm.localName != 'tr') row = elm.closest('tr');
        const mousedown = (state) => {
          if (state === true || state === false) {
            row.mousedown = state;
          } else {
            return row.mousedown;
          }
        }
        for (let i = 0; i < this.clickables.length; i++) {
          if (this.clickables[i] != row) {
            this.clickables[i].classList.remove('hover', 'selected');
          }
        }
        if (elm.localName == 'a' || elm.parentElement.localName == 'a') return;
        if (event.type == 'mousedown') {
          mousedown(event.which == 1);
        }
        if (event.type == 'mousemove') {
          mousedown(false);
        }
        if (event.type == 'mouseup') {
          mousedown() && this.EditAddress(row.dataset);
          mousedown(false);
        }
      },
    }
  }
</script>

<style lang="scss" scoped>
  @import '../../../assets/style/variables/vital';

  .title {
    font-size: 1.622rem;
    font-weight: 600;
    margin-bottom: 35px;
    display: flex;
    align-items: flex-end;
  }

  .flex1 {
    //max-width: 627px;
    //max-width: calc(50% - 14px);
  }
</style>