<template>
  <div :for="name" class="image-upload">
		<div :class="['image-view', {'image-set': src}]" :style="[{'--image': src && `url(${src})`}]" :placeholder="placeholder">
			<input type="file" :name="name" title="" @input="change" :accept="accept || '.jpg, .jpeg, .png'" :disabled="disabled" />
		</div>
  </div>
</template>

<script>
	export default {
		name: 'ImageUpload',
		props: ['name', 'image', 'placeholder', 'accept', 'disabled'],
		data() {
			return {
				src: this.image
			}
		},
		watch: {
			image(url) {
				this.src = url;
			}
		},
		methods: {
			change(e) {
				let file = e.target.files[0];
				let reader = new FileReader();
				if (!file) return;
				reader.onload = (event) => {
					let base64 = event.target.result;
					window.fetch(base64).then(response => response.blob()).then(blob => URL.createObjectURL(blob)).then(url => this.url = url);
					this.$emit('change', {file, file_name: file.name,	opt: {}, data: base64});
				};
				reader.readAsDataURL(file);
			}
		}
	}
</script>

<style lang="scss" scoped>
	.image-upload {
		width: 200px;
		height: 200px;
		padding: 7px;
		display: flex;
		position: relative;
		flex-direction: column;
		border-radius: 4px;
		border: 1px solid rgba(60, 60, 60, 0.26);

		.label-text {
			cursor: default;
			user-select: none;
			position: relative;
		}

		.image-view {
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			position: relative;
			background-size: contain;
			background-repeat: no-repeat;
			background-position: center;

			&.image-set {
				background-image: var(--image);
			}

			&:before, 
			&:after {
				top: 0;
				left: 0;
				color: grey;
				width: 100%;
				height: 100%;
				display: flex;
				font-size: 2em;
				font-weight: bold;
				position: absolute;
				text-align: center;
				align-items: center;
				justify-content: center;
				background: lightgray;
			}

			&:not(.image-set):before {
				content: attr(placeholder);				
			}

			&:hover:after {
				content: '';
				background: transparent;
				pointer-events: none;
			}

			input[type=file] {
				top: 0;
				left: 0;
				margin: -7px;
				opacity: 0;
				display: block;
				cursor: pointer;
				width: calc(100% + 14px);
				height: calc(100% + 14px);
				position: absolute;
			}
		}
	}
</style>
