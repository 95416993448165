<template>
  <div class="wrapper">
    <div class="title">
      <h1>{{ $t('Deadlines') }}</h1>
    </div>
    <div class="grid-search">
      <input
        type="text"
        v-model="searchQuery"
        @input="QueryLists()"
        :placeholder="$t('Search by Shipping method')"
        spellcheck="false"
      />
    </div>
    <table class="list table odd-even">
      <tbody>
        <tr
          :data-id="deadline.id"
          :class="['config-list__row', { clickable: false }]"
          :key="deadline.id"
          v-for="deadline in formattedDeadlines"
        >
          <td>
            <div>{{ deadline.id }}</div>
          </td>
          <td class="tableShip">
            <div style="display: flex; align-items: center">
              <div :class="['courier-icon', deadline.courier]" />
              <span style="margin-left: 10px">{{
                deadline.shipping_description
              }}</span>
            </div>
          </td>
          <td>
            <div style="display: flex; align-items: center">
              <span
                style="margin-left: 5px"
                :key="day"
                v-for="day in deadline.days_mask"
                >{{ day }}</span
              >
            </div>
          </td>
          <td>
            <div style="display: flex; align-items: center">
              <span style="margin-left: 10px"
                >{{ deadline.deadline_hour }} :
                {{ deadline.deadline_minute }}</span
              >
            </div>
          </td>
          <td class="tableCour">
            <div style="display: flex; align-items: center">
              <div :class="['courier-icon', deadline.courier]" />
              <span style="margin-left: 10px">{{ deadline.courier }}</span>
            </div>
          </td>
          <td class="actions">
            <a class="icon dots" @click.prevent="" href="">
              <ul class="item-actions">
                <li>
                  <a @click.prevent="DeleteDeadline(deadline.id)">{{
                    $t('Delete')
                  }}</a>
                </li>
              </ul>
            </a>
          </td>
        </tr>
      </tbody>
      <thead>
        <tr>
          <th>{{ $t('ID') }}</th>
          <th>{{ $t('Shipping method') }}</th>
          <th>{{ $t('Week') }}</th>
          <th>{{ $t('Deadline') }}</th>
          <th>{{ $t('Courier') }}</th>
          <th class="edit" />
        </tr>
      </thead>
    </table>
    <div class="grid-pagination">
      <div class="page-navigation">
        <div
          class="page-turn prev disabled"
          @click="PageController(false)"
        ></div>
        <div class="page-number">
          <label class="page-number-current">
            <input
              type="number"
              min="1"
              :max="page.last"
              :value="page.current"
              @blur="PageNavigator"
              @keydown="PageNavigator"
            />
            <span class="placeholder">{{ NumberFormat(page.current) }}</span>
          </label>
          <span class="page-number-separator">/</span>
          <div class="page-number-last">{{ NumberFormat(page.last) }}</div>
        </div>
        <div
          class="page-turn next"
          :class="{ disabled: page.last == 1 }"
          @click="PageController(true)"
        ></div>
      </div>
    </div>
    <div class="loading" v-if="loading">
      <div class="loading-element">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  </div>
</template>
<script>
import { BPA } from '@/helpers/BPA';
import { Tool } from '@/helpers/Tool';

export default {
  name: 'DashboardListing',
  mixins: [BPA, Tool ],
  data() {
    return {
      deadlines: {},
      shippingOptions: {},
      formattedDeadlines: {},
      searchQuery: '',
      loading: false,
      page: {
        current: 1,
        number: 1,
        last: 1,
        order: {
          direction: 'desc',
          by: 'id',
        },
      },
    };
  },
  async mounted() {
    this.shippingOptions = await this.GetAllShippingOptions();

    this.QueryLists();
  },
  methods: {
    GetTotalPage(deadline_total) {
      return Math.ceil(deadline_total / 20);
    },
    async GetAllShippingOptions() {
      this.loading = true;
      return await new Promise((resolve, reject) => {
        BPA.api
          .GetShippingOptions()
          .then((response) => {
            return BPA.api.response({ response, return: 'json' });
          })
          .then((response) => {
            if (!response.ok || !response.result) return reject();

            this.loading = false;

            resolve(response.result);
          });
      });
    },
    FormatDeadlines(initialDeadlines, shippingOpt) {
      let formatDeadlines = initialDeadlines.items.map((deadlines) => {
        return {
          ...deadlines,
          courier: shippingOpt.find(
            (shippingOption) =>
              shippingOption.shipping_method === deadlines.shipping_description
          ).agent_code,
        };
      });

      formatDeadlines.forEach((arr) =>
        arr.deadline_hour < 10
          ? (arr.deadling_hour = arr.deadline_hour.toLocaleString('en-US', {
              minimumIntegerDigits: 2,
              useGrouping: false,
            }))
          : arr.deadline_hour
      );
      formatDeadlines.forEach((arr) =>
        arr.deadline_minute < 10
          ? (arr.deadline_minute = arr.deadline_minute.toLocaleString('en-US', {
              minimumIntegerDigits: 2,
              useGrouping: false,
            }))
          : arr.deadline_hour
      );

      this.formattedDeadlines = formatDeadlines;
    },
    async DeleteDeadline(deadline_id) {
      await this.$eventHub.$emit('ValidateModalStart', {
        approve: 'Yes, delete deadline',
        disapprove: 'No, keep deadline',
        message: 'Deletes current deadline permanently from system.',
        type: 'danger',
      });
      this.$eventHub.$on('ValidateModalStop', (approve) => {
        this.$eventHub.$off('ValidateModalStop');

        if (!approve) return;

        BPA.api
          .DeleteDeadline(deadline_id)
          .then((response) => {
            return BPA.api.response({ response });
          })
          .then((response) => {
            if (!response.ok) return;

            this.$eventHub.$emit('ShowMessages', {
              message: 'Deadline successfully deleted',
              type: 'success',
              hide: 2000,
            });

            this.formattedDeadlines = this.formattedDeadlines.filter(
              (deadline) => deadline.id !== deadline_id
            );
          })
          .catch((e) => e);
      });
    },
    NumberFormat(number) {
      return Tool.NumberFormat(number);
    },
    async QueryLists() {
      this.loading = true;
      await BPA.api
        .GetDeadlineLists({
          query: this.searchQuery || ' ',
          order_by: this.page.order.by,
          page_offset: this.page.current,
          order_direction: this.page.order.direction,
        })
        .then((response) => {
          return BPA.api.response({ response, return: 'json' });
        })
        .then((response) => {
          if (!response.ok || !response.result) return;
          let list = response.result;

          this.page.last = this.GetTotalPage(list.count) || 1;

          this.FormatDeadlines(list, this.shippingOptions);

          this.$nextTick().then(() => {
            this.SetPageJumpWidth();
          });
          this.loading = false;
        })
        .catch((e) => e);
    },
    PageController(next_page) {
      let load_page;
      const page = {};
      document.querySelectorAll('.page-turn').forEach((arrow) => {
        if (arrow)
          page[arrow.classList.contains('prev') ? 'prev' : 'next'] = arrow;
      });
      if (next_page) {
        if (this.page.current != this.page.last) {
          this.page.current++;
          load_page = true;
          if (this.page.current == this.page.last) {
            page.next.classList.add('disabled');
          }
          page.prev.classList.remove('disabled');
        }
      } else {
        if (this.page.current > 1) {
          this.page.current--;
          load_page = true;
          if (this.page.current == 1) {
            page.prev.classList.add('disabled');
          }
          page.next.classList.remove('disabled');
        }
      }
      if (load_page) {
        this.QueryLists();
      }
    },
    PageNavigator(e) {
      const page = {
        event: e.type,
        input: e.target,
        last: Number(e.target.max),
        first: Number(e.target.min),
        number: Number(e.target.value),
      };
      const within_limits = (n) => {
        return n >= page.first && n <= page.last;
      };
      const set_page_number = (n) => {
        this.page.number = n || page.number;
        page.number = Number(this.page.number);
      };
      document.querySelectorAll('.page-turn').forEach((arrow) => {
        if (arrow)
          page[arrow.classList.contains('prev') ? 'prev' : 'next'] = arrow;
      });
      set_page_number();
      if (page.event == 'keydown') {
        if (e.key == 'Enter') {
          page.input.blur();
        }
      }
      if (page.event == 'blur') {
        ['prev', 'next'].map((arrow) => {
          page[arrow] && page[arrow].classList.remove('disabled');
        });
        if (page.number <= page.first) {
          set_page_number(page.first);
          page.input.value = page.first;
          page.prev.classList.add('disabled');
          if (page.last == page.first) {
            page.next.classList.add('disabled');
          }
        } else if (page.number >= page.last) {
          set_page_number(page.last);
          page.input.value = page.last;
          page.next.classList.add('disabled');
          if (page.first == page.last) {
            page.prev.classList.add('disabled');
          }
        }
        if (within_limits(page.number) && page.number != this.page.current) {
          this.page.current = page.number;
          this.QueryLists();
        }
      }
    },
    SetPageJumpWidth() {
      const current = document.querySelector('.page-number-current');
      const last = document.querySelector('.page-number-last');
      if (current && last) {
        const rect = last.getBoundingClientRect();
        const input = current.querySelector('input');
        if (rect && rect.width) {
          current.style.setProperty('width', rect.width + 'px');
        } else {
          current.style.removeProperty('width');
        }
        if (input) {
          input.dispatchEvent(new Event('blur'));
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import 'src/assets/style/variables/icons.scss';
@import 'src/assets/style/variables/colors.scss';
@import 'src/assets/style/variables/fonts.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 500px;
}
.title {
  font-weight: 600;
  color: $lightFont;
  padding-bottom: 2px;
  padding-top: 4px;
}
.tableShip {
  min-width: 60%;
}
.tableCour {
  min-width: 30%;
}
.courier-icon {
  width: 30px;
  height: 30px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 50%;

  &.bring {
    background-image: $bring;
  }

  &.burd {
    background-image: $burd;
  }

  &.dao {
    background-image: $dao;
  }

  &.dhl {
    background-image: $dhl;
  }

  &.gls {
    background-image: $gls;
  }

  &.instabox {
    background-image: $instabox;
  }

  &.pdk {
    background-image: $pdk;
  }

  &.royalmail {
    background-image: $royalmail;
  }

  &.shop {
    background-image: $shop;
  }

  &.dfm {
    background-image: $dfm;
  }

  &.dpd {
    background-image: $dpd;
  }

  &.pat {
    background-image: $pat;
  }
}
.grid-search {
  width: 60%;
  position: relative;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 30px;
  background-color: $white;

  input:not(.vs__search):not([type='checkbox']) {
    width: 60%;
    border: none;
    color: $textFont;
    padding-left: 35px;
    box-sizing: border-box;
    outline: none;
    font-size: $f-l;

    &::placeholder {
      font-size: $f-l;
      color: darken($lightGrey, 20%);
    }

    &:not(.vs__search) {
      height: 35px;
      border-bottom: 2px solid $lightGrey;
    }
  }

  &:after {
    content: ' ';
    width: 30px;
    height: 30px;
    display: inline-block;
    background-image: url('../../../../assets/images/icons/search.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 70%;
    position: absolute;
    bottom: 5px;
    left: 0px;
  }
}
.grid-pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: $f-xl;
  line-height: 24px;
  user-select: none;
  bottom: 0;
  padding: 10px;
  position: sticky;
  margin-bottom: -30px;
  background-color: $white;
  //box-shadow: 0px -2px 0px 0px #eaeaea;
  box-shadow: 0px -2px 0px 0px lightgrey;
  /*
    &.shadow {
      margin-left: -10px;
      margin-right: -10px;
      box-shadow: 0 -8px 10px -10px rgba(0, 0, 0, 0.25);
    }
    */
  .current-page {
    margin-left: 10px;
    margin-right: 10px;
    line-height: 0px;
    top: 4px;
    position: relative;
  }

  .arrow {
    font-size: $f-xxxl;
    text-decoration: none;
    color: $textFont;

    > span {
      display: flex;
      justify-content: center;
      width: 20px;
    }
  }
}
.page-navigation {
  height: 24px;
  display: flex;
  position: sticky;
  align-items: center;
  justify-content: center;

  .page {
    &-turn {
      width: 24px;
      height: 24px;
      display: block;
      flex-shrink: 0;
      cursor: pointer;
      overflow: hidden;
      position: relative;
      border-radius: 50%;
      background-color: $blue;

      &:after {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        content: '';
        display: block;
        position: absolute;
        mask-size: 35%;
        mask-repeat: no-repeat;
        mask-position: center;
        background-color: $white;
      }
      &.prev {
        &:after {
          mask-image: $chevron-left;
        }
      }

      &.next {
        &:after {
          mask-image: $chevron-right;
        }
      }

      &.disabled {
        cursor: default;
        background-color: lightgrey;

        &:after {
          background-color: $textFont;
        }
      }
    }

    &-number {
      height: 24px;
      display: flex;
      flex-shrink: 0;
      padding: 0 20px;
      align-items: center;
      justify-content: center;

      &-current input,
      &-current input ~ .placeholder,
      &-last {
        color: inherit;
        font-size: inherit;
        font-family: inherit;
      }

      &-current {
        height: 24px;
        text-align: center;
        position: relative;

        input {
          border: 0;
          width: 100%;
          height: 24px;
          outline: none;
          appearance: none;
          text-align: inherit;
          color: transparent;
          margin-bottom: -2px;
          position: relative;
          -moz-appearance: textfield;
          border-bottom: 2px solid $lightGrey;
          transition: border 0.075s ease-in-out;

          &:focus {
            color: $textFont;
            border-color: $blue;

            & ~ .placeholder {
              color: transparent;
            }
          }

          &::placeholder {
            color: $lightGrey;
          }

          &::-webkit-outer-spin-button,
          &::-webkit-inner-spin-button {
            appearance: none;
            margin: 0;
          }
        }

        .placeholder {
          right: 0px;
          width: 100%;
          height: 24px;
          cursor: text;
          text-align: inherit;
          user-select: none;
          position: absolute;
          pointer-events: none;
        }
      }

      &-separator {
        width: 30px;
        text-align: center;
        flex-shrink: 0;
      }
    }
  }
}
</style>
